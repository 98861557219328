export default function (state, payload) {
  const uploads = [...state.uploads];
  const uploadIndex = uploads.findIndex((u) => u.id === payload.id);

  uploads[uploadIndex] = {
    ...uploads[uploadIndex],
    ...payload,
  };

  state.uploads = uploads;
}

<template>
  <div class="flex items-start">
    <div
      class="w-50 min-h-30 border border-solid"
      :class="[
        {
          'leading-0': !file.deleted,
          'bg-white-8': !file.deleted,
          'border-white-4': !file.deleted,
          'bg-red-5': file.deleted,
          'border-red-1': file.deleted,
        },
      ]"
    >
      <div v-if="file.deleted" class="w-full h-full flex items-center justify-center text-center px-2">
        {{ $t('record.video_deleted') }}
      </div>

      <img v-else-if="thumbnailUrl" :src="thumbnailUrl" class="max-w-full max-h-full" alt="Clip thumbnail" />
    </div>

    <div class="ml-6">
      <h3 class="text-base text-white-1">{{ $t('editor.removed_at') }}</h3>
      <p>{{ $d(new Date(removedAt), 'long') }}</p>

      <h4 class="text-white-1 mt-2">{{ $t('editor.clip_length') }}</h4>
      <p>{{ formattedLength }}</p>

      <Button class="p-0 mt-4" color="btn-link-white-1" :label="$t('editor.restore_clip')" @click="restore">
        <template #icon>
          <RestoreSVG class="h-5 w-5 mr-2" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import RestoreSVG from '@/assets/svgs/restore.svg';

import { formatSeconds } from '@/helpers';

export default {
  name: 'EditorTrashClip',
  components: { Button, RestoreSVG },
  props: {
    clip: {
      type: Object,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    removedAt: {
      type: String,
      required: true,
    },
  },
  computed: {
    thumbnailUrl() {
      if (!this.clip.frontend || !this.clip.frontend.thumbnail_url) {
        return null;
      }

      return this.clip.frontend.thumbnail_url;
    },
    formattedLength() {
      return formatSeconds(this.clip[this.clip.type].duration_ms / 1000);
    },
  },
  methods: {
    restore() {
      this.$emit('restore');
    },
  },
};
</script>

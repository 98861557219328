import requestFileSystemDirectory from './request_file_system_directory';
import readEntries from './read_entries';

export default function (token) {
  return new Promise((resolve, reject) => {
    requestFileSystemDirectory(token).then((dirEntry) => {
      const dirReader = dirEntry.createReader();

      readEntries(dirReader)
        .then((entries) => {
          const dirs = entries.filter((e) => e.isDirectory);
          const names = dirs.map((d) => d.name);

          resolve(names);
        })
        .catch((error) => reject(error));
    });
  });
}

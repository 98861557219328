export default function () {
  return {
    id: null,

    recorder_token: '',
    speaker_id: null,
    upload_id: null,
    has_local_data: true,

    version_name: '',
    created_at: null,
    updated_at: null,

    post_production_note: '',
    links: [],

    recordings: [],
    files: [],
    timelines: {
      speaker: {
        video: [],
        audio: [],
      },

      slides: {
        video: [],
        audio: [],
      },
    },

    manually_edited: false,

    editor: {
      removed_clips: [],
      history: [], // [{ duration, timelines, removed_clips }]
      history_index: -1,
    },

    duration: 0,

    recording_in_progress: null,

    feedback: {
      value: null,
      note: '',
      uuid: null,
    },

    version: 4,
  };
}

<template>
  <Upload
    v-model="computedValue"
    :multiple="multiple"
    :disabled="disabled"
    :readOnly="readOnly"
    :accept="accept"
    :attachmentType="attachmentType"
    dragDrop
    tag="div"
    class="rounded-lg min-h-50"
    #default="{ triggerClick, triggerDelete }"
  >
    <div class="h-full flex flex-column items-center justify-center p-4">
      <p v-if="!readOnly && !disabled && title && !isFileSelected" class="text-xl text-white-1 font-bold mb-3">
        {{ title }}
      </p>

      <p v-if="!readOnly && !disabled && message && !isFileSelected" class="font-light mb-3">
        {{ message }}
      </p>

      <div v-if="isFileSelected">
        <SelectedTag
          v-for="(file, index) in newValue"
          :key="index"
          class="my-1"
          :label="(file && file.file_name) || '<unknown file>'"
          :disabled="disabled || readOnly"
          @delete="triggerDelete(index)"
          @click.stop
        />
      </div>

      <Button
        v-if="!readOnly && !disabled && (multiple || !isFileSelected)"
        type="button"
        class="w-7/8 max-w-80 mt-3"
        color="btn-primary-outline"
        :label="selectButtonText || $t('select')"
        @click.stop="triggerClick"
      />
    </div>
  </Upload>
</template>

<script>
import Upload from '@/components/Upload.vue';
import Button from '@/components/Button.vue';
import SelectedTag from '@/components/SelectedTag.vue';

export default {
  name: 'FilesDragDrop',
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  components: { Upload, Button, SelectedTag },
  props: {
    customValue: {
      type: [Object, Array],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    nothingSelectedText: {
      type: String,
      default: null,
    },
    selectButtonText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    attachmentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newValue: this.customValue,
    };
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('customInput', value);
      },
    },
    isFileSelected() {
      return this.newValue && this.newValue.length > 0;
    },
  },
};
</script>

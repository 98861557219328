import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }, { order }) {
  const requestData = {
    speakers_token: state.speakersToken,
    speakers_order: order,
  };

  Logger.log(`Update speakers order, order: ${JSON.stringify(order)}`);

  return makeRequest('POST', `${API_URL}/tokens/${state.tokenId}/speakers/order`, requestData).then((response) => {
    commit('setKey', { key: 'speakers', speakers: response.data });
  });
}

export default function (locale = 'en') {
  const now = new Date();
  const hourOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const dateOptions = {
    day: 'numeric',
    month: locale === 'en' ? 'short' : 'long',
  };

  const hour = new Intl.DateTimeFormat(locale, hourOptions);
  const date = new Intl.DateTimeFormat(locale, dateOptions);

  return `${hour.format(now)}, ${date.format(now)}`;
}

<template>
  <li class="flex items-center w-full relative" :class="sortableClass">
    <Position
      v-if="!hideNumber"
      :class="[
        'mr-6',
        {
          'absolute': floatingNumber,
          'right-full': floatingNumber,
        },
      ]"
      number="css"
    />
    <slot />
  </li>
</template>

<script>
import Position from '@/components/Position.vue';

export default {
  name: 'OrderedListItem',
  components: { Position },
  props: {
    hideNumber: {
      type: Boolean,
      default: false,
    },
    floatingNumber: {
      type: Boolean,
      default: true,
    },
    sortableClass: {
      type: String,
      required: true,
    },
  },
};
</script>

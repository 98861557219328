import Logger from '@/modules/logger';

export default function ({ commit, state, getters }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;

    let newData = {
      id: version.id,
      editor: {
        ...version.editor,
        history: [...version.editor.history],
        history_index: version.editor.history_index + 1,
      },
      manually_edited: false,
    };

    newData.editor.history.push({
      duration: version.duration,
      removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
      timelines: JSON.parse(JSON.stringify(version.timelines)),
      manually_edited: version.manually_edited,
    });

    const history = JSON.parse(JSON.stringify(version.editor.history[0]));
    newData = {
      ...newData,
      duration: history.duration,
      timelines: history.timelines,
      editor: {
        ...version.editor,
        ...newData.editor,
        removed_clips: history.removed_clips,
      },
    };

    if (state.player.elapsed > history.duration) {
      commit('player/setPlayerKey', { key: 'elapsed', elapsed: history.duration }, { root: true });
    }

    Logger.log('Editor reset, timelines:', newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

<template>
  <ContainerWithHeaderAndBack>
    <div class="pt-14 pb-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('about.title') }}</h1>

      <p class="container-sm text-center mt-3">{{ $t('about.message') }}</p>

      <div class="container-xl mt-12">
        <div class="flex flex-column lg:flex-row w-full">
          <Recommendations class="flex-1" />

          <div class="max-w-150 lg:max-w-full w-full mx-auto flex-1 mt-8 lg:mt-0 lg:ml-8">
            <AboutVideo />

            <div class="text-right mt-4">
              <a :href="$t('about.pdf_link')" target="_blank" rel="noopener noreferrer">
                {{ $t('about.pdf') }}
              </a>
            </div>
          </div>
        </div>

        <div v-if="!hideActions" class="text-center mt-6">
          <router-link :to="{ name: 'Setup' }" custom #default="{ href, navigate }">
            <Button
              :href="href"
              :label="$t('about.continue_to_setup')"
              class="w-80 mx-auto no-underline hover:no-underline"
              block
              @click="navigate"
            />
          </router-link>

          <router-link :to="{ name: 'SelfRecording' }" custom #default="{ href, navigate }">
            <a :href="href" class="inline-block mt-4" @click="navigate">
              {{ $t('about.self_recording') }}
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import Recommendations from '@/components/Recommendations.vue';
import AboutVideo from '@/components/AboutVideo.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'About',
  components: { ContainerWithHeaderAndBack, Recommendations, AboutVideo, Button },
  computed: {
    hideActions() {
      return this.$route.params.hideActions;
    },
  },
};
</script>

<template>
  <div class="flex items-center bg-white-9 rounded py-1 mt-1 hover:bg-white-8">
    <div class="flex-1 px-2 text-left">
      {{ token }}
    </div>

    <div class="flex-none w-25 text-center px-2">
      <router-link :to="{ name: 'Welcome', query: { token } }" custom #default="{ href, navigate }">
        <Tooltip>
          <template #trigger>
            <Button
              disableMinHeight
              :href="href"
              :rounded="false"
              color="btn-transparent-white"
              class="p-0 rounded-full w-8 h-8"
              @click="navigate"
            >
              <template #icon>
                <OpenSVG class="fill-white-1 w-5 h-5" />
              </template>
            </Button>
          </template>

          <template #default>
            {{ $t('recorded_tokens.open') }}
          </template>
        </Tooltip>
      </router-link>

      <Tooltip>
        <template #trigger>
          <Button
            disableMinHeight
            :rounded="false"
            color="btn-transparent-white"
            class="p-0 rounded-full w-8 h-8 ml-2"
            @click="triggerDelete"
          >
            <template #icon>
              <TrashSVG class="fill-red-1 w-5 h-5" />
            </template>
          </Button>
        </template>

        <template #default>
          {{ $t('recorded_tokens.delete') }}
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import TrashSVG from '@/assets/svgs/trash.svg';
import OpenSVG from '@/assets/svgs/open.svg';

export default {
  name: 'RecordedTokensRow',
  components: { Button, Tooltip, TrashSVG, OpenSVG },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    triggerDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<template>
  <div class="flex items-stretch justify-center lg:justify-end">
    <Button v-if="!isUploaded" class="ml-2 w-84" color="btn-primary-outline" :disabled="disabled" @click="backup">
      <template #default>
        <div class="flex flex-column justify-center text-left ml-2">
          <span class="font-bold">{{ $t('record.create_cloud_backup.label') }}</span>
          <span class="text-xs flex items-center">
            {{ $t('record.create_cloud_backup.sublabel') }}

            <Tooltip iconSize="small" :tippyOptions="{ placement: 'bottom' }" @click.stop.prevent>
              {{ $t('record.create_cloud_backup.tooltip') }}
            </Tooltip>
          </span>
        </div>
      </template>

      <template #icon>
        <CloudUploadSVG class="w-6 h-6" />
      </template>
    </Button>

    <Button class="ml-2 w-100" :disabled="disabled" @click="submit">
      <template #default>
        <div class="flex flex-column justify-center text-left ml-2">
          <span class="font-bold">{{ $t('record.submit_as_final.label') }}</span>
          <span class="text-xs">{{ $t('record.submit_as_final.sublabel') }}</span>
        </div>
      </template>

      <template #icon>
        <SendSVG class="w-6 h-6" />
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import CloudUploadSVG from '@/assets/svgs/cloud_upload.svg';
import SendSVG from '@/assets/svgs/send.svg';

export default {
  name: 'RecordUploadActions',
  components: { Button, Tooltip, CloudUploadSVG, SendSVG },
  props: {
    isUploaded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    backup() {
      this.$emit('backup');
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<template>
  <VueMarkdownPlus
    :html="false"
    :anchorAttributes="anchorAttributes"
    :source="$t(`help.faq.items[${index}].answer`, contentAttributes)"
  />
</template>

<script>
import VueMarkdownPlus from 'vue-markdown-plus';

import ReportImage from '@/assets/report.jpg';

export default {
  name: 'FaqAnswer',
  components: { VueMarkdownPlus },
  props: {
    index: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
  },
  computed: {
    anchorAttributes() {
      return {
        target: '_blank',
      };
    },
    contentAttributes() {
      return {
        email: this.email,
        reportImage: ReportImage,
        createBackupLabel: this.$i18n.t('record.create_cloud_backup.label'),
        submitFinalLabel: this.$i18n.t('record.submit_as_final.label'),
        selfRecordLabel: this.$i18n.t('about.self_recording'),
      };
    },
  },
};
</script>

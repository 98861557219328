import 'audioworklet-polyfill';

class VolumeMeterNode extends AudioWorkletNode {
  constructor(context, updateIntervalInMS) {
    super(context, 'vumeter', {
      numberOfInputs: 1,
      numberOfOutputs: 0,
      channelCount: 1,
      processorOptions: {
        updateIntervalInMS: updateIntervalInMS || 16.67,
      },
    });

    this._updateIntervalInMS = updateIntervalInMS;
    this._volume = 0;

    this.port.onmessage = (event) => {
      if (event.data.volume) {
        this._volume = event.data.volume;
      }
    };

    this.port.start();
  }

  get sensibleVolume() {
    return (this._volume * 100) / 5;
  }

  get updateInterval() {
    return this._updateIntervalInMS;
  }

  set updateInterval(updateIntervalInMS) {
    this._updateIntervalInMS = updateIntervalInMS;
    this.port.postMessage({ updateIntervalInMS });
  }
}

export default VolumeMeterNode;

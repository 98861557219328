import Logger from '@/modules/logger';

import { deleteVersion } from '@/helpers';

export default function ({ commit, dispatch, state, getters }, { id: idToDelete }) {
  Logger.log(`Delete version, id: ${idToDelete}`);

  const doDelete = () => {
    const versionToRemove = state.versions[idToDelete];

    return deleteVersion(state.versions, versionToRemove).then(() => {
      const versions = Object.keys(state.versions).reduce((r, id) => {
        if (id !== idToDelete) {
          r[id] = state.versions[id];
        }

        return r;
      }, {});

      commit('setKey', { key: 'versions', versions });

      if (versionToRemove.upload_id) {
        return dispatch('deleteUpload', { id: versionToRemove.upload_id });
      }

      return true;
    });
  };

  if (idToDelete !== state.currentVersionId) {
    return doDelete();
  }

  const keepVersions = getters.sortedVersions.filter((v) => v.id !== idToDelete);
  const newCurrentVersion = keepVersions.find((v) => v.has_local_data);

  if (!newCurrentVersion) {
    return dispatch('addVersion').then((version) => {
      commit('setKey', { key: 'currentVersionId', currentVersionId: version.id });

      return doDelete();
    });
  }

  commit('setKey', {
    key: 'currentVersionId',
    currentVersionId: newCurrentVersion.id,
  });

  return doDelete();
}

<template>
  <div class="text-center m-auto py-4 px-6">
    <template v-if="!sent">
      <h1 class="container-md text-white-1 text-2xl">{{ $t('thank_you.feedback.title') }}</h1>

      <p class="container-sm font-light text-center mt-3">
        {{ $t('thank_you.feedback.message') }}
      </p>

      <Feedback class="mt-6" :isSelfRecording="isSelfRecording" @sent="feedbackSent" />
    </template>

    <template v-else>
      <h1 class="container-md text-white-1 text-2xl">{{ $t('thank_you.title') }}</h1>

      <Button
        v-if="!isSelfRecording"
        :loading="!upload"
        :label="$t('thank_you.open_share_link')"
        block
        class="w-80 mt-9 mx-auto"
        @click="redirectToSharePage"
      />
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Feedback from '@/components/Feedback.vue';
import Button from '@/components/Button.vue';

import { parseUrlParams } from '@/helpers';

export default {
  name: 'ThankYou',
  components: { Feedback, Button },
  props: {
    isSelfRecording: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sent: false,
    };
  },
  methods: {
    ...mapMutations(['setKey']),
    feedbackSent() {
      this.sent = true;
    },
    redirectToSharePage() {
      const params = parseUrlParams(this.upload.share_link_url);

      this.setKey({ key: 'isUploadVisible', isUploadVisible: false });
      this.$router.replace({ name: 'Share', query: params });
    },
  },
};
</script>

export default function (state, payload) {
  state.tokenId = payload.id;

  state.recorderToken = payload.recorder_token;

  state.supportEmail = payload.support_email;

  state.conferenceName = payload.conference_name;
  state.presentationTitle = payload.presentation_title;
  state.presentationDurationS = payload.presentation_duration_s;

  if (!payload.share_page) {
    state.speakersToken = payload.speakers_token;

    state.deadlineAt = payload.first_deadline_at;

    state.multiplePartsRecordingEnabled = payload.multiple_parts_recording_enabled;
    state.allowSelfUploadWithOneFile = payload.allow_self_upload_with_one_file;
    state.allowUploadWithoutAttachedFiles = payload.allow_upload_without_attached_slides;

    state.speakers = payload.speakers;
    state.uploads = payload.uploads;

    state.speakersInitialized = payload.speakers.length > 0;
  }
}

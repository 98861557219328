<template>
  <Modal size="small" :active="active" @close="cancel">
    <div class="text-center px-4 py-8">
      <h2 class="text-2xl text-white-1">{{ $t('permission_modal.storage.title') }}</h2>

      <p class="font-light mt-3">{{ $t('permission_modal.storage.message') }}</p>

      <div class="mt-10">
        <Button :label="$t('permission_modal.approve')" @click="confirm" />

        <Button class="ml-4" color="btn-primary-outline" :label="$t('permission_modal.decline')" @click="cancel" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'StoragePermissionModal',
  components: { Modal, Button },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

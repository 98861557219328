import isServer from './is_server';
import validate from './validate';
import elementPropName from './element_prop_name';

export default function (name, listeners, handlerValidator, el, binding, vNode) {
  if (!validate(name, binding) || isServer(vNode)) {
    return;
  }

  const propName = elementPropName(name);

  function handler(e) {
    if (!vNode.context) {
      return;
    }

    if (!handlerValidator(el, e)) {
      return;
    }

    el[propName].callback(e);
  }

  el[propName] = {
    handler,
    callback: binding.value,
  };

  listeners.forEach((listener) => {
    document.addEventListener(listener, handler);
  });
}

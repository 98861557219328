<template>
  <component
    :is="tagName"
    class="relative items-center py-1 focus:z-1"
    :class="[
      color,
      {
        'rounded': rounded,
        'flex': block,
        'inline-flex': !block,
      },
      ...classes,
    ]"
    :disabled="disabled || loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="iconPosition === 'left'" name="icon" :size-class="iconClasses" />

    <div v-if="label" class="flex flex-column justify-center">
      <span class="font-bold">{{ label }}</span>

      <slot v-if="$slots.default" />
    </div>

    <slot v-else-if="$slots.default" />

    <slot v-if="iconPosition === 'right'" name="icon" :size-class="iconClasses" />

    <div v-if="loading" class="flex items-center justify-center absolute inset-0 bg-black-5">
      <Spinner size="small" />
    </div>
  </component>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Button',
  components: { Spinner },
  props: {
    label: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    align: {
      type: String,
      default: 'center',
    },
    color: {
      type: String,
      default: 'btn-primary',
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['a', 'button'].includes(value);
      },
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    iconSize: {
      type: String,
      default: 'auto',
      validate: (value) => [null, 'auto'].includes(value),
    },
    disableMinHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagName() {
      if (this.$attrs.disabled !== undefined && this.$attrs.disabled !== false) {
        return 'button';
      }

      if (this.$attrs.href) {
        return 'a';
      }

      return this.tag;
    },
    setMinHeight() {
      return (!!this.label || !!this.$slots.default) && !this.disableMinHeight;
    },
    classes() {
      const result = [];

      if (this.align === 'center') {
        result.push('justify-center');
        result.push('text-center');
      } else if (this.align === 'right') {
        result.push('justify-end');
        result.push('text-right');
      } else if (this.align === 'left') {
        result.push('justify-start');
        result.push('text-left');
      }

      if (this.color.includes('link')) {
        result.push('border-0');
        result.push('hover:underline');

        return result;
      }

      result.push('border-2');
      result.push('border-solid');

      if (this.size === 'small') {
        if (this.setMinHeight) {
          result.push('min-h-9');
        }

        result.push('px-3');
        result.push('text-sm');
      } else if (this.size === 'medium') {
        if (this.setMinHeight) {
          result.push('min-h-13');
        }

        result.push('px-5');
        result.push('text-base');
      } else if (this.size === 'large') {
        if (this.setMinHeight) {
          result.push('min-h-15');
        }

        result.push('px-7');
        result.push('text-base');
      }

      return result;
    },
    iconClasses() {
      const result = [];

      if (this.iconSize !== 'auto') {
        return result;
      }

      if (this.iconPosition === 'left') {
        result.push('mr-2');
      } else {
        result.push('ml-2');
      }

      switch (this.size) {
        case 'small': {
          result.push('w-3');
          result.push('h-3');
          break;
        }

        case 'large': {
          result.push('w-5');
          result.push('h-5');
          break;
        }

        default: {
          result.push('w-4');
          result.push('h-4');
        }
      }

      return result;
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

import initialVersion from '@/store/initial_version';

import pad from './pad';
import migrations from './local_version_state_migrations';

export default function (data) {
  const INITIAL_VERSION = initialVersion();

  if (!data.version) {
    data.version = 1;
  }

  while (data.version < INITIAL_VERSION.version) {
    const migrationFunc = `v${pad(data.version)}`;

    if (migrations[migrationFunc]) {
      data = migrations[migrationFunc](data);
    }

    data.version++;
  }

  return data;
}

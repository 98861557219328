<template>
  <div>
    <Dropdown :disabled="isUploading || isRecording" class="min-w-50">
      <template #default="{ open, toggle }">
        <Button
          @click="toggle"
          :rounded="false"
          :disabled="isUploading || isRecording"
          color="btn-transparent-white"
          size="small"
          align="left"
          class="rounded-lg w-full"
          iconPosition="right"
        >
          <div class="flex-1 flex items-center justify-between">
            <SpeakerNameState :name="speaker.name" :isSubmitted="isSubmitted" :isUploading="isUploading" class="mr-3" />

            <Position v-if="showPosition" :number="speakerIndex + 1" :label="$t('speaker')" size="small" />
          </div>

          <template v-if="!isUploading" #icon>
            <ChevronUpSVG v-if="open" class="ml-2 w-4 h-4" />

            <ChevronDownSVG v-else class="ml-2 w-4 h-4" />
          </template>
        </Button>
      </template>

      <template #items>
        <div class="p-2">
          <Button
            :label="$t('header.user_menu.rename_speaker')"
            color="btn-link-white"
            :class="linkClasses"
            block
            align="left"
            @click="showSpeakerRenameModal"
          />

          <router-link v-if="showSpeakers" :to="{ name: 'Speakers' }" custom #default="{ href, navigate }">
            <a :href="href" :class="linkClasses" @click="navigate">
              {{ $t('header.user_menu.speakers') }}
            </a>
          </router-link>

          <router-link v-if="showRecorder" :to="{ name: 'Setup' }" custom #default="{ href, navigate }">
            <a :href="href" :class="linkClasses" @click="navigate">
              {{ $t('header.user_menu.recording') }}
            </a>
          </router-link>

          <a v-if="showShare" :href="submittedUpload.share_link_url" :class="linkClasses">
            {{ $t('header.user_menu.share') }}
          </a>

          <router-link
            v-if="showAbout"
            :to="{ name: 'About', params: { hideActions: true } }"
            custom
            #default="{ href, navigate }"
          >
            <a :href="href" :class="linkClasses" @click="navigate">
              {{ $t('header.user_menu.about') }}
            </a>
          </router-link>

          <router-link v-if="showHelp" :to="{ name: 'Help' }" custom #default="{ href, navigate }">
            <a :href="href" :class="linkClasses" @click="navigate">
              {{ $t('header.user_menu.help') }}
            </a>
          </router-link>

          <router-link :to="{ path: '/logout' }" custom #default="{ href, navigate }">
            <a :href="href" :class="linkClasses" @click="navigate">
              {{ $t('header.user_menu.logout') }}
            </a>
          </router-link>

          <LanguageSelect class="w-full mt-1" />

          <Button
            :label="$t('header.user_menu.download_all_versions')"
            color="btn-link-white"
            :class="linkClasses"
            block
            align="left"
            @click="downloadAllLocalVersions"
          />

          <Button
            :label="$t('header.user_menu.local_data_link')"
            color="btn-link-white"
            :class="linkClasses"
            block
            align="left"
            @click="showLocalDataLinkModal"
          />
        </div>
      </template>
    </Dropdown>

    <SpeakerRenameModal :active="speakerRenameModalActive" @close="hideSpeakerRenameModal" />
    <LocalDataLinkModal :active="localDataLinkModalActive" @close="hideLocalDataLinkModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import SpeakerNameState from '@/components/SpeakerNameState.vue';
import Position from '@/components/Position.vue';
import LanguageSelect from '@/components/LanguageSelect.vue';
import SpeakerRenameModal from '@/components/SpeakerRenameModal.vue';
import LocalDataLinkModal from '@/components/LocalDataLinkModal.vue';

import ChevronUpSVG from '@/assets/svgs/chevron_up.svg';
import ChevronDownSVG from '@/assets/svgs/chevron_down.svg';

export default {
  name: 'HeaderUser',
  components: {
    Dropdown,
    Button,
    SpeakerNameState,
    Position,
    LanguageSelect,
    SpeakerRenameModal,
    LocalDataLinkModal,
    ChevronUpSVG,
    ChevronDownSVG,
  },
  data() {
    return {
      speakerRenameModalActive: false,
      localDataLinkModalActive: false,
    };
  },
  computed: {
    ...mapState(['registerSpeaker', 'speakers', 'loggedInSpeakerId', 'isRecording', 'isUploading', 'uploads']),
    speakerIndex() {
      if (this.registerSpeaker) {
        return null;
      }

      return this.speakers.findIndex((s) => s.id === this.loggedInSpeakerId);
    },
    speaker() {
      return this.registerSpeaker || this.speakers[this.speakerIndex];
    },
    submittedUpload() {
      if (!this.isSubmitted) {
        return null;
      }

      return this.uploads.find((u) => u.speaker_id === this.loggedInSpeakerId && u.final_recording);
    },
    isSubmitted() {
      if (this.registerSpeaker || this.isUploading) {
        return false;
      }

      const speaker = this.speakers.find((s) => s.id === this.loggedInSpeakerId);

      return !speaker.recording_enabled && speaker.disabled_recording_reason === 'final_recording_submitted';
    },
    isNewSpeaker() {
      return this.speakerIndex === null;
    },
    showPosition() {
      return this.speakerIndex !== null && this.speakers.length > 1;
    },
    showSpeakers() {
      return !this.isSubmitted && !this.isNewSpeaker && !['Speakers', 'SpeakersOrder'].includes(this.$route.name);
    },
    showRecorder() {
      return !this.isSubmitted && !this.isNewSpeaker && !['Setup', 'Record'].includes(this.$route.name);
    },
    showAbout() {
      return !this.isSubmitted && !this.isNewSpeaker && this.$route.name !== 'About';
    },
    showHelp() {
      return !this.isNewSpeaker && this.$route.name !== 'Help';
    },
    showShare() {
      return !this.isNewSpeaker && this.isSubmitted && !!this.submittedUpload;
    },
    linkClasses() {
      return [
        'text-white-1',
        'block',
        'text-sm',
        'px-2',
        'py-1',
        'mt-1',
        'w-full',
        'rounded',
        'no-underline',
        'hover:underline',
        'hover:bg-white-7',
        'focus:outline-white',
      ];
    },
  },
  methods: {
    ...mapActions(['downloadAllLocalVersions']),
    showSpeakerRenameModal() {
      this.speakerRenameModalActive = true;
    },
    hideSpeakerRenameModal() {
      this.speakerRenameModalActive = false;
    },
    showLocalDataLinkModal() {
      this.localDataLinkModalActive = true;
    },
    hideLocalDataLinkModal() {
      this.localDataLinkModalActive = false;
    },
  },
};
</script>

export default function (method, url, data = null) {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  const options = {
    method,
    cache: 'no-cache',
    credentials: 'omit',
    headers,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    options.body = JSON.stringify(data);
  }

  return fetch(url, options)
    .then((response) => Promise.all([response.status, response.json()]))
    .then(([status, response]) => {
      if (status < 200 || status >= 400 || !response.success) {
        let error;
        let errorMessages = [];
        if (response.code) {
          error = new Error(response.code);
        } else {
          error = new Error('unknown_server_error');
        }

        if (response.errors) {
          errorMessages = errorMessages.concat(response.errors);
        }

        if (response.error) {
          errorMessages.push(response.error);
        }

        error.customMessage = errorMessages.join(' ');
        error.statusCode = status;

        throw error;
      }

      return response;
    });
}

<template>
  <div class="flex items-center justify-between w-full">
    <div class="flex items-stretch">
      <Tooltip>
        <template #trigger>
          <Button
            color="btn-white-white"
            size="small"
            :disabled="editorHistoryIndex < 0"
            :label="$t('editor.undo')"
            @click="undo"
          >
            <template #icon>
              <UndoSVG class="w-6 h-6 mr-2" />
            </template>
          </Button>
        </template>

        <template #default>
          <span v-html="$t('editor.undo_tooltip')" />
        </template>
      </Tooltip>

      <Tooltip>
        <template #trigger>
          <Button
            color="btn-white-white"
            size="small"
            class="ml-2"
            :disabled="editorHistoryIndex === editorHistory.length - 1"
            :label="$t('editor.redo')"
            @click="redo"
          >
            <template #icon>
              <RedoSVG class="w-6 h-6 mr-2" />
            </template>
          </Button>
        </template>

        <template #default>
          <span v-html="$t('editor.redo_tooltip')" />
        </template>
      </Tooltip>
    </div>

    <PlayerProgressTime class="flex-none text-white-1 w-34 px-2 text-center" :elapsed="elapsed" :duration="duration" />

    <div class="flex items-stretch">
      <Tooltip>
        <template #trigger>
          <Button
            color="btn-white-white"
            size="small"
            :label="$t('editor.zoom_out')"
            :disabled="isZoomOutDisabled"
            @mousedown="handleStartZoomLongPress($event, false)"
            @touchstart.prevent="handleStartZoomLongPress($event, false)"
            @click="handleZoomClick($event, false)"
            @mouseup="handleStopZoomLongPress"
            @mouseleave="handleStopZoomLongPress"
            @touchend="handleStopZoomLongPress"
            @touchcancel="handleStopZoomLongPress"
            @keydown.enter.space.prevent="handleStartZoomLongPress($event, false)"
            @keyup.enter.space.prevent="handleStopZoomLongPress"
            @focusout="handleStopZoomLongPress"
          >
            <template #icon>
              <ZoomOutSVG class="w-6 h-6 mr-2" />
            </template>
          </Button>
        </template>

        <template #default>
          <span v-html="$t('editor.zoom_out_tooltip')" />
        </template>
      </Tooltip>

      <Tooltip>
        <template #trigger>
          <Button
            color="btn-white-white"
            size="small"
            class="ml-2"
            :label="$t('editor.zoom_in')"
            :disabled="isZoomInDisabled"
            @mousedown="handleStartZoomLongPress($event, true)"
            @touchstart.prevent="handleStartZoomLongPress($event, true)"
            @click="handleZoomClick($event, true)"
            @mouseup="handleStopZoomLongPress"
            @mouseleave="handleStopZoomLongPress"
            @touchend="handleStopZoomLongPress"
            @touchcancel="handleStopZoomLongPress"
            @keydown.enter.space.prevent="handleStartZoomLongPress($event, true)"
            @keyup.enter.space.prevent="handleStopZoomLongPress"
            @focusout="handleStopZoomLongPress"
          >
            <template #icon>
              <ZoomInSVG class="w-6 h-6 mr-2" />
            </template>
          </Button>
        </template>

        <template #default>
          <span v-html="$t('editor.zoom_in_tooltip')" />
        </template>
      </Tooltip>

      <Button
        class="ml-2"
        color="btn-white-white"
        size="small"
        :label="$t('editor.fit_to_screen')"
        @click="fitToScreen"
      >
        <template #icon>
          <FitToArrowsSVG class="w-6 h-6 mr-2" />
        </template>
      </Button>

      <Dropdown class="ml-2 relative -top-1" itemsUnderTrigger dark contentClass="">
        <template #default="{ toggle }">
          <button
            type="button"
            @click.stop.prevent="toggle"
            :class="[
              'btn-transparent-white',
              'relative',
              'z-1',
              'flex',
              'justify-center',
              'items-center',
              'rounded',
              'h-9',
              'w-6',
              'relative',
              'top-1',
            ]"
          >
            <VerticalDostSVG class="fill-white-1 w-6 h-6" />
          </button>
        </template>

        <template #items>
          <div class="pt-3 pb-4 px-6 w-53 cursor-default" @click.stop.prevent>
            <Button
              block
              align="left"
              color="btn-link-white"
              class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
              :label="$t('editor.reset')"
              :disabled="!resetEnabled"
              @click="reset"
            />

            <Button
              block
              align="left"
              color="btn-link-white"
              class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
              :label="$t('editor.open_trash')"
              @click="openTrash"
            />
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';
import PlayerProgressTime from '@/components/PlayerProgressTime.vue';

import UndoSVG from '@/assets/svgs/undo.svg';
import RedoSVG from '@/assets/svgs/redo.svg';
import ZoomInSVG from '@/assets/svgs/zoom_in.svg';
import ZoomOutSVG from '@/assets/svgs/zoom_out.svg';
import FitToArrowsSVG from '@/assets/svgs/fit_to_arrows.svg';
import VerticalDostSVG from '@/assets/svgs/vertical_dots.svg';

export default {
  name: 'EditorToolbar',
  components: {
    Dropdown,
    Button,
    Tooltip,
    PlayerProgressTime,
    UndoSVG,
    RedoSVG,
    ZoomInSVG,
    ZoomOutSVG,
    FitToArrowsSVG,
    VerticalDostSVG,
  },
  props: {
    scale: {
      Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    elapsed: {
      type: Number,
      required: true,
    },
    editorHistory: {
      type: Array,
      required: true,
    },
    editorHistoryIndex: {
      type: Number,
      required: true,
    },
    resetEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timesPressed: 1,
    };
  },
  computed: {
    isZoomOutDisabled() {
      return this.scale <= 1;
    },
    isZoomInDisabled() {
      return false;
      // return this.scale >= 100;
    },
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
    undo() {
      this.$emit('undo');
    },
    redo() {
      this.$emit('redo');
    },
    openTrash() {
      this.$emit('openTrash');
    },
    fitToScreen() {
      this.$emit('fitToScreen');
    },
    emitScaleChanged(newScale) {
      this.$emit('scaleChanged', newScale);
    },
    zoomOut() {
      const newScale = Math.max(1, this.scale - 0.1);
      this.emitScaleChanged(newScale);
    },
    zoomIn() {
      const newScale = this.scale + 0.1;
      this.emitScaleChanged(newScale);
    },
    handleZoomClick(event, zoomIn) {
      if (event.detail !== 0 || event.type !== 'click') {
        return;
      }

      if (zoomIn) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    zoomLongPressTick(zoomIn) {
      if (zoomIn) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }

      this._intervalRef = setTimeout(() => this.zoomLongPressTick(zoomIn), 250 / (2 * this.timesPressed++));
    },
    handleStartZoomLongPress(event, zoomIn) {
      if (this._intervalRef) {
        return;
      }

      this.zoomLongPressTick(zoomIn);
    },
    handleStopZoomLongPress() {
      if (!this._intervalRef) {
        return;
      }

      this.timesPressed = 1;

      clearTimeout(this._intervalRef);

      this._intervalRef = null;
    },
  },
};
</script>

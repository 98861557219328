<template>
  <div class="relative w-full pb-9/16">
    <iframe
      title="About SlidesLive"
      src="https://player.vimeo.com/video/553288288"
      class="absolute h-full w-full left-0 top-0"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'AboutVideo',
};
</script>

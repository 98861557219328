var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex h-34"},[_c('Draggable',_vm._b({staticClass:"flex-none list-style-none flex h-full items-stretch",attrs:{"tag":"ol","draggable":"li"},model:{value:(_vm.computedClips),callback:function ($$v) {_vm.computedClips=$$v},expression:"computedClips"}},'Draggable',_vm.dragOptions,false),_vm._l((_vm.computedClips),function(clip,index){return _c('EditorClip',{key:`${clip[clip.type].start_ms}:${clip[clip.type].duration_ms}`,attrs:{"clip":clip,"scale":_vm.scale,"file":_vm.clipFile(clip),"isCurrent":_vm.currentClipIndex === index},on:{"remove":function($event){return _vm.removeClip(index)}}})}),1),_c('div',{class:[
      'flex-1',
      'bg-black-6',
      'flex',
      'items-center',
      'justify-center',
      'py-2',
      'px-4',
      'text-center',
      'text-xs',
      'text-white-4',
      'user-select-none',
    ]},[_vm._v(" "+_vm._s(_vm.$t('editor.new_recordings_here'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Button color="btn-transparent-white-3" size="small" :disabled="disabled" @click="create">
    <template #default>
      {{ $t('record.new_version') }}

      <Tooltip v-if="!disabled" class="ml-1" @click.stop.prevent>
        {{ $t('record.new_version_tooltip') }}
      </Tooltip>
    </template>

    <template #icon>
      <PlusFullCircleSVG class="w-6 h-6 mr-2" />
    </template>
  </Button>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import PlusFullCircleSVG from '@/assets/svgs/plus_full_circle.svg';

export default {
  name: 'NewVersionTab',
  components: { Button, Tooltip, PlusFullCircleSVG },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification']),
    ...mapActions(['addVersion']),
    create() {
      if (this.disabled) {
        return;
      }

      this.$emit('create');
    },
  },
};
</script>

import Logger from '@/modules/logger';

export default function ({ commit, getters }, { clipsStart }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;
    const timelines = JSON.parse(JSON.stringify(version.timelines));
    const prevToNewStartMap = {};

    timelines.speaker.video = timelines.speaker.video.sort(
      (a, b) => clipsStart.indexOf(a[a.type].start_ms) - clipsStart.indexOf(b[b.type].start_ms),
    );

    let prevDuration = 0;

    timelines.speaker.video.forEach((clip) => {
      prevToNewStartMap[clip[clip.type].start_ms] = prevDuration;
      clip[clip.type].start_ms = prevDuration;

      prevDuration += clip[clip.type].duration_ms;
    });

    Object.keys(timelines).forEach((timelineKey) => {
      Object.keys(timelines[timelineKey]).forEach((kind) => {
        if (timelineKey === 'speaker' && kind === 'video') {
          return;
        }

        timelines[timelineKey][kind] = timelines[timelineKey][kind].sort(
          (a, b) => clipsStart.indexOf(a[a.type].start_ms) - clipsStart.indexOf(b[b.type].start_ms),
        );

        timelines[timelineKey][kind].forEach((clip) => {
          clip[clip.type].start_ms = prevToNewStartMap[clip[clip.type].start_ms];
        });
      });
    });

    const newData = {
      id: version.id,
      timelines,
      editor: {
        ...version.editor,
        history: [...version.editor.history],
        history_index: version.editor.history_index + 1,
      },
      manually_edited: true,
    };
    newData.editor.history.splice(newData.editor.history_index);
    newData.editor.history.push({
      duration: version.duration,
      removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
      timelines: JSON.parse(JSON.stringify(version.timelines)),
      manually_edited: version.manually_edited,
    });

    Logger.log('Reorder clips, timelines:', newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

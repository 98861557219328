<template>
  <div>
    <div class="flex h-84 rounded-lg bg-white-8 p-2 rounded-scrollbar relative">
      <div v-if="disabled" class="absolute inset-0 bg-black-3 rounded-lg z-2 cursor-not-allowed" />

      <transition name="fade" mode="out-in">
        <EditorTrash
          v-if="trashActive"
          :clips="currentVersion.editor.removed_clips"
          :files="currentVersion.files"
          :loading="actionInProgress"
          @restoreClip="triggerRestoreClip"
          @close="closeTrash"
          class="relative z-1"
        />

        <div
          v-else
          ref="editorContent"
          tabindex="0"
          class="flex-1 flex flex-column justify-between max-w-full relative z-1 outline-none group"
          @keydown.home.end.delete.space.prevent.stop
          @keyup.left.right.down.up.prevent.stop
          @keydown.left.prevent.stop="seekStep('back')"
          @keydown.right.prevent.stop="seekStep('forth')"
          @keydown.down.prevent.stop="seekClip('prev')"
          @keydown.up.prevent.stop="seekClip('next')"
          @keydown.z="shortcutZ"
          @keydown.y="shortcutY"
          @keydown.c="shortcutC"
          @keyup.home.prevent.stop="seekEdge('beginning')"
          @keyup.end.prevent.stop="seekEdge('end')"
          @keyup.delete.prevent.stop="triggerRemoveClip(currentClipIndex)"
          @keyup.space.prevent.stop="togglePause"
          @keydown.49.107="triggerZoomIn"
          @keyup.49.107="stopZoomIn"
          @keydown.189.109="triggerZoomOut"
          @keyup.189.109="stopZoomOut"
        >
          <EditorToolbar
            ref="toolbar"
            class="pb-2"
            :scale="scale"
            :duration="currentVersion.duration"
            :elapsed="elapsed"
            :editorHistory="currentVersion.editor.history"
            :editorHistoryIndex="currentVersion.editor.history_index"
            :resetEnabled="currentVersion.manually_edited"
            @fitToScreen="fitToScreen"
            @scaleChanged="scaleChanged"
            @openTrash="openTrash"
            @reset="triggerReset"
            @undo="triggerUndo"
            @redo="triggerRedo"
          />

          <div class="relative">
            <EditorTimeline
              ref="timeline"
              class="relative z-1"
              :isActive="isActive"
              :scale="scale"
              :duration="currentVersion.duration"
              :elapsed="elapsed"
              :timelines="currentVersion.timelines"
              :files="currentVersion.files"
              :loading="actionInProgress"
              :currentClipIndex="currentClipIndex"
              @scaleChanged="scaleChanged"
              @seekTo="seekTo"
              @cutClip="triggerCutClip"
              @reorderClips="triggerReorderClips"
              @removeClip="triggerRemoveClip"
            />

            <transition name="fade" mode="out-in">
              <EditorRemoveClipConfirm
                class="z-2 rounded-b"
                v-if="removeConfirmActive"
                :loading="actionInProgress"
                @confirm="confirmRemoveClip"
                @cancel="cancelRemoveClip"
              />
            </transition>
          </div>
        </div>
      </transition>
    </div>

    <p class="text-xs text-white-5 mt-3">
      <span class="font-bold">{{ $t('editor.tip') }}:</span>
      {{ $t('editor.tip_message') }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import EditorToolbar from '@/components/EditorToolbar.vue';
import EditorRemoveClipConfirm from '@/components/EditorRemoveClipConfirm.vue';
import EditorTimeline from '@/components/EditorTimeline.vue';
import EditorTrash from '@/components/EditorTrash.vue';

import { upsertJSONFile } from '@/helpers';

export default {
  name: 'Editor',
  components: {
    EditorToolbar,
    EditorRemoveClipConfirm,
    EditorTimeline,
    EditorTrash,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionClipIndex: null,
      actionInProgress: false,
      removeConfirmActive: false,
      trashActive: false,
      scale: 1,
    };
  },
  computed: {
    ...mapState('player', ['elapsed', 'paused']),
    ...mapGetters(['currentVersion']),
    currentClipIndex() {
      let index = this.currentVersion.timelines.speaker.video.findIndex(
        (v) => this.elapsed >= v[v.type].start_ms && this.elapsed < v[v.type].start_ms + v[v.type].duration_ms,
      );

      if (index < 0) {
        index = this.currentVersion.timelines.speaker.video.length - 1;
      }

      return index;
    },
  },
  methods: {
    ...mapMutations('player', ['setPlayerKey']),
    ...mapActions(['undo', 'redo', 'reset', 'reorderClips', 'removeClip', 'cutClip', 'restoreClip']),
    triggerReset() {
      if (!this.currentVersion.manually_edited) {
        return;
      }

      this.reset().then(this.saveVersionToFile.bind(this));
    },
    triggerUndo() {
      if (this.currentVersion.editor.history_index < 0) {
        return;
      }

      this.undo().then(this.saveVersionToFile.bind(this));
    },
    triggerRedo() {
      if (this.currentVersion.editor.history_index === this.currentVersion.editor.history.length - 1) {
        return;
      }

      this.redo().then(this.saveVersionToFile.bind(this));
    },
    closeTrash() {
      this.trashActive = false;
    },
    openTrash() {
      this.trashActive = true;
    },
    fitToScreen() {
      this.$refs.timeline.fitToScreen();
    },
    scaleChanged(newScale) {
      this.scale = newScale;
    },
    shortcutZ(event) {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }

      if (
        (event.ctrlKey && !event.metaKey && !event.shiftKey && !event.altKey) ||
        (event.metaKey && !event.ctrlKey && !event.shiftKey && !event.altKey)
      ) {
        event.stopPropagation();
        event.preventDefault();

        this.triggerUndo();
      } else if (event.metaKey && event.shiftKey && !event.ctrlKey && !event.altKey) {
        event.stopPropagation();
        event.preventDefault();

        this.triggerRedo();
      }
    },
    shortcutY(event) {
      if (!event.ctrlKey) {
        return;
      }

      if (!event.metaKey && !event.shiftKey && !event.altKey) {
        event.stopPropagation();
        event.preventDefault();

        this.triggerRedo();
      }
    },
    shortcutC(event) {
      if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      this.triggerCutClip();
    },
    seekStep(direction) {
      let newElapsed;

      if (direction === 'back') {
        newElapsed = this.elapsed - 500;
      } else if (direction === 'forth') {
        newElapsed = this.elapsed + 500;
      }

      newElapsed = Math.round(Math.min(Math.max(newElapsed, 0), this.currentVersion.duration));

      this.seekTo(newElapsed);
    },
    seekClip(direction) {
      let newElapsed;

      if (direction === 'prev') {
        const currentClip = this.currentVersion.timelines.speaker.video[this.currentClipIndex];

        if (this.elapsed > currentClip[currentClip.type].start_ms || this.currentClipIndex === 0) {
          newElapsed = currentClip[currentClip.type].start_ms;
        } else {
          const prevClip = this.currentVersion.timelines.speaker.video[this.currentClipIndex - 1];
          newElapsed = prevClip[prevClip.type].start_ms;
        }
      } else if (direction === 'next') {
        let nextClip = this.currentVersion.timelines.speaker.video.find((v) => v[v.type].start_ms > this.elapsed);

        if (nextClip) {
          newElapsed = nextClip[nextClip.type].start_ms;
        } else {
          nextClip =
            this.currentVersion.timelines.speaker.video[this.currentVersion.timelines.speaker.video.length - 1];
          newElapsed = nextClip[nextClip.type].start_ms + nextClip[nextClip.type].duration_ms;
        }
      }

      this.seekTo(newElapsed);
    },
    seekEdge(edge) {
      const newElapsed = edge === 'beginning' ? 0 : this.currentVersion.duration;

      this.seekTo(newElapsed);
    },
    seekTo(newElapsed) {
      if (newElapsed === this.elapsed) {
        return;
      }

      this.setPlayerKey({ key: 'elapsed', elapsed: newElapsed });
    },
    triggerZoomIn(event) {
      if (event.key !== '+') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$refs.toolbar.handleStartZoomLongPress(event, true);
    },
    stopZoomIn(event) {
      if (event.key !== '+') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$refs.toolbar.handleStopZoomLongPress();
    },
    triggerZoomOut(event) {
      if (event.key !== '-') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$refs.toolbar.handleStartZoomLongPress(event, false);
    },
    stopZoomOut(event) {
      if (event.key !== '-') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$refs.toolbar.handleStopZoomLongPress();
    },
    togglePause() {
      this.setPlayerKey({ key: 'paused', paused: !this.paused });
    },
    triggerReorderClips(clipsStart) {
      this.actionInProgress = true;

      setTimeout(() => {
        this.reorderClips({ clipsStart })
          .then(this.saveVersionToFile.bind(this))
          .then(() => {
            this.actionInProgress = false;
            this.focusEditorContent();
          });
      }, 30000);
    },
    triggerRemoveClip(index) {
      document.activeElement.blur();
      this.actionClipIndex = index;
      this.removeConfirmActive = true;
    },
    confirmRemoveClip() {
      if (!this.paused) {
        this.togglePause();
      }

      this.actionInProgress = true;

      this.removeClip({ index: this.actionClipIndex })
        .then(this.saveVersionToFile.bind(this))
        .then(() => {
          this.actionClipIndex = null;
          this.removeConfirmActive = false;
          this.actionInProgress = false;
          this.focusEditorContent();
        });
    },
    cancelRemoveClip() {
      this.actionClipIndex = null;
      this.removeConfirmActive = false;
      this.focusEditorContent();
    },
    triggerCutClip() {
      const cutAtMs = Math.round(this.elapsed);
      const clipExists = this.currentVersion.timelines.speaker.video.some((r) => r[r.type].start_ms === cutAtMs);

      if (clipExists || cutAtMs === this.currentVersion.duration || cutAtMs === 0) {
        return;
      }

      this.actionInProgress = true;

      this.cutClip({ cutAtMs })
        .then(this.saveVersionToFile.bind(this))
        .then(() => {
          this.actionInProgress = false;
          this.focusEditorContent();
        });
    },
    triggerRestoreClip(index) {
      this.actionInProgress = true;

      this.restoreClip({ index })
        .then(this.saveVersionToFile.bind(this))
        .then(() => {
          this.actionInProgress = false;
          this.focusEditorContent();
        });
    },
    saveVersionToFile() {
      return upsertJSONFile(this.currentVersion);
    },
    focusEditorContent() {
      if (this.$refs.editorContent) {
        this.$refs.editorContent.focus();
      }
    },
  },
};
</script>

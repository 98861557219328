<template>
  <div>
    <Switcher v-model="computedRequested" :disabled="switcherDisabled" labelPosition="left" class="mx-12 relative">
      <span class="text-white-1 text-xl font-bold">{{ $t('setup.microphone.title') }}</span>

      <Spinner
        v-if="switcherDisabled || isWaitingForSpeakerStream"
        size="small"
        class="absolute left-full top-0 bottom-0 my-auto"
      />
    </Switcher>

    <transition name="slide" mode="out-in">
      <p v-if="!computedRequested" class="mx-12 mt-2 font-light">
        {{ $t('setup.not_connected') }}
      </p>

      <div v-else class="pt-6">
        <Select
          v-model="computedId"
          :options="computedDevices"
          :placeholder="$t('setup.microphone.select_placeholder')"
          valueKey="deviceId"
          labelKey="label"
          class="z-2"
        />

        <MicrophonePreview
          class="text-center mt-10 relative z-0"
          :stream="speakerStream"
          :errors="microphoneErrors"
          :loading="isWaitingForSpeakerStream"
        />

        <MicrophoneTest class="mt-10" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Switcher from '@/components/Switcher.vue';
import Select from '@/components/Select.vue';
import MicrophonePreview from '@/components/MicrophonePreview.vue';
import MicrophoneTest from '@/components/MicrophoneTest.vue';
import Spinner from '@/components/Spinner.vue';

import Logger from '@/modules/logger';

export default {
  name: 'SetupMicrophone',
  components: { Switcher, Select, MicrophonePreview, MicrophoneTest, Spinner },
  computed: {
    ...mapState([
      'devices',
      'speakerStream',
      'microphoneErrors',
      'microphoneEnabled',
      'microphoneRequested',
      'cameraRequested',
      'cameraEnabled',
      'microphoneId',
      'isWaitingForSpeakerStream',
    ]),
    computedRequested: {
      get() {
        return this.microphoneRequested;
      },
      set(value) {
        this.setKey({ key: 'microphoneRequested', microphoneRequested: value });

        Logger.log(`SetupMicrophone: requested: ${value}`);
      },
    },
    computedId: {
      get() {
        return this.microphoneId;
      },
      set(value) {
        this.setKey({ key: 'microphoneId', microphoneId: value });
      },
    },
    switcherDisabled() {
      return this.cameraEnabled !== this.cameraRequested || this.microphoneEnabled !== this.computedRequested;
    },
    computedDevices() {
      return this.devices.filter((device) => device.kind === `audioinput`);
    },
  },
  methods: {
    ...mapMutations(['setKey']),
  },
};
</script>

<template>
  <Form class="text-center" @submit="triggerSendFeedback">
    <FeedbackList v-model="value" :disabled="sent" />

    <Input
      v-if="value"
      name="rating"
      tag="textarea"
      v-model="note"
      :readonly="sent"
      :placeholder="$t('thank_you.feedback.placeholder')"
      class="mx-auto w-full max-w-96 min-h-33 resize-vertical mt-6"
      :validateOnBlur="false"
    />

    <Button
      v-if="!sent"
      type="submit"
      :disabled="!value"
      :loading="inProgress"
      :label="$t('thank_you.feedback.send')"
      block
      class="w-65 mt-9 mx-auto"
    />
  </Form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import FeedbackList from '@/components/FeedbackList.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';

import { upsertJSONFile } from '@/helpers';

export default {
  name: 'Feedback',
  components: { FeedbackList, Form, Input, Button },
  props: {
    isSelfRecording: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sent: false,
      inProgress: false,
    };
  },
  computed: {
    ...mapState(['submitVersionId', 'selfRecording']),
    ...mapGetters(['submitVersion']),
    source() {
      if (this.isSelfRecording) {
        return this.selfRecording;
      }

      return this.submitVersion;
    },
    value: {
      get() {
        return this.source.feedback.value;
      },
      set(value) {
        this.updateFeedback('value', value);
      },
    },
    note: {
      get() {
        return this.source.feedback.note;
      },
      set(value) {
        this.updateFeedback('note', value);
      },
    },
  },
  methods: {
    ...mapMutations(['updateSelfRecording', 'updateVersion']),
    ...mapActions(['sendFeedback']),
    updateFeedback(prop, value) {
      const newFeedback = {
        ...this.source.feedback,
        [prop]: value,
      };

      if (this.isSelfRecording) {
        this.updateSelfRecording({ feedback: newFeedback });
        return;
      }

      this.updateVersion({ id: this.submitVersionId, feedback: newFeedback });
    },
    triggerSendFeedback() {
      this.inProgress = true;

      this.sendFeedback({ selfRecording: this.isSelfRecording }).then(() => {
        this.sent = true;

        if (this.isSelfRecording) {
          this.inProgress = false;
          this.$emit('sent');
          return;
        }

        upsertJSONFile(this.submitVersion).then(() => {
          this.inProgress = false;
          this.$emit('sent');
        });
      });
    },
  },
};
</script>

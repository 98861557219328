<template>
  <div class="relative">
    <router-link v-if="!disabled" :to="{ name: 'Setup' }" custom #default="{ href, navigate }">
      <Button
        class="absolute right-0 top-0 p-0"
        :class="isRecording && 'hidden'"
        color="btn-link-white-3"
        :href="href"
        :label="$t('recorder.setup')"
        @click="navigate"
      >
        <template #icon>
          <SetupSVG class="h-5 w-5 mr-2" />
        </template>
      </Button>
    </router-link>

    <RecordingProgress
      :class="!showProgress && 'hidden'"
      :isRecording="isRecording"
      :isPaused="isPaused"
      :duration="duration"
    />

    <RecorderActions class="mt-2" :version="currentVersion" :disabled="disabled" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import Button from '@/components/Button.vue';
import RecordingProgress from '@/components/RecordingProgress.vue';
import RecorderActions from '@/components/RecorderActions.vue';

import SetupSVG from '@/assets/svgs/setup.svg';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import Logger from '@/modules/logger';
import Recorder from '@/modules/recorder';

export default {
  name: 'Recorder',
  components: { Button, RecordingProgress, RecorderActions, SetupSVG },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ErrorNotificationsMixin],
  data() {
    return {
      interrupted: false,
      addLastRecordingDuration: false,
    };
  },
  computed: {
    ...mapState(['isRecording', 'isPaused', 'lastRecordingDuration']),
    ...mapGetters(['currentVersion']),
    hasRecordings() {
      return this.currentVersion.recordings.length > 0;
    },
    showProgress() {
      return this.hasRecordings || this.isRecording || this.isPaused;
    },
    duration() {
      if (this.addLastRecordingDuration) {
        return this.currentVersion.duration + this.lastRecordingDuration;
      }

      return this.currentVersion.duration;
    },
  },
  methods: {
    ...mapMutations(['setKey']),
    ...mapActions(['triggerStopRecording']),
    interruptedFromRecorder(data) {
      if (this.interrupted) {
        return;
      }

      this.interrupted = true;

      Logger.log('Recording interrupted, data:', data);

      this.notificationFromError(data.error, {
        sound: 'error',
        synthesisMessage: this.$i18n.t('errors.stopped_check_recorder'),
      });

      this.triggerStopRecording({ interrupted: true });
    },
    recorderProgress({ kind, size, processed }) {
      const percent = Math.floor((processed / size) * 100);
      const storeKey = `${kind}RecorderProgress`;
      this.setKey({ key: storeKey, [storeKey]: `${percent} %` });
    },
    recorderCleanup({ kind }) {
      const storeKey = `${kind}RecorderProgress`;
      this.setKey({ key: storeKey, [storeKey]: 'cleanup' });
    },
    recorderDone({ kind }) {
      const storeKey = `${kind}RecorderProgress`;
      this.setKey({ key: storeKey, [storeKey]: '' });
    },
  },
  mounted() {
    const speakerRecorder = new Recorder(this.currentVersion.recorder_token, this.currentVersion.speaker_id, 'speaker');
    const screenSharingRecorder = new Recorder(
      this.currentVersion.recorder_token,
      this.currentVersion.speaker_id,
      'screenSharing',
    );

    speakerRecorder.on('interrupted', this.interruptedFromRecorder.bind(this));
    speakerRecorder.on('progress', this.recorderProgress.bind(this));
    speakerRecorder.on('cleanup', this.recorderCleanup.bind(this));
    speakerRecorder.on('done', this.recorderDone.bind(this));
    screenSharingRecorder.on('interrupted', this.interruptedFromRecorder.bind(this));
    screenSharingRecorder.on('progress', this.recorderProgress.bind(this));
    screenSharingRecorder.on('cleanup', this.recorderCleanup.bind(this));
    screenSharingRecorder.on('done', this.recorderDone.bind(this));

    this.setKey({ key: 'speakerRecorder', speakerRecorder });
    this.setKey({ key: 'screenSharingRecorder', screenSharingRecorder });
  },
  watch: {
    isRecording(value) {
      if (!value) {
        return;
      }

      this.addLastRecordingDuration = true;
    },
    currentVersion(value, oldValue) {
      if (value.duration === oldValue.duration) {
        return;
      }

      this.addLastRecordingDuration = false;
    },
  },
  beforeDestroy() {
    if (!this.isRecording) {
      return;
    }

    this.triggerStopRecording();
  },
};
</script>

export default function (stream) {
  let audioDeviceId = null;
  let videoDeviceId = null;

  if (stream) {
    for (const track of stream.getTracks()) {
      const trackSettings = track.getSettings();

      if (track.kind === 'audio') {
        audioDeviceId = trackSettings.deviceId;
      } else if (track.kind === 'video') {
        videoDeviceId = trackSettings.deviceId;
      }
    }
  }

  return { audioDeviceId, videoDeviceId };
}

<template>
  <div id="app" class="flex items-center justify-center min-w-192 w-full h-full">
    <transition name="fade" mode="out-in">
      <BrowserError v-if="hasBrowserError && $route.name !== 'Help'" :errors="browserErrors" />

      <UnknownUser v-else-if="isUnknownUser && $route.name !== 'Help'" />

      <div v-else-if="isLoading && $route.name !== 'Help'">
        <div v-if="!storagePermissionModalActive && !storageDeclined" class="text-center">
          <Spinner />
        </div>

        <StorageDeclined
          v-else-if="!storagePermissionModalActive && storageDeclined"
          @retry="openStoragePermissionModal"
        />

        <StoragePermissionModal
          :active="storagePermissionModalActive"
          @confirm="confirmStoragePermission"
          @cancel="cancelStoragePermission"
        />
      </div>

      <router-view v-else />
    </transition>

    <Notifications />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import BrowserError from '@/views/BrowserError.vue';
import UnknownUser from '@/views/UnknownUser.vue';
import StorageDeclined from '@/views/StorageDeclined.vue';
import Spinner from '@/components/Spinner.vue';
import StoragePermissionModal from '@/components/StoragePermissionModal.vue';
import Notifications from '@/components/Notifications.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';
import RefetchTokenDataMixin from '@/mixins/refetch_token_data';

import Logger from '@/modules/logger';
import RecorderSettings from '@/modules/recorder_settings';

import {
  browserErrors,
  loadRecordedTokens,
  loadLocalSpeakers,
  getStorageInfo,
  requestStorage,
  createTokenDirectory,
  parseUrlParams,
} from '@/helpers';

export default {
  name: 'App',
  components: {
    Spinner,
    BrowserError,
    UnknownUser,
    StoragePermissionModal,
    StorageDeclined,
    Notifications,
  },
  mixins: [ErrorNotificationsMixin, RefetchTokenDataMixin],
  data() {
    return {
      storageInfo: null,
      storageDeclined: false,
      storagePermissionModalActive: false,
    };
  },
  computed: {
    ...mapState([
      'isLoading',
      'isLoaded',
      'grantedBytes',
      'recorderToken',
      'recordedTokens',
      'useFallbackMimeType',
      'loggedInSpeakerId',
      'screenSharingStream',
      'speakers',
      'uploads',
    ]),
    browserErrors() {
      return browserErrors(['Share', 'Embed'].includes(this.$route.name));
    },
    hasBrowserError() {
      return this.browserErrors.length > 0;
    },
    isUnknownUser() {
      return (
        this.isLoaded &&
        (!this.recorderToken || this.recorderToken === '') &&
        !['Help', 'RecordedTokens', 'Share', 'Embed'].includes(this.$route.name) &&
        !this.$route.query.share
      );
    },
    isFetchDisabled() {
      return this.hasBrowserError || ['RecordedTokens', 'Help'].includes(this.$route.name);
    },
    token() {
      return this.$route.query.token || this.recorderToken;
    },
    isTokenFirstLoad() {
      return (
        !this.token ||
        (!!this.$route.query.token &&
          ((!!this.recorderToken && this.$route.query.token !== this.recorderToken) || !this.recorderToken))
      );
    },
  },
  methods: {
    ...mapMutations(['setKey', 'showLoading', 'hideLoading', 'resetStore', 'logout', 'deleteNotification']),
    ...mapActions([
      'fetchTokenData',
      'loadSpeakerLocalVersions',
      'mergeSpeakerLocalVersionsAndUploads',
      'restoreUnfinishedRecording',
    ]),
    hideStoragePermissionModal() {
      this.storagePermissionModalActive = false;
    },
    openStoragePermissionModal() {
      this.storageDeclined = false;
      this.storagePermissionModalActive = true;
    },
    cancelStoragePermission() {
      this.storageDeclined = true;
      this.hideStoragePermissionModal();
    },
    confirmStoragePermission() {
      this.hideStoragePermissionModal();

      requestStorage(this.storageInfo.usage)
        .then((grantedBytes) => {
          this.setKey({ key: 'grantedBytes', grantedBytes });

          this.continueSetupAfterStoragePermission();
        })
        .catch((errors) => {
          if (!Array.isArray(errors)) {
            errors = [errors];
          }

          errors.forEach((error) => {
            this.notificationFromError(error);
          });

          this.cancelStoragePermission();
        });
    },
    continueSetupAfterStoragePermission() {
      new Promise((resolve) => {
        if (this.recordedTokens.includes(this.token)) {
          resolve(false);
          return;
        }

        createTokenDirectory(this.token, this.grantedBytes).then(() => resolve(true));
      })
        .then((isNew) => {
          Logger.storageQuotaGranted(this.token);

          if (isNew) {
            return [];
          }

          return loadLocalSpeakers(this.token);
        })
        .then((localSpeakers) => {
          this.setKey({ key: 'localSpeakers', localSpeakers });

          if (!this.loggedInSpeakerId) {
            return null;
          }

          return this.loadSpeakerLocalVersions({ id: this.loggedInSpeakerId })
            .then(this.mergeSpeakerLocalVersionsAndUploads.bind(this, { id: this.loggedInSpeakerId }))
            .then(this.restoreUnfinishedRecording);
        })
        .catch((errors) => {
          if (!Array.isArray(errors)) {
            errors = [errors];
          }

          errors.forEach((error) => {
            this.notificationFromError([error, 'contact_support']);
          });
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    initialTokenDataFetch() {
      this.fetchTokenData({ token: this.token })
        .then(() => {
          this.deleteNotification({ code: 'token_is_required::contact_support' });

          if (
            this.loggedInSpeakerId &&
            !['Welcome', 'Register', 'Help', 'Share', 'Embed', 'RecordedTokens'].includes(this.$route.name)
          ) {
            const speaker = this.speakers.find((s) => s.id === this.loggedInSpeakerId);

            if (speaker && !speaker.recording_enabled) {
              const submittedUpload = this.uploads.find(
                (u) => u.speaker_id === this.loggedInSpeakerId && u.final_recording,
              );
              const params = parseUrlParams(submittedUpload.share_link_url);

              this.$router.replace({ name: 'Share', query: params });
              this.hideLoading();
              return null;
            }
          }

          return getStorageInfo();
        })
        .then((storageInfo) => {
          if (!storageInfo) {
            return;
          }

          this.storageInfo = storageInfo;
          const availableSpace = storageInfo.quota - storageInfo.usage;

          if (availableSpace >= RecorderSettings.minStorageBytes) {
            this.continueSetupAfterStoragePermission();
          } else {
            this.openStoragePermissionModal();
          }
        })
        .catch((error) => {
          this.notificationFromError([error, 'contact_support']);
          this.hideLoading();
        });
    },
  },
  watch: {
    $route(route) {
      if (
        !this.isFetchDisabled &&
        !['Share', 'Embed', 'RecordedTokens'].includes(route.name) &&
        this.isTokenFirstLoad
      ) {
        this.resetStore({ except: ['recordedTokens', 'notifications'] });
        this.initialTokenDataFetch();
        return;
      }

      if (!['Setup', 'Record'].includes(route.name) && this.screenSharingStream) {
        for (const track of this.screenSharingStream.getTracks()) {
          track.stop();
        }

        this.setKey({ key: 'screenSharingStream', screenSharingStream: null });
      }
    },
  },
  created() {
    this.setKey({ key: 'initialHistoryLength', initialHistoryLength: window.history.length });

    if (this.hasBrowserError) {
      return;
    }

    this.showLoading();

    const logger = new Logger(this.token);
    Logger.log(`Logger initialized with token '${logger.token}'`);
    Logger.log(`User agent: ${navigator.userAgent}`);

    if (this.$route.query.share) {
      if (this.isTokenFirstLoad) {
        this.resetStore({ except: ['recordedTokens', 'notifications'] });
      }

      this.hideLoading();

      return;
    }

    loadRecordedTokens()
      .then((recordedTokens) => {
        this.setKey({ key: 'recordedTokens', recordedTokens });

        if (this.isFetchDisabled) {
          this.hideLoading();
          return;
        }

        if (!this.token) {
          this.resetStore({ except: ['recordedTokens', 'notifications'] });
          this.hideLoading();
          this.notificationFromError(['token_is_required', 'contact_support']);
          return;
        }

        if (this.isTokenFirstLoad) {
          this.resetStore({ except: ['recordedTokens', 'notifications'] });
        }

        this.initialTokenDataFetch();
      })
      .catch((errors) => {
        if (!Array.isArray(errors)) {
          errors = [errors];
        }

        errors.forEach((error) => {
          this.notificationFromError([error, 'contact_support']);
        });
      });
  },
};
</script>

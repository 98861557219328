import Vue from 'vue';
import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  arrow: true,
  animation: 'shift-away',
  appendTo: document.body,
  theme: 'slideslive',
  size: 'small',
});
Vue.component('tippy', TippyComponent);

<template>
  <div class="flex w-full p-1 min-h-16 bg-white-9">
    <div class="flex flex-column justify-center pl-3 lg:pl-11 w-5/12 lg:w-5/12">
      <p class="text-white-1 font-black">{{ conferenceName }}</p>
      <p class="font-light">{{ presentationTitle }}</p>
    </div>

    <div class="flex items-center justify-end pl-2 lg:pl-4 w-7/12 lg:w-7/12">
      <div v-if="presentationDurationS" class="w-1/4">
        <p class="text-white-1 font-black">{{ $t('header.speech_length') }}</p>
        <p class="font-light">{{ formattedDuration }}</p>
      </div>

      <div v-if="!hideDeadline && deadlineAt" class="pl-2 lg:pl-4 w-1/4">
        <p class="text-white-1 font-black">{{ $t('header.deadline') }}</p>
        <p class="font-light flex items-center">
          <DateFromNow :date="deadlineAt" highlightPast />

          <Tooltip class="ml-1">
            {{ $d(new Date(deadlineAt), 'deadline') }}
          </Tooltip>
        </p>
      </div>

      <div class="pl-2 lg:pl-4 w-1/2 flex justify-end">
        <HeaderUser v-if="isLoggedId" />

        <LanguageSelect v-else class="mr-4" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Tooltip from '@/components/Tooltip.vue';
import DateFromNow from '@/components/DateFromNow.vue';
import HeaderUser from '@/components/HeaderUser.vue';
import LanguageSelect from '@/components/LanguageSelect.vue';

import { formatTime } from '@/helpers';

export default {
  name: 'Header',
  components: { Tooltip, DateFromNow, HeaderUser, LanguageSelect },
  props: {
    hideDeadline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'conferenceName',
      'presentationTitle',
      'presentationDurationS',
      'deadlineAt',
      'loggedInSpeakerId',
      'registerSpeaker',
      'speakers',
    ]),
    formattedDuration() {
      return formatTime(this.presentationDurationS);
    },
    isLoggedId() {
      return (
        (this.loggedInSpeakerId && this.speakers.some((s) => s.id === this.loggedInSpeakerId)) || this.registerSpeaker
      );
    },
  },
};
</script>

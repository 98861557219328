import Logger from '@/modules/logger';

export default function ({ state, commit, dispatch }, { id }) {
  commit('login', { id });

  Logger.log(`Log in speaker, id: ${id}`);

  return dispatch('loadSpeakerLocalVersions', { id })
    .then(() => dispatch('mergeSpeakerLocalVersionsAndUploads', { id }))
    .then(() => {
      if (!state.localSpeakers.includes(id)) {
        commit('setKey', { key: 'localSpeakers', localSpeakers: [...state.localSpeakers, id] });
      }

      return dispatch('restoreUnfinishedRecording');
    });
}

<template>
  <div class="flex flex-column items-center justify-center absolute inset-0" :class="!tightBg && 'bg-black-3'">
    <Spinner :class="tightBg && ['absolute', 'inset-0', 'm-auto', 'bg-black', 'rounded-full']" />

    <div v-if="$slots.default" class="mt-4 text-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'OverlaySpinner',
  components: { Spinner },
  props: {
    tightBg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

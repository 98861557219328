<template>
  <div
    :class="[
      'flex',
      'flex-column',
      'items-stretch',
      'justify-center',
      'px-6',
      'py-4',
      'min-h-17',
      'border-y',
      'border-r',
      'border-dashed',
      'border-white-3',
      'rounded-lg',
      'w-full',
      {
        'border-l-0': showHandle,
        'border-l': !showHandle,
      },
    ]"
  >
    <div
      v-if="showHandle"
      :class="[
        'handle',
        'absolute',
        'flex',
        'items-center',
        'rounded-lg',
        'bg-white-7',
        'left-0',
        'inset-y-0',
        'cursor-grab',
      ]"
    >
      <VerticalDostSVG class="fill-white-3 w-4 h-4" />
    </div>

    <div class="flex justify-between items-center">
      <p>{{ $t('speakers_list.not_set') }}</p>

      <Tooltip v-if="showDelete">
        <template #trigger>
          <Button
            disableMinHeight
            :rounded="false"
            @click="deleteSpeaker"
            color="btn-transparent-white"
            class="p-0 rounded-full w-8 h-8"
          >
            <template #icon>
              <TrashSVG class="w-5 h-5" />
            </template>
          </Button>
        </template>

        <template #default>
          <span class="no-wrap">{{ $t('speakers_list.delete_speaker') }}</span>
        </template>
      </Tooltip>
    </div>

    <Button
      v-if="showContinueAs"
      :label="$t('speakers_list.continue_as_speaker', { position: speakerPosition })"
      @click="continueAs"
      block
      class="mt-4 w-full"
    />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import VerticalDostSVG from '@/assets/svgs/vertical_dots.svg';
import TrashSVG from '@/assets/svgs/trash.svg';

import { positionSuffix } from '@/helpers';

export default {
  name: 'NotSetItem',
  components: { Button, Tooltip, VerticalDostSVG, TrashSVG },
  props: {
    index: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showContinueAs: {
      type: Boolean,
      default: false,
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    speakerPosition() {
      const position = this.index + 1;
      const suffix = positionSuffix(this.$i18n.locale, position);

      return `${position}${suffix}`;
    },
  },
  methods: {
    deleteSpeaker() {
      this.$emit('deleteSpeaker', { index: this.index, id: this.id });
    },
    continueAs() {
      this.$emit('continueAs', { index: this.index, id: this.id });
    },
  },
};
</script>

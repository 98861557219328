<template>
  <div class="flex w-full">
    <div class="flex-none w-1/2 pr-3">
      <DragDrop
        :title="$t('self_recording_upload.speaker.title')"
        :selectButtonText="$t('self_recording_upload.speaker.select')"
        :readOnly="readOnly"
        accept="audio/*,video/*"
        v-model="computedSpeakerFiles"
        attachmentType="speaker"
        multiple
      />
    </div>

    <div class="flex-none w-1/2 pl-3">
      <DragDrop
        :title="$t('self_recording_upload.slides.title')"
        :selectButtonText="$t('self_recording_upload.slides.select')"
        :readOnly="readOnly"
        accept="video/*"
        v-model="computedSlidesFiles"
        attachmentType="slides"
        multiple
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import DragDrop from '@/components/DragDrop.vue';

export default {
  name: 'SelfRecordingFiles',
  components: { DragDrop },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      speakerFiles: [],
      slidesFiles: [],
    };
  },
  computed: {
    ...mapState(['selfRecording']),
    computedSpeakerFiles: {
      get() {
        return this.speakerFiles;
      },
      set(value) {
        this.setFiles('speaker', value);
      },
    },
    computedSlidesFiles: {
      get() {
        return this.slidesFiles;
      },
      set(value) {
        this.setFiles('slides', value);
      },
    },
  },
  methods: {
    ...mapMutations(['updateSelfRecording']),
    setFiles(type, value) {
      if (!Array.isArray(value)) {
        value = [value];
      }

      this[`${type}Files`] = value;

      const files = [...this.speakerFiles, ...this.slidesFiles];
      this.updateSelfRecording({ files });
    },
  },
  mounted() {
    this.selfRecording.files.forEach((file) => {
      if (file.attachment.type === 'speaker') {
        this.speakerFiles.push(file);
      } else if (file.attachment.type === 'slides') {
        this.slidesFiles.push(file);
      }
    });
  },
};
</script>

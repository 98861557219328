<template>
  <ul class="text-white-1">
    <li v-for="(item, index) of items" :key="index" :class="index > 0 && 'mt-2'">
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SelfRecordingRequirements',
  computed: {
    items() {
      return this.$i18n.t('self_recording.requirements');
    },
  },
};
</script>

import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }, { id: idToDelete }) {
  const upload = state.uploads.find((u) => u.id === idToDelete);

  if (!upload) {
    return true;
  }

  const requestBody = {
    upload_token: upload.upload_token,
  };

  Logger.log(`Delete upload, id: ${idToDelete}`);

  return makeRequest('DELETE', `${API_URL}/token_uploads/${idToDelete}`, requestBody).then(() => {
    const uploads = state.uploads.filter((u) => u.id !== idToDelete);
    commit('setKey', { key: 'uploads', uploads });
  });
}

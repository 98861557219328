import requestFileSystemDirectory from './request_file_system_directory';
import readEntries from './read_entries';

export default function (token, speakerId, fileNames = [], withEntries = false) {
  return new Promise((resolve, reject) => {
    if (fileNames.length === 0) {
      resolve([]);
      return;
    }

    requestFileSystemDirectory(token, speakerId)
      .then((dirEntry) => {
        const dirReader = dirEntry.createReader();

        return readEntries(dirReader);
      })
      .then((entries) => {
        const fileNamesWithRequirement = Array.isArray(fileNames[0]);
        const files = entries.filter((entry) => {
          return fileNamesWithRequirement
            ? fileNames.some((f) => f[0] === entry.name)
            : fileNames.indexOf(entry.name) >= 0;
        });
        const requiredFiles = fileNamesWithRequirement ? fileNames.filter((f) => f[1]).map((f) => f[0]) : fileNames;
        const result = [];
        let processedFiles = 0;

        if (files.length === 0) {
          if (requiredFiles.length > 0) {
            reject(new Error('files_not_found'));
            return;
          }

          resolve(result);
          return;
        }

        const processFile = (entry, file) => {
          const isFileRequired = requiredFiles.some((f) => f === file.name);

          if (isFileRequired) {
            const fIndex = requiredFiles.indexOf(file.name);
            requiredFiles.splice(fIndex, 1);
          }

          if (withEntries) {
            result.push({ file, entry });
          } else {
            result.push(file);
          }

          if (++processedFiles === files.length) {
            if (requiredFiles.length > 0) {
              reject(new Error('files_not_found'));
              return;
            }

            resolve(result);
          }
        };

        for (const entry of files) {
          entry.file(
            (file) => processFile(entry, file),
            (error) => console.warn(error),
          );
        }
      })
      .catch((error) => reject(error));
  });
}

export default function (duration, sound, timeUpdateCallback = null) {
  return new Promise((resolve) => {
    const audio = new Audio(sound);

    let startedAt = null;
    let lastUpdateTime = null;
    let updateInterval = null;

    const callTimeUpdateCallback = (time) => {
      if (!lastUpdateTime || lastUpdateTime < time) {
        if (timeUpdateCallback) {
          timeUpdateCallback(time, audio);
        }

        lastUpdateTime = time;
      }
    };

    let ended = false;
    const endAndResolve = () => {
      if (!ended) {
        if (updateInterval) {
          clearInterval(updateInterval);
        }

        audio.pause();
        ended = true;
        resolve();
      }
    };

    const startUpdateInterval = () => {
      startedAt = window.performance.now();
      updateInterval = setInterval(() => {
        const currentTime = (window.performance.now() - startedAt) / 1000.0;
        callTimeUpdateCallback(currentTime, audio);

        if (currentTime > duration) {
          endAndResolve();
        }
      }, 100);
    };

    audio.onended = endAndResolve;
    audio.onerror = endAndResolve;
    audio.ontimeupdate = () => {
      clearInterval(updateInterval);
      callTimeUpdateCallback(audio.currentTime);
    };

    const playPromise = audio.play();
    if (playPromise && playPromise.then && playPromise.catch) {
      playPromise.then(() => startUpdateInterval()).catch(() => endAndResolve());
    } else {
      startUpdateInterval();
    }
  });
}

import RecorderSettings from '@/modules/recorder_settings';

export default function (usage = 0) {
  return new Promise((resolve, reject) => {
    navigator.webkitPersistentStorage.requestQuota(
      RecorderSettings.requestedStorageBytes + usage,
      (grantedBytes) => {
        if (grantedBytes >= RecorderSettings.minStorageBytes) {
          resolve(grantedBytes);
          return;
        }

        const errors = [];

        if (grantedBytes === 0) {
          errors.push('storage_declined');
        } else {
          errors.push('small_storage_quota');
          errors.push('check_storage_space');
        }

        reject(errors);
      },
      (error) => {
        const errors = [];

        errors.push('unable_to_request_storage');
        errors.push('check_storage_space');

        reject(errors, error);
      },
    );
  });
}

import { captureMessage, Severity } from '@sentry/browser';

import errorToObject from './error_to_object';

function sendErrorToSentry(category, error) {
  let name = error.name;
  if (error.message) {
    name += `: ${error.message}`;
  }

  if (error.customMessage) {
    name += ` (${error.customMessage})`;
  }

  captureMessage(`[${category}] ${name}`, {
    level: Severity.Error,
    extra: {
      error: errorToObject(error),
    },
  });
}

export default sendErrorToSentry;

export default function (url, time = 0) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    const doGetImage = () => {
      const scale = 136 / video.videoHeight;
      const w = video.videoWidth * scale;
      const h = video.videoHeight * scale;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = w;
      canvas.height = h;

      ctx.drawImage(video, 0, 0, w, h);

      let dataURI = canvas.toDataURL('image/jpeg');

      if (dataURI === 'data:,') {
        dataURI =
          // eslint-disable-next-line max-len
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
      }

      resolve(dataURI);
    };

    video.onloadedmetadata = () => {
      video.onloadedmetadata = null;
      video.currentTime = time / 1000;

      video.onseeked = () => {
        video.onseeked = null;
        doGetImage();
      };
    };

    video.onerror = () => {
      reject();
    };

    video.src = url;
  });
}

<template>
  <OrderedList
    :items="speakers"
    :sortableEnabled="sortable"
    sortableClass="sortable"
    sortGroup="speakers"
    floatingNumbers
    @sorted="sorted"
    #default="{ item, index }"
  >
    <SpeakerItem
      v-if="item.name"
      :speaker="item"
      :index="index"
      :showHandle="sortable"
      :shareLinkUrl="shareLinkUrl(item)"
      :shareLinkIsSelfRecording="shareLinkIsSelfRecording(item)"
      :showContinueAs="showContinueAsSpeaker"
      @continueAs="continueAs"
    />

    <NotSetItem
      v-else
      :id="item.id"
      :index="index"
      :showHandle="sortable"
      :showDelete="showDelete"
      :showContinueAs="showContinueAsNotSet"
      @deleteSpeaker="deleteSpeaker"
      @continueAs="continueAs"
    />
  </OrderedList>
</template>

<script>
import OrderedList from '@/components/OrderedList.vue';
import SpeakerItem from '@/components/SpeakerItem.vue';
import NotSetItem from '@/components/NotSetItem.vue';

export default {
  name: 'SpeakersList',
  components: {
    OrderedList,
    SpeakerItem,
    NotSetItem,
  },
  props: {
    sortable: {
      type: Boolean,
      default: false,
    },
    speakers: {
      type: Array,
      required: true,
    },
    showContinueAsSpeaker: {
      type: Boolean,
      default: false,
    },
    showContinueAsNotSet: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    uploads: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    speakersCount() {
      return this.speakers.length;
    },
    onlyOneSpeaker() {
      return this.speakersCount === 1;
    },
  },
  methods: {
    shareLinkUrl(item) {
      if (this.uploads.length === 0) {
        return '';
      }

      const upload = this.uploads.find((u) => u.speaker_id === item.id && u.final_recording);

      if (!upload || !upload.share_link_url) {
        return '';
      }

      return upload.share_link_url;
    },
    shareLinkIsSelfRecording(item) {
      if (this.uploads.length === 0) {
        return false;
      }

      const upload = this.uploads.find((u) => u.speaker_id === item.id && u.final_recording);

      if (!upload || !upload.share_link_url) {
        return false;
      }

      return upload.name === 'Self recording';
    },
    sorted(items) {
      this.$emit('sorted', items);
    },
    deleteSpeaker(data) {
      this.$emit('deleteSpeaker', data);
    },
    continueAs(data) {
      this.$emit('continueAs', data);
    },
  },
};
</script>

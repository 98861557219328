<template>
  <div class="flex items-center text-sm">
    <component :is="flagComponent" class="w-6 mr-2" />

    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'LanguageItem',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    flagComponent: {
      type: Object,
      default: null,
    },
  },
};
</script>

<template>
  <Tabs v-model="activeTab">
    <TabItem :visible="!hasClips || multiplePartsRecordingEnabled" :disabled="disabled" value="recorder">
      <template #trigger>
        <div class="flex items-center py-2 pr-1">
          <CameraSVG class="fill-white-1 w-6 h-6" />

          <span class="text-white-1 font-black ml-2 no-wrap">{{ $t('record.recorder') }}</span>
        </div>
      </template>

      <template #default>
        <Recorder class="mt-4" :disabled="disabled" />
      </template>
    </TabItem>

    <TabItem :disabled="disabled || !hasClips || isRecording" value="editor">
      <template #trigger>
        <div class="flex items-center py-2 pr-1">
          <ScissorsSVG class="fill-white-1 w-6 h-6" />

          <span class="text-white-1 font-black ml-2 no-wrap">{{ $t('record.editor') }}</span>
        </div>
      </template>

      <template #default="{ isActive }">
        <Editor class="mt-4" :disabled="disabled" :isActive="isActive" />
      </template>
    </TabItem>

    <TabItem :disabled="disabled || !hasClips || isRecording" value="notes">
      <template #trigger>
        <div class="flex items-center py-2 pr-1">
          <EditSVG class="fill-white-1 w-6 h-6" />

          <span class="text-white-1 font-black ml-2 no-wrap">{{ $t('record.notes') }}</span>
        </div>
      </template>

      <template #default>
        <PostProNote class="mt-4" :disabled="disabled" />
      </template>
    </TabItem>
  </Tabs>
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import TabItem from '@/components/TabItem.vue';
import Recorder from '@/components/Recorder.vue';
import Editor from '@/components/Editor.vue';
import PostProNote from '@/components/PostProNote.vue';

import CameraSVG from '@/assets/svgs/camera.svg';
import ScissorsSVG from '@/assets/svgs/scissors.svg';
import EditSVG from '@/assets/svgs/edit.svg';

export default {
  name: 'RecordTabs',
  components: {
    Tabs,
    TabItem,
    Recorder,
    Editor,
    PostProNote,
    CameraSVG,
    ScissorsSVG,
    EditSVG,
  },
  props: {
    hasClips: {
      type: Boolean,
      default: false,
    },
    multiplePartsRecordingEnabled: {
      type: Boolean,
      default: true,
    },
    isRecording: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: !this.hasClips || this.multiplePartsRecordingEnabled ? 'recorder' : 'editor',
    };
  },
  watch: {
    showRecorder(value) {
      if (value || this.activeTab !== 'recorder') {
        return;
      }

      this.activeTab = 'editor';
    },
    hasClips(value) {
      if (!value) {
        this.activeTab = 'recorder';
      }
    },
  },
};
</script>

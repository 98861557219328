<template>
  <div class="flex self-stretch p-4 w-full rounded-scrollbar">
    <EventOverview :hideRegister="hideRegister" />

    <WelcomeContent />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EventOverview from '@/components/EventOverview.vue';
import WelcomeContent from '@/components/WelcomeContent.vue';

export default {
  name: 'Welcome',
  components: {
    EventOverview,
    WelcomeContent,
  },
  computed: {
    ...mapState(['speakers', 'speakersInitialized']),
    hideRegister() {
      const unassignedSpeakers = this.speakers.filter((s) => !s.name && !s.email);

      return this.speakersInitialized && unassignedSpeakers.length === 0;
    },
  },
};
</script>

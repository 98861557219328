import Logger from '@/modules/logger';

import { getVideoThumbnail } from '@/helpers';

function getClipByTime(clips, time) {
  let prevDuration = 0;

  for (let i = 0; i < clips.length; i++) {
    const clip = clips[i];

    if (time < prevDuration + clip[clip.type].duration_ms) {
      return clip;
    }

    prevDuration += clip[clip.type].duration_ms;
  }

  return null;
}

export default function ({ commit, getters }, { cutAtMs }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;
    const cutClip = getClipByTime(version.timelines.speaker.video, cutAtMs);
    const startMs = cutClip[cutClip.type].start_ms;
    const newClipIndex = {};

    const timelines = Object.keys(version.timelines).reduce((result, timelineKey) => {
      result[timelineKey] = Object.keys(version.timelines[timelineKey]).reduce((timeline, kind) => {
        const clipIndex = version.timelines[timelineKey][kind].findIndex((c) => c[c.type].start_ms === startMs);

        timeline[kind] = JSON.parse(JSON.stringify(version.timelines[timelineKey][kind]));

        if (clipIndex < 0) {
          return timeline;
        }

        const clip = timeline[kind][clipIndex];
        const newClip = JSON.parse(JSON.stringify(clip));

        clip[clip.type].duration_ms = cutAtMs - startMs;
        newClip[newClip.type].start_ms = clip[clip.type].start_ms + clip[clip.type].duration_ms;
        newClip[newClip.type].in_cut_ms += clip[clip.type].duration_ms;
        newClip[newClip.type].duration_ms -= clip[clip.type].duration_ms;

        timeline[kind].splice(clipIndex + 1, 0, newClip);
        newClipIndex[timelineKey] = clipIndex + 1;

        return timeline;
      }, {});

      return result;
    }, {});

    const newData = {
      id: version.id,
      timelines,
      editor: {
        ...version.editor,
        history: [...version.editor.history],
        history_index: version.editor.history_index + 1,
      },
      manually_edited: true,
    };
    newData.editor.history.splice(newData.editor.history_index);
    newData.editor.history.push({
      duration: version.duration,
      removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
      timelines: JSON.parse(JSON.stringify(version.timelines)),
      manually_edited: version.manually_edited,
    });

    Logger.log(`Cut clip, at ms: ${cutAtMs}, timelines:`, newData.timelines);

    const finish = () => {
      commit('updateVersion', newData);
      resolve();
    };

    const speakerClip = timelines.speaker.video[newClipIndex.speaker];
    const slidesClip = newClipIndex.slides ? timelines.slides.video[newClipIndex.slides] : null;
    const thumbnailSource = slidesClip || speakerClip;
    const thumbnailSourceFile = version.files.find((f) => f.id === thumbnailSource[thumbnailSource.type].file_id);

    getVideoThumbnail(thumbnailSourceFile.url, speakerClip[speakerClip.type].in_cut_ms)
      .then((url) => {
        speakerClip.frontend = {
          thumbnail_url: url,
        };

        finish();
      })
      .catch(finish);
  });
}

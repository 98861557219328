import initialState from '../initial_state';

export default function (state) {
  const INITIAL_STATE = initialState();

  state.registerSpeaker = INITIAL_STATE.registerSpeaker;
  state.loggedInSpeakerId = INITIAL_STATE.loggedInSpeakerId;

  state.versions = INITIAL_STATE.versions;
  state.currentVersionId = INITIAL_STATE.currentVersionId;

  state.cameraRequested = INITIAL_STATE.cameraRequested;
  state.cameraEnabled = INITIAL_STATE.cameraEnabled;
  state.microphoneRequested = INITIAL_STATE.microphoneRequested;
  state.microphoneEnabled = INITIAL_STATE.microphoneEnabled;
  state.screenSharingRequested = INITIAL_STATE.screenSharingRequested;
  state.screenSharingEnabled = INITIAL_STATE.screenSharingEnabled;

  state.selfRecording = INITIAL_STATE.selfRecording;

  state.elapsed = INITIAL_STATE.elapsed;

  state.isUploadVisible = INITIAL_STATE.isUploadVisible;
  state.uploadingProgress = INITIAL_STATE.uploadingProgress;
}

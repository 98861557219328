var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center -mx-1"},[_c('div',{staticClass:"w-full flex items-start relative z-1",style:(_vm.fitViewportStyle)},[(_vm.isSpinnerVisible)?_c('OverlaySpinner',{staticClass:"z-2"},[(_vm.speakerRecorderProgress !== '')?_c('p',[_vm._v(_vm._s(_vm.$t('record.processing_video'))+": "+_vm._s(_vm.speakerProgressText))]):_vm._e(),(_vm.screenSharingRecorderProgress !== '')?_c('p',[_vm._v(" "+_vm._s(_vm.$t('record.processing_screen'))+": "+_vm._s(_vm.screenSharingProgressText)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex-1 relative z-1"},[_c('SpeakerStream',{staticClass:"border-4 border-solid",class:[
          {
            'border-transparent': !_vm.isRecording,
            'border-accent': _vm.isRecording,
          },
        ],scopedSlots:_vm._u([{key:"default",fn:function({ stream, enabled }){return [_c('MediaPreview',{attrs:{"stream":stream,"enabled":enabled,"errors":_vm.cameraErrors,"icon":"SpeakerSVG"},scopedSlots:_vm._u([{key:"default",fn:function({ klass }){return [_c('MicrophonePreview',{staticClass:"absolute left-2",class:klass,attrs:{"stream":stream,"errors":_vm.microphoneErrors,"loading":_vm.isWaitingForSpeakerStream,"dark":""}}),_c('RecordingState',{staticClass:"absolute top-2 right-4 h-8",attrs:{"isRecording":_vm.isRecording,"isPaused":_vm.isPaused}})]}}],null,true)})]}}])})],1),_c('div',{staticClass:"flex-1 relative z-1"},[_c('ScreenSharingStream',{staticClass:"border-4 border-solid",class:[
          {
            'border-transparent': !_vm.isRecording || !_vm.screenSharingEnabled || _vm.screenSharingErrors.length > 0,
            'border-accent': _vm.isRecording && _vm.screenSharingEnabled && _vm.screenSharingErrors.length === 0,
          },
        ],scopedSlots:_vm._u([{key:"default",fn:function({ stream, enabled }){return [_c('MediaPreview',{attrs:{"stream":stream,"enabled":enabled,"errors":_vm.screenSharingErrors,"icon":"PresentationSVG"}},[(enabled)?_c('RecordingState',{staticClass:"absolute top-2 right-4 h-8",attrs:{"isRecording":_vm.isRecording,"isPaused":_vm.isPaused}}):_vm._e()],1)]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Modal size="small" :active="active" @close="close">
    <div class="text-center px-4 py-8">
      <h2 class="text-2xl text-white-1">{{ $t('rename_speaker.title') }}</h2>

      <Form class="mt-4" @submit="confirm">
        <Input
          v-model="newValue"
          name="name"
          type="text"
          :placeholder="$t('placeholders.name')"
          class="block w-78 mx-auto"
          required
          :validateOnBlur="false"
        />

        <div class="mt-6">
          <Button type="submit" :label="$t('rename_speaker.confirm')" :loading="loading" />

          <Button class="ml-4" color="btn-primary-outline" :label="$t('cancel')" :loading="loading" @click="close" />
        </div>
      </Form>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';

export default {
  name: 'SpeakerRenameModal',
  components: { Modal, Button, Form, Input },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      newValue: '',
    };
  },
  computed: {
    ...mapState(['registerSpeaker', 'loggedInSpeakerId', 'speakers']),
    currentSpeaker() {
      if (this.registerSpeaker) {
        return this.registerSpeaker;
      }

      return this.speakers.find((s) => s.id === this.loggedInSpeakerId);
    },
  },
  watch: {
    active(value) {
      if (!value) {
        return;
      }

      this.newValue = this.currentSpeaker.name;
    },
  },
  methods: {
    ...mapMutations(['setKey']),
    ...mapActions(['updateSpeaker']),
    close() {
      this.$emit('close');
    },
    confirm() {
      if (this.registerSpeaker) {
        this.setKey({
          key: 'registerSpeaker',
          registerSpeaker: { ...this.registerSpeaker, name: this.newValue },
        });
        this.close();
        return;
      }

      this.loading = true;

      this.updateSpeaker({
        id: this.loggedInSpeakerId,
        name: this.newValue,
        email: this.currentSpeaker.email,
      }).then(() => {
        this.loading = false;
        this.close();
      });
    },
  },
};
</script>

<template>
  <ContainerWithHeaderAndBack>
    <div class="text-center pt-14 pb-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('speakers.title') }}</h1>

      <router-link v-if="speakers.length > 1" :to="{ name: 'SpeakersOrder' }" custom #default="{ href, navigate }">
        <Button
          :href="href"
          :loading="inProgress"
          :label="$t('speakers_order.reorder')"
          color="btn-primary-outline"
          class="w-64 mx-auto mt-6 no-underline hover:no-underline"
          block
          @click="navigate"
        />
      </router-link>

      <SpeakersList
        :showContinueAsNotSet="showContinueAs"
        :speakers="speakers"
        :showDelete="speakers.length > 1"
        class="mt-6 mx-auto w-88"
        @deleteSpeaker="triggerDeleteSpeaker"
        @continueAs="continueAs"
      />

      <Button
        :loading="inProgress"
        :label="$t('speakers_list.add_new')"
        color="btn-primary-outline"
        class="w-64 mt-6"
        @click="triggerAddSpeaker"
      />
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import Button from '@/components/Button.vue';
import SpeakersList from '@/components/SpeakersList.vue';

export default {
  name: 'Speakers',
  components: { ContainerWithHeaderAndBack, Button, SpeakersList },
  data() {
    return {
      inProgress: false,
    };
  },
  computed: {
    ...mapState(['speakers', 'registerSpeaker', 'loggedInSpeakerId']),
    showContinueAs() {
      return !!this.registerSpeaker;
    },
  },
  methods: {
    ...mapActions(['addSpeaker', 'deleteSpeaker', 'assignSpeaker']),
    triggerAddSpeaker() {
      this.inProgress = true;
      this.addSpeaker().finally(() => {
        this.inProgress = false;
      });
    },
    triggerDeleteSpeaker({ id }) {
      this.inProgress = true;
      this.deleteSpeaker({ id }).finally(() => {
        this.inProgress = false;
      });
    },
    continueAs({ id }) {
      const payload = {
        id,
        ...this.registerSpeaker,
      };

      this.assignSpeaker(payload)
        .then(() => {
          this.$router.push({ name: 'About' });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'min-h-50',
    'flex',
    'flex-column',
    'items-center',
    'justify-center',
    'p-4',
    'text-white-3',
    'text-base',
    'border',
    'border-dashed',
    'w-full',
    'rounded-lg',
    'text-center',
    {
      'bg-white-9': !_vm.readOnly && !_vm.disabled,
      'border-transparent': !_vm.readOnly,
      'border-white-3': _vm.readOnly,
    },
  ]},[(!_vm.readOnly && !_vm.disabled && _vm.title && !_vm.isLinkSelected)?_c('p',{staticClass:"text-xl text-white-1 font-bold mb-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.isLinkSelected)?_c('div',_vm._l((_vm.customValue),function(link,index){return _c('SelectedTag',{key:index,staticClass:"my-1",attrs:{"label":link,"disabled":_vm.disabled || _vm.readOnly},on:{"delete":function($event){return _vm.deleteLink(index)},"click":function($event){$event.stopPropagation();}}})}),1):_vm._e(),_c('Form',{staticClass:"w-full",on:{"submit":_vm.addLink}},[(!_vm.readOnly && !_vm.disabled)?_c('Input',{staticClass:"w-7/8 max-w-80 mt-3 mx-auto",attrs:{"name":"url","type":"url","placeholder":_vm.placeholder,"constraints":_vm.linkConstraints,"required":"","validateOnBlur":false},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):_vm._e(),(!_vm.readOnly && !_vm.disabled)?_c('Button',{staticClass:"w-7/8 max-w-80 mt-3",attrs:{"type":"submit","color":"btn-primary-outline","label":_vm.selectButtonText || _vm.$t('select')}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
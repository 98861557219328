<template>
  <div class="flex items-center justify-center">
    <Button
      v-if="startVisible"
      class="mx-2 w-65"
      color="btn-accent"
      :label="$t('recorder.start_recording')"
      :loading="isWaitingForStreams || isCountdownModalActive || isWaitingForWebMFiles"
      :disabled="disabled"
      @click="startRecording"
    >
      <template #icon>
        <DotSVG class="mr-2 w-6 h-6" />
      </template>
    </Button>

    <Button
      v-else-if="continueVisible"
      class="mx-2 w-65"
      color="btn-accent-outline"
      :label="$t('recorder.continue_recording')"
      :loading="isWaitingForStreams || isCountdownModalActive || isWaitingForWebMFiles"
      :disabled="disabled"
      @click="continueRecording"
    >
      <template #icon>
        <DotSVG class="mr-2 w-6 h-6" />
      </template>
    </Button>

    <Button
      v-else-if="pauseVisible"
      class="mx-2 w-65"
      color="btn-accent-outline"
      :label="$t('recorder.pause_recording')"
      :disabled="disabled"
      @click="pauseRecording"
    >
      <template #icon>
        <PauseSVG class="mr-2 w-6 h-6" />
      </template>
    </Button>

    <Button
      v-if="stopVisible"
      class="mx-2 w-65"
      color="btn-accent"
      :label="$t('recorder.stop_recording')"
      :disabled="disabled"
      @click="stopRecording"
    >
      <template #icon>
        <StopSVG class="mr-2 w-6 h-6" />
      </template>
    </Button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Button from '@/components/Button.vue';

import DotSVG from '@/assets/svgs/dot.svg';
import PauseSVG from '@/assets/svgs/pause.svg';
import StopSVG from '@/assets/svgs/stop.svg';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import { upsertJSONFile } from '@/helpers';

export default {
  name: 'RecorderActions',
  components: { Button, DotSVG, PauseSVG, StopSVG },
  mixins: [ErrorNotificationsMixin],
  props: {
    version: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'recorderToken',
      'isRecording',
      'isPaused',
      'isWaitingForStreams',
      'speakerStream',
      'screenSharingEnabled',
      'screenSharingStream',
      'multiplePartsRecordingEnabled',
      'isWaitingForStreams',
      'isWaitingForWebMFiles',
      'isCountdownModalActive',
    ]),
    hasClips() {
      return this.version.timelines.speaker.video.length > 0;
    },
    startVisible() {
      return !this.hasClips && !this.isRecording;
    },
    continueVisible() {
      return this.hasClips && this.multiplePartsRecordingEnabled && !this.isRecording;
    },
    pauseVisible() {
      return this.isRecording && this.multiplePartsRecordingEnabled;
    },
    stopVisible() {
      return this.isRecording || this.isPaused;
    },
  },
  watch: {
    speakerStream(value) {
      if (!this.isWaitingForStreams || !value) {
        return;
      }

      if (!this.screenSharingEnabled || this.screenSharingStream) {
        this.startRecording();
      }
    },
    screenSharingStream(value) {
      if (!this.isWaitingForStreams || !value) {
        return;
      }

      if (this.speakerStream) {
        this.startRecording();
      }
    },
  },
  methods: {
    ...mapMutations(['setKey']),
    ...mapActions(['triggerStartRecording', 'triggerStopRecording']),
    startRecording() {
      this.setKey({ key: 'recordingStartRequested', recordingStartRequested: true });

      this.triggerStartRecording()
        .then((data) => {
          if (!data) {
            return;
          }

          this.setKey({ key: 'recordingStartRequested', recordingStartRequested: false });
          this.handleToggleRecording();
        })
        .catch((errors) => {
          this.setKey({ key: 'recordingStartRequested', recordingStartRequested: false });

          if (errors.forEach) {
            errors.forEach((error) => {
              this.notificationFromError([error, 'recorder_reload_and_try_again']);
            });
          } else {
            this.notificationFromError([errors, 'recorder_reload_and_try_again']);
          }
        });
    },
    stopRecording(payload) {
      this.triggerStopRecording(payload)
        .then((data) => {
          if (!data) {
            return;
          }

          this.handleToggleRecording();
        })
        .catch((errors) => {
          errors.forEach((error) => {
            this.notificationFromError([error, 'recorder_reload_and_try_again']);
          });
        });
    },
    continueRecording() {
      this.startRecording();
    },
    pauseRecording() {
      this.stopRecording({ paused: true });
    },
    handleToggleRecording() {
      upsertJSONFile(this.version);
    },
  },
};
</script>

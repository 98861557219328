import Welcome from '@/views/Welcome.vue';
import Share from '@/views/Share.vue';
import Embed from '@/views/Embed.vue';
import Register from '@/views/Register.vue';
import SpeakersCount from '@/views/SpeakersCount.vue';
import Speakers from '@/views/Speakers.vue';
import SpeakersOrder from '@/views/SpeakersOrder.vue';
import About from '@/views/About.vue';
import Help from '@/views/Help.vue';
import SelfRecording from '@/views/SelfRecording.vue';
import SelfRecordingUpload from '@/views/SelfRecordingUpload.vue';
import Setup from '@/views/Setup.vue';
import Record from '@/views/Record.vue';
import RecordedTokens from '@/views/RecordedTokens.vue';

export default [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      title: '',
    },
  },
  {
    path: '/share',
    name: 'Share',
    component: Share,
    meta: {
      title: 'share',
    },
  },
  {
    path: '/embed',
    name: 'Embed',
    component: Embed,
    meta: {
      title: 'embed',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'register',
    },
  },
  {
    path: '/speakers-count',
    name: 'SpeakersCount',
    component: SpeakersCount,
    meta: {
      title: 'speakers_count',
    },
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers,
    meta: {
      title: 'speakers',
    },
  },
  {
    path: '/speakers-order',
    name: 'SpeakersOrder',
    component: SpeakersOrder,
    meta: {
      title: 'speakers_order',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'about',
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      title: 'help',
    },
  },
  {
    path: '/self-recording',
    name: 'SelfRecording',
    component: SelfRecording,
    meta: {
      title: 'self_recording',
    },
  },
  {
    path: '/self-recording/new',
    name: 'SelfRecordingUpload',
    component: SelfRecordingUpload,
    meta: {
      title: 'self_recording_upload',
    },
  },
  {
    path: '/setup',
    name: 'Setup',
    component: Setup,
    meta: {
      title: 'setup',
    },
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
    meta: {
      title: 'record',
    },
  },
  {
    path: '/recorded-tokens',
    name: 'RecordedTokens',
    component: RecordedTokens,
    meta: {
      title: 'recorded_tokens',
    },
  },
];

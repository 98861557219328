import iterateVersionThroughMigrations from './iterate_version_through_migrations';
import flagVersionDeletedFiles from './flag_version_deleted_files';
import removeVersionAttachments from './remove_version_attachments';

export default function (stringJSON, existingFiles) {
  let version;

  try {
    version = JSON.parse(stringJSON);
  } catch (e) {
    return Promise.reject(e);
  }

  version = iterateVersionThroughMigrations(version);
  version = removeVersionAttachments(version);

  return flagVersionDeletedFiles(version, existingFiles);
}

/* eslint-disable import/prefer-default-export */

import changeLocale from './change_locale';
import authCheck from './auth_check';
import logging from './logging';
import titleUpdate from './title_update';

function registerHooks(router, store, i18n) {
  router.beforeEach((to, from, next) => changeLocale(i18n, store, to, from, next));
  router.beforeEach((to, from, next) => authCheck(store, to, from, next));
  router.beforeEach(logging);
  router.afterEach((to) => titleUpdate(i18n, to));
}

export { registerHooks };

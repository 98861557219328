import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }) {
  const requestData = {
    speakers_token: state.speakersToken,
    speaker_count: state.speakers.length + 1,
  };

  Logger.log(`Add speaker, count: ${requestData.speaker_count}`);

  return makeRequest('POST', `${API_URL}/tokens/${state.tokenId}/speakers/count`, requestData).then((response) => {
    commit('setKey', { key: 'speakers', speakers: response.data });
  });
}

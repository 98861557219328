function readEntries(reader) {
  return new Promise((resolve, reject) => {
    reader.readEntries(
      (entries) => {
        if (entries.length === 0) {
          resolve(entries);
          return;
        }

        readEntries(reader)
          .then((ent) => resolve(entries.concat(ent)))
          .catch((err) => reject(err));
      },
      (error) => reject(error),
    );
  });
}

export default readEntries;

import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';
import Logger from '@/modules/logger';

import { makeRequest, sendErrorToSentry, errorToObject, iterateVersionThroughMigrations } from '@/helpers';

import { API_URL } from '@/constants';

export default function (store, { tokenId, shareToken, embed = 'no' }) {
  if (!tokenId || !shareToken) {
    const errors = [];

    if (!tokenId) {
      errors.push(new Error('token_id_is_required'));
    }

    if (!shareToken) {
      errors.push(new Error('share_token_is_required'));
    }

    return Promise.reject(errors);
  }

  return makeRequest('GET', `${API_URL}/token_uploads/${tokenId}/share?share_token=${shareToken}&embed=${embed}`)
    .then((response) => {
      SentryAddBreadcrumb({
        category: 'download_version_json_from_cloud',
        message: `Response data`,
        data: Object.keys(response).reduce((obj, key) => {
          obj[key] = typeof response[key] === 'boolean' ? `${response[key]}` : response[key];

          return obj;
        }, {}),
        level: Severity.Debug,
      });
      Logger.log(`DownloadVersionJSONFromCloud: ${JSON.stringify(response.data)}`);

      const jsonFileUrl = response.data.upload.files['slrec-json'];
      if (!jsonFileUrl) {
        throw new Error('share_missing_json');
      }

      const jsonFetch = fetch(jsonFileUrl).then((jsonResponse) => {
        if (
          jsonResponse.status < 200 ||
          jsonResponse.status >= 400 ||
          !jsonResponse.headers.get('content-type').includes('application/json')
        ) {
          return jsonResponse
            .text()
            .catch((err) => {
              const error = new Error('share_loading_failed', { cause: err });
              error.statusCode = jsonResponse.status;
              throw error;
            })
            .then((errorText) => {
              if (errorText.includes('InvalidObjectState')) {
                throw new Error('share_expired');
              }

              const error = new Error('share_loading_failed');
              error.statusCode = jsonResponse.status;
              throw error;
            });
        }

        return jsonResponse.json();
      });

      return Promise.all([response.data, jsonFetch]);
    })
    .then(([data, jsonData]) => {
      jsonData = iterateVersionThroughMigrations(jsonData);
      jsonData.files = jsonData.files.filter((f) => f.type !== 'attachment');
      jsonData.files = jsonData.files.map((file) => {
        file.url = data.upload.files[file.id];

        if (!file.url) {
          throw new Error('share_missing_file');
        }

        return file;
      });

      const shareData = {
        json: jsonData,
        token: data.token,
        upload: data.upload,
      };

      return shareData;
    })
    .catch((error) => {
      if (error.customMessage === 'page_not_found' && error.statusCode === 404) {
        error.message = 'token_upload_not_found';
      }

      Logger.log(`FetchShareData error: ${error.name}; ${JSON.stringify(errorToObject(error))}`);

      const ignoredErrorNames = ['TypeError', 'AbortError'];
      const ignoredErrorMessages = [
        'share_token_event_done',
        'share_expired',
        'token_upload_not_uploaded',
        'token_upload_not_found',
        'invalid_share_token',
      ];

      if (!ignoredErrorNames.includes(error.name) && !ignoredErrorMessages.includes(error.message)) {
        sendErrorToSentry('DOWNLOAD VERSION JSON FROM CLOUD ERROR', error);
      }

      throw error;
    });
}

<template>
  <div class="overflow-auto pt-14 pb-1 px-1" @scroll="scrollCursor">
    <div
      ref="timelineContainer"
      :class="[
        'min-w-full',
        'px-4',
        'pt-1',
        'relative',
        'rounded',
        'group-focus:bg-accent-8',
        'group-focus-within:bg-accent-8',
      ]"
      :style="`width: ${computedContainerWidth}px;`"
      @mousemove="moveCursor"
      @mouseenter="showCursor"
      @mouseleave="hideCursor"
      @click="seekToCursor"
      @focusin="showCursor"
      @focusout="hideCursor"
      @keydown.left.right.prevent="moveCursor"
    >
      <div
        ref="cursor"
        :class="[
          'border-y-0',
          'border-x',
          'border-solid',
          'border-white-3',
          'events-none',
          'absolute',
          'top-0',
          'bottom-5',
          'z-2',
          {
            hidden: !cursorVisible,
          },
        ]"
        :style="`left: ${computedCursorLeft}px;`"
      />

      <EditorCurrentPosition
        v-if="timelines.speaker.video.length > 0"
        class="ml-4 z-3"
        :elapsed="elapsed"
        :scale="computedScale"
        :loading="loading"
        @cut="cutClipAtCurrentElapsed"
      />

      <EditorClips
        class="z-1"
        :scale="computedScale"
        :timelines="timelines"
        :files="files"
        :currentClipIndex="currentClipIndex"
        @reorder="reorderClips"
        @removeClip="removeClip"
      />

      <EditorTimes class="relative z-1 pt-3 pb-5" :partsCount="timelineParts" :partSize="computedPartSize" />
    </div>
  </div>
</template>

<script>
import EditorClips from '@/components/EditorClips.vue';
import EditorCurrentPosition from '@/components/EditorCurrentPosition.vue';
import EditorTimes from '@/components/EditorTimes.vue';

import { getOffset } from '@/helpers';

export default {
  name: 'EditorTimeline',
  model: {
    prop: 'scale',
    event: 'scaleChanged',
  },
  components: { EditorClips, EditorCurrentPosition, EditorTimes },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      required: true,
    },
    elapsed: {
      type: Number,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
    timelines: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    currentClipIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      initialized: false,
      newScale: this.scale,
      timelineParts: 0,
      cursorVisible: false,
      cursorLeft: 0,
      scrollLeft: 0,
    };
  },
  computed: {
    containerLeftOffset() {
      return getOffset('left', this.$refs.timelineContainer, true);
    },
    containerPadding() {
      const containerStyle = window.getComputedStyle(this.$refs.timelineContainer);
      const paddingLeft = parseInt(containerStyle.paddingLeft, 10);
      const paddingRight = parseInt(containerStyle.paddingRight, 10);

      return paddingLeft + paddingRight;
    },
    viewportWidth() {
      return this.$refs.timelineContainer.clientWidth - this.containerPadding;
    },
    computedContainerWidth() {
      if (!this.isActive || !this.initialized) {
        return 0;
      }

      return (this.timelineParts - 1) * this.computedPartSize + 1 + this.containerPadding;
    },
    computedPartSize() {
      return this.computedScale;
    },
    computedScale: {
      get() {
        return this.newScale;
      },
      set(value) {
        this.newScale = value;
        this.computeLayout();
      },
    },
    computedCursorLeft() {
      if (!this.initialized) {
        return 0;
      }

      const left = this.cursorLeft + this.scrollLeft;
      const min = this.containerPadding / 2;
      const max = this.$refs.timelineContainer.scrollWidth - this.containerPadding / 2;

      return Math.min(Math.max(left, min), max);
    },
    initialScale() {
      const minParts = Math.ceil((this.duration * 1.15) / 1000);
      const fitParts = Math.floor(this.viewportWidth);

      return Math.max(fitParts / minParts, 1);
    },
  },
  methods: {
    scrollCursor(event) {
      this.scrollLeft = event.currentTarget.scrollLeft;
    },
    moveCursor(event) {
      const left = event.pageX - this.containerLeftOffset;
      this.cursorLeft = left;
    },
    showCursor() {
      this.cursorVisible = true;
    },
    hideCursor() {
      this.cursorVisible = false;
    },
    seekToCursor() {
      let newElapsed = this.timeFromCursor();
      newElapsed = Math.round(Math.min(Math.max(newElapsed, 0), this.duration));

      this.$emit('seekTo', newElapsed);
    },
    reorderClips(clipsStart) {
      this.$emit('reorderClips', clipsStart);
    },
    removeClip(index) {
      this.$emit('removeClip', index);
    },
    cutClipAtCurrentElapsed() {
      this.$emit('cutClip');
    },
    fitToScreen() {
      this.computedScale = this.initialScale;
      this.$emit('scaleChanged', this.computedScale);
    },
    timeFromCursor() {
      return ((this.computedCursorLeft - this.containerPadding / 2) / this.computedScale) * 1000;
    },
    computeLayout() {
      const minParts = Math.ceil((this.duration * 1.15) / 1000) + 1;
      const fitParts = Math.floor((this.viewportWidth - 1) / this.computedPartSize) + 1;

      this.timelineParts = Math.max(minParts, fitParts);
    },
  },
  watch: {
    isActive(value) {
      if (!value) {
        return;
      }

      if (!this.initialized) {
        this.initialized = true;

        setTimeout(() => {
          this.fitToScreen();
        }, 100); // timeout to wait for the element to show for the correct width

        return;
      }

      this.computeLayout();
    },
    scale(value) {
      if (value === this.computedScale) {
        return;
      }

      this.computedScale = value;
    },
  },
  mounted() {
    if (this.isActive) {
      this.initialized = true;
      this.fitToScreen();
    }
  },
};
</script>

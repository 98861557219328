import ResumableUpload from '@/modules/resumable_upload';

import { API_URL } from '@/constants';

export default function ({ commit, dispatch, state }, { uploadId, uploadToken }) {
  return new Promise((resolve) => {
    const initResumableUpload = () => {
      const url = `${API_URL}/token_uploads/${uploadId}/start_upload`;
      const resumableUpload = new ResumableUpload({
        uploadInfoEndpoint: url,
        uploadInfoRequest: {
          noCsrfTokenHeader: true,
        },
        uploadInfoData: {
          upload_token: uploadToken,
        },
      });

      commit('setKey', { key: 'resumableUpload', resumableUpload });

      resolve(resumableUpload);
    };

    if (state.resumableUpload) {
      dispatch('destroyResumableUpload').then(() => {
        initResumableUpload();
      });
      return;
    }

    initResumableUpload();
  });
}

<template>
  <div class="w-full container-lg m-auto py-4">
    <h1 class="text-3xl text-white-1 text-center">{{ $t('self_recording_upload.title') }}</h1>

    <p class="font-light text-center mt-3">
      {{ $t('self_recording_upload.message') }}
      <router-link :to="{ name: 'SelfRecording' }" custom #default="{ href, navigate }">
        <a :href="href" @click="navigate">{{ $t('self_recording_upload.message_requirements') }}</a> </router-link
      >.
    </p>

    <SelfRecordingFiles class="mt-6" :readOnly="isUploadVisible" />

    <Button
      v-if="!isUploadVisible"
      :disabled="continueDisabled"
      :label="$t('self_recording_upload.upload_as_final')"
      block
      class="w-64 mx-auto mt-6"
      @click="submit"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SelfRecordingFiles from '@/components/SelfRecordingFiles.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'SelfRecordingNew',
  components: { SelfRecordingFiles, Button },
  computed: {
    ...mapState(['selfRecording', 'isUploadVisible', 'allowSelfUploadWithOneFile']),
    continueDisabled() {
      const speakerFile = this.selfRecording.files.some((f) => f[f.type] && f[f.type].type === 'speaker');
      const slidesFile = this.selfRecording.files.some((f) => f[f.type] && f[f.type].type === 'slides');

      return this.allowSelfUploadWithOneFile ? !speakerFile && !slidesFile : !speakerFile || !slidesFile;
    },
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

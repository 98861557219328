function versionName(versions, name) {
  const copyNumberRegexp = /( \(([\d]+)\))?$/;

  name = name.replace(copyNumberRegexp, '');

  const nameRegexp = new RegExp(`^${name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')} \\(([\\d]+)\\)$`);
  const versionsIds = Object.keys(versions);
  const copiesNumbers = versionsIds
    .filter((vId) => versions[vId].version_name.match(nameRegexp))
    .map((vId) => parseInt(versions[vId].version_name.match(copyNumberRegexp)[2], 10));

  const copyNumber = copiesNumbers.length > 0 ? Math.max(...copiesNumbers) + 1 : 2;

  return `${name} (${copyNumber})`;
}

export default function (versions, version) {
  return versionName(versions, version.version_name.trim());
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-column',
    'items-stretch',
    'justify-center',
    'px-6',
    'py-4',
    'min-h-17',
    'border-y',
    'border-r',
    'border-dashed',
    'border-white-3',
    'rounded-lg',
    'w-full',
    {
      'border-l-0': _vm.showHandle,
      'border-l': !_vm.showHandle,
    },
  ]},[(_vm.showHandle)?_c('div',{class:[
      'handle',
      'absolute',
      'flex',
      'items-center',
      'rounded-lg',
      'bg-white-7',
      'left-0',
      'inset-y-0',
      'cursor-grab',
    ]},[_c('VerticalDostSVG',{staticClass:"fill-white-3 w-4 h-4"})],1):_vm._e(),_c('div',{staticClass:"flex justify-between items-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('speakers_list.not_set')))]),(_vm.showDelete)?_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"p-0 rounded-full w-8 h-8",attrs:{"disableMinHeight":"","rounded":false,"color":"btn-transparent-white"},on:{"click":_vm.deleteSpeaker},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('TrashSVG',{staticClass:"w-5 h-5"})]},proxy:true}],null,false,2560183012)})]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.$t('speakers_list.delete_speaker')))])]},proxy:true}],null,false,170952248)}):_vm._e()],1),(_vm.showContinueAs)?_c('Button',{staticClass:"mt-4 w-full",attrs:{"label":_vm.$t('speakers_list.continue_as_speaker', { position: _vm.speakerPosition }),"block":""},on:{"click":_vm.continueAs}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default function (state, payload) {
  const now = new Date().toISOString();

  if (!payload.skipUpdatedAt) {
    payload.updated_at = now;
  }
  delete payload.skipUpdatedAt;

  const versions = {
    ...state.versions,
    [payload.id]: {
      ...(state.versions[payload.id] || {}),
      ...payload,
    },
  };

  state.versions = versions;
}

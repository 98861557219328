<template>
  <div
    :class="[
      'focus-within:outline-primary',
      'focus:z-1',
      'relative',
      'oveflow-hidden',
      {
        'rounded': rounded,
        'inline-flex': !block,
        'flex': block,
      },
    ]"
  >
    <input
      :class="[
        'flex-1',
        'text-white-3',
        'text-base',
        'placeholder-white-5',
        'bg-white-7',
        'border',
        'border-solid',
        'border-transparent',
        'outline-none',
        'w-full',
        'text-center',
        {
          'rounded-l': rounded,
          'min-h-9': this.size === 'small',
          'px-3': this.size === 'small',
          'py-1': this.size === 'small',
          'min-h-13': this.size === 'medium',
          'px-5': this.size === 'medium',
          'py-3': this.size === 'medium',
          'min-h-15': this.size === 'large',
          'px-7': this.size === 'large',
          'py-5': this.size === 'large',
        },
      ]"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="computedValue"
      :step="minStepNumber"
      :max="max"
      :min="min"
      :size="size"
      :disabled="disabled"
    />

    <div class="flex flex-column w-7">
      <Button
        type="button"
        :rounded="false"
        color="btn-white-white"
        class="flex-1 p-0"
        :class="[{ 'rounded-tr': rounded }]"
        :disabled="disabled || disabledMax"
        @mousedown="handleStartLongPress($event, true)"
        @touchstart.prevent="handleStartLongPress($event, true)"
        @click="handleControlClick($event, true)"
        @mouseup="handleStopLongPress"
        @mouseleave="handleStopLongPress"
        @touchend="handleStopLongPress"
        @touchcancel="handleStopLongPress"
        @focusin="handleStartLongPress($event, true)"
        @focusout="handleStopLongPress"
      >
        <template #icon>
          <UpArrowSVG class="w-5 h-5" />
        </template>
      </Button>

      <Button
        type="button"
        :rounded="false"
        color="btn-white-white"
        class="flex-1 p-0"
        :class="[{ 'rounded-br': rounded }]"
        :disabled="disabled || disabledMin"
        @mousedown="handleStartLongPress($event, false)"
        @touchstart.prevent="handleStartLongPress($event, false)"
        @click="handleControlClick($event, false)"
        @mouseup="handleStopLongPress"
        @mouseleave="handleStopLongPress"
        @touchend="handleStopLongPress"
        @touchcancel="handleStopLongPress"
        @focusin="handleStartLongPress($event, false)"
        @focusout="handleStopLongPress"
      >
        <template #icon>
          <DownArrowSVG class="w-5 h-5" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import UpArrowSVG from '@/assets/svgs/up_arrow.svg';
import DownArrowSVG from '@/assets/svgs/down_arrow.svg';

export default {
  name: 'NumberInput',
  components: { Button, UpArrowSVG, DownArrowSVG },
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  props: {
    customValue: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: [Number, String],
      default: undefined,
    },
    max: {
      type: [Number, String],
      default: undefined,
    },
    step: {
      type: [Number, String],
      default: undefined,
    },
    minStep: {
      type: [Number, String],
      default: undefined,
    },
    exponential: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.customValue,
      newStep: this.step || 1,
      newMinStep: this.minStep,
      timesPressed: 1,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        let newValue = value;

        if (value === '' || value === undefined || value === null) {
          if (this.minNumber !== undefined) {
            newValue = this.minNumber;
          } else {
            newValue = null;
          }
        }

        this.newValue = newValue;

        if (!Number.isNaN(newValue) && newValue !== null && newValue !== '-0') {
          this.$emit('customInput', Number(newValue));
        }
      },
    },
    minNumber() {
      return typeof this.min === 'string' ? parseFloat(this.min) : this.min;
    },
    maxNumber() {
      return typeof this.max === 'string' ? parseFloat(this.max) : this.max;
    },
    stepNumber() {
      return typeof this.newStep === 'string' ? parseFloat(this.newStep) : this.newStep;
    },
    minStepNumber() {
      const step = typeof this.newMinStep !== 'undefined' ? this.newMinStep : this.newStep;
      return typeof step === 'string' ? parseFloat(step) : step;
    },
    disabledMin() {
      return this.computedValue - this.stepNumber < this.minNumber;
    },
    disabledMax() {
      return this.computedValue + this.stepNumber > this.maxNumber;
    },
    stepDecimals() {
      const step = this.minStepNumber.toString();
      const index = step.indexOf('.');

      if (index >= 0) {
        return step.substring(index + 1).length;
      }

      return 0;
    },
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
    step(value) {
      this.newStep = value;
    },
    minStep(value) {
      this.newMinStep = value;
    },
  },
  methods: {
    decrement() {
      if (typeof this.minNumber === 'undefined' || this.computedValue - this.stepNumber >= this.minNumber) {
        if (this.computedValue === null || typeof this.computedValue === 'undefined') {
          if (this.maxNumber) {
            this.computedValue = this.maxNumber;
            return;
          }

          this.computedValue = 0;
        }

        const value = this.computedValue - this.stepNumber;
        this.computedValue = Number(value.toFixed(this.stepDecimals));
      }
    },
    increment() {
      if (typeof this.maxNumber === 'undefined' || this.computedValue + this.stepNumber <= this.maxNumber) {
        if (this.computedValue === null || typeof this.computedValue === 'undefined') {
          if (this.minNumber) {
            this.computedValue = this.minNumber;
            return;
          }

          this.computedValue = 0;
        }

        const value = this.computedValue + this.stepNumber;
        this.computedValue = Number(value.toFixed(this.stepDecimals));
      }
    },
    handleControlClick(event, inc) {
      if (event.detail !== 0 || event.type !== 'click') {
        return;
      }

      if (inc) {
        this.increment();
      } else {
        this.decrement();
      }
    },
    longPressTick(inc) {
      if (inc) {
        this.increment();
      } else {
        this.decrement();
      }

      this._$intervalRef = setTimeout(
        () => this.longPressTick(inc),
        this.exponential ? 250 / (this.exponential * this.timesPressed++) : 250,
      );
    },
    handleStartLongPress(event, inc) {
      clearTimeout(this._$intervalRef);

      this.longPressTick(inc);
    },
    handleStopLongPress() {
      if (!this._$intervalRef) {
        return;
      }

      this.timesPressed = 1;

      clearTimeout(this._$intervalRef);

      this._$intervalRef = null;
    },
  },
};
</script>

<template>
  <div class="flex flex-column items-center justify-center">
    <div
      :class="[
        'flex',
        'items-center',
        'justify-center',
        'rounded-full',
        'bg-white-8',
        'text-white-3',
        'font-black',
        ...sizeClasses,
        {
          'ordered-list-number': number === 'css',
        },
      ]"
    >
      {{ numberText }}
    </div>

    <p v-if="label" class="text-white-3 text-xs font-medium">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: 'OrderedListItem',
  props: {
    number: {
      type: [Number, String],
      validate: (value) => (typeof value === 'string' && value === 'css') || !Number.isNaN(Number(value)),
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    numberText() {
      if (this.number === 'css') {
        return '';
      }

      return `${this.number}.`;
    },
    sizeClasses() {
      const result = [];

      if (this.size === 'small') {
        result.push('w-7');
        result.push('h-7');
      } else {
        result.push('w-10');
        result.push('h-10');
      }

      return result;
    },
  },
};
</script>

<style lang="scss">
.ordered-list-number {
  counter-increment: ordered-list-counter;

  &::before {
    content: counter(ordered-list-counter) '.';
  }
}
</style>

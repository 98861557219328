<template>
  <div class="text-center px-4 py-8">
    <h2 class="text-2xl text-white-1">{{ $t('download.modal.title') }}</h2>

    <p class="font-light mt-3">{{ $t('download.modal.message') }}</p>

    <p v-if="cloudDownloadProgress.totalBytes > 1" class="text-sm mt-3">
      {{ $t('download.files') }}:
      <span class="font-bold">
        {{ this.cloudDownloadProgress.totalFiles - this.cloudDownloadProgress.pendingFiles }}
        /
        {{ this.cloudDownloadProgress.totalFiles }}
      </span>
      <br />
      {{ $t('download.size') }}:
      <span class="font-bold">
        {{ humanFileSize(this.cloudDownloadProgress.totalDownloadedBytes) }}
        /
        {{ humanFileSize(this.cloudDownloadProgress.totalBytes) }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { humanFileSize, formatTime } from '@/helpers';

export default {
  name: 'VersionDownloadModal',
  mixins: [{ methods: { humanFileSize, formatTime } }],
  computed: {
    ...mapState(['cloudDownloadProgress']),
    progressBarActiveStyle() {
      return `width: ${this.percent}%;`;
    },
    percent() {
      if (this.cloudDownloadProgress.totalProgress >= 100) {
        return 100;
      }

      return Math.min(Math.round(this.cloudDownloadProgress.totalProgress * 10) / 10, 99.9);
    },
    percentText() {
      return Number(this.percent).toFixed(1);
    },
  },
};
</script>

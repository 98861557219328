export default function readAsArrayBuffer(blob, progressCallback = null) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    if (progressCallback) {
      reader.onprogress = (event) => {
        progressCallback({
          done: event.loaded === event.total,
          size: event.total,
          processed: event.loaded,
        });
      };
    }

    reader.onerror = (event) => {
      reject(event.error);
    };

    reader.readAsArrayBuffer(blob);
  });
}

import Logger from '@/modules/logger';

export default function ({ commit, getters }, { index }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;
    const timelines = JSON.parse(JSON.stringify(version.timelines));
    const removedClips = JSON.parse(JSON.stringify(version.editor.removed_clips));
    const clips = removedClips.splice(index, 1)[0];

    delete clips.removedAt;

    Object.keys(clips).forEach((timelineKey) => {
      Object.keys(clips[timelineKey]).forEach((kind) => {
        const clip = clips[timelineKey][kind];

        clip[clip.type].start_ms = version.duration;

        timelines[timelineKey][kind].push(clip);
      });
    });

    const newData = {
      id: version.id,
      timelines,
      editor: {
        removed_clips: removedClips,
        history: [...version.editor.history],
        history_index: version.editor.history_index + 1,
      },
      duration: version.duration + clips.speaker.video[clips.speaker.video.type].duration_ms,
      manually_edited: true,
    };
    newData.editor.history.splice(newData.editor.history_index);
    newData.editor.history.push({
      duration: version.duration,
      removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
      timelines: JSON.parse(JSON.stringify(version.timelines)),
      manually_edited: version.manually_edited,
    });

    Logger.log('Restore clip, timelines:', newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-auto pt-14 pb-1 px-1",on:{"scroll":_vm.scrollCursor}},[_c('div',{ref:"timelineContainer",class:[
      'min-w-full',
      'px-4',
      'pt-1',
      'relative',
      'rounded',
      'group-focus:bg-accent-8',
      'group-focus-within:bg-accent-8',
    ],style:(`width: ${_vm.computedContainerWidth}px;`),on:{"mousemove":_vm.moveCursor,"mouseenter":_vm.showCursor,"mouseleave":_vm.hideCursor,"click":_vm.seekToCursor,"focusin":_vm.showCursor,"focusout":_vm.hideCursor,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 0)return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();return _vm.moveCursor.apply(null, arguments)}}},[_c('div',{ref:"cursor",class:[
        'border-y-0',
        'border-x',
        'border-solid',
        'border-white-3',
        'events-none',
        'absolute',
        'top-0',
        'bottom-5',
        'z-2',
        {
          hidden: !_vm.cursorVisible,
        },
      ],style:(`left: ${_vm.computedCursorLeft}px;`)}),(_vm.timelines.speaker.video.length > 0)?_c('EditorCurrentPosition',{staticClass:"ml-4 z-3",attrs:{"elapsed":_vm.elapsed,"scale":_vm.computedScale,"loading":_vm.loading},on:{"cut":_vm.cutClipAtCurrentElapsed}}):_vm._e(),_c('EditorClips',{staticClass:"z-1",attrs:{"scale":_vm.computedScale,"timelines":_vm.timelines,"files":_vm.files,"currentClipIndex":_vm.currentClipIndex},on:{"reorder":_vm.reorderClips,"removeClip":_vm.removeClip}}),_c('EditorTimes',{staticClass:"relative z-1 pt-3 pb-5",attrs:{"partsCount":_vm.timelineParts,"partSize":_vm.computedPartSize}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
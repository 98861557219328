<template>
  <div class="bg-white-9 rounded-lg inline-flex">
    <span
      :class="['flex', 'items-center', 'flex-1', 'text-white-3', 'text-base', 'w-full', 'text-center', 'px-5', 'py-3']"
    >
      <CheckCircleSVG class="fill-green w-6 h-6 mr-2" />

      {{ label }}
    </span>

    <Button
      v-if="!disabled"
      type="button"
      size="small"
      color="btn-transparent-white"
      class="rounded-r"
      :rounded="false"
      @click.stop="handleDelete"
    >
      <template #icon>
        <CrossSVG class="w-5 h-5" />
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import CheckCircleSVG from '@/assets/svgs/check_circle.svg';
import CrossSVG from '@/assets/svgs/cross.svg';

export default {
  name: 'SelectedTag',
  components: { Button, CheckCircleSVG, CrossSVG },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDelete() {
      this.$emit('delete');
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"flex"},[(!_vm.dragDrop)?[_vm._t("default",null,{"triggerClick":_vm.clickInput,"triggerDelete":_vm.handleDelete})]:_c('div',{class:[
      'flex-1',
      'text-white-3',
      'text-base',
      'border',
      'border-dashed',
      'outline-none',
      'w-full',
      'rounded-lg',
      'text-center',
      {
        'bg-white-9': !_vm.readOnly && (_vm.disabled || !_vm.dragDropFocus),
        'bg-white-7': !_vm.readOnly && !_vm.disabled && _vm.dragDropFocus,
        'border-transparent': !_vm.readOnly,
        'border-white-3': _vm.readOnly,
      },
    ],on:{"dragover":function($event){$event.preventDefault();return _vm.updateDragDropFocus(true)},"dragleave":function($event){$event.preventDefault();return _vm.updateDragDropFocus(false)},"dragenter":function($event){$event.preventDefault();return _vm.updateDragDropFocus(true)},"drop":function($event){$event.preventDefault();return _vm.onFileChange.apply(null, arguments)}}},[_vm._t("default",null,{"triggerClick":_vm.clickInput,"triggerDelete":_vm.handleDelete})],2),_c('input',_vm._b({ref:"input",staticClass:"none",attrs:{"type":"file","multiple":_vm.multiple,"accept":_vm.accept,"disabled":_vm.disabled},on:{"change":_vm.onFileChange}},'input',_vm.$attrs,false))],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { downloadZip } from 'client-zip';

import Logger from '@/modules/logger';
import StreamSaver from '@/modules/stream_saver';

import { requestFileSystemDirectory, readEntries } from '@/helpers';

const getFiles = (dirEntry, version) => {
  return new Promise((resolve, reject) => {
    const dirReader = dirEntry.createReader();

    readEntries(dirReader)
      .then((rootDirEntries) => {
        const logFileEntry = rootDirEntries.find((entry) => entry.name === Logger.fileName);
        const speakerDirEntry = rootDirEntries.find((entry) => entry.isDirectory && entry.name === version.speaker_id);
        const speakerDirReader = speakerDirEntry.createReader();

        return Promise.all([logFileEntry, readEntries(speakerDirReader)]);
      })
      .then(([logFileEntry, entries]) => {
        const fileNames = version.files.filter((f) => f.type !== 'attachment').map((f) => f.file_name);

        const files = [];

        entries = entries.filter((entry) => fileNames.includes(entry.name) || entry.name.startsWith(version.id));
        entries.push(logFileEntry);

        for (const entry of entries) {
          entry.file(
            (file) => {
              files.push(file);

              if (files.length >= entries.length) {
                resolve(files);
              }
            },
            (error) => console.warn(error),
          );
        }
      })
      .catch((error) => reject(error));
  });
};

export default function ({ commit, state }, { id }) {
  const version = state.versions[id];

  return requestFileSystemDirectory(state.recorderToken)
    .then((dirEntry) => {
      Logger.pause();

      return getFiles(dirEntry, version);
    })
    .then((files) => {
      const versionName = version.version_name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '_');
      const archiveName = `slideslive_archive-${versionName}`;
      const response = downloadZip(files);
      const fileStream = StreamSaver.createWriteStream(`${archiveName}.zip`, {
        autoClose: false,
      });

      Logger.log(`Download version, id: ${id}, files: ${files.length}`);

      commit('setKey', { key: 'localDownloadStream', localDownloadStream: fileStream });

      return response.body.pipeTo(fileStream);
    })
    .then(() => {
      Logger.resume();

      commit('setKey', { key: 'localDownloadStream', localDownloadStream: null });
    })
    .catch((error) => {
      console.error(error);
    });
}

import Logger from '@/modules/logger';

export default function ({ state, commit, getters }, { index = -1, startMs = null }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;
    const timelines = JSON.parse(JSON.stringify(version.timelines));
    const prevToNewStartMap = {};
    const removedClips = {
      removedAt: new Date().toISOString(),
    };

    if (index > -1) {
      startMs = version.timelines.speaker.video[index][version.timelines.speaker.video[index].type].start_ms;
    }

    const filterTimeline = (timeline, timelineKey, kind) => {
      const indexToRemove = timeline.findIndex((c) => c[c.type].start_ms === startMs);

      if (indexToRemove < 0) {
        return;
      }

      if (!removedClips[timelineKey]) {
        removedClips[timelineKey] = {};
      }

      removedClips[timelineKey][kind] = timeline.splice(indexToRemove, 1)[0];
    };

    filterTimeline(timelines.speaker.video, 'speaker', 'video');

    let prevDuration = 0;

    timelines.speaker.video.forEach((clip) => {
      prevToNewStartMap[clip[clip.type].start_ms] = prevDuration;
      clip[clip.type].start_ms = prevDuration;

      prevDuration += clip[clip.type].duration_ms;
    });

    Object.keys(timelines).forEach((timelineKey) => {
      Object.keys(timelines[timelineKey]).forEach((kind) => {
        if (timelineKey === 'speaker' && kind === 'video') {
          return;
        }

        filterTimeline(timelines[timelineKey][kind], timelineKey, kind);

        timelines[timelineKey][kind].forEach((clip) => {
          clip[clip.type].start_ms = prevToNewStartMap[clip[clip.type].start_ms];
        });
      });
    });

    const duration = timelines.speaker.video.reduce((sum, c) => sum + c[c.type].duration_ms, 0);
    const newData = {
      id: version.id,
      timelines,
      duration,
      editor: {
        removed_clips: [...version.editor.removed_clips],
        history: [...version.editor.history],
        history_index: version.editor.history_index + 1,
      },
      manually_edited: true,
    };
    newData.editor.history.splice(newData.editor.history_index);

    if (Object.keys(removedClips).length > 0) {
      const speakerClip = removedClips.speaker.video;

      if (
        state.player.elapsed > speakerClip[speakerClip.type].start_ms &&
        state.player.elapsed < speakerClip[speakerClip.type].start_ms + speakerClip[speakerClip.type].duration_ms
      ) {
        const newElapsed = speakerClip[speakerClip.type].start_ms;

        commit('player/setPlayerKey', { key: 'elapsed', elapsed: newElapsed }, { root: true });
      } else if (
        state.player.elapsed >=
        speakerClip[speakerClip.type].start_ms + speakerClip[speakerClip.type].duration_ms
      ) {
        const newElapsed = state.player.elapsed - speakerClip[speakerClip.type].duration_ms;

        commit('player/setPlayerKey', { key: 'elapsed', elapsed: newElapsed }, { root: true });
      }

      newData.editor.removed_clips.unshift(removedClips);
    }

    newData.editor.history.push({
      duration: version.duration,
      removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
      timelines: JSON.parse(JSON.stringify(version.timelines)),
      manually_edited: version.manually_edited,
    });

    Logger.log(`Remove clip, start ms: ${startMs}, timelines:`, newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

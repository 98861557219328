import { captureMessage, Severity } from '@sentry/browser';
import { mapMutations } from 'vuex';

import Timer from '@/modules/timer';

import { DELETED_VIDEO_STEP } from '@/constants';

import ErrorNotificationsMixin from './error_notifications';

export default {
  mixins: [ErrorNotificationsMixin],

  data() {
    return {
      ended: false,

      index: 0,
      nextIndex: null,

      speakerTmpPaused: null,
      slidesTmpPaused: null,

      playAfterSeeked: false,
      waitForSeekEnd: false,

      deletedTimer: null,

      lastSeeked: null,
      elapsedWhenLastSeeked: null,
    };
  },

  computed: {
    computedIndex: {
      get() {
        return this.index;
      },
      set(value) {
        this.index = value;

        if (this.slidesFile) {
          this.slidesHidden = false;
        } else {
          this.slidesHidden = true;
          this.updateStyle('slides', 0, 0);
        }
      },
    },
    tmpPaused() {
      const slidesTmpPaused = this.slidesFile && !this.slidesFile.deleted ? this.slidesTmpPaused : null;

      if (this.speakerTmpPaused === null && slidesTmpPaused === null) {
        return null;
      }

      if (this.speakerTmpPaused === null) {
        return this.slidesTmpPaused;
      }

      if (slidesTmpPaused === null) {
        return this.speakerTmpPaused;
      }

      return this.speakerTmpPaused || slidesTmpPaused;
    },
    videoRefName() {
      return `video${this.computedIndex % 2}`;
    },
    otherVideoRefName() {
      return `video${(this.computedIndex + 1) % 2}`;
    },
    speakerTimeline() {
      return this.version.timelines.speaker.video;
    },
    slidesTimeline() {
      return this.version.timelines.slides.video;
    },
    speakerClip() {
      if (this.computedIndex >= this.speakerTimeline.length) {
        this.computedIndex = this.speakerTimeline.length - 1;
        this.updateVideo();
      }

      if (this.computedIndex < 0) {
        this.computedIndex = 0;
        this.updateVideo();
      }

      const clip = this.speakerTimeline[this.computedIndex];
      return clip[clip.type];
    },
    slidesClip() {
      const clip = this.slidesTimeline.find((c) => c[c.type].start_ms === this.speakerClip.start_ms);

      if (!clip) {
        return null;
      }

      return clip[clip.type];
    },
    speakerFile() {
      return this.version.files.find((f) => f.id === this.speakerClip.file_id);
    },
    slidesFile() {
      if (!this.slidesClip) {
        return null;
      }

      return this.version.files.find((f) => f.id === this.slidesClip.file_id);
    },
    nextSpeakerClip() {
      const clip = this.speakerTimeline[this.nextIndex];
      return clip[clip.type];
    },
    nextSlidesClip() {
      const clip = this.slidesTimeline.find((c) => c[c.type].start_ms === this.nextSpeakerClip.start_ms);

      if (!clip) {
        return null;
      }

      return clip[clip.type];
    },
    nextSpeakerFile() {
      return this.version.files.find((f) => f.id === this.nextSpeakerClip.file_id);
    },
    nextSlidesFile() {
      if (!this.nextSlidesClip) {
        return null;
      }

      return this.version.files.find((f) => f.id === this.nextSlidesClip.file_id);
    },
    durations() {
      return this.speakerTimeline.map((clip) => clip[clip.type].duration_ms);
    },
    prevVideosDuration() {
      let prevVideosDuration = 0;

      for (let i = 0; i < this.computedIndex; i++) {
        prevVideosDuration += this.durations[i];
      }

      return prevVideosDuration;
    },
    speakerWindow() {
      return this.$refs.speaker;
    },
    slidesWindow() {
      return this.$refs.slides;
    },
    speaker() {
      return this.speakerWindow.$refs[this.videoRefName];
    },
    slides() {
      return this.slidesWindow.$refs[this.videoRefName];
    },
  },

  methods: {
    ...mapMutations(['deleteNotification']),
    updateStyle(kind, height, width) {
      let videoStyle = '';

      if (height > 0 && width > 0) {
        const pct = (height / width) * 100;
        videoStyle = `padding-bottom: ${pct}%`;
      }

      this[`${kind}Style`] = videoStyle;
    },
    handleCanplay(event, kind) {
      const video = event.target;

      if (video !== this[kind]) {
        return;
      }

      if (video.videoHeight > 0 && (kind !== 'slides' || !this.slidesHidden)) {
        this.updateStyle(kind, parseInt(video.videoHeight, 10), parseInt(video.videoWidth, 10));
      }

      if (kind === 'speaker' && !this.slidesFile) {
        this.slidesLoading = false;
        this.slidesTmpPaused = null;
      }

      this[`${kind}Loading`] = false;

      if (this.tmpPaused !== false || this.waitForSeekEnd || this.loading) {
        this[`${kind}TmpPaused`] = null;
        return;
      }

      this[`${kind}TmpPaused`] = null;

      if (this.ended) {
        this.pause();
      } else {
        this.play();
      }
    },
    handleWaiting(event, kind) {
      const video = event.target;

      if (video !== this[kind]) {
        return;
      }

      this[`${kind}Loading`] = true;

      if (this[`${kind}TmpPaused`] === null) {
        this[`${kind}TmpPaused`] = this.computedPaused;
      }

      if (this.tmpPaused) {
        return;
      }

      this.pause(false, false, kind === 'speaker', kind === 'slides');
    },
    handleSeeking(event) {
      if (event.target !== this.speaker) {
        return;
      }

      if (this.speaker.currentTime >= this.slides.duration) {
        this.slides.currentTime = this.slides.duration;
        return;
      }

      this.slides.currentTime = this.speaker.currentTime;
    },
    handleSeeked(event) {
      if (event.target !== this.speaker) {
        return;
      }

      if (!this.playAfterSeeked || this.waitForSeekEnd) {
        return;
      }

      this.playAfterSeeked = false;

      if (this.ended) {
        this.pause();
      } else {
        this.play();
      }
    },
    handleTimeUpdate(event = null) {
      if (this.ended || (event && event.target !== this.speaker)) {
        return;
      }

      const currentTimeMs = this.speaker.currentTime * 1000 - this.speakerClip.in_cut_ms;

      this.computedElapsed = Math.min(this.prevVideosDuration + currentTimeMs, this.version.duration);

      if (currentTimeMs >= this.speakerClip.duration_ms) {
        this.pause();
        this.handleEnded();
        return;
      }

      if (this.slidesHidden || this.slidesDeleted) {
        return;
      }

      const slidesTimeDelta = Math.abs(this.speaker.currentTime - this.slides.currentTime);

      if (slidesTimeDelta < 0.5) {
        return;
      }

      if (this.speaker.currentTime >= this.slides.duration) {
        if (this.slides.ended) {
          return;
        }

        this.slides.currentTime = this.slides.duration;
        return;
      }

      this.slides.currentTime = this.speaker.currentTime;
    },
    handlePlaying(event, kind) {
      const video = event.target;

      if (video !== this[kind]) {
        return;
      }

      this[`${kind}Loading`] = false;

      if (video.videoHeight > 0 && (kind !== 'slides' || !this.slidesHidden)) {
        this.updateStyle(kind, parseInt(video.videoHeight, 10), parseInt(video.videoWidth, 10));
      }

      if (kind !== 'speaker') {
        return;
      }

      this.nextIndex = (this.computedIndex + 1) % this.speakerTimeline.length;

      if (this.computedIndex === this.nextIndex) {
        return;
      }

      while (!this.nextSpeakerFile) {
        this.nextIndex = (this.nextIndex + 1) % this.speakerTimeline.length;

        if (this.computedIndex === this.nextIndex) {
          return;
        }
      }

      this.updateVideo(true);
    },
    handlePlay(event, kind) {
      const video = event.target;

      if (video !== this[kind]) {
        video.pause();
        return;
      }

      if (this.computedPaused) {
        this.play();
      }
    },
    handlePause(event, kind) {
      const video = event.target;

      if (this.ended || video !== this[kind] || (kind === 'slides' && video.ended)) {
        return;
      }

      if (!(this.speaker.paused && (!this.slidesFile || this.slides.paused))) {
        this.pause(false, true, this.speakerLoading, this.slidesLoading);
      }
    },
    handleEnded() {
      if (this.nextIndex === null) {
        this.nextIndex = 0;
      }

      if (this.nextIndex === 0) {
        this.lastSeeked = null;
        this.elapsedWhenLastSeeked = null;
        this.playAfterSeeked = false;
        this.ended = true;
        return;
      }

      this.computedIndex = this.nextIndex;

      this.play();
    },
    play(forceState = false) {
      if (this.tmpPaused !== null) {
        if (forceState) {
          this.computedPaused = false;
        }

        return;
      }

      this.computedPaused = false;

      if (this.loading || this.playAfterSeeked) {
        return;
      }

      if (this.ended || this.computedElapsed === this.version.duration) {
        this.seekTo(0, { playAfterSeeked: true });
        return;
      }

      if (this.speakerDeleted) {
        this.deletedTimer.resume();
      } else if (this.speaker.paused) {
        const playPromise = this.speaker.play();

        if (playPromise && playPromise.catch) {
          playPromise.catch(() => {});
        }
      }

      if (!this.slidesHidden && !this.slidesDeleted && this.slides.paused && !this.slides.ended) {
        const playPromise = this.slides.play();

        if (playPromise && playPromise.catch) {
          playPromise.catch(() => {});
        }
      }
    },
    pause(forceState = false, updateTmp = true, skipSpeaker = false, skipSlides = false) {
      if (this.tmpPaused !== null) {
        if (!this.waitForSeekEnd && updateTmp && !skipSpeaker && !skipSlides) {
          this.slidesTmpPaused = true;
          this.speakerTmpPaused = true;
        }

        if (forceState) {
          this.computedPaused = true;
        }
      } else {
        this.computedPaused = true;
      }

      if (!skipSpeaker) {
        if (this.speakerDeleted) {
          this.deletedTimer.pause();
        } else if (!this.speaker.paused) {
          this.speaker.pause();
        }
      }

      if (!skipSlides && !this.slidesHidden && !this.slidesDeleted && !this.slides.paused && !this.slides.ended) {
        this.slides.pause();
      }
    },
    seekTo(timeInMs, { waitForSeekEnd = false, playAfterSeeked = !this.computedPaused } = {}) {
      if (
        timeInMs === this.computedElapsed ||
        (timeInMs === this.lastSeeked && this.computedElapsed === this.elapsedWhenLastSeeked)
      ) {
        return;
      }

      this.lastSeeked = timeInMs;
      this.elapsedWhenLastSeeked = this.computedElapsed;

      this.playAfterSeeked = playAfterSeeked;
      this.waitForSeekEnd = waitForSeekEnd;
      this.ended = false;

      this.speakerLoading = false;
      this.slidesLoading = false;
      this.speakerTmpPaused = this.computedPaused;
      this.slidesTmpPaused = this.computedPaused;

      if (!this.computedPaused) {
        this.pause(false, false);
      }

      const goTo = (i, t) => {
        if (i !== this.computedIndex) {
          this.setVideoByIndex(i);
        }

        const realClipTime = (this.speakerClip.in_cut_ms + t) / 1000;

        this.computedElapsed = Math.min(this.prevVideosDuration + t, this.version.duration);

        if (this.speakerFile.deleted) {
          this.speakerTmpPaused = null;

          if (!this.slidesHidden && !this.slidesDeleted) {
            this.slides.currentTime = realClipTime;
          }

          if (this.playAfterSeeked) {
            this.playAfterSeeked = false;

            if (!this.waitForSeekEnd) {
              this.play();
            }
          }

          return;
        }

        if (this.speaker.currentTime === realClipTime) {
          this.speakerTmpPaused = null;
          this.slidesTmpPaused = null;

          if (this.playAfterSeeked) {
            this.playAfterSeeked = false;

            if (!this.waitForSeekEnd) {
              this.play();
            }
          }

          return;
        }

        this.speakerLoading = true;
        this.speaker.currentTime = realClipTime;

        if (!this.slidesHidden && !this.slidesDeleted) {
          this.slidesLoading = true;
          this.slides.currentTime = realClipTime;
        }
      };

      if (this.version.duration <= timeInMs) {
        const lastIndex = this.durations.length - 1;

        goTo(lastIndex, this.durations[lastIndex]);

        return;
      }

      let prevDuration = 0;

      for (let i = 0; i < this.durations.length; i++) {
        if (timeInMs < prevDuration + this.durations[i]) {
          goTo(i, Math.max(0, timeInMs - prevDuration));
          break;
        } else if (i === this.durations.length - 1) {
          goTo(i, this.durations[i]);
          break;
        } else {
          prevDuration += this.durations[i];
        }
      }
    },
    seekEnd() {
      this.waitForSeekEnd = false;

      if (this.tmpPaused || !this.playAfterSeeked) {
        return;
      }

      this.playAfterSeeked = false;

      if (this.ended) {
        this.pause();
      } else {
        this.play();
      }
    },
    toggle(forceState = false) {
      if (this.computedPaused) {
        this.play(forceState);
      } else {
        this.pause(forceState);
      }
    },
    setVideoByIndex(index, firstTryIndex = -1) {
      index %= this.speakerTimeline.length;

      if (index === firstTryIndex) {
        return;
      }

      this.computedIndex = index;

      if (!this.speakerFile) {
        if (firstTryIndex < 0) {
          firstTryIndex = index;
        }

        this.setVideoByIndex(index + 1, firstTryIndex);
        return;
      }

      this.updateVideo();
    },
    updateIndex() {
      let updated = false;
      let prevDurations = 0;

      for (let i = 0; i < this.durations.length; i++) {
        if (prevDurations + this.durations[i] > this.elapsed) {
          this.computedIndex = i;
          updated = true;
          return;
        }

        prevDurations += this.durations[i];
      }

      if (!updated) {
        this.computedIndex = this.durations.length - 1;
      }
    },
    updateVideo(nextIndex = false) {
      let speakerFile = this.speakerFile;
      let slidesFile = this.slidesFile;
      let speakerClip = this.speakerClip;
      let slidesClip = this.slidesClip;
      let speakerTarget = this.speaker;
      let slidesTarget = this.slides;

      if (nextIndex) {
        speakerFile = this.nextSpeakerFile;
        slidesFile = this.nextSlidesFile;
        speakerClip = this.nextSpeakerClip;
        slidesClip = this.nextSlidesClip;
        speakerTarget = this.speakerWindow.$refs[this.otherVideoRefName];
        slidesTarget = this.slidesWindow.$refs[this.otherVideoRefName];
      }

      if (!speakerFile) {
        if (!nextIndex) {
          this.setVideoByIndex(this.computedIndex + 1);
        }

        return;
      }

      if (!speakerTarget || !slidesTarget) {
        return;
      }

      this.updateSpeakerVideo(speakerTarget, speakerFile, speakerClip, nextIndex);
      this.updateSlidesFile(slidesTarget, slidesFile, slidesClip, nextIndex);
    },
    updateSpeakerVideo(target, file, clip, nextIndex = false) {
      if (file.deleted) {
        if (!nextIndex) {
          this.speakerDeleted = true;
          this.setDeletedTimer(clip.duration_ms);
        }

        return;
      }

      if (!nextIndex) {
        this.clearDeletedTimer();
        this.speakerDeleted = false;
      }

      if (target.src !== file.url) {
        if (target.reloadTimeout) {
          clearTimeout(target.reloadTimeout);
          target.reloadTimeout = null;
        }

        target.reloadNotWorking = false;
        target.src = file.url;
      }

      if (target.currentTime !== clip.in_cut_ms / 1000) {
        target.currentTime = clip.in_cut_ms / 1000;
      }

      if (nextIndex) {
        target.pause();
      }
    },
    updateSlidesFile(target, file, clip, nextIndex = false) {
      if (!file) {
        if (!nextIndex) {
          this.slidesHidden = true;
          this.updateStyle('slides', 0, 0);
        }

        return;
      }

      if (file.deleted) {
        if (!nextIndex) {
          this.slidesDeleted = true;
        }

        return;
      }

      if (!nextIndex) {
        this.slidesDeleted = false;
      }

      if (target.src !== file.url) {
        if (target.reloadTimeout) {
          clearTimeout(target.reloadTimeout);
          target.reloadTimeout = null;
        }

        target.reloadNotWorking = false;
        target.src = file.url;
      }

      if (target.currentTime !== clip.in_cut_ms / 1000) {
        target.currentTime = clip.in_cut_ms / 1000;
      }

      if (nextIndex) {
        target.pause();
      } else {
        this.slidesHidden = false;
      }
    },
    setDeletedTimer(duration) {
      this.clearDeletedTimer();

      const setTimer = (delay) => {
        this.deletedTimer = new Timer(
          () => {
            this.computedElapsed = Math.min(this.computedElapsed + delay, this.version.duration);

            const timerElapsed = this.computedElapsed - this.prevVideosDuration;

            if (timerElapsed >= duration) {
              this.pause();
              this.handleEnded();
              this.clearDeletedTimer();

              return;
            }

            setTimer(Math.min(duration - timerElapsed, DELETED_VIDEO_STEP));
          },
          delay,
          !this.paused,
        );
      };

      setTimer(Math.min(duration, DELETED_VIDEO_STEP));
    },
    clearDeletedTimer() {
      if (!this.deletedTimer) {
        return;
      }

      this.deletedTimer.clear();
      this.deletedTimer = null;
    },
    handleVideoError(el) {
      const error = el.error;

      console.warn(el.error);

      if (error.constructor.name !== 'MediaError') {
        captureMessage(`[VIDEO ERROR] ${error.constructor.name}[${error.code}]: ${error.message}`, {
          level: Severity.Error,
          extra: {
            error: {
              name: error.constructor.name,
              code: error.code,
              message: error.message,
            },
          },
        });
      }

      this.notificationFromError('player_video_error');

      if (!el.reloadTimeout) {
        el.reloadTimeout = setTimeout(() => {
          el.reloadTimeout = null;
          el.reloadNotWorking = true;

          if (!el.error) {
            this.deleteNotification({ code: 'player_video_error' });
          }
        }, 1000);
      }

      if (!el.reloadNotWorking) {
        const currentTime = el.currentTime;

        el.load();
        el.currentTime = currentTime;
      }
    },
    initSpeakerVideo() {
      const setProps = (el) => {
        el.playsinline = true;
        el.muted = false;
        el.preload = 'auto';

        el.oncanplay = (event) => this.handleCanplay(event, 'speaker');
        el.onwaiting = (event) => this.handleWaiting(event, 'speaker');
        el.onplaying = (event) => this.handlePlaying(event, 'speaker');
        el.onplay = (event) => this.handlePlay(event, 'speaker');
        el.onpause = (event) => this.handlePause(event, 'speaker');
        el.onended = (event) => this.handleEnded(event);
        el.onseeking = (event) => this.handleSeeking(event);
        el.onseeked = (event) => this.handleSeeked(event);
        el.ontimeupdate = (event) => this.handleTimeUpdate(event);
        el.onerror = () => this.handleVideoError(el);
      };

      setProps(this.speakerWindow.$refs.video0);
      setProps(this.speakerWindow.$refs.video1);
    },
    initslidesVideo() {
      const setProps = (el) => {
        el.playsinline = true;
        el.muted = true;
        el.preload = 'auto';

        el.oncanplay = (event) => this.handleCanplay(event, 'slides');
        el.onwaiting = (event) => this.handleWaiting(event, 'slides');
        el.onplaying = (event) => this.handlePlaying(event, 'slides');
        el.onplay = (event) => this.handlePlay(event, 'slides');
        el.onpause = (event) => this.handlePause(event, 'slides');
        el.onerror = () => this.handleVideoError(el);
      };

      setProps(this.slidesWindow.$refs.video0);
      setProps(this.slidesWindow.$refs.video1);
    },
    initPlayer() {
      this.initSpeakerVideo();
      this.initslidesVideo();

      this.setVideoByIndex(0);
    },
  },

  watch: {
    version(value, oldValue) {
      if (value.timelines !== oldValue.timelines) {
        this.updateIndex();
        this.updateVideo();
      }
    },
  },

  mounted() {
    this.initPlayer();
  },
};

import Logger from '@/modules/logger';

export default function ({ commit, dispatch, state, getters }, payload = {}) {
  Logger.log(`Trigger stop recording, set paused: ${!!payload.paused}`);

  if (state.isPaused) {
    commit('setKey', { key: 'isPaused', isPaused: !!payload.isPaused });

    return null;
  }

  if (payload.paused) {
    commit('setKey', { key: 'isPaused', isPaused: true });
    delete payload.paused;
  }

  if (state.isWaitingForStreams) {
    commit('setKey', { key: 'isRecording', isRecording: false });
    commit('setKey', { key: 'isWaitingForStreams', isWaitingForStreams: false });

    return null;
  }

  commit('setKey', { key: 'isWaitingForWebMFiles', isWaitingForWebMFiles: true });

  return dispatch('stopRecording', payload).then((data) => {
    if (!data) {
      return null;
    }

    commit('setKey', {
      key: 'isWaitingForWebMFiles',
      isWaitingForWebMFiles: false,
    });

    return getters.currentVersion;
  });
}

<script>
import { v4 as uuidv4 } from 'uuid';

import TooltipHelpIcon from '@/components/TooltipHelpIcon.vue';

export default {
  name: 'Tooltip',
  components: { TooltipHelpIcon },
  props: {
    iconSize: {
      type: String,
      default: 'medium',
      validate: (value) => ['none', 'small', 'medium', 'large'].includes(value),
    },
    tippyOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
  methods: {
    toggleTippy() {
      if (this.disabled) {
        this.$refs[`tippy-${this.id}`].tip.disable();
      } else {
        this.$refs[`tippy-${this.id}`].tip.enable();
      }
    },
  },
  watch: {
    disabled() {
      this.toggleTippy();
    },
  },
  updated() {
    this.toggleTippy();
  },
  render(createElement) {
    let vnode;

    const iconSize = this.iconSize;

    const tippy = createElement(
      'tippy',
      {
        ref: `tippy-${this.id}`,
        attrs: {
          to: this.id,
          ...this.tippyOptions,
        },
      },
      this.$slots.default || this.$scopedSlots.default(),
    );

    if (this.$slots.trigger || this.$scopedSlots.trigger) {
      vnode = this.$slots.trigger || this.$scopedSlots.trigger();
      vnode.forEach((node) => {
        node.data = node.data || {};
        node.data.attrs = node.data.attrs || {};

        if (node.data.attrs.name) {
          this.id = node.data.attrs.name;
          tippy.data.attrs.to = this.id;
          tippy.data.ref = `tippy-${this.id}`;
        } else {
          node.data.attrs.name = this.id;
        }

        if (!node.componentOptions) {
          node.children = node.children || [];
          node.children.push(tippy);
        } else {
          node.componentOptions.children = node.componentOptions.children || [];
          node.componentOptions.children.push(tippy);
        }
      });

      return vnode;
    }

    const infoCircle = createElement(TooltipHelpIcon, {
      props: {
        size: iconSize,
      },
    });

    vnode = createElement(
      'div',
      {
        attrs: {
          name: this.id,
        },
        on: this.$listeners,
        class: ['leading-0', 'cursor-help', 'rounded', 'inline-block', 'focus:outline-white'],
      },
      [infoCircle, tippy],
    );

    return vnode;
  },
};
</script>

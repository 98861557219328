var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,_vm._g(_vm._b({tag:"component",staticClass:"relative items-center py-1 focus:z-1",class:[
    _vm.color,
    {
      'rounded': _vm.rounded,
      'flex': _vm.block,
      'inline-flex': !_vm.block,
    },
    ..._vm.classes,
  ],attrs:{"disabled":_vm.disabled || _vm.loading}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.iconPosition === 'left')?_vm._t("icon",null,{"sizeClass":_vm.iconClasses}):_vm._e(),(_vm.label)?_c('div',{staticClass:"flex flex-column justify-center"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.label))]),(_vm.$slots.default)?_vm._t("default"):_vm._e()],2):(_vm.$slots.default)?_vm._t("default"):_vm._e(),(_vm.iconPosition === 'right')?_vm._t("icon",null,{"sizeClass":_vm.iconClasses}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center absolute inset-0 bg-black-5"},[_c('Spinner',{attrs:{"size":"small"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
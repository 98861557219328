export default function (entry) {
  return new Promise((resolve, reject) => {
    entry.file(
      (file) => {
        const reader = new FileReader();

        reader.onloadend = (event) => {
          try {
            JSON.parse(event.target.result);
            resolve();
          } catch (error) {
            reject(error);
          }
        };

        reader.readAsText(file);
      },
      (error) => reject(error),
    );
  });
}

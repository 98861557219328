<template>
  <div
    :class="[
      'flex',
      'flex-column',
      'justify-between',
      'bg-white-8',
      'rounded-lg',
      'w-1/3',
      'min-w-80',
      'max-w-120',
      'px-8',
      'pt-11',
      'pb-9',
      'mr-4',
    ]"
  >
    <div class="flex-1 overflow-auto">
      <h1 class="text-white-1">{{ conferenceName }}</h1>
      <p class="text-white-1 font-light">{{ $t('app_name') }}</p>

      <h2 class="text-white-1 mt-14">{{ $t('welcome.overview.speech_title') }}</h2>
      <p class="text-white-1 font-light mt-2">{{ presentationTitle }}</p>

      <div v-if="deadlineAt" class="mt-6">
        <h2 class="text-white-1">{{ $t('welcome.overview.deadline') }}</h2>
        <p class="text-white-1 mt-2">
          <DateFromNow class="font-bold" :date="deadlineAt" highlightPast />
          <br />
          <span class="font-light">{{ $d(new Date(deadlineAt), 'deadline') }}</span>
        </p>
      </div>

      <div v-if="speakers.length !== 1" class="mt-6">
        <h2 class="text-white-1">{{ $t('welcome.overview.number_of_speakers') }}</h2>
        <p class="text-white-1 font-light mt-2">
          <span v-if="speakers.length > 0">{{ speakers.length }}</span>
          <span v-else>-</span>
        </p>
      </div>

      <div v-if="presentationDurationS" class="mt-6">
        <h2 class="text-white-1">{{ $t('welcome.overview.speech_length') }}</h2>
        <p class="text-white-1 font-light mt-2">{{ formattedDuration }}</p>
      </div>
    </div>

    <div v-if="!hideRegister" class="text-center mt-9">
      <router-link :to="{ name: 'Register' }" custom #default="{ href, navigate }">
        <Button
          :href="href"
          @click="navigate"
          :label="$t('welcome.overview.register_cta')"
          block
          class="w-full no-underline hover:no-underline"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';
import DateFromNow from '@/components/DateFromNow.vue';

import { formatTime } from '@/helpers';

export default {
  name: 'WelcomeOverview',
  components: { Button, DateFromNow },
  props: {
    hideRegister: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['conferenceName', 'presentationTitle', 'presentationDurationS', 'deadlineAt', 'speakers']),
    formattedDuration() {
      return formatTime(this.presentationDurationS);
    },
  },
};
</script>

<template>
  <ContainerWithHeaderAndBack>
    <div class="container-lg w-full pt-14 pb-4">
      <h1 class="text-3xl text-white-1 text-center container-md">{{ $t('setup.title') }}</h1>

      <div class="flex w-full mt-18">
        <SetupCamera class="flex-none w-1/3 pr-10" />

        <SetupMicrophone class="flex-none w-1/3 px-5" />

        <SetupScreenSharing class="flex-none w-1/3 pl-10" />
      </div>

      <div class="text-center mt-12">
        <Button
          :label="$t('setup.continue_to_recorder')"
          :disabled="disabledContinue"
          block
          class="mx-auto min-w-102"
          @click.stop="goToRecorder"
        />

        <router-link :to="{ name: 'Help' }" custom #default="{ href, navigate }">
          <a :href="href" class="inline-block mt-4" @click="navigate">
            {{ $t('setup.have_trouble') }}
          </a>
        </router-link>
      </div>
    </div>

    <SetupModalWithoutCamera :active="confirmModalActive" @cancel="cancelGoToRecorder" @confirm="goToRecorder(false)" />
  </ContainerWithHeaderAndBack>
</template>

<script>
import { mapState } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import SetupCamera from '@/components/SetupCamera.vue';
import SetupMicrophone from '@/components/SetupMicrophone.vue';
import SetupScreenSharing from '@/components/SetupScreenSharing.vue';
import SetupModalWithoutCamera from '@/components/SetupModalWithoutCamera.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'Setup',
  components: {
    ContainerWithHeaderAndBack,
    SetupCamera,
    SetupMicrophone,
    SetupScreenSharing,
    SetupModalWithoutCamera,
    Button,
  },
  data() {
    return {
      confirmModalActive: false,
    };
  },
  computed: {
    ...mapState([
      'microphoneRequested',
      'microphoneEnabled',
      'cameraRequested',
      'cameraEnabled',
      'screenSharingRequested',
      'screenSharingEnabled',
    ]),
    disabledContinue() {
      return (
        !this.microphoneEnabled ||
        !this.microphoneRequested !== !this.microphoneEnabled ||
        this.cameraRequested !== this.cameraEnabled ||
        this.screenSharingRequested !== this.screenSharingEnabled
      );
    },
  },
  methods: {
    cancelGoToRecorder() {
      this.confirmModalActive = false;
    },
    goToRecorder(validate = true) {
      if (validate && !this.cameraEnabled) {
        this.confirmModalActive = true;
        return;
      }

      this.$router.push({ name: 'Record' });
    },
  },
};
</script>

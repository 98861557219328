import assignSpeaker from './assign_speaker';
import updateSpeaker from './update_speaker';
import updateSpeakerCount from './update_speaker_count';
import updateSpeakersOrder from './update_speakers_order';
import addSpeaker from './add_speaker';
import deleteSpeaker from './delete_speaker';
import loginSpeaker from './login_speaker';
import upload from './upload';
import deleteUpload from './delete_upload';
import initCurrentVersion from './init_current_version';
import addVersion from './add_version';
import deleteVersion from './delete_version';
import renameVersion from './rename_version';
import copyVersion from './copy_version';
import downloadLocalVersion from './download_local_version';
import downloadCloudVersion from './download_cloud_version';
import triggerStartRecording from './trigger_start_recording';
import triggerStopRecording from './trigger_stop_recording';
import startRecording from './start_recording';
import stopRecording from './stop_recording';
import triggerCancelRecordingCountdown from './trigger_cancel_recording_countdown';
import fetchTokenData from './fetch_token_data';
import addClip from './add_clip';
import removeClip from './remove_clip';
import cutClip from './cut_clip';
import restoreClip from './restore_clip';
import reorderClips from './reorder_clips';
import undo from './undo';
import redo from './redo';
import reset from './reset';
import initResumableUpload from './init_resumable_upload';
import destroyResumableUpload from './destroy_resumable_upload';
import sendFeedback from './send_feedback';
import fetchShareData from './fetch_share_data';
import deleteTokenFiles from './delete_token_files';
import loadSpeakerLocalVersions from './load_speaker_local_versions';
import restoreUnfinishedRecording from './restore_unfinished_recording';
import handleFinishedRecording from './handle_finished_recording';
import downloadAllLocalVersions from './download_all_local_versions';
import mergeSpeakerLocalVersionsAndUploads from './merge_speaker_local_versions_and_uploads';
import downloadVersionJSONFromCloud from './download_version_json_from_cloud';

export default {
  assignSpeaker,
  updateSpeaker,
  updateSpeakerCount,
  updateSpeakersOrder,
  addSpeaker,
  deleteSpeaker,
  loginSpeaker,
  upload,
  deleteUpload,
  initCurrentVersion,
  addVersion,
  deleteVersion,
  renameVersion,
  copyVersion,
  downloadLocalVersion,
  downloadCloudVersion,
  triggerStartRecording,
  triggerStopRecording,
  startRecording,
  stopRecording,
  triggerCancelRecordingCountdown,
  fetchTokenData,
  addClip,
  removeClip,
  cutClip,
  restoreClip,
  reorderClips,
  undo,
  redo,
  reset,
  initResumableUpload,
  destroyResumableUpload,
  sendFeedback,
  fetchShareData,
  deleteTokenFiles,
  loadSpeakerLocalVersions,
  restoreUnfinishedRecording,
  handleFinishedRecording,
  downloadAllLocalVersions,
  mergeSpeakerLocalVersionsAndUploads,
  downloadVersionJSONFromCloud,
};

import Logger from '@/modules/logger';

export default function ({ commit, getters }) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;
    const index = version.editor.history_index + 2;

    if (index >= version.editor.history.length) {
      return;
    }

    const history = JSON.parse(JSON.stringify(version.editor.history[index]));
    let newData = {
      id: version.id,
      editor: {
        history_index: index - 1,
      },
    };

    if (index === version.editor.history.length - 1) {
      newData.editor.history = [...version.editor.history];
      newData.editor.history.pop();
    }

    newData = {
      ...newData,
      duration: history.duration,
      timelines: history.timelines,
      editor: {
        ...version.editor,
        ...newData.editor,
        removed_clips: history.removed_clips,
      },
      manually_edited: history.manually_edited,
    };

    Logger.log('Editor redo, timelines:', newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

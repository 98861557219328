import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ commit, state, getters }, { selfRecording = false }) {
  const source = selfRecording ? state.selfRecording : getters.submitVersion;
  const { upload_id: uploadId, feedback } = source;
  const { value, note, uuid } = feedback;

  const url = uuid ? `${API_URL}/feedbacks/${uuid}` : `${API_URL}/feedbacks`;
  const method = uuid ? 'PUT' : 'POST';

  const requestData = {
    feedback: {
      token_id: state.tokenId,
      upload_id: uploadId || state.currentUploadId,
      rating: value,
      note,
    },
  };

  Logger.log(`Send feedback, data: ${JSON.stringify(requestData.feedback)}`);

  return makeRequest(method, url, requestData)
    .then(({ data }) => {
      if (data.uuid === uuid) {
        return;
      }

      const newFeedback = {
        ...feedback,
        uuid: data.uuid,
      };

      if (selfRecording) {
        commit('updateSelfRecording', { feedback: newFeedback });
      } else {
        commit('updateVersion', { id: source.id, feedback: newFeedback });
      }
    })
    .catch((errors) => {
      console.warn(errors);
    });
}

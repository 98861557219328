import { v4 as uuidv4 } from 'uuid';

import Logger from '@/modules/logger';

import { removeConfirmCloseTab, createFileObject } from '@/helpers';

import INITIAL_RECORDING from '../initial_recording';

function createRecordingFileData({ type, recording }) {
  return createFileObject({
    type,
    typeData: {
      duration_ms: recording.durationMs,
      duration_fallback: recording.durationFallback,
      bytes_written: recording.bytesWritten,
      expected_bytes_written: recording.expectedBytesWritten,
      needs_webm_fix: recording.needsWebmFix,
      webm_fix_successful: recording.webmFixSuccessful,
    },
    fileName: recording.fileName,
    fileSize: recording.fileSize,
    fileUrl: recording.fileUrl,
    mimeType: recording.mimeType,
  });
}

export default function (
  { dispatch, commit, state, getters },
  { speakerRecording, slidesRecording, version = getters.currentVersion },
) {
  return Promise.resolve()
    .then(() => {
      if (speakerRecording.interrupted || (slidesRecording && slidesRecording.interrupted)) {
        removeConfirmCloseTab();
        return [speakerRecording, slidesRecording];
      }

      if (speakerRecording.error || (slidesRecording && slidesRecording.error)) {
        removeConfirmCloseTab();

        const errors = [];

        if (speakerRecording.error) {
          errors.push(speakerRecording.error);
        }

        if (slidesRecording && slidesRecording.error) {
          errors.push(slidesRecording.error);
        }

        commit('setKey', { key: 'recordingErrors', recordingErrors: errors });

        throw errors;
      }

      Logger.log(
        // eslint-disable-next-line max-len
        'Add speakerRecording and slidesRecording to version, data:',
        {
          versionId: version.id,
          version,
        },
      );

      const speakerRecordingFileData = createRecordingFileData({
        type: 'speaker_recording',
        recording: speakerRecording,
      });

      let slidesRecordingFileData;
      if (slidesRecording) {
        slidesRecordingFileData = createRecordingFileData({
          type: 'slides_recording',
          recording: slidesRecording,
        });
      }

      const recordingData = {
        ...INITIAL_RECORDING,
        id: uuidv4(),
        recorded_at: state.recordingStartedAt,

        camera_enabled: speakerRecording.video,
        microphone_enabled: speakerRecording.audio,
        screen_sharing_enabled: !!slidesRecording,

        speaker_recording_file_id: speakerRecordingFileData.id,
        slides_recording_file_id: slidesRecordingFileData ? slidesRecordingFileData.id : null,

        speaker_slides_recording_start_diff_ms: slidesRecording
          ? slidesRecording.startedAt - speakerRecording.startedAt
          : 0,
        speaker_slides_recording_stop_diff_ms: slidesRecording
          ? slidesRecording.stoppedAt - speakerRecording.stoppedAt
          : 0,
      };

      const updatedVersionData = {
        recordings: [...version.recordings, recordingData],
        files: [...version.files, speakerRecordingFileData],
      };

      if (slidesRecordingFileData) {
        updatedVersionData.files.push(slidesRecordingFileData);
      }

      const clipData = {
        durationMs: speakerRecording.durationFallback ?? speakerRecording.durationMs,
        speakerFileId: speakerRecordingFileData.id,
        slidesFileId: slidesRecordingFileData ? slidesRecordingFileData.id : null,
      };

      commit('updateVersion', {
        id: version.id,
        ...updatedVersionData,
      });

      return dispatch('addClip', { ...clipData, versionId: version.id }).then(() => {
        removeConfirmCloseTab();
        return [speakerRecording, slidesRecording];
      });
    })
    .catch((error) => {
      removeConfirmCloseTab();
      throw error;
    });
}

<template>
  <div class="text-center">
    <p class="text-xl text-white-1 font-bold">{{ time }}</p>

    <RecordingState class="mt-1" :isRecording="isRecording" :isPaused="isPaused" />
  </div>
</template>

<script>
import RecordingState from '@/components/RecordingState.vue';

import { formatSeconds } from '@/helpers';

export default {
  name: 'RecordingProgress',
  components: { RecordingState },
  props: {
    isRecording: {
      type: Boolean,
      default: false,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    time() {
      return formatSeconds(this.duration / 1000);
    },
  },
};
</script>

<template>
  <div class="text-center">
    <h2 class="text-white-1 text-2xl">{{ $t('record.create_local_copy.title') }}</h2>

    <p class="container-sm font-light text-center mt-3">
      {{ $t('record.create_local_copy.message') }}
    </p>

    <Button class="w-68 mt-6" :label="$t('record.create_local_copy.create')" @click="copy" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'RecordCopyToEdit',
  components: { Button },
  methods: {
    copy() {
      this.$emit('copy');
    },
  },
};
</script>

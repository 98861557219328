export default function (url = window.location.href) {
  const pos = [];
  let i = 0;

  while (i >= 0 && i < url.length) {
    i = url.indexOf('?', i);

    if (i >= 0) {
      pos.push(i);
      i++;
    }
  }

  if (pos.length === 0) return {};

  const hash = url.indexOf('#');
  let params = [];

  pos.forEach((p) => {
    const searchTo = hash > p ? hash : url.length;
    params = params.concat(url.slice(p + 1, searchTo).split('&'));
  });

  let result = {};

  params.forEach((h) => {
    const param = h.split('=');
    const name = param[0];
    const value = param.length > 1 ? param[1] : null;

    result = {
      ...result,
      [name]: value,
    };
  });

  return result;
}

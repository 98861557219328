import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import { Vue as SentryVue } from '@sentry/integrations';
import { Integrations as SentryIntegrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://9bff01a53dc144779647fe57261ada08@o116153.ingest.sentry.io/5627620',
  release: __COMMIT_HASH__,
  integrations: [
    new SentryVue({
      Vue,
      tracing: true,
      logErrors: true,
    }),
    new SentryIntegrations.BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          name: `${window.location.pathname}${window.location.search}`,
        };
      },
    }),
  ],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

<template>
  <div class="max-h-full">
    <div class="flex-1 bg-white-9 flex rounded-lg p-6 relative max-h-full">
      <Tooltip :tippyOptions="{ placement: 'left' }">
        <template #trigger>
          <Button
            disableMinHeight
            :rounded="false"
            color="btn-transparent-white"
            class="p-0 rounded-full w-10 h-10 absolute right-8 top-3"
            @click="cancel"
          >
            <template #icon>
              <CrossSVG class="w-6 w-6 fill-white-1" />
            </template>
          </Button>
        </template>

        <template #default>
          {{ $t('cancel') }}
        </template>
      </Tooltip>

      <div class="flex flex-column m-auto rounded-scrollbar w-full max-h-full">
        <div class="flex-1 overflow-auto pb-4">
          <transition name="fade" mode="out-in">
            <ConfirmFinalUpload v-if="isConfirmFinalVisible" @continue="nextStep" />

            <ConfirmSpeakersOrder v-else-if="isConfirmSpeakersOrderVisible" @continue="nextStep" />

            <Attachments
              v-else-if="isAttachmentsVisible"
              :isSelfRecording="isSelfRecording"
              @submit="submitAttachments"
              @skip="skipAttachments"
            />
          </transition>
        </div>

        <StepsIndicator :step="step" :totalSteps="totalSteps" @click="goToStep" class="mt-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';
import ConfirmFinalUpload from '@/components/ConfirmFinalUpload.vue';
import ConfirmSpeakersOrder from '@/components/ConfirmSpeakersOrder.vue';
import Attachments from '@/components/Attachments.vue';
import StepsIndicator from '@/components/StepsIndicator.vue';
import Tooltip from '@/components/Tooltip.vue';

import CrossSVG from '@/assets/svgs/cross.svg';

export default {
  name: 'RecordSubmit',
  components: {
    Button,
    ConfirmFinalUpload,
    ConfirmSpeakersOrder,
    Attachments,
    StepsIndicator,
    Tooltip,
    CrossSVG,
  },
  props: {
    isSelfRecording: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    ...mapState(['speakers']),
    totalSteps() {
      if (this.isSelfRecording) {
        if (this.speakers.length === 1) {
          return 1;
        }

        return 2;
      }

      if (this.speakers.length === 1) {
        return 2;
      }

      return 3;
    },
    isConfirmFinalVisible() {
      return !this.isSelfRecording && this.step === 1;
    },
    isConfirmSpeakersOrderVisible() {
      return this.speakers.length > 1 && this.step === this.totalSteps - 1;
    },
    isAttachmentsVisible() {
      return this.step === this.totalSteps;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    nextStep() {
      this.step += +1;
    },
    goToStep(value) {
      this.step = value;
    },
    submitAttachments() {
      this.$emit('continue');
    },
    skipAttachments() {
      this.$emit('continue');
    },
  },
};
</script>

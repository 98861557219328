import { addBreadcrumb as SentryAddBreadcrumb, captureMessage, Severity } from '@sentry/browser';

import Logger from '@/modules/logger';
import WebmSeekableMetadataFixer from '@/modules/webm_seekable_metadata_fixer';

import i18n from '@/plugins/i18n';

import { getFilesByNames, finishRecording, upsertJSONFile } from '@/helpers';

function triggerFinishRecording(commit, token, speakerId, startedRecording, file) {
  return new Promise((resolve) => {
    startedRecording.fixedAt = new Date().getTime();

    let state = 'processing';

    const log = (error, skipConsole = false) => {
      if (!skipConsole || process.env.NODE_ENV === 'development') {
        console.log(`Recorder#restore ${startedRecording.kind}: ${error}`);
      }

      Logger.log(`Recorder#restore ${startedRecording.kind}: ${error}`);
    };

    const warn = (error) => {
      console.warn(`Recorder#restore ${startedRecording.kind}: ${error}`);
      Logger.log(`Recorder#restore ${startedRecording.kind}: ${error}`);
    };

    const recordingRecorderProgressStoreKey = `${startedRecording.kind}RecorderProgress`;

    let webmSeekableMetadataFixer;

    const callFinishRecording = () => {
      finishRecording(
        token,
        speakerId,
        startedRecording,
        webmSeekableMetadataFixer,
        file.entry,
        {
          log: log.bind(this),
          warn: warn.bind(this),
        },
        true,
      ).then((finishedRecording) => {
        commit('setKey', {
          key: recordingRecorderProgressStoreKey,
          [recordingRecorderProgressStoreKey]: 'cleanup',
        });

        resolve(finishedRecording);
      });
    };

    const updateProgress = ({ done, processed, size }) => {
      processed = state === 'processing' ? processed : size + processed;
      size *= 2;

      const progress = Math.floor((processed / size) * 100);

      commit('setKey', {
        key: recordingRecorderProgressStoreKey,
        [recordingRecorderProgressStoreKey]: `${progress} %`,
      });

      if (done && state === 'processing') {
        state = 'fixing';
        callFinishRecording();
      }
    };

    if (startedRecording.needsWebmFix) {
      webmSeekableMetadataFixer = new WebmSeekableMetadataFixer(startedRecording.kind, updateProgress.bind(this), true);

      webmSeekableMetadataFixer.processData(file.file);
    } else {
      callFinishRecording();
    }
  });
}

export default function ({ state, commit, dispatch }) {
  const versionIdWithUnfinishedRecording = Object.keys(state.versions).find((vId) => {
    if (state.versions[vId].recording_in_progress) {
      return true;
    }

    return false;
  });

  if (!versionIdWithUnfinishedRecording) {
    return Promise.resolve();
  }

  commit('setKey', { key: 'isRestoring', isRestoring: true });
  commit('addNotification', {
    type: 'info',
    code: 'restore_unfinished_recording',
    content: i18n.t('restore_unfinished_recording.notification'),
    spinner: true,
    uniq: true,
  });

  const version = state.versions[versionIdWithUnfinishedRecording];
  const { speaker: startedSpeakerRecording, slides: startedSlidesRecording } = version.recording_in_progress;
  const fileNames = [startedSpeakerRecording.tmpFileName];

  if (startedSlidesRecording) {
    fileNames.push(startedSlidesRecording.tmpFileName);
  }

  return getFilesByNames(version.recorder_token, version.speaker_id, fileNames, true)
    .then((data) => {
      const speakerFile = data.find((d) => d.file.name === startedSpeakerRecording.tmpFileName);
      const slidesFile = startedSlidesRecording
        ? data.find((d) => d.file.name === startedSlidesRecording.tmpFileName)
        : null;

      return Promise.all([
        triggerFinishRecording(
          commit,
          version.recorder_token,
          version.speaker_id,
          startedSpeakerRecording,
          speakerFile,
        ),
        startedSlidesRecording &&
          triggerFinishRecording(
            commit,
            version.recorder_token,
            version.speaker_id,
            startedSlidesRecording,
            slidesFile,
          ),
      ]);
    })
    .then(([finishedSpeakerRecording, finishedSlidesRecording]) => {
      SentryAddBreadcrumb({
        category: 'restore_recording',
        message: `Data`,
        data: {
          speakerRecording: finishedSpeakerRecording,
          slidesRecording: finishedSlidesRecording,
        },
        level: Severity.Debug,
      });
      Logger.log(
        `Recording restored, data: ${JSON.stringify({
          speakerRecording: finishedSpeakerRecording,
          slidesRecording: finishedSlidesRecording,
        })}`,
      );

      return dispatch('handleFinishedRecording', {
        speakerRecording: finishedSpeakerRecording,
        slidesRecording: finishedSlidesRecording,
        version,
      });
    })
    .catch((error) => {
      captureMessage(`[RESTORE RECORDING ERROR] ${error.message || JSON.stringify(error)}`, {
        level: Severity.Error,
      });
      Logger.log(`Recording restoration error: ${JSON.stringify(error)}`);
    })
    .finally(() => {
      commit('updateVersion', {
        id: version.id,
        recording_in_progress: null,
      });

      commit('setKey', { key: 'speakerRecorderProgress', speakerRecorderProgress: '' });
      commit('setKey', { key: 'screenSharingRecorderProgress', screenSharingRecorderProgress: '' });
      commit('deleteNotification', { code: 'restore_unfinished_recording' });
      commit('setKey', { key: 'isRestoring', isRestoring: false });

      return upsertJSONFile(state.versions[version.id]);
    });
}

<template>
  <p
    class="flex items-center justify-center text-white-1 text-xs font-bold"
    :class="!isRecording && !isPaused && 'hidden'"
  >
    {{ label }}

    <DotSVG v-if="!isPaused" class="fill-accent ml-1 w-4 h-4" />

    <PauseSVG v-else class="fill-accent ml-1 w-4 h-4" />
  </p>
</template>

<script>
import DotSVG from '@/assets/svgs/dot.svg';
import PauseSVG from '@/assets/svgs/pause.svg';

export default {
  name: 'RecordingState',
  components: { DotSVG, PauseSVG },
  props: {
    isRecording: {
      type: Boolean,
      default: false,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      if (this.isPaused) {
        return this.$i18n.t('paused');
      }

      return this.$i18n.t('recording');
    },
  },
};
</script>

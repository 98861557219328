import { parseUrlParams } from '@/helpers';

export default function (store, to, from, next) {
  const { speakersInitialized, loggedInSpeakerId, speakers, registerSpeaker, uploads } = store.state;

  const search = window.location.search;
  const query = {
    ...parseUrlParams(search),
    ...to.query,
  };

  if (search) {
    const hashQuery = Object.keys(query)
      .map((k) => `${k}=${query[k]}`)
      .join('&');

    window.history.replaceState({}, document.title, `/#/?${hashQuery}`);
  }

  const continueWithQuery = () => {
    if (!search) {
      return next();
    }

    return next({ name: to.name, replace: true, query });
  };

  if (to.path === '/logout') {
    store.commit('logout');
    return next({ name: 'Welcome', query });
  }

  if (to.matched.length === 0) {
    return next({ name: 'Welcome', replace: true, query });
  }

  if (to.name === 'Welcome' && query.share) {
    if (query.embed === 'true' || query.embed === 'yes' || query.embed === true) {
      return next({ name: 'Embed', replace: true, query });
    }

    return next({ name: 'Share', replace: true, query });
  }

  if (!loggedInSpeakerId && !registerSpeaker) {
    if (!['Welcome', 'Register', 'Help', 'Share', 'Embed', 'RecordedTokens'].includes(to.name)) {
      return next({ name: 'Welcome', replace: true, query });
    }

    return continueWithQuery();
  }

  if (!speakersInitialized) {
    if (!['Welcome', 'Register', 'Help', 'SpeakersCount', 'Share', 'Embed', 'RecordedTokens'].includes(to.name)) {
      return next({ name: 'SpeakersCount', replace: true, query });
    }

    return continueWithQuery();
  }

  if (!['Welcome', 'Register', 'Help', 'Share', 'Embed', 'RecordedTokens'].includes(to.name)) {
    const speaker = speakers.find((s) => s.id === loggedInSpeakerId);

    if (speaker && !speaker.recording_enabled) {
      if (speaker.disabled_recording_reason === 'final_recording_submitted') {
        if (to.name === 'Share' || uploads.length === 0) {
          return continueWithQuery();
        }

        const submittedUpload = uploads.find((u) => u.speaker_id === loggedInSpeakerId && u.final_recording);
        const params = parseUrlParams(submittedUpload.share_link_url);

        return next({ name: 'Share', replace: true, query: params });
      }

      return next({
        name: 'Help',
        replace: true,
        params: {
          disabledRecording: true,
          reason: speaker.disabled_recording_reason,
        },
      });
    }
  }

  return continueWithQuery();
}

import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import getSpeed from '@uppy/utils/lib/getSpeed';
import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';

import AwsS3MultipartUploadHelper from './aws_s3_multipart_upload_helper';
import Logger from './logger';

class ResumableUpload {
  constructor(options = {}) {
    const uploadInfoRequestOptions = options.uploadInfoRequest || {};

    this.options = {
      uploadInfoEndpoint: options.uploadInfoEndpoint || null,
      uploadInfoData: options.uploadInfoData || {},

      retryTimeout: options.retryTimeout || 5000,
      awsS3MultipartExpires: options.awsS3MultipartExpires || 2 * 60 * 60,
      awsS3MultipartLimit: options.awsS3MultipartLimit || 10,

      uploadInfoRequest: {
        noCsrfTokenHeader: uploadInfoRequestOptions.noCsrfTokenHeader || false,
        method: uploadInfoRequestOptions.method || 'POST',
        credentials: uploadInfoRequestOptions.credentials || 'omit',
      },
    };

    this.props = {
      service: null,
      retryTimeouts: new Map(),
    };

    this.pendingUploads = [];
    this.uploadProgress = {};

    this.callbacks = new Map([
      ['initialized', new Set()],
      ['error', new Set()],

      ['uploadAdded', new Set()],
      ['uploadStarted', new Set()],
      ['uploadProgress', new Set()],
      ['uploadComplete', new Set()],
      ['uploadStalled', new Set()],
      ['uploadError', new Set()],
      ['uploadRetry', new Set()],

      ['totalProgress', new Set()],
      ['totalComplete', new Set()],
    ]);

    this.fileCallbacks = new Map();
  }

  on(event, callback) {
    this.callbacks.get(event).add(callback);
  }

  emit(fileId, event, ...args) {
    if (fileId) {
      this.emitFileCallbacks(fileId, event, ...args);
    }

    for (const callback of this.callbacks.get(event)) {
      callback(...args);
    }
  }

  emitFileCallbacks(fileId, event, ...args) {
    const fileCallbacks = this.fileCallbacks.get(fileId);
    if (!fileCallbacks) {
      return;
    }

    const callback = fileCallbacks[event];
    if (!callback) {
      return;
    }

    callback(...args);
  }

  initialize() {
    if (!this.options.uploadInfoEndpoint) {
      const error = new Error('upload_no_info_endpoint');

      this.logError(error);
      this.emit(null, 'error', { error, message: error.message });

      return Promise.reject(error);
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (!this.options.uploadInfoRequest.noCsrfTokenHeader) {
      const csrfTokenMetaTag = document.querySelector("meta[name='csrf-token']");
      if (csrfTokenMetaTag) {
        headers['X-CSRF-Token'] = csrfTokenMetaTag.content;
      } else {
        console.warn('CSRF token meta tag not found!');
      }
    }

    const data = JSON.stringify(this.options.uploadInfoData);

    return fetch(this.options.uploadInfoEndpoint, {
      method: this.options.uploadInfoRequest.method,
      cache: 'no-cache',
      credentials: this.options.uploadInfoRequest.credentials,
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data,
    })
      .catch((err) => {
        console.error(err);

        const error = new Error('upload_server_not_responding', { cause: err });
        error.customMessage = `${err.name}: ${err.message}`;

        this.logError(error);
        this.emit(null, 'error', { error, message: error.message });

        throw error;
      })
      .then((response) =>
        response.json().catch((err) => {
          console.error(err);

          const error = new Error('upload_internal_server_error', { cause: err });
          error.customMessage = `${err.name}: ${err.message}`;

          this.logError(error);
          this.emit(null, 'error', { error, message: error.message });

          throw error;
        }),
      )
      .then((response) => {
        if (response.success) {
          this.initializeUppyFromUploadInfo(response.upload_info || response.data);
          return;
        }

        const errorMessages = response.errors ? response.errors : ['Unknown upload server error.'];
        const error = new Error('upload_server_error');
        error.customMessage = errorMessages.join(' ');

        this.logError(error);
        this.emit(null, 'error', { error: null, message: errorMessages });

        throw error;
      });
  }

  initializeUppyFromUploadInfo(uploadInfo) {
    this.service = uploadInfo.service;

    if (uploadInfo.service === 'tus') {
      throw new Error('tus_upload_not_supported');
    } else if (uploadInfo.service === 'aws_s3_multipart') {
      this.initializeUppyWithAwsS3Multipart(uploadInfo);
    }

    this.setupUppyEventHandlers();

    this.emit(null, 'initialized', { service: uploadInfo.service });
  }

  initializeUppyWithAwsS3Multipart(uploadInfo) {
    this.awsS3MultipartHelper = new AwsS3MultipartUploadHelper({
      region: uploadInfo.options.region,
      bucket: uploadInfo.options.bucket,
      acl: uploadInfo.options.acl,
      prefix: uploadInfo.options.prefix,
      credentials: {
        accessKeyId: uploadInfo.options.credentials.access_key_id,
        secretAccessKey: uploadInfo.options.credentials.secret_access_key,
        sessionToken: uploadInfo.options.credentials.session_token,
      },
      expires: this.options.awsS3MultipartExpires,
    });

    const awsS3MultipartOptions = {
      limit: this.options.awsS3MultipartLimit,
      retryDelays: [500, 1000, 1500],

      createMultipartUpload: (file) => this.awsS3MultipartHelper.createMultipartUpload(file),
      listParts: (file, { uploadId, key }) => this.awsS3MultipartHelper.listParts(file, { uploadId, key }),
      prepareUploadParts: (file, partData) => this.awsS3MultipartHelper.prepareUploadParts(file, partData),
      abortMultipartUpload: (file, { uploadId, key }) =>
        this.awsS3MultipartHelper.abortMultipartUpload(file, { uploadId, key }),
      completeMultipartUpload: (file, { uploadId, key, parts }) =>
        this.awsS3MultipartHelper.completeMultipartUpload(file, { uploadId, key, parts }),
    };

    this.uppy = new Uppy(this.uploadInfoToUppyOptions(uploadInfo)).use(AwsS3Multipart, awsS3MultipartOptions);
  }

  startUpload() {
    return this.uppy.upload();
  }

  abortUpload() {
    return this.uppy.cancelAll();
  }

  retryUpload(fileId) {
    const retryNumber = ++this.uploadProgress[fileId].retries;
    this.logDebug('retryUpload', fileId, retryNumber, this.uploadProgress[fileId].retries);

    return this.uppy.retryUpload(fileId);
  }

  addFile(name, type, data, callbacks = null, meta = null) {
    let nameForUpload;
    if (this.awsS3MultipartHelper) {
      nameForUpload = this.awsS3MultipartHelper.keyWithPrefix(name);
    } else {
      nameForUpload = name;
    }

    const fileId = this.uppy.addFile({
      name: nameForUpload,
      type,
      data,
      source: 'Local',
      isRemote: false,
    });

    if (callbacks) {
      this.fileCallbacks.set(fileId, callbacks);
    }

    if (meta) {
      this.uppy.setFileMeta(fileId, meta);
    }

    this.pendingUploads.push(fileId);
    this.uploadProgress[fileId] = this.createEmptyUploadProgress(fileId, data);

    this.emit(fileId, 'uploadAdded', this.uploadProgress[fileId]);

    return fileId;
  }

  setupUppyEventHandlers() {
    this.uppy.on('file-added', (file) => this.logDebug('file-added', file));
    this.uppy.on('file-removed', (file) => {
      this.logDebug('file-removed', file);

      this.removeRetryTimeout(file.id);
    });
    this.uppy.on('upload', (data) => {
      this.logDebug('upload', data);
      this.setUploadStart(data.fileIDs);
    });
    this.uppy.on('upload-progress', (file, progress) => this.setUploadProgress(file, progress));
    this.uppy.on('upload-success', (file, response) => {
      this.logDebug('upload-success', file, response);
      this.setUploadComplete(file, response);
    });
    this.uppy.on('complete', (result) => this.logDebug('complete', result));
    this.uppy.on('error', () => {
      const errorMessage = this.uppy.getState().error;
      this.logError('error', errorMessage);
    });
    this.uppy.on('upload-error', (file, error, response) => {
      const errorCode = error ? error.code : '<error undefined>';
      const errorMessage = error ? error.message : '<error undefined>';
      const errorSourceResponseText = error.source ? error.source.responseText : '<no source field in error>';

      this.logDebug(
        'upload-error',
        file,
        error,
        response,
        file.id,
        errorCode,
        errorMessage,
        errorSourceResponseText || '<error source response text not set>',
      );
      this.setUploadErrorAndRetry(file, error, response);
    });
    this.uppy.on('upload-retry', (fileId) => {
      this.logDebug('upload-retry', fileId);
      this.emit(fileId, 'uploadRetry', this.uploadProgress[fileId]);
    });
    this.uppy.on('info-visible', () => this.logDebug('info-visible', this.uppy.getState().info));
    this.uppy.on('info-hidden', () => this.logDebug('info-hidden'));
    this.uppy.on('cancel-all', () => this.logDebug('cancel-all'));
    this.uppy.on('restriction-failed', (file, error) => this.logDebug('restriction-failed', file, error));
    this.uppy.on('reset-progress', () => this.logDebug('reset-progress'));
  }

  setUploadStart(fileIds) {
    for (const fileId of fileIds) {
      this.uploadProgress[fileId].started = true;
      this.uploadProgress[fileId].lastUpdateAt = window.performance.now();

      this.emit(fileId, 'uploadStarted', this.uploadProgress[fileId]);
    }
  }

  setUploadProgress(file, progress) {
    if (this.uploadProgress[file.id].error) {
      this.uploadProgress[file.id].error = false;
    }

    this.uploadProgress[file.id].bytesUploaded = progress.bytesUploaded;
    this.uploadProgress[file.id].bytesTotal = progress.bytesTotal;
    this.uploadProgress[file.id].progress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    this.uploadProgress[file.id].speed = getSpeed(this.uploadProgress[file.id]);
    this.uploadProgress[file.id].lastUpdateAt = window.performance.now();

    this.emit(file.id, 'uploadProgress', this.uploadProgress[file.id]);

    this.updateTotalProgress();
  }

  setUploadComplete(file, response) {
    const pendingUploadIndex = this.pendingUploads.indexOf(file.id);
    this.pendingUploads.splice(pendingUploadIndex, 1);

    if (this.uploadProgress[file.id]) {
      this.uploadProgress[file.id].complete = true;

      this.uploadProgress[file.id].response = response;
      if (file.meta) {
        this.uploadProgress[file.id].meta = file.meta;
      }
    }

    this.emit(file.id, 'uploadComplete', this.uploadProgress[file.id]);

    if (this.pendingUploads.length === 0) {
      this.emit(null, 'totalComplete', this.uploadProgress);
    }
  }

  // eslint-disable-next-line no-unused-vars
  setUploadErrorAndRetry(file, error, response) {
    console.error(error);

    SentryAddBreadcrumb({
      category: 'resumable_upload',
      message: `Upload error and retry`,
      data: {
        error,
      },
      level: Severity.Error,
    });

    let retry = true;
    let errorMessage;
    let errorType = 'error';
    if (error.code === 'ExpiredToken') {
      retry = false;
      errorMessage = 'upload_expired_token';
    } else if (error.code === 'NoSuchUpload' || error.code === 'InvalidAccessKeyId' || error.code === 'AccessDenied') {
      retry = false;
      errorMessage = ['upload_aws3_error', error.message];
    } else if (error.code === 'RequestTimeTooSkewed') {
      retry = false;
      errorMessage = ['upload_request_time_too_skewed'];
    } else if (error.code === 'NetworkingError' || (error.code === undefined && error.message === 'Unknown error')) {
      errorMessage = ['upload_networking_error'];
      errorType = 'warning';
    } else if (error.code === 'TimeoutError') {
      errorMessage = ['upload_timeout_error'];
      errorType = 'warning';
    } else if (error.code === 'AwsS3/Multipart') {
      errorMessage = ['upload_aws3_error', 'retry_5'];
    } else {
      errorMessage = ['upload_error', 'retry_5', error.message];
    }

    if (retry) {
      this.uploadProgress[file.id].error = true;
      this.uploadProgress[file.id].errorMessage = errorMessage;
      this.uploadProgress[file.id].errorType = errorType;

      this.emit(file.id, 'uploadError', this.uploadProgress[file.id]);

      if (this.options.retryTimeout) {
        const retryTimeout = setTimeout(() => {
          this.removeRetryTimeout(file.id);
          this.retryUpload(file.id).catch(() => {});
        }, this.options.retryTimeout);

        this.addRetryTimeout(file.id, retryTimeout);
      }
    } else {
      this.uppy.reset();
      this.emit(null, 'error', { error, type: errorType, message: [errorMessage] });
    }
  }

  updateTotalProgress() {
    let totalSpeed = 0;
    let totalUploadedBytes = 0;
    let totalBytes = 0;

    for (const fileId of Object.keys(this.uploadProgress)) {
      const progress = this.uploadProgress[fileId];

      totalUploadedBytes += progress.bytesUploaded;
      totalBytes += progress.bytesTotal;

      if (!progress.error) {
        totalSpeed += progress.speed;

        if (!progress.complete && progress.bytesTotal > 0) {
          const diff = window.performance.now() - progress.lastUpdateAt;

          if (diff > 60000) {
            this.emit(fileId, 'uploadStalled', progress);
          } else if (progress.bytesUploaded === progress.bytesTotal && diff > 30000) {
            this.emit(fileId, 'uploadStalled', progress);
          }
        }
      }
    }

    const totalProgress = {
      pendingFiles: this.pendingUploads.length,
      totalFiles: Object.keys(this.uploadProgress).length,
      totalSpeed,
      totalUploadedBytes,
      totalBytes,
      totalProgress: (totalUploadedBytes / totalBytes) * 100,
      timeRemaining: (totalBytes - totalUploadedBytes) / totalSpeed,
    };

    this.emit(null, 'totalProgress', totalProgress);
  }

  // eslint-disable-next-line no-unused-vars
  uploadInfoToUppyOptions(uploadInfo) {
    return {
      meta: {},
    };
  }

  createEmptyUploadProgress(fileId, data) {
    return {
      fileId,
      uploadStarted: new Date(),
      bytesUploaded: 0,
      bytesTotal: data.size || 0,
      progress: 0,
      speed: 0,
      retries: 0,
      lastUpdateAt: window.performance.now(),
      started: false,
      complete: false,
      error: false,
      errorMessage: '',
    };
  }

  addRetryTimeout(fileId, retryTimeout) {
    this.props.retryTimeouts.set(fileId, retryTimeout);
  }

  removeRetryTimeout(fileId) {
    if (!this.props.retryTimeouts.has(fileId)) return;

    clearTimeout(this.props.retryTimeouts.get(fileId));
    this.props.retryTimeouts.delete(fileId);
  }

  clearAllRetryTimeouts() {
    for (const timeout of this.props.retryTimeouts.values()) {
      clearTimeout(timeout);
    }

    this.props.retryTimeouts.clear();
  }

  logError(...args) {
    SentryAddBreadcrumb({
      category: 'resumable_upload',
      message: `Upload error`,
      data: {
        args,
      },
      level: Severity.Error,
    });
    Logger.log(`ResumableUpload error: ${JSON.stringify(args)}`);

    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    console.log(args);
  }

  logDebug(...args) {
    Logger.log(`ResumableUpload log: ${JSON.stringify(args)}`);

    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    console.log(args);
  }

  get initialized() {
    return !!this.uppy;
  }

  get service() {
    return this.props.service;
  }

  set service(service) {
    this.props.service = service;
  }
}

export default ResumableUpload;

import initialState from '../initial_state';

export default function (state, { except = [] } = {}) {
  const INITIAL_STATE = initialState();
  const keys = Object.keys(INITIAL_STATE);

  for (const key of keys) {
    if (!except.includes(key)) {
      state[key] = INITIAL_STATE[key];
    }
  }
}

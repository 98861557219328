var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-column items-center justify-center"},[_c('div',{class:[
      'flex',
      'items-center',
      'justify-center',
      'rounded-full',
      'bg-white-8',
      'text-white-3',
      'font-black',
      ..._vm.sizeClasses,
      {
        'ordered-list-number': _vm.number === 'css',
      },
    ]},[_vm._v(" "+_vm._s(_vm.numberText)+" ")]),(_vm.label)?_c('p',{staticClass:"text-white-3 text-xs font-medium"},[_vm._v(_vm._s(_vm.label))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="text-center mx-auto container-lg py-1">
    <h1 class="text-white-1 text-2xl mx-auto container-md">
      {{ $t('attachments.title') }}
    </h1>

    <p class="container-sm font-light text-center mt-3">
      {{ $t('attachments.message') }}
    </p>

    <div class="flex mt-6">
      <div class="flex-1 py-1">
        <DragDrop
          :title="$t('attachments.files.title')"
          :message="$t('attachments.files.message')"
          :selectButtonText="selectFilesText"
          accept=".pdf,.ppt,.pptx,.key"
          attachmentType="presentation"
          v-model="computedFiles"
          multiple
        />
      </div>

      <div class="flex-none flex flex-column items-center px-4 min-w-40">
        <div class="flex-1 border-x-0 border-r-0 border-l border-dashed border-white-3"></div>

        <span class="flex-none uppercase text-white-1 font-black py-2">{{ $t('attachments.or') }}</span>

        <div class="flex-1 border-x-0 border-r-0 border-l border-dashed border-white-3"></div>
      </div>

      <div class="flex-1 py-1 min-h-50">
        <LinksSelect
          :title="$t('attachments.links.title')"
          :placeholder="$t('attachments.links.placeholder')"
          :selectButtonText="selectLinksText"
          v-model="computedLinks"
        />
      </div>
    </div>

    <Button
      class="mx-auto w-82 mt-10"
      block
      :label="$t('attachments.submit')"
      :disabled="computedFiles.length === 0 && computedLinks.length === 0"
      @click="submit"
    />

    <Button
      v-if="allowUploadWithoutAttachedFiles"
      v-show="computedFiles.length === 0 && computedLinks.length === 0"
      class="underline mt-8"
      color="btn-link-white-3 p-0"
      :label="$t('attachments.cannot_share')"
      @click="showModal"
    />

    <Modal v-if="allowUploadWithoutAttachedFiles" size="medium" :active="modalActive" @close="hideModal">
      <AttachmentsSkipModal @cancel="hideModal" @confirm="skip" />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import DragDrop from '@/components/DragDrop.vue';
import LinksSelect from '@/components/LinksSelect.vue';
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';
import AttachmentsSkipModal from '@/components/AttachmentsSkipModal.vue';

export default {
  name: 'Attachments',
  components: { DragDrop, LinksSelect, Button, Modal, AttachmentsSkipModal },
  props: {
    isSelfRecording: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalActive: false,
      files: [],
    };
  },
  computed: {
    ...mapState(['submitVersionId', 'selfRecording', 'allowUploadWithoutAttachedFiles']),
    ...mapGetters(['submitVersion']),
    computedFiles: {
      get() {
        return this.files;
      },
      set(value) {
        this.files = value;
      },
    },
    computedLinks: {
      get() {
        if (this.isSelfRecording) {
          return this.selfRecording.links;
        }

        return this.submitVersion.links;
      },
      set(value) {
        if (this.isSelfRecording) {
          this.updateSelfRecording({ links: value });
          return;
        }

        this.updateVersion({ id: this.submitVersionId, links: value });
      },
    },
    selectFilesText() {
      if (this.computedFiles.length > 0) {
        return this.$i18n.t('attachments.files.add_more');
      }

      return this.$i18n.t('attachments.files.add');
    },
    selectLinksText() {
      if (this.computedLinks.length > 0) {
        return this.$i18n.t('attachments.links.add_more');
      }

      return this.$i18n.t('attachments.links.add');
    },
  },
  methods: {
    ...mapMutations(['setKey', 'updateVersion', 'updateSelfRecording']),
    showModal() {
      this.modalActive = true;
    },
    hideModal() {
      this.modalActive = false;
    },
    submit() {
      if (this.isSelfRecording) {
        const files = [...this.selfRecording.files, ...this.files];
        this.updateSelfRecording({ files });
      } else {
        const files = [...this.submitVersion.files, ...this.files];
        this.updateVersion({ id: this.submitVersionId, files });
      }

      this.$emit('submit');
    },
    skip() {
      this.$emit('skip');
      this.hideModal();
    },
  },
};
</script>

<template>
  <div>
    <div class="relative">
      <div
        class="flex items-start z-1 outline-none relative group events-none"
        tabindex="0"
        @click="controlsToggle($event, 'click')"
        @keydown.space.enter.prevent.stop
        @keyup.space.enter="controlsToggle"
        @keyup.left.right.prevent.stop="controlsSeekEnd"
        @keydown.left.prevent.stop="controlsMove('back')"
        @keydown.right.prevent.stop="controlsMove('forth')"
      >
        <OverlaySpinner v-if="loading" tightBg class="z-2" />

        <div class="flex-1 mr-1 relative z-1" :class="withBackground && 'bg-black'">
          <MediaPreview icon="SpeakerSVG" :backgroundStyle="speakerStyle" class="events-all">
            <PlayerControls
              class="z-2"
              :parts="durations"
              :duration="version.duration"
              :elapsed="computedElapsed"
              :paused="computedPaused"
              @seekToPct="controlsSeekToPct"
              @seekEnd="controlsSeekEnd"
              @toggle="controlsToggle"
            />

            <PlayerWindow ref="speaker" :index="computedIndex" :deleted="speakerDeleted" />
          </MediaPreview>
        </div>

        <div class="flex-1 ml-1 relative z-1" :class="withBackground && 'bg-black'">
          <MediaPreview icon="PresentationSVG" :backgroundStyle="slidesStyle" class="events-all">
            <PlayerWindow
              ref="slides"
              :index="computedIndex"
              :deleted="slidesDeleted"
              :class="slidesHidden && 'hidden'"
            />
          </MediaPreview>
        </div>
      </div>

      <Tooltip class="absolute top-4 right-4 z-2" :tippyOptions="{ placement: 'left' }">
        <p class="text-xs">{{ $t('record.slides_to_images') }}</p>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import OverlaySpinner from '@/components/OverlaySpinner.vue';
import MediaPreview from '@/components/MediaPreview.vue';
import PlayerControls from '@/components/PlayerControls.vue';
import PlayerWindow from '@/components/PlayerWindow.vue';
import Tooltip from '@/components/Tooltip.vue';

import RecorderPlayerMixin from '@/mixins/recorder_player';

export default {
  name: 'RecordPlayer',
  components: {
    OverlaySpinner,
    MediaPreview,
    PlayerControls,
    PlayerWindow,
    Tooltip,
  },
  mixins: [RecorderPlayerMixin],
  props: {
    version: {
      type: Object,
      required: true,
    },
    withBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newElapsed: 0,
      newPaused: true,

      slidesStyle: '',
      speakerStyle: '',

      speakerLoading: false,
      slidesLoading: false,

      slidesHidden: false,

      speakerDeleted: false,
      slidesDeleted: false,

      possibleClickAfterSeekend: false,
    };
  },
  computed: {
    ...mapState('player', ['elapsed', 'paused']),
    computedElapsed: {
      get() {
        return this.newElapsed;
      },
      set(value) {
        if (value === this.newElapsed) {
          return;
        }

        this.newElapsed = value;

        if (value !== this.elapsed) {
          this.setPlayerKey({ key: 'elapsed', elapsed: value });
        }
      },
    },
    computedPaused: {
      get() {
        return this.newPaused;
      },
      set(value) {
        if (value === this.newPaused) {
          return;
        }

        this.newPaused = value;
        this.setPlayerKey({ key: 'paused', paused: value });
      },
    },
    loading() {
      return this.speakerLoading || this.slidesLoading;
    },
  },
  methods: {
    ...mapMutations(['setKey']),
    ...mapMutations('player', ['setPlayerKey']),
    controlsToggle(event, origin) {
      if (origin === 'click' && this.possibleClickAfterSeekend) {
        return;
      }

      this.toggle(true);
    },
    controlsSeekToPct(pct) {
      const time = (pct / 100) * this.version.duration;
      this.seekTo(time, { waitForSeekEnd: true });
    },
    controlsSeekEnd() {
      this.seekEnd();

      this.possibleClickAfterSeekend = true;

      setTimeout(() => {
        this.possibleClickAfterSeekend = false;
      }, 500);
    },
    controlsMove(direction) {
      let newPct;

      if (direction === 'back') {
        newPct = (Math.max(0, this.elapsed - 10000) * 100) / this.version.duration;
      } else if (direction === 'forth') {
        newPct = (Math.min(this.elapsed + 10000, this.version.duration) * 100) / this.version.duration;
      }

      this.controlsSeekToPct(newPct);
    },
  },
  watch: {
    elapsed(value) {
      if (value === this.computedElapsed) {
        return;
      }

      this.seekTo(value);
    },
    paused(value) {
      if (value === this.computedPaused) {
        return;
      }

      this.newPaused = value;

      if (value) {
        this.pause(true);
      } else {
        this.play(true);
      }
    },
  },
  mounted() {
    this.setPlayerKey({ key: 'paused', paused: true });
    this.setPlayerKey({ key: 'elapsed', elapsed: 0 });
  },
};
</script>

function errorToObject(error) {
  return {
    name: error.name,
    message: error.message,
    code: error.code,
    customMessage: error.customMessage,
    statusCode: error.statusCode,
    string: error.toString && error.toString(),
    cause: error.cause && errorToObject(error.cause),
  };
}

export default errorToObject;

import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';
import Logger from '@/modules/logger';

import { addConfirmCloseTab } from '@/helpers';

export default function ({ commit, dispatch, state }) {
  const startedAt = new Date().toISOString();

  addConfirmCloseTab();

  commit('setKey', { key: 'recordingErrors', recordingErrors: [] });
  commit('setKey', { key: 'uploadingErrors', uploadingErrors: [] });
  commit('setKey', { key: 'uploadingFilesErrors', uploadingFilesErrors: [] });

  commit('setKey', { key: 'recordingStartedAt', recordingStartedAt: startedAt });
  commit('setKey', { key: 'lastRecordingDuration', lastRecordingDuration: 0 });

  commit('setKey', { key: 'isRecording', isRecording: true });
  commit('setKey', { key: 'isPaused', isPaused: false });

  return Promise.all([
    state.speakerRecorder.startRecording(state.speakerStream, state.grantedBytes, startedAt),
    state.screenSharingEnabled &&
      state.screenSharingErrors.length === 0 &&
      state.screenSharingRecorder.startRecording(state.screenSharingStream, state.grantedBytes, startedAt),
  ]).then(([startedSpeakerRecording, startedSlidesRecording]) => {
    SentryAddBreadcrumb({
      category: 'start_recording',
      message: `Data`,
      data: {
        speakerRecording: startedSpeakerRecording,
        slidesRecording: startedSlidesRecording,
      },
      level: Severity.Debug,
    });
    Logger.log(
      `Recording started, data: ${JSON.stringify({
        speakerRecording: startedSpeakerRecording,
        slidesRecording: startedSlidesRecording,
      })}`,
    );

    if (startedSpeakerRecording.error || (startedSlidesRecording && startedSlidesRecording.error)) {
      const errors = [];

      if (startedSpeakerRecording.error) {
        errors.push(startedSpeakerRecording.error);
      }

      if (startedSlidesRecording && startedSlidesRecording.error) {
        errors.push(startedSlidesRecording.error);
      }

      commit('setKey', { key: 'recordingErrors', recordingErrors: errors });
      dispatch('stopRecording', { skipNoStreamRecorderErrors: true });

      throw errors;
    }

    const recordingStartedAt = Math.round(window.performance.now());
    let lastDurationUpdateAt;

    const updateDuration = () => {
      if (!state.isRecording || state.isWaitingForWebMFiles) {
        return;
      }

      const now = Math.round(window.performance.now());

      if (!lastDurationUpdateAt || now - lastDurationUpdateAt >= 500) {
        const currentRecordingDuration = now - recordingStartedAt;

        commit('setKey', {
          key: 'lastRecordingDuration',
          lastRecordingDuration: currentRecordingDuration,
        });

        lastDurationUpdateAt = now;
      }

      requestAnimationFrame(() => updateDuration());
    };

    updateDuration();

    commit('updateVersion', {
      id: state.currentVersionId,
      recording_in_progress: {
        speaker: startedSpeakerRecording,
        slides: startedSlidesRecording,
      },
    });

    return [startedSpeakerRecording, startedSlidesRecording];
  });
}

<template>
  <div>
    <h2 class="text-white-1">
      {{ $t('help.faq.title') }}
    </h2>

    <Collapse animation="slide" class="mt-4" v-for="(item, index) of faqItems" :key="index">
      <template #trigger="{ toggle, open }">
        <button type="button" class="flex cursor-pointer items-center outline-none group" @click="toggle(!open)">
          <div class="flex rounded group-focus:outline-primary">
            <ChevronUpSVG v-if="open" class="fill-primary-3 w-6 h-6" />
            <ChevronDownSVG v-else class="fill-primary-3 w-6 h-6" />
          </div>

          <h3 class="text-white-1 text-base text-left ml-2">
            {{ item.question }}
          </h3>
        </button>
      </template>

      <div class="markdown pl-8 pt-3">
        <FaqAnswer :index="index" :email="computedEmail" />
      </div>
    </Collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Collapse from '@/components/Collapse.vue';
import FaqAnswer from '@/components/FaqAnswer.vue';

import ChevronUpSVG from '@/assets/svgs/chevron_up.svg';
import ChevronDownSVG from '@/assets/svgs/chevron_down.svg';

export default {
  name: 'Faq',
  components: { Collapse, FaqAnswer, ChevronUpSVG, ChevronDownSVG },
  computed: {
    ...mapState(['supportEmail']),
    faqItems() {
      return this.$i18n.t('help.faq.items');
    },
    computedEmail() {
      return this.supportEmail || 'support@slideslive.com';
    },
  },
};
</script>

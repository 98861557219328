import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }, { id, name, email }) {
  const requestData = {
    speakers_token: state.speakersToken,
    speaker: {
      name,
      email,
    },
  };

  Logger.log(`Rename speaker, id: ${id}, data: ${JSON.stringify(requestData.speaker)}`);

  return makeRequest('PUT', `${API_URL}/tokens/${state.tokenId}/speakers/${id}`, requestData).then((response) => {
    commit('setKey', { key: 'speakers', speakers: response.data });
  });
}

<template>
  <form @submit.prevent.stop="submit" novalidate>
    <slot :handleValidity="handleValidity" />
  </form>
</template>

<script>
export default {
  name: 'Form',
  provide() {
    return {
      Form: this,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputs: [],
      errors: [],
    };
  },
  methods: {
    handleValidity(name, valid) {
      if (valid) {
        this.errors = this.errors.filter((e) => e !== name);
      } else if (!this.errors.includes(name)) {
        this.errors.push(name);
      }
    },
    validate() {
      let valid = true;

      this.inputs.forEach((input) => {
        const inputValid = input.validate(true);

        if (!inputValid) {
          valid = false;
        }
      });

      return valid;
    },
    submit() {
      if (this.disabled) {
        return;
      }

      if (!this.validate() || Object.keys(this.errors).length > 0) {
        return;
      }

      this.$emit('submit');
    },
    _registerInput(input) {
      this.inputs.push(input);
      input.$on('validityChange', this.handleValidity);
    },
    _unregisterInput(input) {
      input.$off('validityChange', this.handleValidity);
      this.errors = this.errors.filter((e) => e !== input.$attrs.name);
      this.inputs = this.inputs.filter((it) => it !== input);
    },
  },
};
</script>

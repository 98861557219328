<template>
  <button
    type="button"
    :class="[
      'border-2',
      'border-solid',
      'border-transparent',
      'text-white-1',
      'flex',
      'h-16',
      'items-center',
      'text-left',
      'relative',
      'z-1',
      'outline-none',
      'group',
      {
        'bg-white-9': active,
        'bg-white-10': !active,
        'focus-within:z-2': isLocal && !hasBrokenData && !disabled && !sharePage,
        'hover:bg-white-8': isLocal && !hasBrokenData && !disabled && !sharePage,
        'active:bg-white-9': isLocal && !hasBrokenData && !disabled && !sharePage,
        'focus:border-white-7': isLocal && !hasBrokenData && !disabled && !sharePage,
        'focus-within:opacity-1': isLocal && !hasBrokenData && !disabled && !sharePage,
        'cursor-default': !isLocal || hasBrokenData || (active && disabled) || sharePage,
        'cursor-not-allowed': (!isLocal || (!active && disabled)) && !sharePage,
      },
    ]"
    @click="open"
  >
    <Tooltip v-if="!isLocal && !active && !sharePage">
      <template #trigger>
        <div class="absolute inset-0 z-3" />
      </template>

      <template #default>
        <span v-if="isSelfRecording">
          {{ $t('version.is_self_recording') }}
        </span>

        <span v-else>
          {{ $t('version.not_local') }}
        </span>
      </template>
    </Tooltip>

    <div
      :class="[
        {
          'opacity-4': !active,
          'group-hover:opacity-1': !active && isLocal && !hasBrokenData && !disabled,
        },
      ]"
      class="flex-none flex flex-column items-center justify-center px-3 w-17 text-center"
    >
      <template v-if="!sharePage && !isUploaded">
        <CompSVG class="fill-primary w-6 h-6" />

        <span class="uppercase text-primary text-tiny font-black">{{ $t('version.local') }}</span>
      </template>

      <template v-else>
        <CloudSVG class="fill-primary w-6 h-6" />

        <span class="uppercase text-primary text-tiny font-black">{{ $t('version.cloud') }}</span>
      </template>
    </div>

    <VersionNameState
      ref="nameState"
      :name="name"
      :isUploaded="sharePage || isUploaded"
      :isSubmitted="isSubmitted"
      :stateInsteadOfTime="sharePage"
      :date="showDate"
      :class="[
        {
          'opacity-4': !active,
          'group-hover:opacity-1': !active && isLocal && !hasBrokenData && !disabled,
        },
      ]"
      class="flex-1 pr-8 w-1"
    />

    <VersionItemDropdown
      v-if="!sharePage"
      :isLocal="isLocal"
      :isUploaded="isUploaded"
      :hasBrokenData="hasBrokenData"
      :isSubmitted="isSubmitted"
      :hasRecordings="hasRecordings"
      :disabled="disabled"
      :downloadingVersionJSONFromCloud="downloadingVersionJSONFromCloud"
      @delete="triggerDelete"
      @copy="triggerCopy"
      @rename="triggerRename"
      @downloadLocal="triggerDownloadLocal"
      @downloadCloud="triggerDownloadCloud"
      @submit="triggerSubmit"
    />
  </button>
</template>

<script>
import { mapMutations } from 'vuex';

import VersionNameState from '@/components/VersionNameState.vue';
import VersionItemDropdown from '@/components/VersionItemDropdown.vue';
import Tooltip from '@/components/Tooltip.vue';

import CompSVG from '@/assets/svgs/comp.svg';
import CloudSVG from '@/assets/svgs/cloud.svg';

export default {
  name: 'VersionItem',
  components: {
    VersionNameState,
    VersionItemDropdown,
    Tooltip,
    CompSVG,
    CloudSVG,
  },
  props: {
    version: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sharePage: {
      type: Boolean,
      default: false,
    },
    downloadingVersionJSONFromCloud: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLocal() {
      return this.version.has_local_data;
    },
    hasBrokenData() {
      return this.version.broken_data;
    },
    isUploaded() {
      return !this.isLocal || !!this.version.upload_id;
    },
    isSubmitted() {
      return this.version.final_recording;
    },
    name() {
      return this.version.version_name || this.version.name;
    },
    isSelfRecording() {
      return this.name === 'Self recording';
    },
    showDate() {
      return this.version.created_at || this.version.recorded_at;
    },
    hasRecordings() {
      return this.version.recordings ? this.version.recordings.length > 0 : false;
    },
  },
  methods: {
    ...mapMutations(['setKey']),
    open() {
      if (!this.isLocal || this.hasBrokenData || this.disabled) {
        return;
      }

      this.setKey({ key: 'currentVersionId', currentVersionId: this.version.id });
    },
    triggerDelete() {
      this.$emit('delete', { local: this.isLocal, ...this.version });
    },
    triggerCopy() {
      if (!this.isLocal) {
        return;
      }

      this.$emit('copy', this.version);
    },
    triggerRename() {
      if (this.version.final_recording) {
        return;
      }

      this.$emit('rename', this.version);
    },
    triggerDownloadLocal() {
      if (!this.isLocal) {
        return;
      }

      this.$emit('downloadLocal', this.version);
    },
    triggerDownloadCloud() {
      if (this.isLocal) {
        return;
      }

      this.$emit('downloadCloud', this.version);
    },
    triggerSubmit() {
      if (this.isSubmitted) {
        return;
      }

      this.$emit('submit', this.version.id);
    },
  },
};
</script>

<template>
  <ol class="list-style-none flex items-end text-white-6 text-xs w-full">
    <li
      v-for="tick in partsCount"
      :key="tick"
      class="border-y-0 border-r-0 border-l border-solid border-l-white-6 relative"
      :class="[
        {
          'hidden': isTickHidden(tick),
          'h-6': isBigTick(tick),
          'h-4': !isBigTick(tick) && isMediumTick(tick),
          'h-3': !isBigTick(tick) && !isMediumTick(tick),
        },
      ]"
      :style="`width: ${tick === partsCount ? 1 : partSize}px;`"
    >
      <span v-if="shouldRenderTime(tick)" class="absolute left-0 top-full -translate-x-1/2">
        {{ formatTick(tick) }}
      </span>
    </li>
  </ol>
</template>

<script>
import { formatSeconds } from '@/helpers';

export default {
  name: 'EditorTimes',
  props: {
    partsCount: {
      type: Number,
      default: 0,
    },
    partSize: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    isBigTick(tick) {
      let result = tick % 60 === 1;

      if (this.partSize >= 22.5) {
        result = tick % 10 === 1;
      } else if (this.partSize >= 5) {
        result = tick % 30 === 1;
      }

      return result;
    },
    isMediumTick(tick) {
      let result = tick % 10 === 1;

      if (this.partSize >= 5) {
        result = tick % 2 === 1;
      } else if (this.partSize >= 2.5) {
        result = tick % 5 === 1;
      }

      return result;
    },
    isTickHidden(tick) {
      let result = false;

      if (this.partSize < 1) {
        result = tick % 10 !== 1;
      } else if (this.partSize < 2.5) {
        result = tick % 5 !== 1;
      } else if (this.partSize < 5) {
        result = tick % 2 !== 1;
      }

      return result;
    },
    shouldRenderTime(tick) {
      let result = tick % 60 === 1;

      if (this.partSize >= 40) {
        result = true;
      } else if (this.partSize >= 22.5) {
        result = tick % 2 === 1;
      } else if (this.partSize >= 10) {
        result = tick % 5 === 1;
      } else if (this.partSize >= 4) {
        result = tick % 10 === 1;
      } else if (this.partSize >= 1.5) {
        result = tick % 30 === 1;
      }

      return result;
    },
    formatTick(value) {
      return formatSeconds(value - 1);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex absolute -translate-x-px top-0 bottom-5 z-1",style:(`left: ${_vm.computedLeft}px;`)},[_c('div',{staticClass:"border-y-0 border-x border-solid border-accent-1 h-full"}),_c('div',{class:[
      'absolute',
      'flex',
      'overflow-visible',
      'w-auto',
      'events-none',
      'bottom-full',
      'left-0',
      'pb-2',
      {
        '-left-4': _vm.isAtBeginning,
        'right-0': !_vm.isAtBeginning,
        'justify-center': !_vm.isAtBeginning,
      },
    ]},[_c('div',{staticClass:"absolute w-4 h-4 arrow-bottom arrow-black-1 -bottom-2",class:[
        {
          'left-4': _vm.isAtBeginning,
          '-ml-2': _vm.isAtBeginning,
          'translate-x-px': _vm.isAtBeginning,
        },
      ]}),_c('div',{class:[
        'text-left',
        'rounded',
        'text-xs',
        'font-normal',
        'py-2',
        'px-4',
        'relative',
        'text-white-1',
        'bg-black-1',
      ]},[_c('p',{staticClass:"text-xs font-black pr-10"},[_vm._v(_vm._s(_vm.formatteddElapsed))]),_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"absolute right-0 inset-y-0 w-10 p-0 rounded-r events-all",attrs:{"disableMinHeight":"","rounded":false,"loading":_vm.loading,"color":"btn-accent"},on:{"click":function($event){$event.stopPropagation();return _vm.cut.apply(null, arguments)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ScissorsSVG',{staticClass:"w-6 w-6"})]},proxy:true}])})]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"no-wrap max-w"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('editor.cut_tooltip'))}})])]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
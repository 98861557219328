<template>
  <Dropdown class="absolute top-1 right-1 z-10" :disabled="disabled" itemsUnderTrigger dark>
    <template #default="{ toggle }">
      <div class="flex items-center h-13">
        <button
          type="button"
          @click.stop.prevent="toggle"
          :class="[
            'btn-transparent-white',
            'relative',
            'z-1',
            'flex',
            'justify-center',
            'items-center',
            'rounded',
            'h-9',
            'w-6',
          ]"
          :disabled="disabled"
        >
          <VerticalDostSVG class="fill-white-1 w-6 h-6" />
        </button>
      </div>
    </template>

    <template #items>
      <div class="pt-3 pb-4 px-6 w-53 cursor-default" @click.stop.prevent>
        <Button
          v-if="isLocal && !hasBrokenData && !isSubmitted"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.rename')"
          @click="triggerRename"
        />

        <Button
          v-if="isLocal && !hasBrokenData"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.copy')"
          @click="triggerCopy"
        />

        <Button
          v-if="isLocal && !hasBrokenData"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.download_local')"
          @click="triggerDownloadLocal"
        />

        <Button
          v-if="!isSubmitted"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.submit_as_final')"
          :disabled="downloadingVersionJSONFromCloud"
          :loading="downloadingVersionJSONFromCloud"
          @click="triggerSubmit"
        />

        <Button
          v-if="!isLocal && !isSubmitted"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.download_cloud')"
          @click="triggerDownloadCloud"
        />

        <Button
          v-if="isLocal && !isSubmitted"
          block
          align="left"
          color="btn-link-white"
          class="text-sm mt-1 py-1 px-2 hover:bg-white-7 w-full"
          :label="$t('version.delete')"
          @click="triggerDelete"
        />
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';

import VerticalDostSVG from '@/assets/svgs/vertical_dots.svg';

export default {
  name: 'VersionItemDropdown',
  components: { Dropdown, Button, VerticalDostSVG },
  props: {
    isLocal: {
      type: Boolean,
      default: false,
    },
    hasBrokenData: {
      type: Boolean,
      default: false,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    hasRecordings: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    downloadingVersionJSONFromCloud: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    triggerDelete() {
      this.$emit('delete');
    },
    triggerCopy() {
      this.$emit('copy');
    },
    triggerRename() {
      this.$emit('rename');
    },
    triggerDownloadLocal() {
      this.$emit('downloadLocal');
    },
    triggerDownloadCloud() {
      this.$emit('downloadCloud');
    },
    triggerSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

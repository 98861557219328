<template>
  <ContainerWithHeaderAndBack>
    <div class="text-center m-auto py-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('speakers_count.title') }}</h1>

      <SpeakersCountForm class="mt-6" />
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import SpeakersCountForm from '@/components/SpeakersCountForm.vue';

export default {
  name: 'SpeakersCount',
  components: { ContainerWithHeaderAndBack, SpeakersCountForm },
};
</script>

import requestFileSystemDirectory from './request_file_system_directory';
import readEntries from './read_entries';

function getFilesToDelete(versions, versionToRemove) {
  const result = [
    `${versionToRemove.id}.json`,
    `${versionToRemove.id}_1.json`,
    `${versionToRemove.id}_2.json`,
    ...versionToRemove.files.map((f) => f.type !== 'attachment' && f.file_name),
  ];
  const versionsIds = Object.keys(versions);

  for (const versionId of versionsIds) {
    if (versionId !== versionToRemove.id) {
      for (let i = 1; i < result.length; i++) {
        if (versions[versionId].files.some((file) => file.file_name === result[i])) {
          result.splice(i, 1);
          i--;
        }
      }
    }
  }

  return result;
}

export default function (versions, versionToRemove) {
  return new Promise((resolve, reject) => {
    requestFileSystemDirectory(versionToRemove.recorder_token, versionToRemove.speaker_id).then((dirEntry) => {
      const dirReader = dirEntry.createReader();

      readEntries(dirReader)
        .then((entries) => {
          const filesToRemove = getFilesToDelete(versions, versionToRemove);
          let removedCounter = 0;

          if (filesToRemove.length === 0) {
            resolve();
            return;
          }

          const entriesToRemove = entries.filter((e) => filesToRemove.includes(e.name));

          if (entriesToRemove.length === 0) {
            resolve();
            return;
          }

          const deleteSuccess = (entry) => {
            if (process.env.NODE_ENV === 'development') {
              console.log(`Removed ${entry.fullPath}`);
            }

            if (++removedCounter >= entriesToRemove.length) {
              resolve();
            }
          };

          for (const entry of entriesToRemove) {
            entry.remove(
              () => deleteSuccess(entry),
              (error) => console.warn('Removing error', error),
            );
          }
        })
        .catch((error) => reject(error));
    });
  });
}

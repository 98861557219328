import Logger from '@/modules/logger';

import { playSound } from '@/helpers';

import { RECORDING_COUNTDOWN_S } from '@/constants';

import countdownSound from '@/assets/countdown_4s.mp3';

export default function ({ commit, dispatch, state, getters }, { countdownSeconds = RECORDING_COUNTDOWN_S } = {}) {
  if (!state.speakerStream || (state.screenSharingEnabled && !state.screenSharingStream)) {
    commit('setKey', { key: 'isWaitingForStreams', isWaitingForStreams: true });
    return null;
  }

  Logger.log(`Trigger start recording, countdown: ${countdownSeconds} s`);

  const countdown = () =>
    new Promise((resolve) => {
      playSound(countdownSeconds, countdownSound, (time, audio) => {
        if (!state.isCountdownModalActive) {
          audio.pause();
          return;
        }

        const remainingSeconds = Math.max(countdownSeconds - Math.max(Math.round(time + 0.2) - 1, 0), 0);

        if (remainingSeconds === state.recordingCountdownValue) {
          return;
        }

        commit('setKey', {
          key: 'recordingCountdownValue',
          recordingCountdownValue: remainingSeconds,
        });
      }).then(() => {
        if (!state.isCountdownModalActive) {
          return;
        }

        commit('setKey', { key: 'isCountdownModalActive', isCountdownModalActive: false });

        setTimeout(() => {
          resolve();
        }, 200);
      });
    });

  if (!countdownSeconds) {
    commit('setKey', { key: 'isWaitingForStreams', isWaitingForStreams: false });

    return dispatch('startRecording');
  }

  commit('setKey', { key: 'isCountdownModalActive', isCountdownModalActive: true });
  commit('setKey', { key: 'isWaitingForStreams', isWaitingForStreams: false });
  commit('setKey', {
    key: 'recordingCountdownValue',
    recordingCountdownValue: countdownSeconds,
  });

  return countdown()
    .then(() => dispatch('startRecording'))
    .then(() => getters.currentVersion);
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-column',
    'justify-between',
    'bg-white-8',
    'rounded-lg',
    'w-1/3',
    'min-w-80',
    'max-w-120',
    'px-8',
    'pt-11',
    'pb-9',
    'mr-4',
  ]},[_c('div',{staticClass:"flex-1 overflow-auto"},[_c('h1',{staticClass:"text-white-1"},[_vm._v(_vm._s(_vm.conferenceName))]),_c('p',{staticClass:"text-white-1 font-light"},[_vm._v(_vm._s(_vm.$t('app_name')))]),_c('h2',{staticClass:"text-white-1 mt-14"},[_vm._v(_vm._s(_vm.$t('welcome.overview.speech_title')))]),_c('p',{staticClass:"text-white-1 font-light mt-2"},[_vm._v(_vm._s(_vm.presentationTitle))]),(_vm.deadlineAt)?_c('div',{staticClass:"mt-6"},[_c('h2',{staticClass:"text-white-1"},[_vm._v(_vm._s(_vm.$t('welcome.overview.deadline')))]),_c('p',{staticClass:"text-white-1 mt-2"},[_c('DateFromNow',{staticClass:"font-bold",attrs:{"date":_vm.deadlineAt,"highlightPast":""}}),_c('br'),_c('span',{staticClass:"font-light"},[_vm._v(_vm._s(_vm.$d(new Date(_vm.deadlineAt), 'deadline')))])],1)]):_vm._e(),(_vm.speakers.length !== 1)?_c('div',{staticClass:"mt-6"},[_c('h2',{staticClass:"text-white-1"},[_vm._v(_vm._s(_vm.$t('welcome.overview.number_of_speakers')))]),_c('p',{staticClass:"text-white-1 font-light mt-2"},[(_vm.speakers.length > 0)?_c('span',[_vm._v(_vm._s(_vm.speakers.length))]):_c('span',[_vm._v("-")])])]):_vm._e(),(_vm.presentationDurationS)?_c('div',{staticClass:"mt-6"},[_c('h2',{staticClass:"text-white-1"},[_vm._v(_vm._s(_vm.$t('welcome.overview.speech_length')))]),_c('p',{staticClass:"text-white-1 font-light mt-2"},[_vm._v(_vm._s(_vm.formattedDuration))])]):_vm._e()]),(!_vm.hideRegister)?_c('div',{staticClass:"text-center mt-9"},[_c('router-link',{attrs:{"to":{ name: 'Register' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('Button',{staticClass:"w-full no-underline hover:no-underline",attrs:{"href":href,"label":_vm.$t('welcome.overview.register_cta'),"block":""},on:{"click":navigate}})]}}],null,false,601562801)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import requestFileSystemDirectory from './request_file_system_directory';
import checkValidJSONFile from './check_valid_json_file';

export default function (data) {
  data.files = data.files.filter((f) => f.type !== 'attachment');

  console.time('Create JSON blob');
  const dataString = JSON.stringify(data);
  const blob = new Blob([dataString], { type: 'application/json' });
  console.timeEnd('Create JSON blob');

  const doWrite = (suffix = '_1') =>
    new Promise((res, rej) => {
      requestFileSystemDirectory(data.recorder_token, data.speaker_id, 1024 * 1024).then((dirEntry) => {
        dirEntry.getFile(
          `${data.id}${suffix}.json`,
          { create: true },
          (fileEntry) => {
            fileEntry.createWriter(
              (fileWriter) => {
                fileWriter.onwriteend = () => {
                  fileWriter.onwriteend = () => {
                    fileWriter.onwriteend = null;

                    console.timeEnd('Write JSON blob');

                    res(fileEntry);
                  };

                  console.time('Write JSON blob');
                  fileWriter.write(blob);
                };

                fileWriter.seek(0);
                fileWriter.truncate(0);
              },
              (error) => rej(error),
            );
          },
          (error) => rej(error),
        );
      });
    });

  return doWrite()
    .then((entry) => checkValidJSONFile(entry))
    .catch(() => doWrite().then((entry) => checkValidJSONFile(entry)))
    .then(() => doWrite('_2'));
}

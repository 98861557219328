class RecorderSettings {
  static get requestedStorageBytes() {
    return 10 * 1024 * 1024 * 1024;
  }

  static get minStorageBytes() {
    return 1024 * 1024 * 512;
  }

  static get recordingMimeType() {
    return 'video/webm';
  }

  static get speakerRecordingBitrate() {
    return 3 * 1024 * 1024;
  }

  static get screenSharingRecordingBitrate() {
    return 3 * 1024 * 1024;
  }
}

export default RecorderSettings;

import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }, { id }) {
  const requestData = {
    speakers_token: state.speakersToken,
  };

  Logger.log(`Detele speaker, id: ${id}`);

  return makeRequest('DELETE', `${API_URL}/tokens/${state.tokenId}/speakers/${id}`, requestData).then((response) => {
    commit('setKey', { key: 'speakers', speakers: response.data });
  });
}

import Logger from '@/modules/logger';

export default function ({ commit, dispatch }, payload) {
  Logger.log(`FetchShareData: ${JSON.stringify(payload)}`);

  return dispatch('downloadVersionJSONFromCloud', payload).then((data) => {
    commit('setTokenData', { ...data.token, share_page: true });

    data.json.has_local_data = false;

    const speaker = data.upload.speaker;

    delete data.upload.files;
    delete data.upload.speaker;

    const shareData = {
      token: data.token,
      upload: data.upload,
      speaker,
      version: data.json,
    };

    return shareData;
  });
}

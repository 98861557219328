<template>
  <div class="text-center">
    <h3 class="text-base font-medium">{{ $t('setup.microphone.microphone_test') }}</h3>

    <div class="flex items-top justify-center text-sm mt-3">
      <Button
        @click="toggleRecording"
        size="small"
        class="w-35"
        color="btn-primary-outline"
        :disabled="!recordEnabled"
        :label="recordLabel"
      >
        <template v-if="isRecording" #icon>
          <DotSVG class="w-4 h-4 mr-2" />
        </template>
      </Button>

      <Button
        @click="togglePlaying"
        size="small"
        class="ml-2 w-35"
        color="btn-primary-outline"
        :label="playLabel"
        :disabled="!playEnabled"
      />

      <audio autoplay ref="audio" @ended="stopPlaying" class="w-0 h-0 overflow-hidden">
        <track kind="captions" />
      </audio>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';

import DotSVG from '@/assets/svgs/dot.svg';

import { AUDIO_TEST_LENGTH_IN_S } from '@/constants';

export default {
  name: 'MicrophoneTest',
  components: { Button, DotSVG },
  data() {
    return {
      isRecording: false,
      isPlaying: false,
      recordingTimeout: null,
      mediaRecorder: null,
      recordedChunks: [],
    };
  },
  computed: {
    ...mapState(['microphoneEnabled', 'microphoneErrors', 'speakerStream', 'isWaitingForSpeakerStream']),
    recordLabel() {
      if (this.isRecording) {
        return this.$i18n.t('setup.microphone.recording_test');
      }

      return this.$i18n.t('setup.microphone.record_test', {
        length: `${AUDIO_TEST_LENGTH_IN_S} s`,
      });
    },
    playLabel() {
      if (this.isPlaying) {
        return this.$i18n.t('setup.microphone.stop');
      }

      return this.$i18n.t('setup.microphone.play');
    },
    recordEnabled() {
      return (
        !this.isWaitingForSpeakerStream &&
        !this.isPlaying &&
        this.microphoneEnabled &&
        this.microphoneErrors.length === 0 &&
        this.speakerStream
      );
    },
    playEnabled() {
      return (
        !this.isRecording && this.microphoneEnabled && this.recordedChunks.length && this.microphoneErrors.length === 0
      );
    },
  },
  methods: {
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      this.isRecording = true;
      this.recordedChunks = [];

      this.mediaRecorder = new MediaRecorder(this.speakerStream);
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;

      try {
        this.mediaRecorder.start();
      } catch (err) {
        this.mediaRecorder = null;
        return;
      }

      this.recordingTimeout = setTimeout(() => {
        this.recordingTimeout = null;
        this.stopRecording();
      }, AUDIO_TEST_LENGTH_IN_S * 1000);
    },
    stopRecording() {
      if (this.mediaRecorder) {
        if (this.mediaRecorder.state !== 'inactive') {
          this.mediaRecorder.stop();
        }

        this.mediaRecorder = null;
      }

      if (this.recordingTimeout) {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = null;
      }

      this.isRecording = false;
    },
    handleDataAvailable(event) {
      if (event.data.size > 0) {
        this.recordedChunks = [...this.recordedChunks, event.data];
      }
    },
    togglePlaying() {
      if (this.isPlaying) {
        this.stopPlaying();
      } else {
        this.startPlaying();
      }
    },
    startPlaying() {
      this.isPlaying = true;

      const superBuffer = new Blob(this.recordedChunks);
      this.$refs.audio.src = window.URL.createObjectURL(superBuffer);
    },
    stopPlaying() {
      if (!this.$refs.audio) {
        this.isPlaying = false;
        return;
      }

      this.$refs.audio.pause();
      this.$refs.audio.src = '';

      this.isPlaying = false;
    },
  },
  beforeDestroy() {
    if (this.isRecording) this.stopRecording();
  },
};
</script>

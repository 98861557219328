<template>
  <div class="text-center px-4 py-8">
    <h2 class="text-2xl text-white-1">{{ title }}</h2>

    <Form class="mt-4" @submit="confirm">
      <Input
        v-model="newValue"
        name="name"
        type="text"
        :placeholder="placeholder"
        class="block w-78 mx-auto"
        required
        :validateOnBlur="false"
      />

      <div class="mt-6">
        <Button type="submit" :label="$t('version.rename_modal.confirm')" :loading="loading" />
      </div>
    </Form>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';

export default {
  name: 'VersionNameModal',
  components: { Button, Form, Input },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: '',
    };
  },
  watch: {
    name: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm', this.newValue);
    },
  },
};
</script>

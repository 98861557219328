var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'focus-within:outline-primary',
    'focus:z-1',
    'relative',
    'oveflow-hidden',
    {
      'rounded': _vm.rounded,
      'inline-flex': !_vm.block,
      'flex': _vm.block,
    },
  ]},[(((_vm.$attrs).type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],class:[
      'flex-1',
      'text-white-3',
      'text-base',
      'placeholder-white-5',
      'bg-white-7',
      'border',
      'border-solid',
      'border-transparent',
      'outline-none',
      'w-full',
      'text-center',
      {
        'rounded-l': _vm.rounded,
        'min-h-9': this.size === 'small',
        'px-3': this.size === 'small',
        'py-1': this.size === 'small',
        'min-h-13': this.size === 'medium',
        'px-5': this.size === 'medium',
        'py-3': this.size === 'medium',
        'min-h-15': this.size === 'large',
        'px-7': this.size === 'large',
        'py-5': this.size === 'large',
      },
    ],attrs:{"step":_vm.minStepNumber,"max":_vm.max,"min":_vm.min,"size":_vm.size,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.computedValue)?_vm._i(_vm.computedValue,null)>-1:(_vm.computedValue)},on:{"change":function($event){var $$a=_vm.computedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedValue=$$c}}}},'input',_vm.$attrs,false),_vm.$listeners)):(((_vm.$attrs).type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],class:[
      'flex-1',
      'text-white-3',
      'text-base',
      'placeholder-white-5',
      'bg-white-7',
      'border',
      'border-solid',
      'border-transparent',
      'outline-none',
      'w-full',
      'text-center',
      {
        'rounded-l': _vm.rounded,
        'min-h-9': this.size === 'small',
        'px-3': this.size === 'small',
        'py-1': this.size === 'small',
        'min-h-13': this.size === 'medium',
        'px-5': this.size === 'medium',
        'py-3': this.size === 'medium',
        'min-h-15': this.size === 'large',
        'px-7': this.size === 'large',
        'py-5': this.size === 'large',
      },
    ],attrs:{"step":_vm.minStepNumber,"max":_vm.max,"min":_vm.min,"size":_vm.size,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.computedValue,null)},on:{"change":function($event){_vm.computedValue=null}}},'input',_vm.$attrs,false),_vm.$listeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],class:[
      'flex-1',
      'text-white-3',
      'text-base',
      'placeholder-white-5',
      'bg-white-7',
      'border',
      'border-solid',
      'border-transparent',
      'outline-none',
      'w-full',
      'text-center',
      {
        'rounded-l': _vm.rounded,
        'min-h-9': this.size === 'small',
        'px-3': this.size === 'small',
        'py-1': this.size === 'small',
        'min-h-13': this.size === 'medium',
        'px-5': this.size === 'medium',
        'py-3': this.size === 'medium',
        'min-h-15': this.size === 'large',
        'px-7': this.size === 'large',
        'py-5': this.size === 'large',
      },
    ],attrs:{"step":_vm.minStepNumber,"max":_vm.max,"min":_vm.min,"size":_vm.size,"disabled":_vm.disabled,"type":(_vm.$attrs).type},domProps:{"value":(_vm.computedValue)},on:{"input":function($event){if($event.target.composing)return;_vm.computedValue=$event.target.value}}},'input',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticClass:"flex flex-column w-7"},[_c('Button',{staticClass:"flex-1 p-0",class:[{ 'rounded-tr': _vm.rounded }],attrs:{"type":"button","rounded":false,"color":"btn-white-white","disabled":_vm.disabled || _vm.disabledMax},on:{"mousedown":function($event){return _vm.handleStartLongPress($event, true)},"touchstart":function($event){$event.preventDefault();return _vm.handleStartLongPress($event, true)},"click":function($event){return _vm.handleControlClick($event, true)},"mouseup":_vm.handleStopLongPress,"mouseleave":_vm.handleStopLongPress,"touchend":_vm.handleStopLongPress,"touchcancel":_vm.handleStopLongPress,"focusin":function($event){return _vm.handleStartLongPress($event, true)},"focusout":_vm.handleStopLongPress},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('UpArrowSVG',{staticClass:"w-5 h-5"})]},proxy:true}])}),_c('Button',{staticClass:"flex-1 p-0",class:[{ 'rounded-br': _vm.rounded }],attrs:{"type":"button","rounded":false,"color":"btn-white-white","disabled":_vm.disabled || _vm.disabledMin},on:{"mousedown":function($event){return _vm.handleStartLongPress($event, false)},"touchstart":function($event){$event.preventDefault();return _vm.handleStartLongPress($event, false)},"click":function($event){return _vm.handleControlClick($event, false)},"mouseup":_vm.handleStopLongPress,"mouseleave":_vm.handleStopLongPress,"touchend":_vm.handleStopLongPress,"touchcancel":_vm.handleStopLongPress,"focusin":function($event){return _vm.handleStartLongPress($event, false)},"focusout":_vm.handleStopLongPress},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DownArrowSVG',{staticClass:"w-5 h-5"})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="text-center mx-auto container-sm py-1">
    <WarningSVG class="fill-white-1 w-12 h-12" />

    <h1 class="text-white-1 text-2xl mt-3">{{ $t('record.confirm_submit_final.title') }}</h1>

    <p class="container-sm font-light text-center mt-3">
      {{ $t('record.confirm_submit_final.message') }}
    </p>

    <Button class="w-68 mt-6" :label="$t('record.confirm_submit_final.confirm')" @click="confirm" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import WarningSVG from '@/assets/svgs/warning.svg';

export default {
  name: 'ConfirmFinalUpload',
  components: { Button, WarningSVG },
  methods: {
    confirm() {
      this.$emit('continue');
    },
  },
};
</script>

import * as Sentry from '@sentry/browser';

import {
  findDeviceIdsInStream,
  requestFileSystemDirectory,
  finishRecording,
  toSnakeCase,
  errorToObject,
} from '@/helpers';

import { RECORDING_INTERRUPT_AFTER_S } from '@/constants';

import Logger from './logger';
import RecorderSettings from './recorder_settings';
import WebmSeekableMetadataFixer from './webm_seekable_metadata_fixer';

class Recorder {
  constructor(token, speakerId, kind) {
    this.token = token;
    this.speakerId = speakerId;
    this.kind = kind;

    this.callbacks = {
      progress: new Set(),
      cleanup: new Set(),
      interrupted: new Set(),
      done: new Set(),
    };

    this.stream = null;
    this.grantedBytes = null;
    this.triggeredAt = null;
    this.startedAt = null;
    this.stoppedAt = null;

    this.fileEntry = null;
    this.fileWriter = null;
    this.fileWriterReady = true;

    this.expectedFileSize = 0;

    this.mediaRecorder = null;
    this.defaultMimeType = null;
    this.recordedChunksToWrite = [];
    this.recordedChunkBeingWritten = null;

    this.webmSeekableMetadataFixer = null;

    this.dataAvailableTimeout = null;
    this.fileWriterWriteTimeout = null;

    this.startInProgress = false;
    this.stopInProgress = false;

    this.lastError = null;
    this.lastEventTargetError = null;
  }

  getDefaultMimeType(stream) {
    return new Promise((resolve) => {
      const mediaRecorder = new MediaRecorder(stream, this.recordingOptions);

      mediaRecorder.onstart = () => {
        mediaRecorder.stop();
        resolve(mediaRecorder.mimeType);
      };

      try {
        mediaRecorder.start();
      } catch (err) {
        resolve('');
      }
    });
  }

  async startRecording(stream, grantedBytes, triggeredAt) {
    if (this.startInProgress) {
      this.warn('Multiple starts triggered.', '#start');
      return Promise.resolve(null);
    }

    this.startInProgress = true;

    this.stream = stream;
    this.grantedBytes = grantedBytes;
    this.triggeredAt = triggeredAt;
    this.startedAt = null;
    this.stoppedAt = null;

    this.fileWriterReady = true;

    this.expectedFileSize = 0;

    this.recordedChunksToWrite = [];
    this.recordedChunkBeingWritten = null;

    if (!this.defaultMimeType) {
      this.defaultMimeType = await this.getDefaultMimeType(this.stream);
    }

    return new Promise((resolve) => {
      const resolveError = (error, errorKey) => {
        this.mediaRecorder.ondataavailable = null;
        this.mediaRecorder = null;
        this.startInProgress = false;
        this.warn(error, '#start');
        resolve({ kind: this.kind, error: errorKey });
      };

      if (!this.stream) {
        this.startInProgress = false;
        resolveError(`No stream set!`, `recorder_${toSnakeCase(this.kind)}_no_stream`);
        return;
      }

      this.mediaRecorder = new MediaRecorder(this.stream, this.recordingOptions);
      this.mediaRecorder.ondataavailable = this._handleDataAvailable.bind(this);

      requestFileSystemDirectory(this.token, this.speakerId)
        .then((dirEntry) => {
          dirEntry.getFile(
            this.recordingFileName,
            { create: true, exclusive: true },
            (fileEntry) => {
              this.fileEntry = fileEntry;

              fileEntry.createWriter(
                (fileWriter) => {
                  this.fileWriter = fileWriter;

                  this._startRecordingWithFileWriter()
                    .then(() => {
                      const startedRecording = this._createStartedRecording();
                      this.startInProgress = false;
                      resolve(startedRecording);
                    })
                    .catch((error) => {
                      resolveError(
                        `Unable to start recording (${error.name})`,
                        `recorder_${toSnakeCase(this.kind)}_unable_to_start`,
                      );
                    });
                },
                (error) => {
                  this.fileEntry = null;
                  resolveError(
                    `Unable to create FileWriter. Error: ${JSON.stringify(error)}`,
                    `recorder_${toSnakeCase(this.kind)}_unable_to_create_tmp_filewriter`,
                  );
                },
              );
            },
            (error) =>
              resolveError(
                `Unable to get FileEntry. Error: ${JSON.stringify(error)}`,
                `recorder_${toSnakeCase(this.kind)}_unable_to_get_tmp_fileentry`,
              ),
          );
        })
        .catch((error) =>
          resolveError(
            `Unable to get main directory. Error: ${JSON.stringify(error)}`,
            `recorder_${toSnakeCase(this.kind)}_unable_to_get_main_directory`,
          ),
        );
    });
  }

  stopRecording(skipNoStreamRecorderErrors = false, interrupted = false) {
    if (this.stopInProgress) {
      this.warn('Multiple stops triggered.', '#stop');
      return Promise.resolve(null);
    }

    this.stopInProgress = true;

    this._clearDataAvailableTimeout();
    this._progressWebmFixer({ size: 1, processed: 0, done: false });

    return new Promise((resolve) => {
      const resolveError = (error, errorKey) => {
        this.stopInProgress = false;
        this.warn(error, '#stop');
        this._progressWebmFixerDone();
        resolve({ kind: this.kind, error: errorKey });
      };

      const log = (error, skipConsole = false) => {
        this.log(error, skipConsole, '#stop');
      };

      const warn = (error) => {
        this.warn(error, '#stop');
      };

      if (!this.stream) {
        this.webmSeekableMetadataFixer = null;
        this.fileEntry = null;

        if (!skipNoStreamRecorderErrors) {
          resolveError(`No stream set!`, `recorder_${toSnakeCase(this.kind)}_no_stream`);
        } else {
          this.stopInProgress = false;
          this._progressWebmFixerDone();
          resolve();
        }

        return;
      }

      if (!this.mediaRecorder) {
        this.webmSeekableMetadataFixer = null;
        this.fileEntry = null;

        if (!skipNoStreamRecorderErrors) {
          resolveError(`No recorder!`, `recorder_${toSnakeCase(this.kind)}_no_recorder`);
        } else {
          this.stopInProgress = false;
          this._progressWebmFixerDone();
          resolve();
        }

        return;
      }

      const doStop = () => {
        let recordedFileSize = 0;

        if (this.fileWriter) {
          recordedFileSize = this.fileWriter.length;

          this.fileWriter.onerror = null;
          this.fileWriter.onwriteend = null;
          this.fileWriter = null;
        }

        const recordedMimeType = this.mediaRecorder.mimeType.split(';')[0];
        const requestedMimeType = this.mediaRecorder.mimeType.split(';')[0];

        if (!recordedMimeType || !requestedMimeType || recordedMimeType.trim() !== requestedMimeType.trim()) {
          log(
            // eslint-disable-next-line max-len
            `Recorded file MIME type ${this.mediaRecorder.mimeType} doesn't match MIME type requested ${this.recordingOptions.mimeType}.`,
          );
        }

        log(
          // eslint-disable-next-line max-len
          `Wrote ${recordedFileSize} B. Expected written bytes to be ${this.expectedFileSize} B`,
        );

        if (recordedFileSize !== this.expectedFileSize) {
          Sentry.captureMessage(
            // eslint-disable-next-line max-len
            `[RECORDER STOP] Wrote ${recordedFileSize} B, expected ${this.expectedFileSize} B for ${this.kind}.`,
            {
              level: Sentry.Severity.Info,
              extra: {
                error: this.lastError && errorToObject(this.lastError),
                event_target_error: this.lastEventTargetError && errorToObject(this.lastEventTargetError),
              },
            },
          );
        } else if (this.lastError || this.lastEventTargetError) {
          Sentry.captureMessage(
            // eslint-disable-next-line max-len
            `[RECORDER STOP] Error`,
            {
              level: Sentry.Severity.Info,
              extra: {
                error: this.lastError && errorToObject(this.lastError),
                event_target_error: this.lastEventTargetError && errorToObject(this.lastEventTargetError),
              },
            },
          );
        }

        this.lastError = null;
        this.lastEventTargetError = null;

        if (!this.fileEntry) {
          this.mediaRecorder = null;
          resolveError('No FileEntry!', `recorder_${toSnakeCase(this.kind)}_no_tmp_file_entry`);
          return;
        }

        if (interrupted) {
          this.mediaRecorder = null;
          this.stopInProgress = false;
          this._progressWebmFixerDone();
          resolve({
            kind: this.kind,
            startedAt: this.startedAt,
            stoppedAt: this.stoppedAt,
            interrupted: true,
          });
          return;
        }

        const startedRecording = this._createStartedRecording({
          stoppedAt: this.stoppedAt,
          bytesWritten: recordedFileSize,
          expectedBytesWritten: this.expectedFileSize,
        });

        finishRecording(this.token, this.speakerId, startedRecording, this.webmSeekableMetadataFixer, this.fileEntry, {
          log: log.bind(this),
          warn: warn.bind(this),
        }).then((finishedRecording) => {
          this.mediaRecorder = null;
          this.webmSeekableMetadataFixer = null;
          this.fileEntry = null;
          this.stopInProgress = false;

          this._progressWebmFixerDone();
          resolve(finishedRecording);
        });
      };

      const stopWhenAllChunksAreWritten = () => {
        if (!this.fileWriterReady || this.recordedChunksToWrite.length > 0) {
          log(
            `Waiting for all recorded chunks to be written and FileWriter to be ready, ${
              this.recordedChunksToWrite.length
            } chunks pending, FileWriter ${this.fileWriterReady ? 'ready' : 'not ready'}`,
          );

          setTimeout(stopWhenAllChunksAreWritten, 250);
          return;
        }

        doStop();
      };

      this.mediaRecorder.onstop = () => {
        this.stoppedAt = new Date().getTime();
        log('Stopped MediaRecorder', true);
        stopWhenAllChunksAreWritten();
      };

      setTimeout(() => {
        try {
          if (this.mediaRecorder) {
            this.mediaRecorder.stop();
          }
        } catch (error) {
          warn(`MediaRecorder stopping error. Error: ${JSON.stringify(error)}; Stack: ${error.stack}`);

          stopWhenAllChunksAreWritten();
        }
      }, 500);
    });
  }

  on(event, callback) {
    this.callbacks[event].add(callback);
  }

  runCallbacks(event, ...args) {
    for (const callback of this.callbacks[event]) {
      callback(...args);
    }
  }

  log(error, skipConsole = false, suffix = '') {
    if (!skipConsole || process.env.NODE_ENV === 'development') {
      console.log(`Recorder${suffix} ${this.kind}: ${error}`);
    }

    Logger.log(`Recorder${suffix} ${this.kind}: ${error}`);
  }

  warn(error, suffix = '') {
    console.warn(`Recorder${suffix} ${this.kind}: ${error}`);
    Logger.log(`Recorder${suffix} ${this.kind}: ${error}`);
  }

  _startRecordingWithFileWriter() {
    let lastUnwrittenChunk = null;

    this.fileWriter.onerror = (error) => {
      this.lastError = error;
      this.lastEventTargetError = error.target && error.target.error;

      const errorString = JSON.stringify(errorToObject(error));
      const eventTargetErrorString =
        error.target && error.target.error && JSON.stringify(errorToObject(error.target.error));

      this.log(
        // eslint-disable-next-line max-len
        `FileWriter onError: error = ${errorString}, event.target.error = ${eventTargetErrorString}`,
      );

      if (error.target.error.name === 'QuotaExceededError') {
        this.runCallbacks('interrupted', {
          kind: this.kind,
          error: 'quota_exceeded_error',
        });

        return;
      }

      if (this.fileWriter.length !== this.expectedFileSize) {
        this.log(
          // eslint-disable-next-line max-len
          `FileWriter onError: written bytes ${this.fileWriter.length} don't match expected bytes ${this.expectedFileSize}`,
        );

        this.runCallbacks('interrupted', {
          kind: this.kind,
          error: `recorder_${toSnakeCase(this.kind)}_write_error`,
        });
      }
    };

    this.fileWriter.onwriteend = () => {
      if (this.fileWriter.length !== this.expectedFileSize) {
        this.log(
          // eslint-disable-next-line max-len
          `FileWriter onWriteEnd: written bytes ${this.fileWriter.length} don't match expected bytes ${this.expectedFileSize}`,
        );
      }

      if (this.fileWriter.length === this.expectedFileSize - this.recordedChunkBeingWritten.size) {
        if (
          !lastUnwrittenChunk ||
          lastUnwrittenChunk.size !== this.recordedChunkBeingWritten.size ||
          !this.stopInProgress
        ) {
          this.log(
            // eslint-disable-next-line max-len
            `FileWriter onWriteEnd: looks like last chunk wasn't written, size = ${this.recordedChunkBeingWritten.size} B, adding it back to list of chunks to write`,
          );

          lastUnwrittenChunk = this.recordedChunkBeingWritten;
          this.expectedFileSize -= this.recordedChunkBeingWritten.size;
          this.recordedChunksToWrite.unshift(this.recordedChunkBeingWritten);
        } else {
          lastUnwrittenChunk = null;
        }
      } else {
        lastUnwrittenChunk = null;

        if (this.webmSeekableMetadataFixer) {
          this.webmSeekableMetadataFixer.processData(this.recordedChunkBeingWritten);
        }
      }

      this.recordedChunkBeingWritten = null;
      this._clearFileWriteWriteTimeout();

      if (this.recordedChunksToWrite.length) {
        this._writeDataToFile(this.recordedChunksToWrite.shift());
        return;
      }

      this.fileWriterReady = true;
    };

    if (this.needsWebmFix) {
      this.webmSeekableMetadataFixer = new WebmSeekableMetadataFixer(this.kind, this._progressWebmFixer.bind(this));
    }

    return new Promise((resolve, reject) => {
      this.mediaRecorder.onstart = () => {
        this.startedAt = new Date().getTime();
        this.log('Start MediaRecorder', true, '#start');
        this._setDataAvailableTimeout();
        resolve();
      };

      try {
        this.mediaRecorder.start(1000);
      } catch (err) {
        reject(err);
      }
    });
  }

  _handleDataAvailable({ data }) {
    if (data.size <= 0) {
      this.log('Trying to write 0 B data.', true);
      return;
    }

    if (!this.fileWriterReady) {
      if (this.dataAvailableTimeout !== null) {
        this._setDataAvailableTimeout();
      }

      this.recordedChunksToWrite.push(data);
      return;
    }

    this.fileWriterReady = false;
    this._writeDataToFile(data, true);
  }

  _writeDataToFile(data, dataTimeout = false) {
    if (!this.fileWriter) {
      this.log('Trying to write to file without FileWriter.', true);
      return;
    }

    if (dataTimeout && this.dataAvailableTimeout !== null) {
      this._setDataAvailableTimeout();
    }

    this.recordedChunkBeingWritten = data;

    this.expectedFileSize += data.size;
    this.fileWriter.write(data);

    this._setFileWriterWriteTimeout();
  }

  _setDataAvailableTimeout() {
    this._clearDataAvailableTimeout();

    this.dataAvailableTimeout = setTimeout(() => {
      this.runCallbacks('interrupted', {
        kind: this.kind,
        error: [`recorder_${toSnakeCase(this.kind)}_stream_data_not_available`, 'stream_data_not_available'],
      });
    }, RECORDING_INTERRUPT_AFTER_S * 1000);
  }

  _clearDataAvailableTimeout() {
    if (this.dataAvailableTimeout) {
      clearTimeout(this.dataAvailableTimeout);
    }

    this.dataAvailableTimeout = null;
  }

  _setFileWriterWriteTimeout() {
    this._clearFileWriteWriteTimeout();

    this.fileWriterWriteTimeout = setTimeout(() => {
      this.warn(
        // eslint-disable-next-line max-len
        `FileWriter write timeout, ${this.recordedChunksToWrite.length} chunks pending, FileWriter ${
          this.fileWriterReady ? 'ready' : 'not ready'
        }`,
      );
    }, RECORDING_INTERRUPT_AFTER_S * 1000);
  }

  _clearFileWriteWriteTimeout() {
    if (this.fileWriterWriteTimeout) {
      clearTimeout(this.fileWriterWriteTimeout);
    }

    this.fileWriterWriteTimeout = null;
  }

  _progressWebmFixerDone() {
    this.runCallbacks('done', { kind: this.kind });
  }

  _progressWebmFixer(data) {
    const { size, processed, done } = data;

    if (done) {
      this.runCallbacks('cleanup', { kind: this.kind });
      return;
    }

    this.runCallbacks('progress', { kind: this.kind, size, processed });
  }

  _createStartedRecording({ stoppedAt = null, bytesWritten = null, expectedBytesWritten = null } = {}) {
    const { audioDeviceId, videoDeviceId } = findDeviceIdsInStream(this.stream);
    const mimeType = this.mediaRecorder.mimeType;

    const startedRecording = {
      kind: this.kind,
      audio: !!audioDeviceId,
      video: !!videoDeviceId,
      mimeType,
      startedAt: this.startedAt,
      stoppedAt,
      recordingFileName: this.recordingFileName,
      fileName: this.fileName,
      bytesWritten,
      expectedBytesWritten,
      needsWebmFix: this.needsWebmFix,
    };

    if (this.kind === 'screenSharing') {
      startedRecording.displaySurface = this.displaySurface;
    }

    return startedRecording;
  }

  get recordingOptions() {
    const options = {};

    if (this.defaultMimeType) {
      options.mimeType = this.defaultMimeType;
    }

    if (this.kind === 'screenSharing') {
      return options;
    }

    options.videoBitsPerSecond = RecorderSettings.speakerRecordingBitrate;

    return options;
  }

  get displaySurface() {
    if (this.kind === 'screenSharing') {
      const videoTracks = this.stream.getVideoTracks();

      if (videoTracks.length) {
        return videoTracks[0].getSettings().displaySurface;
      }
    }

    return undefined;
  }

  get recordingFileName() {
    if (this.needsWebmFix) {
      return `tmp_${this.fileName}`;
    }

    return this.fileName;
  }

  get fileName() {
    const nameDate = this.triggeredAt.replace(/([0-9-]*)T([0-9:]*).*/, '$1$2').replace(/[^0-9]/g, '');
    const kindSuffix = this.kind === 'screenSharing' ? 'sl' : 'sp';

    return `slrec_${nameDate}_${kindSuffix}.${this.recordingFileExtension}`;
  }

  get recordingFileExtension() {
    if (this.mediaRecorder.mimeType.startsWith('video/mp4')) {
      return 'mp4';
    }

    if (this.mediaRecorder.mimeType.startsWith('video/webm')) {
      return 'webm';
    }

    if (this.mediaRecorder.mimeType.startsWith('video/x-matroska')) {
      return 'mkv';
    }

    return 'video';
  }

  get needsWebmFix() {
    return this.mediaRecorder.mimeType.startsWith('video/webm');
  }
}

export default Recorder;

<template>
  <div class="flex justify-between w-full">
    <div class="flex-1 flex items-center w-1" ref="tabsContainer">
      <div class="flex items-end">
        <template v-if="!sharePage">
          <VersionItem
            v-for="version in filteredSortedVersions.slice(0, renderTabsCount)"
            v-on="$listeners"
            :active="version.id === currentVersion.id"
            :disabled="disabled"
            :version="version"
            :key="version.id"
            :downloadingVersionJSONFromCloud="downloadingVersionsJSONFromCloud.includes(version.id)"
            class="w-70 mr-2 rounded-t-lg"
          />
        </template>

        <transition name="slide" mode="out-in">
          <VersionItem
            v-if="!sharePage || lastRenderedVersion.id === currentVersion.id"
            v-on="$listeners"
            ref="tab"
            :active="lastRenderedVersion.id === currentVersion.id"
            :sharePage="sharePage"
            :disabled="disabled"
            :version="lastRenderedVersion"
            :key="lastRenderedVersion.id"
            :downloadingVersionJSONFromCloud="downloadingVersionsJSONFromCloud.includes(lastRenderedVersion.id)"
            class="w-70 rounded-t-lg"
          />
        </transition>
      </div>

      <div v-if="!guest" class="flex-none ml-2 px-1">
        <Tooltip v-if="sharePage">
          <template #trigger>
            <NewVersionTab disabled ref="newVersion" />
          </template>

          <template #default>
            <p class="text-xs">{{ $t('share.already_editing') }}</p>
          </template>
        </Tooltip>

        <NewVersionTab v-else :disabled="disabled" ref="newVersion" @create="$listeners.create" />
      </div>
    </div>

    <AllVersionsTab
      v-if="!sharePage"
      class="flex-none flex items-center h-16 ml-2"
      :disabled="disabled"
      :sortedVersions="filteredSortedVersions"
      :activeId="currentVersion.id"
      :downloadingVersionsJSONFromCloud="downloadingVersionsJSONFromCloud"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import VersionItem from '@/components/VersionItem.vue';
import NewVersionTab from '@/components/NewVersionTab.vue';
import AllVersionsTab from '@/components/AllVersionsTab.vue';
import Tooltip from '@/components/Tooltip.vue';

export default {
  name: 'VersionsTabs',
  components: { VersionItem, NewVersionTab, AllVersionsTab, Tooltip },
  props: {
    hideAll: {
      type: Boolean,
      default: false,
    },
    currentVersion: {
      type: Object,
      required: true,
    },
    sortedVersions: {
      type: Array,
      default: () => [],
    },
    sharePage: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    guest: {
      type: Boolean,
      default: false,
    },
    downloadingVersionsJSONFromCloud: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabsCount: 1,
      resizeHandler: this.updateVisibleTabs.bind(this),
    };
  },
  computed: {
    filteredSortedVersions() {
      if (this.sharePage) {
        return [this.currentVersion];
      }

      return this.sortedVersions;
    },
    renderTabsCount() {
      if (this.sharePage) {
        return 0;
      }

      return Math.min(this.filteredSortedVersions.length - 1, this.tabsCount - 1);
    },
    lastRenderedVersion() {
      if (this.sharePage) {
        return this.currentVersion;
      }

      const currentVersionIndex = this.filteredSortedVersions.findIndex((v) => v.id === this.currentVersion.id);

      return currentVersionIndex >= this.renderTabsCount
        ? this.currentVersion
        : this.filteredSortedVersions[this.renderTabsCount];
    },
  },
  watch: {
    sortedVersions() {
      this.updateVisibleTabs();
    },
  },
  methods: {
    updateVisibleTabs() {
      if (this.disabled || this.guest) {
        return;
      }

      const newVersionMargin = 8;
      const otherVersionMargin = 8;
      let availableSpace = 280;
      let versionWidth = 280;

      if (this.$refs.tabsContainer && this.$refs.newVersion) {
        availableSpace =
          this.$refs.tabsContainer.offsetWidth - (this.$refs.newVersion.$el.offsetWidth + newVersionMargin);
      }

      if (this.$refs.tab) {
        versionWidth = this.$refs.tab.$el.offsetWidth + otherVersionMargin;
      }

      const tabsCount = Math.floor((availableSpace + otherVersionMargin) / versionWidth);

      if (tabsCount === this.tabsCount) {
        return;
      }

      this.tabsCount = tabsCount;
    },
  },
  created() {
    setTimeout(() => {
      this.updateVisibleTabs();
    }, 0);

    window.addEventListener('resize', this.resizeHandler, false);
    window.addEventListener('orientationchange', this.resizeHandler, false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler, false);
    window.removeEventListener('orientationchange', this.resizeHandler, false);
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'border-2',
    'border-solid',
    'border-transparent',
    'text-white-1',
    'flex',
    'h-16',
    'items-center',
    'text-left',
    'relative',
    'z-1',
    'outline-none',
    'group',
    {
      'bg-white-9': _vm.active,
      'bg-white-10': !_vm.active,
      'focus-within:z-2': _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled && !_vm.sharePage,
      'hover:bg-white-8': _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled && !_vm.sharePage,
      'active:bg-white-9': _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled && !_vm.sharePage,
      'focus:border-white-7': _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled && !_vm.sharePage,
      'focus-within:opacity-1': _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled && !_vm.sharePage,
      'cursor-default': !_vm.isLocal || _vm.hasBrokenData || (_vm.active && _vm.disabled) || _vm.sharePage,
      'cursor-not-allowed': (!_vm.isLocal || (!_vm.active && _vm.disabled)) && !_vm.sharePage,
    },
  ],attrs:{"type":"button"},on:{"click":_vm.open}},[(!_vm.isLocal && !_vm.active && !_vm.sharePage)?_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"absolute inset-0 z-3"})]},proxy:true},{key:"default",fn:function(){return [(_vm.isSelfRecording)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('version.is_self_recording'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('version.not_local'))+" ")])]},proxy:true}],null,false,1120661330)}):_vm._e(),_c('div',{staticClass:"flex-none flex flex-column items-center justify-center px-3 w-17 text-center",class:[
      {
        'opacity-4': !_vm.active,
        'group-hover:opacity-1': !_vm.active && _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled,
      },
    ]},[(!_vm.sharePage && !_vm.isUploaded)?[_c('CompSVG',{staticClass:"fill-primary w-6 h-6"}),_c('span',{staticClass:"uppercase text-primary text-tiny font-black"},[_vm._v(_vm._s(_vm.$t('version.local')))])]:[_c('CloudSVG',{staticClass:"fill-primary w-6 h-6"}),_c('span',{staticClass:"uppercase text-primary text-tiny font-black"},[_vm._v(_vm._s(_vm.$t('version.cloud')))])]],2),_c('VersionNameState',{ref:"nameState",staticClass:"flex-1 pr-8 w-1",class:[
      {
        'opacity-4': !_vm.active,
        'group-hover:opacity-1': !_vm.active && _vm.isLocal && !_vm.hasBrokenData && !_vm.disabled,
      },
    ],attrs:{"name":_vm.name,"isUploaded":_vm.sharePage || _vm.isUploaded,"isSubmitted":_vm.isSubmitted,"stateInsteadOfTime":_vm.sharePage,"date":_vm.showDate}}),(!_vm.sharePage)?_c('VersionItemDropdown',{attrs:{"isLocal":_vm.isLocal,"isUploaded":_vm.isUploaded,"hasBrokenData":_vm.hasBrokenData,"isSubmitted":_vm.isSubmitted,"hasRecordings":_vm.hasRecordings,"disabled":_vm.disabled,"downloadingVersionJSONFromCloud":_vm.downloadingVersionJSONFromCloud},on:{"delete":_vm.triggerDelete,"copy":_vm.triggerCopy,"rename":_vm.triggerRename,"downloadLocal":_vm.triggerDownloadLocal,"downloadCloud":_vm.triggerDownloadCloud,"submit":_vm.triggerSubmit}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="bg-white-9 p-2 rounded-lg flex flex-column w-full">
    <div class="flex items-center font-black text-white-1 py-1">
      <div class="flex-1 px-2 text-left">
        {{ $t('recorded_tokens.name') }}
      </div>

      <div class="flex-none w-25 text-center px-2">
        {{ $t('recorded_tokens.actions') }}
      </div>
    </div>

    <RecordedTokensRow
      v-for="token in recordedTokens"
      :key="token"
      :token="token"
      @delete="triggerDeleteToken(token)"
    />

    <Modal size="small" :active="deleteModalActive" @close="hideDeleteModal">
      <RecordedTokenDeleteModal :loading="deleteInProgress" @cancel="hideDeleteModal" @confirm="confirmDeleteToken" />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import RecordedTokensRow from '@/components/RecordedTokensRow.vue';
import Modal from '@/components/Modal.vue';
import RecordedTokenDeleteModal from '@/components/RecordedTokenDeleteModal.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

export default {
  name: 'RecordedTokensTable',
  mixins: [ErrorNotificationsMixin],
  components: { RecordedTokensRow, Modal, RecordedTokenDeleteModal },
  data() {
    return {
      actionToken: null,
      deleteModalActive: false,
      deleteInProgress: false,
    };
  },
  computed: {
    ...mapState(['recordedTokens']),
  },
  methods: {
    ...mapActions(['deleteTokenFiles']),
    triggerDeleteToken(token) {
      this.actionToken = token;
      this.openDeleteModal();
    },
    confirmDeleteToken() {
      this.deleteInProgress = true;

      this.deleteTokenFiles({ token: this.actionToken })
        .then(() => {
          this.deleteInProgress = false;
          this.hideDeleteModal();
        })
        .catch((errors) => {
          if (!Array.isArray(errors)) {
            errors = [errors];
          }

          errors.forEach((error) => {
            this.notificationFromError(error);
          });
        });
    },
    openDeleteModal() {
      this.deleteModalActive = true;
    },
    hideDeleteModal() {
      this.deleteModalActive = false;
      this.actionToken = null;
    },
  },
};
</script>

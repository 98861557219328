var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'items-center',
    'justify-between',
    'text-white-3',
    'text-base',
    'placeholder-white-5',
    'bg-white-7',
    'min-h-13',
    'rounded',
    'border-2',
    'border-solid',
    'border-transparent',
    'pl-5',
    'pr-3',
    'relative',
  ]},[_c('div',{staticClass:"w-full text-xs overflow-hidden"},[_c('p',{staticClass:"uppercase font-black"},[_vm._v(_vm._s(_vm.label))]),_c('p',{staticClass:"text-white-1 font-light no-wrap overflow-hidden overflow-ellipsis"},[_vm._v(_vm._s(_vm.value))])]),_c('VueCopyToClipboard',{attrs:{"text":_vm.value},on:{"copy":_vm.copy}},[_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"flex-none p-0 ml-1 rounded-full w-8 h-8 relative z-1",attrs:{"disableMinHeight":"","rounded":false,"color":"btn-transparent-white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CopySVG',{staticClass:"w-6 h-6"})]},proxy:true}])})]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('copy'))+" ")]},proxy:true}])})],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.copied)?_c('p',{class:[
        'flex',
        'items-center',
        'justify-center',
        'text-white-1',
        'font-bold',
        'absolute',
        'inset-0',
        'bg-blur-transition',
        'bg-blurred',
        'z-10',
      ]},[_vm._v(" "+_vm._s(_vm.$t('copied_to_clipboard'))+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
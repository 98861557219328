<template>
  <div class="text-center container-md px-4 py-8">
    <LanguageSelect class="fixed top-4 right-7 w-35" />

    <h1 class="text-white-1">{{ $t('unknown_user.title') }}</h1>

    <p class="font-light mt-6" v-html="$t('unknown_user.text', { mail: 'support@slideslive.com', token: token })" />

    <router-link v-if="recordedTokens.length > 0" :to="{ name: 'RecordedTokens' }" custom #default="{ href, navigate }">
      <Button
        :href="href"
        :label="$t('unknown_user.go_to_recorded_tokens')"
        class="w-80 no-underline hover:no-underline mt-6"
        @click="navigate"
      />
    </router-link>

    <router-link :to="{ name: 'Help' }" custom #default="{ href, navigate }">
      <a :href="href" class="fixed bottom-4 right-7" @click="navigate">
        {{ $t('header.user_menu.help') }}
      </a>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';
import LanguageSelect from '@/components/LanguageSelect.vue';

import { parseUrlParams } from '@/helpers';

export default {
  name: 'UnknownUser',
  components: { Button, LanguageSelect },
  computed: {
    ...mapState(['recordedTokens']),
    token() {
      return parseUrlParams().token;
    },
  },
  created() {
    this.$nextTick(() => {
      document.title = `${this.$i18n.t('meta_title.unknown_user')} · ${this.$i18n.t('app_name')}`;
    });
  },
};
</script>

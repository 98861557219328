import RecorderSettings from '@/modules/recorder_settings';

export default function (checkPlayer = false) {
  const errors = [];

  if (checkPlayer) {
    const video = document.createElement('video');

    if (!['probably', 'maybe'].includes(video.canPlayType(RecorderSettings.recordingMimeType))) {
      errors.push('WEBM_NOT_PLAYABLE');
    }

    return errors;
  }

  if (!File.prototype.stream) {
    console.warn('Your browser does not support `File.prototype.stream`.');
    errors.push('FILE_PROTOTYPE_STREAM_NOT_FOUND');
  }

  if (!window.requestFileSystem && !window.webkitRequestFileSystem) {
    console.warn('Your browser does not support `window.requestFileSystem`.');
    errors.push('WINDOW_REQUEST_FILE_SYSTEM_NOT_FOUND');
  }

  if (!navigator.webkitPersistentStorage) {
    console.warn('Your browser does not support `navigator.webkitPersistentStorage`.');
    errors.push('NAVIGATOR_WEBKIT_PERSISTENT_STORAGE_NOT_FOUND');
  } else {
    if (!navigator.webkitPersistentStorage.queryUsageAndQuota) {
      console.warn('Your browser does not support `navigator.webkitPersistentStorage.queryUsageAndQuota`.');
      errors.push('NAVIGATOR_WEBKIT_PERSISTENT_STORAGE_QUERY_USAGE_AND_QUOTA_NOT_FOUND');
    }

    if (!navigator.webkitPersistentStorage.requestQuota) {
      console.warn('Your browser does not support `navigator.webkitPersistentStorage.requestQuota`.');
      errors.push('NAVIGATOR_WEBKIT_PERSISTENT_STORAGE_REQUEST_QUOTA_NOT_FOUND');
    }
  }

  if (!navigator.mediaDevices) {
    console.warn('Your browser does not support `navigator.mediaDevices`.');
    errors.push('NAVIGATOR_MEDIA_DEVICES_NOT_FOUND');
  } else {
    if (!navigator.mediaDevices.getUserMedia) {
      console.warn('Your browser does not support `navigator.mediaDevices.getUserMedia`.');
      errors.push('NAVIGATOR_MEDIA_DEVICES_GET_USER_MEDIA_NOT_FOUND');
    }

    if (!navigator.mediaDevices.getDisplayMedia) {
      console.warn('Your browser does not support `navigator.mediaDevices.getDisplayMedia`.');
      errors.push('NAVIGATOR_MEDIA_DEVICES_GET_DISPLAY_MEDIA_NOT_FOUND');
    }
  }

  return errors;
}

import { getVideoThumbnail } from '@/helpers';

import INITIAL_CLIP from '../initial_clip';

export default function (
  { commit, state },
  { durationMs, speakerFileId, slidesFileId = null, versionId = state.currentVersionId },
) {
  return new Promise((resolve) => {
    const version = state.versions[versionId];
    const startMs = version.timelines.speaker.video.reduce((sum, v) => sum + v[v.type].duration_ms, 0);

    const timelines = JSON.parse(JSON.stringify(version.timelines));
    const speakerClip = {
      type: 'clip',
      clip: {
        ...INITIAL_CLIP,
        file_id: speakerFileId,
        start_ms: startMs,
        duration_ms: durationMs,
      },
    };

    timelines.speaker.video.push(JSON.parse(JSON.stringify(speakerClip)));
    timelines.speaker.audio.push(JSON.parse(JSON.stringify(speakerClip)));

    if (slidesFileId) {
      const slidesClip = {
        type: 'clip',
        clip: {
          ...INITIAL_CLIP,
          file_id: slidesFileId,
          start_ms: startMs,
          duration_ms: durationMs,
        },
      };

      timelines.slides.video.push(JSON.parse(JSON.stringify(slidesClip)));
      timelines.slides.audio.push(JSON.parse(JSON.stringify(slidesClip)));
    }

    const duration = timelines.speaker.video.reduce((sum, c) => sum + c[c.type].duration_ms, 0);

    const finish = () => {
      commit('updateVersion', { id: version.id, timelines, duration });
      resolve();
    };

    const thumbnailSource = version.files.find((f) => f.id === (slidesFileId || speakerFileId));

    getVideoThumbnail(thumbnailSource.url)
      .then((url) => {
        timelines.speaker.video[timelines.speaker.video.length - 1].frontend = {
          thumbnail_url: url,
        };

        finish();
      })
      .catch(finish);
  });
}

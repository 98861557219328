<template>
  <div class="relative bg-white-7">
    <div v-if="isContent" class="absolute inset-0 z-3">
      <slot :klass="errors.length > 0 ? 'top-2' : 'bottom-2'"></slot>

      <ul
        v-if="errors.length > 0"
        :class="[
          'list-style-none',
          'bg-red-2',
          'px-3',
          'py-1',
          'absolute',
          'bottom-0',
          'inset-x-0',
          'text-center',
          'text-white-1',
        ]"
      >
        <li v-for="(error, index) in errors" :key="index">
          <p class="text-sm">{{ $t(`errors.${error}`) }}</p>
        </li>
      </ul>
    </div>

    <video
      v-if="enabled && stream"
      ref="video"
      :srcObject.prop="stream"
      class="absolute inset-0 w-full z-2"
      @loadedmetadata="loadedmetadata"
      @resize="resize"
    >
      <track kind="captions" />
    </video>

    <div
      class="relative pb-9/16"
      :class="[
        {
          'z-1': !iconOverVideo,
          'z-2': iconOverVideo,
        },
      ]"
      :style="computedBgStyle"
    >
      <div class="flex items-end justify-center absolute h-full inset-0">
        <component :is="icon" class="fill-white-3 h-8/9" />
      </div>
    </div>
  </div>
</template>

<script>
import SpeakerSVG from '@/assets/svgs/speaker.svg';
import PresentationSVG from '@/assets/svgs/presentation.svg';

export default {
  name: 'MediaPreview',
  components: { SpeakerSVG, PresentationSVG },
  props: {
    stream: MediaStream,
    backgroundStyle: {
      type: String,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: true,
      validator: (val) => ['SpeakerSVG', 'PresentationSVG'].includes(val),
    },
    iconOverVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { bgStyle: '' };
  },
  computed: {
    isContent() {
      return !!this.$slots.default || !!this.$scopedSlots.default || this.errors.length;
    },
    computedBgStyle() {
      if (this.backgroundStyle) {
        return this.backgroundStyle;
      }

      return this.bgStyle;
    },
    ref() {
      return this.$refs.video;
    },
  },
  methods: {
    setBackgroundStyle(target) {
      const { videoHeight: height, videoWidth: width } = target;

      if (height < 100 || width < 100) {
        return;
      }

      const pct = (height / width) * 100;
      const bgStyle = `padding-bottom: ${pct}%`;

      this.bgStyle = bgStyle;
    },
    loadedmetadata({ target }) {
      if (!this.isPlayer) {
        target.muted = true;
        const playPromise = target.play();
        if (playPromise && playPromise.catch) playPromise.catch(() => {});
      }

      this.setBackgroundStyle(target);
    },
    resize({ target }) {
      this.setBackgroundStyle(target);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-column',
    'items-stretch',
    'justify-center',
    'px-6',
    'py-4',
    'min-h-17',
    'bg-white-7',
    'border',
    'border-solid',
    'border-transparent',
    'rounded-lg',
    'w-full',
    'text-left',
    {
      'cursor-grab': _vm.showHandle,
    },
  ]},[(_vm.showHandle)?_c('div',{class:['handle', 'absolute', 'flex', 'items-center', 'rounded-lg', 'bg-black-4', 'left-0', 'inset-y-0']},[_c('VerticalDostSVG',{staticClass:"fill-white-3 w-4 h-4"})],1):_vm._e(),_c('div',{staticClass:"flex justify-between items-center"},[_c('SpeakerNameState',{attrs:{"name":_vm.speaker.name,"isSubmitted":_vm.isSubmitted}}),(!!_vm.shareLinkUrl && !_vm.shareLinkIsSelfRecording && _vm.isSubmitted)?_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"p-0 rounded-full w-8 h-8",attrs:{"disableMinHeight":"","href":_vm.shareLinkUrl,"rounded":false,"target":"_blank","rel":"noopener noreferrer","color":"btn-transparent-white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('EyeSVG',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,2507591073)})]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('speakers_list.show_speaker_recording'))+" ")]},proxy:true}],null,false,2082580736)}):_vm._e(),(_vm.showDisabledReason || _vm.shareLinkIsSelfRecording)?_c('Tooltip',{attrs:{"iconSize":"large"}},[_c('p',{staticClass:"text-xs"},[(_vm.shareLinkIsSelfRecording)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('speakers_list.share_link_is_self_recording'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t(`speakers_list.disabled_reasons.${_vm.speaker.disabled_recording_reason}`))+" ")])])]):_vm._e()],1),(!_vm.isSubmitted && _vm.showContinueAs && _vm.speaker.recording_enabled)?_c('Button',{staticClass:"mt-4 w-full",attrs:{"label":_vm.$t('speakers_list.continue_as', { name: _vm.speaker.name }),"block":"","color":"btn-primary-outline"},on:{"click":_vm.continueAs}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
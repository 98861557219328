<template>
  <div
    :class="[
      'relative',
      'oveflow-hidden',
      'w-full',
      'user-select-none',
      'rounded-scrollbar',
      {
        'rounded': rounded,
        'inline-flex': !block,
        'flex': block,
        'focus-within:outline-primary': !disabled,
        'focus-within:z-2': !disabled,
        'outline-none': disabled,
      },
    ]"
    v-click-outside="hide"
    v-escape-pressed="hide"
    :disabled="disabled"
    @keydown.up.prevent.stop="toggle($event, 'prev')"
    @keydown.down.prevent.stop="toggle($event, 'next')"
  >
    <button
      type="button"
      :class="[
        'flex',
        'items-center',
        'text-left',
        'text-white-3',
        'text-base',
        'bg-white-7',
        'border-0',
        'outline-none',
        'w-full',
        'overflow-hidden',
        {
          'rounded-l': rounded,
          'min-h-9': this.size === 'small',
          'px-3': this.size === 'small',
          'py-1': this.size === 'small',
          'min-h-13': this.size === 'medium',
          'px-5': this.size === 'medium',
          'py-3': this.size === 'medium',
          'min-h-15': this.size === 'large',
          'px-7': this.size === 'large',
          'py-5': this.size === 'large',
        },
      ]"
      @click="toggle"
    >
      <slot v-if="$slots.item || $scopedSlots.item" name="item" v-bind="currentOption" />

      <div v-else :class="['flex', 'items-center', 'no-wrap', 'overflow-hidden', 'overflow-ellipsis']">
        <span v-if="newValue">{{ labelFromValue(newValue) }}</span>
        <span v-else-if="placeholder" class="text-white-5 italic">{{ placeholder }}</span>
      </div>
    </button>

    <div class="flex flex-column w-7">
      <Button
        type="button"
        :rounded="false"
        color="btn-white-white"
        class="flex-1 p-0"
        :class="[{ 'rounded-r': rounded }]"
        :disabled="disabled"
        @click="toggle"
      >
        <template #icon>
          <ChevronDownSVG v-if="!opened" class="w-5 h-5" />

          <ChevronUpSVG v-else class="w-5 h-5" />
        </template>
      </Button>
    </div>

    <div
      :class="[
        'absolute',
        'top-full',
        'mt-2',
        'max-h-50',
        'p-1',
        '-inset-x-1',
        'overflow-auto',
        {
          none: !opened,
        },
      ]"
    >
      <div :class="['bg-black', { rounded }]">
        <div :class="['bg-white-7', { rounded }]">
          <slot v-if="$slots.default" />

          <div v-else-if="options.length > 0" ref="options">
            <button
              v-for="(option, i) of options"
              type="button"
              :key="i"
              :class="[
                'w-full',
                'text-white-3',
                'text-left',
                'break-word',
                'cursor-pointer',
                'focus:outline-white',
                {
                  'rounded-t': i === 0,
                  'rounded-b': i === options.length - 1,
                  'hover:bg-white-6': value(option) !== newValue,
                  'bg-white-7': value(option) === newValue,
                  'hover:bg-white-5': value(option) === newValue,
                  'px-3': size === 'small',
                  'py-1': size === 'small',
                  'px-5': size === 'medium',
                  'py-2': size === 'medium' || size === 'large',
                  'px-7': size === 'large',
                },
              ]"
              :data-value="value(option)"
              @click="select($event, option)"
              @keyup.up.down.stop.prevent
              @keydown.up.prevent.stop="focusPrevOptionEvent"
              @keydown.down.prevent.stop="focusNextOptionEvent"
            >
              <slot v-if="$slots.item || $scopedSlots.item" name="item" v-bind="option" />

              <div v-else>
                {{ label(option) }}
              </div>
            </button>
          </div>

          <div
            v-else
            class="text-white-4 italic w-full"
            :class="[
              {
                'px-3': size === 'small',
                'py-1': size === 'small',
                'px-5': size === 'medium',
                'py-2': size === 'medium' || size === 'large',
                'px-7': size === 'large',
              },
            ]"
          >
            {{ $t('nothing_to_select') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import ChevronDownSVG from '@/assets/svgs/chevron_down.svg';
import ChevronUpSVG from '@/assets/svgs/chevron_up.svg';

export default {
  name: 'Select',
  components: { Button, ChevronDownSVG, ChevronUpSVG },
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    customValue: {
      type: String,
      required: false,
      default: null,
    },
    disabled: Boolean,
    placeholder: {
      type: String,
      default: null,
    },
    valueKey: {
      type: String,
      default: null,
    },
    labelKey: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.customValue || this.options.length ? this.value(this.options[0]) : null,
      opened: false,
    };
  },
  computed: {
    currentOption() {
      return this.options.find((o) => this.value(o) === this.newValue);
    },
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  methods: {
    label(option) {
      if (option && this.labelKey && this.labelKey in option) {
        return option[this.labelKey];
      }

      return option;
    },
    labelFromValue(value) {
      let option;

      if (!this.labelKey) {
        return value;
      }

      if (value && this.valueKey) {
        option = this.options.find((o) => o[this.valueKey] === value);
      }

      if (!option || !(this.labelKey in option)) {
        return value;
      }

      return option[this.labelKey];
    },
    value(option) {
      if (option && this.valueKey && this.valueKey in option) {
        return option[this.valueKey];
      }

      return option;
    },
    toggle(event, direction = null) {
      if (this.disabled) {
        return;
      }

      if (!direction || !this.opened) {
        this.opened = !this.opened;
      }

      if (!direction) {
        return;
      }

      const currentOptionIndex = this.options.findIndex((o) => this.value(o) === this.newValue);
      const currentOptionElement = this.$refs.options.children[currentOptionIndex];

      setTimeout(() => {
        if (direction === 'prev') {
          this.focusPrevOption(currentOptionElement);
        } else if (direction === 'next') {
          this.focusNextOption(currentOptionElement);
        }
      }, 0);
    },
    open() {
      if (this.disabled) {
        return;
      }

      this.opened = true;
    },
    hide() {
      if (this.disabled) {
        return;
      }

      this.opened = false;
    },
    select(event, option) {
      this.updateSelected(this.value(option));

      event.target.blur();

      setTimeout(() => this.hide(), 0);
    },
    updateSelected(value) {
      this.newValue = value;
      this.$emit('customInput', value);
    },
    focusPrevOptionEvent(event) {
      this.focusPrevOption(event.target);
    },
    focusNextOptionEvent(event) {
      this.focusNextOption(event.target);
    },
    focusPrevOption(target) {
      const prevEl = target.previousElementSibling ? target.previousElementSibling : target.parentNode.lastElementChild;

      if (prevEl === target) {
        return;
      }

      prevEl.focus();
    },
    focusNextOption(target) {
      const nextEl = target.nextElementSibling ? target.nextElementSibling : target.parentNode.firstElementChild;

      if (nextEl === target) {
        return;
      }

      nextEl.focus();
    },
  },
};
</script>

<template>
  <ol class="list-style-none flex items-center justify-center">
    <li v-for="s in totalSteps" :key="s" class="mx-1">
      <button
        type="button"
        class="rounded-full border-none bg-white-8 w-3 h-3 outline-none focus:outline-white"
        :class="s <= step && 'bg-primary'"
        :disabled="s > step"
        :label="s"
        @click="click(s)"
      />
    </li>
  </ol>
</template>

<script>
export default {
  name: 'StepsIndicator',
  props: {
    step: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  methods: {
    click(s) {
      this.$emit('click', s);
    },
  },
};
</script>

<template>
  <div @click.stop class="h-11 absolute bottom-0 inset-x-0">
    <PlayerProgressBar
      @startMove="progressBarStartMove"
      @updateMove="progressBarUpdateMove"
      @endMove="progressBarEndMove"
      :elapsed="elapsed"
      :duration="duration"
      :parts="parts"
      class="absolute bottom-full left-0 translate-y-1/2"
    />

    <div class="flex items-center justify-between w-full h-full bg-black-4 px-4 pt-1">
      <div class="flex-1 flex">
        <Tooltip>
          <template #trigger>
            <Button
              disableMinHeight
              color="btn-transparent-white"
              class="p-0 rounded-full w-8 h-8"
              @keydown.space.enter.stop
              @keyup.space.enter.stop
              @click.stop="toggle"
              :rounded="false"
            >
              <template #icon>
                <PlaySVG v-if="paused" class="fill-accent w-6 h-6 relative left-px" />

                <PauseSVG v-else class="fill-accent w-6 h-6" />
              </template>
            </Button>
          </template>

          <template #default>
            <span class="no-wrap">{{ controlLabel }}</span>
          </template>
        </Tooltip>
      </div>

      <PlayerProgressTime class="text-white-1 text-xs" :elapsed="elapsed" :duration="duration" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import PlayerProgressBar from '@/components/PlayerProgressBar.vue';
import PlayerProgressTime from '@/components/PlayerProgressTime.vue';
import Tooltip from '@/components/Tooltip.vue';

import PlaySVG from '@/assets/svgs/player_play.svg';
import PauseSVG from '@/assets/svgs/player_pause.svg';

export default {
  name: 'PlayerControls',
  components: {
    Button,
    PlayerProgressBar,
    PlayerProgressTime,
    Tooltip,
    PlaySVG,
    PauseSVG,
  },
  props: {
    parts: {
      type: Array,
      required: true,
    },
    elapsed: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 1,
    },
    paused: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    progressBarStyle() {
      const pct = (this.elapsed * 100) / this.duration;

      return `width: ${pct}%`;
    },
    controlLabel() {
      if (!this.paused) {
        return this.$i18n.t('record.pause');
      }

      return this.$i18n.t('record.play');
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
    progressBarStartMove(pct) {
      this.progressBarUpdateMove(pct);
    },
    progressBarUpdateMove(pct) {
      this.$emit('seekToPct', pct);
    },
    progressBarEndMove() {
      this.$emit('seekEnd');
    },
  },
};
</script>

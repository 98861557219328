import Logger from '@/modules/logger';

import { parseUrlParams, mergeVersionWithUpload, requestFileSystemDirectory, upsertJSONFile } from '@/helpers';

function downloadProgress(totalFiles = 0, totalBytes = 1, pendingFiles = totalFiles, totalDownloadedBytes = 0) {
  return {
    pendingFiles,
    totalBytes,
    totalFiles,
    totalProgress: (totalDownloadedBytes / totalBytes) * 100,
    totalDownloadedBytes,
  };
}

export default function ({ state, commit, dispatch }, { id }) {
  Logger.log(`DownloadCloudVersion: ${id}`);

  const version = state.versions[id];
  const abortController = new AbortController();

  if (!version.share_link_url) {
    return Promise.reject();
  }

  const updateProgress = (newData) => {
    const progress = state.cloudDownloadProgress;
    const totalFiles = 'totalFiles' in newData ? newData.totalFiles : progress.totalFiles;
    const totalBytes = 'totalBytes' in newData ? newData.totalBytes : progress.totalBytes;
    const pendingFiles = 'pendingFiles' in newData ? newData.pendingFiles : progress.pendingFiles;
    const totalDownloadedBytes =
      'totalDownloadedBytes' in newData ? newData.totalDownloadedBytes : progress.totalDownloadedBytes;

    commit('setKey', {
      key: 'cloudDownloadProgress',
      cloudDownloadProgress: downloadProgress(totalFiles, totalBytes, pendingFiles, totalDownloadedBytes),
    });
  };

  const writeBlobToFile = (fileWriter, blob) => {
    return new Promise((resolve, reject) => {
      abortController.signal.addEventListener('abort', reject);

      fileWriter.onwriteend = () => {
        if (fileWriter.error) {
          reject();
        }

        fileWriter.onwriteend = null;
        resolve();
      };

      fileWriter.write(blob);
    });
  };

  const readAndWriteDownloadResponse = (reader, fileWriter) => {
    return new Promise((resolve, reject) => {
      abortController.signal.addEventListener('abort', reject);

      const doRead = () => {
        reader
          .read()
          .then(({ value, done }) => {
            if (done) {
              return { done: true };
            }

            const blob = new Blob([value]);

            updateProgress({
              totalDownloadedBytes: state.cloudDownloadProgress.totalDownloadedBytes + value.length,
            });

            return writeBlobToFile(fileWriter, blob).then(() => {
              return { done: false };
            });
          })
          .then(({ done }) => {
            if (done) {
              resolve();
              return;
            }

            doRead();
          })
          .catch(() => {});
      };

      doRead();
    });
  };

  const downloadFile = (fileWriter, fileData) => {
    return fetch(fileData.url, {
      signal: abortController.signal,
    })
      .then((response) => {
        const reader = response.body.getReader();
        const contentLength = Number(response.headers.get('Content-Length'));

        updateProgress({ totalBytes: state.cloudDownloadProgress.totalBytes + contentLength });

        return readAndWriteDownloadResponse(reader, fileWriter);
      })
      .then(() => {
        updateProgress({ pendingFiles: state.cloudDownloadProgress.pendingFiles - 1 });
      });
  };

  const createFileWriter = (dirEntry, fileData) => {
    return new Promise((resolve, reject) => {
      abortController.signal.addEventListener('abort', reject);

      dirEntry.getFile(
        fileData.file_name,
        { create: true },
        (fileEntry) => {
          fileEntry.createWriter(
            (fileWriter) => {
              fileWriter.onerror = (error) => {
                let errorCode = 'write_error';

                if (error.target.error.name === 'QuotaExceededError') {
                  errorCode = 'quota_exceeded_error';
                }

                reject(new Error(errorCode));
              };

              fileWriter.onwriteend = () => {
                downloadFile(fileWriter, fileData)
                  .then(() => {
                    resolve(fileEntry);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              };

              fileWriter.seek(0);
              fileWriter.truncate(0);
            },
            (error) => reject(error),
          );
        },
        (error) => reject(error),
      );
    });
  };

  updateProgress(downloadProgress());

  const upload = state.uploads.find((u) => u.id === version.upload_id);
  const urlParams = parseUrlParams(version.share_link_url);
  const tokenId = urlParams.share;
  const shareToken = urlParams.s;

  return dispatch('downloadVersionJSONFromCloud', { tokenId, shareToken, embed: 'yes' })
    .then(({ json: jsonData }) => {
      jsonData.id = version.id;
      jsonData = mergeVersionWithUpload(jsonData, upload);

      updateProgress({ totalFiles: jsonData.files.length, pendingFiles: jsonData.files.length });

      return requestFileSystemDirectory(state.recorderToken, version.speaker_id, 1024 * 1024).then((dirEntry) => {
        const files = jsonData.files.map((f) => createFileWriter(dirEntry, f));

        return Promise.all([jsonData, ...files]);
      });
    })
    .then((result) => {
      const jsonData = result[0];
      const files = result.slice(1);

      for (let i = 0; i < jsonData.files.length; i++) {
        const fData = jsonData.files[i];
        const fileEntry = files.find((f) => f.name === fData.file_name);

        fData.url = fileEntry.toURL();
      }

      return upsertJSONFile(jsonData).then(() => {
        commit('updateVersion', { ...jsonData, skipUpdatedAt: true });

        return jsonData;
      });
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }

      abortController.abort();

      throw error;
    })
    .finally(() => {
      updateProgress(downloadProgress());
    });
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative bg-white-7"},[(_vm.isContent)?_c('div',{staticClass:"absolute inset-0 z-3"},[_vm._t("default",null,{"klass":_vm.errors.length > 0 ? 'top-2' : 'bottom-2'}),(_vm.errors.length > 0)?_c('ul',{class:[
        'list-style-none',
        'bg-red-2',
        'px-3',
        'py-1',
        'absolute',
        'bottom-0',
        'inset-x-0',
        'text-center',
        'text-white-1',
      ]},_vm._l((_vm.errors),function(error,index){return _c('li',{key:index},[_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t(`errors.${error}`)))])])}),0):_vm._e()],2):_vm._e(),(_vm.enabled && _vm.stream)?_c('video',{ref:"video",staticClass:"absolute inset-0 w-full z-2",domProps:{"srcObject":_vm.stream},on:{"loadedmetadata":_vm.loadedmetadata,"resize":_vm.resize}},[_c('track',{attrs:{"kind":"captions"}})]):_vm._e(),_c('div',{staticClass:"relative pb-9/16",class:[
      {
        'z-1': !_vm.iconOverVideo,
        'z-2': _vm.iconOverVideo,
      },
    ],style:(_vm.computedBgStyle)},[_c('div',{staticClass:"flex items-end justify-center absolute h-full inset-0"},[_c(_vm.icon,{tag:"component",staticClass:"fill-white-3 h-8/9"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
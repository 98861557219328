import { composeDirective } from './helpers';

const NAME = 'escape-pressed';
const LISTENERS = ['keyup'];

function validator(el, e) {
  return e.keyCode === 27;
}

export default composeDirective(NAME, LISTENERS, validator);

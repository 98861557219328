import Logger from '@/modules/logger';

export default function ({ commit }) {
  return new Promise((resolve) => {
    commit('setKey', { key: 'isCountdownModalActive', isCountdownModalActive: false });
    commit('setKey', { key: 'isRecording', isRecording: false });
    commit('setKey', { key: 'isWaitingForStreams', isWaitingForStreams: false });
    commit('setKey', { key: 'recordingStartRequested', recordingStartRequested: false });

    Logger.log('Trigger cancel recording countdown');

    resolve();
  });
}

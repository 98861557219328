<template>
  <InfoCircleSVG class="fill-white-3" :class="sizeClasses" />
</template>

<script>
import InfoCircleSVG from '@/assets/svgs/info_circle.svg';

export default {
  name: 'TooltipHelpIcon',
  components: { InfoCircleSVG },
  props: {
    size: {
      type: String,
      default: 'medium',
      validate: (value) => ['none', 'small', 'medium', 'large'].includes(value),
    },
  },
  computed: {
    sizeClasses() {
      if (this.size === 'small') {
        return ['w-4', 'h-4'];
      }

      if (this.size === 'large') {
        return ['w-6', 'h-6'];
      }

      return ['w-5', 'h-5'];
    },
  },
};
</script>

import AWS from 'aws-sdk';
import * as Sentry from '@sentry/browser';

class AwsS3MultipartUploadHelper {
  constructor(options) {
    this.options = {
      region: options.region || 'us-east-1',
      bucket: options.bucket,
      acl: options.acl || 'public-read',
      expires: options.expires || 5 * 60,
      prefix: options.prefix || null,
    };

    AWS.config.credentials = new AWS.Credentials({
      accessKeyId: options.credentials.accessKeyId,
      secretAccessKey: options.credentials.secretAccessKey,
      sessionToken: options.credentials.sessionToken,
    });

    this.s3 = new AWS.S3({
      signatureVersion: 'v4',
      region: this.options.region,
      correctClockSkew: true,
      retryDelayOptions: {
        base: 100,
        customBackoff: (retryCount) => {
          if (retryCount > 2) return -1;

          return (retryCount + 1) * 200;
        },
      },
    });
  }

  createMultipartUpload(file) {
    return new Promise((resolve, reject) => {
      const key = file.name;
      this.s3.createMultipartUpload(
        {
          Bucket: this.options.bucket,
          Key: key,
          ACL: this.options.acl,
          ContentType: file.type,
          Expires: this.options.expires,
        },
        (error, data) => {
          if (error) {
            this.reportErrorToSentry(error);
            reject(error);
          } else {
            resolve({
              key: data.Key,
              uploadId: data.UploadId,
            });
          }
        },
      );
    });
  }

  listParts(file, { key, uploadId }) {
    return new Promise((resolve, reject) => {
      let parts = [];

      const listPartsPart = (part) => {
        this.s3.listParts(
          {
            Bucket: this.options.bucket,
            Key: key,
            UploadId: uploadId,
            PartNumberMarker: part,
          },
          (error, data) => {
            if (error) {
              this.reportErrorToSentry(error);
              reject(error);
              return;
            }

            parts = parts.concat(data.Parts);
            if (data.IsTruncated) {
              listPartsPart(data.NextPartNumberMarker);
            } else {
              resolve(parts);
            }
          },
        );
      };

      listPartsPart(0);
    });
  }

  prepareUploadPart(file, { uploadId, key, partNumber }) {
    return new Promise((resolve, reject) => {
      this.s3.getSignedUrl(
        'uploadPart',
        {
          Bucket: this.options.bucket,
          Key: key,
          UploadId: uploadId,
          PartNumber: partNumber,
          Body: '',
          Expires: this.options.expires,
        },
        (error, url) => {
          if (error) {
            this.reportErrorToSentry(error);
            reject(error);
          } else {
            resolve({ partNumber, url });
          }
        },
      );
    });
  }

  prepareUploadParts(file, partData) {
    return new Promise((resolve, reject) => {
      const promises = partData.partNumbers.map((partNumber) =>
        this.prepareUploadPart(file, {
          uploadId: partData.uploadId,
          key: partData.key,
          partNumber,
        }),
      );

      Promise.all(promises)
        // eslint-disable-next-line prefer-promise-reject-errors
        .catch(() => reject({ source: { status: 500 } }))
        .then((preparedParts) => {
          const presignedUrls = {};

          for (const preparedPart of preparedParts) {
            presignedUrls[preparedPart.partNumber.toString()] = preparedPart.url;
          }

          resolve({ presignedUrls });
        });
    });
  }

  abortMultipartUpload(file, { key, uploadId }) {
    return new Promise((resolve) => {
      this.s3.abortMultipartUpload(
        {
          Bucket: this.options.bucket,
          Key: key,
          UploadId: uploadId,
        },
        (error) => {
          if (error) {
            // Ignore errors during aborting multipart upload. Uppy doesn't handle them.
          } else {
            resolve({});
          }
        },
      );
    });
  }

  completeMultipartUpload(file, { key, uploadId, parts }) {
    return new Promise((resolve, reject) => {
      this.s3.completeMultipartUpload(
        {
          Bucket: this.options.bucket,
          Key: key,
          UploadId: uploadId,
          MultipartUpload: {
            Parts: this.uniqueParts(parts),
          },
        },
        (error, data) => {
          if (error) {
            this.reportErrorToSentry(error);
            reject(error);
          } else {
            resolve({ location: data.Location });
          }
        },
      );
    });
  }

  uniqueParts(parts) {
    const addedPartNumbers = [];
    const uniqueParts = [];

    for (const part of parts) {
      if (addedPartNumbers.indexOf(part.PartNumber) < 0) {
        addedPartNumbers.push(part.PartNumber);
        uniqueParts.push(part);
      }
    }

    return uniqueParts.sort((a, b) => a.PartNumber - b.PartNumber);
  }

  keyWithPrefix(key) {
    if (!this.options.prefix) return key;

    return `${this.options.prefix}/${key}`;
  }

  reportErrorToSentry(error) {
    if (error.code === 'TimeoutError' || error.code === 'NetworkingError') {
      return;
    }

    Sentry.captureException(error);
  }
}

export default AwsS3MultipartUploadHelper;

<template>
  <div>
    <VersionsTabs class="relative z-1" sharePage :guest="!isOwner" :currentVersion="version" />

    <div class="bg-white-9 rounded-b-lg rounded-tr-lg pt-4 pb-12 px-6 relative z-0 w-full">
      <div class="flex items-center">
        <ShareDetails
          :speaker="speaker.name"
          :uploadedAt="upload.upload_completed_at"
          :isFinal="upload.final_recording"
          :openCount="upload.share_open_count"
        />

        <CopyToClipboard :label="$t('share.share_link')" :value="shareLink" class="max-w-78" />
      </div>

      <RecordPlayer v-if="version.timelines" class="mt-4 py-1" :version="version" />

      <p v-if="isOwner" class="flex items-center justify-center text-white-1 text-center font-black mt-12">
        <ClockSVG class="fill-white-1 w-6 h-6 mr-2" />
        {{ $t('share.recording_is_processed') }}
      </p>
    </div>

    <Logo class="mt-6" />
  </div>
</template>

<script>
import VersionsTabs from '@/components/VersionsTabs.vue';
import RecordPlayer from '@/components/RecordPlayer.vue';
import CopyToClipboard from '@/components/CopyToClipboard.vue';
import ShareDetails from '@/components/ShareDetails.vue';
import Logo from '@/components/Logo.vue';

import ClockSVG from '@/assets/svgs/clock.svg';

import { parseUrlParams } from '@/helpers';

export default {
  name: 'ShareContent',
  components: {
    VersionsTabs,
    RecordPlayer,
    CopyToClipboard,
    ShareDetails,
    Logo,
    ClockSVG,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
    version: {
      type: Object,
      required: true,
    },
    upload: {
      type: Object,
      required: true,
    },
    speaker: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isOwner() {
      return this.loggedInSpeakerId && this.loggedInSpeakerId === this.speaker.id;
    },
    shareLink() {
      const params = parseUrlParams();
      return `${window.location.origin}/?share=${params.share}&s=${params.s}`;
    },
  },
};
</script>

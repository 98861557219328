<template>
  <ContainerWithHeaderAndBack :hideBack="submitInProgress || thankYou">
    <transition name="fade" mode="out-in">
      <ThankYou v-if="thankYou" :upload="currentUpload" isSelfRecording />

      <Submit
        v-else-if="submitInProgress"
        class="flex-1 flex flex-column container-xl w-full py-4"
        isSelfRecording
        @cancel="cancelSubmit"
        @continue="submit"
      />

      <SelfRecordingNew v-else @submit="triggerSubmit" />
    </transition>
  </ContainerWithHeaderAndBack>
</template>

<script>
import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import SelfRecordingNew from '@/components/SelfRecordingNew.vue';
import Submit from '@/components/Submit.vue';
import ThankYou from '@/components/ThankYou.vue';

import SubmitMixin from '@/mixins/submit';

export default {
  name: 'SelfRecording',
  mixins: [SubmitMixin],
  components: { ContainerWithHeaderAndBack, SelfRecordingNew, Submit, ThankYou },
  computed: {
    isSelfRecording() {
      return true;
    },
  },
};
</script>

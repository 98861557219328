import readEntries from './read_entries';

export default function deleteEntries(dirEntry) {
  return new Promise((resolve, reject) => {
    const dirReader = dirEntry.createReader();

    readEntries(dirReader)
      .then((entries) => {
        let deletedCounter = 0;

        if (entries.length === 0) {
          resolve();
        }

        const deletedSuccess = (entry) => {
          console.log(`Deleted ${entry.fullPath}`);

          if (++deletedCounter >= entries.length) {
            resolve();
          }
        };

        const deleteError = (error, entry) => {
          console.warn(`Deleting error entry "${entry.name}"`, error);

          if (++deletedCounter >= entries.length) {
            resolve();
          }
        };

        const doDeleteEntry = (entry) => {
          entry.remove(
            () => deletedSuccess(entry),
            (error) => deleteError(error, entry),
          );
        };

        for (const entry of entries) {
          if (entry.isDirectory) {
            deleteEntries(entry)
              .then(() => {
                doDeleteEntry(entry);
              })
              .catch((error) => deleteError(error, entry));
          } else {
            doDeleteEntry(entry);
          }
        }
      })
      .catch((error) => reject(error));
  });
}

<template>
  <div>
    <div class="flex items-center relative">
      <LockSVG v-if="isUploaded" class="flex-none fill-primary w-6 h-6" />

      <Tooltip :disabled="!isTruncated">
        <template #trigger>
          <p ref="name" class="flex-1 font-black no-wrap overflow-hidden overflow-ellipsis">
            {{ name }}
          </p>
        </template>

        <template #default>
          {{ name }}
        </template>
      </Tooltip>
    </div>

    <template v-if="stateInsteadOfTime">
      <p v-if="isSubmitted" class="text-green text-xs font-black flex items-center">
        <CheckLineSVG class="fill-green w-4 h-4 mr-1" />
        {{ $t('version.submitted') }}
      </p>

      <p v-else class="text-yellow text-xs font-black flex items-center">
        <WarningFullSVG class="fill-yellow w-4 h-4 mr-1" />
        {{ $t('version.not_submitted') }}
      </p>
    </template>

    <p v-else class="text-white-3 text-xs">
      <DateFromNow :key="date" :date="date" />
    </p>
  </div>
</template>

<script>
import DateFromNow from '@/components/DateFromNow.vue';
import Tooltip from '@/components/Tooltip.vue';

import LockSVG from '@/assets/svgs/lock.svg';
import CheckLineSVG from '@/assets/svgs/check_line.svg';
import WarningFullSVG from '@/assets/svgs/warning_full.svg';

export default {
  name: 'VersionNameState',
  components: {
    DateFromNow,
    Tooltip,
    LockSVG,
    CheckLineSVG,
    WarningFullSVG,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    stateInsteadOfTime: {
      type: Boolean,
      default: false,
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTruncated: false,
    };
  },
  watch: {
    name() {
      this.updateTruncated();
    },
  },
  methods: {
    updateTruncated() {
      setTimeout(() => {
        if (!this.$refs.name) {
          return;
        }

        const isTruncated = this.$refs.name.clientWidth < this.$refs.name.scrollWidth;

        if (isTruncated === this.isTruncated) {
          return;
        }

        this.isTruncated = isTruncated;
      }, 0);
    },
  },
  created() {
    this.updateTruncated();
  },
};
</script>

<template>
  <Dropdown :disabled="disabled" itemsUnderTrigger dark>
    <template #default="{ toggle }">
      <Button
        @click="toggle"
        color="btn-transparent-white-3"
        align="right"
        iconPosition="right"
        size="small"
        class="relative z-1"
        :disabled="disabled"
        :label="$t('record.all_versions', { count: sortedVersions.length })"
      >
        <template #icon>
          <HamburgerSVG class="ml-2 w-6 h-6" />
        </template>
      </Button>
    </template>

    <template #items>
      <div class="mt-16 p-1 w-73">
        <div v-for="version in sortedVersions" :version="version" :key="version.id" class="p-1 w-full">
          <VersionItem
            class="rounded-lg bg-white-9 hover:bg-white-8 focus:outline-white w-full"
            :version="version"
            :active="activeId === version.id"
            :downloadingVersionJSONFromCloud="downloadingVersionsJSONFromCloud.includes(version.id)"
            v-on="$listeners"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import VersionItem from '@/components/VersionItem.vue';

import HamburgerSVG from '@/assets/svgs/hamburger.svg';

export default {
  name: 'AllVersionsTab',
  components: { Dropdown, Button, VersionItem, HamburgerSVG },
  props: {
    sortedVersions: {
      type: Array,
      required: true,
    },
    activeId: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    downloadingVersionsJSONFromCloud: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

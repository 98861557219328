<template>
  <div class="flex h-34">
    <Draggable
      v-bind="dragOptions"
      v-model="computedClips"
      tag="ol"
      draggable="li"
      class="flex-none list-style-none flex h-full items-stretch"
    >
      <EditorClip
        v-for="(clip, index) in computedClips"
        :key="`${clip[clip.type].start_ms}:${clip[clip.type].duration_ms}`"
        :clip="clip"
        :scale="scale"
        :file="clipFile(clip)"
        :isCurrent="currentClipIndex === index"
        @remove="removeClip(index)"
      />
    </Draggable>

    <div
      :class="[
        'flex-1',
        'bg-black-6',
        'flex',
        'items-center',
        'justify-center',
        'py-2',
        'px-4',
        'text-center',
        'text-xs',
        'text-white-4',
        'user-select-none',
      ]"
    >
      {{ $t('editor.new_recordings_here') }}
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

import EditorClip from '@/components/EditorClip.vue';

export default {
  name: 'EditorClips',
  components: { Draggable, EditorClip },
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    timelines: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    currentClipIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newClips: JSON.parse(JSON.stringify(this.timelines.speaker.video)),
    };
  },
  watch: {
    timelines(value) {
      if (value.speaker.video === this.newClips) {
        return;
      }

      this.newClips = JSON.parse(JSON.stringify(value.speaker.video));
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        ghostClass: 'opacity-5',
      };
    },
    computedClips: {
      get() {
        return this.newClips;
      },
      set(clips) {
        this.newClips = clips;
        this.$emit(
          'reorder',
          clips.map((c) => c[c.type].start_ms),
        );
      },
    },
  },
  methods: {
    clipFile(clip) {
      return this.files.find((f) => f.id === clip[clip.type].file_id);
    },
    removeClip(index) {
      this.$emit('removeClip', index);
    },
  },
};
</script>

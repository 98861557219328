<template>
  <div class="flex-1 flex flex-column">
    <textarea
      v-if="tag === 'textarea'"
      ref="input"
      :class="classes"
      :value="newValue"
      v-bind="$attrs"
      v-on="$listeners"
      @input="handleInput"
      @change="handleChange"
      @blur="handleBlur"
    />

    <input
      v-else
      ref="input"
      :class="classes"
      :value="newValue"
      v-bind="$attrs"
      v-on="$listeners"
      @input="handleInput"
      @change="handleChange"
      @blur="handleBlur"
    />

    <transition name="slide" mode="out-in">
      <p v-if="message" :class="messageClasses">
        {{ message }}
      </p>
    </transition>
  </div>
</template>

<script>
import { toSnakeCase } from '@/helpers';

export default {
  name: 'Input',
  inject: {
    parent: {
      from: 'Form',
      default: false,
    },
  },
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  props: {
    customValue: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
    },
    tag: {
      type: String,
      default: 'input',
      validator: (value) => {
        return ['textarea', 'input'].includes(value);
      },
    },
    constraints: {
      type: Object,
      default: null,
    },
    submitConstraints: {
      type: Object,
      default: null,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    validateOnChange: {
      type: Boolean,
      default: true,
    },
    disableHTML5Validtaion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alreadyHadError: false,
      newValid: true,
      newValue: null,
      message: null,
      messageType: null,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('customInput', value);
      },
    },
    computedValid: {
      get() {
        return this.newValid;
      },
      set(value) {
        if (value === this.newValid) {
          return;
        }

        const name = this.element ? this.element.name : '';

        this.newValid = value;
        this.$emit('validityChange', name, value);
      },
    },
    isError() {
      return this.message && this.messageType === 'error';
    },
    classes() {
      return [
        'flex-1',
        'text-white-3',
        'text-base',
        'placeholder-white-5',
        'bg-white-7',
        'rounded',
        'border',
        'border-solid',
        'outline-none',
        'focus:z-1',
        'relative',
        {
          'border-red-1': this.isError,
          'focus:outline-red': this.isError,
          'border-transparent': !this.message,
          'focus:outline-primary': !this.message,
          'min-h-9': this.size === 'small',
          'px-3': this.size === 'small',
          'py-1': this.size === 'small',
          'min-h-13': this.size === 'medium',
          'px-5': this.size === 'medium',
          'py-3': this.size === 'medium',
          'min-h-15': this.size === 'large',
          'px-7': this.size === 'large',
          'py-5': this.size === 'large',
        },
      ];
    },
    messageClasses() {
      return [
        'text-left',
        'text-xs',
        'font-black',
        'uppercase',
        'pt-1',
        {
          'text-red-1': this.isError,
          'text-white-1': this.isError,
        },
      ];
    },
    element() {
      return this.$refs.input;
    },
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
    error(value) {
      if (!value) {
        this.checkHtml5Validity();
        return;
      }

      this.message = value;
      this.messageType = 'error';
    },
  },
  methods: {
    handleBlur() {
      if (!this.validateOnBlur) {
        return;
      }

      if (!this.computedValid || this.alreadyHadError) {
        this.validate();
      }
    },
    handleInput({ target: { value } }) {
      if (!this.validateOnChange) {
        return;
      }

      this.updateValue(value);
    },
    handleChange({ target: { value } }) {
      if (!this.validateOnChange) {
        return;
      }

      this.updateValue(value);
    },
    updateValue(value) {
      this.computedValue = value;

      if (!this.computedValid || this.alreadyHadError) {
        this.validate();
      }
    },
    setInvalid(message) {
      this.alreadyHadError = true;
      this.setValidity('error', message || this.element.validationMessage);
    },
    setValidity(type, message) {
      this.$nextTick(() => {
        this.message = message;
        this.messageType = type;
      });
    },
    validate(isSubmit = false) {
      let valid = this.checkHtml5Validity();

      if (valid) {
        valid = this.checkCustomValidity();
      }

      if (valid && isSubmit) {
        valid = this.checkCustomValidity(this.submitConstraints);
      }

      if (valid) {
        this.setValidity(null, null);
      }

      this.computedValid = valid;

      return valid;
    },
    checkHtml5Validity() {
      if (!this.element || this.disableHTML5Validtaion) {
        return true;
      }

      if (!this.element.checkValidity()) {
        const validityKeys = [
          'badInput',
          'patternMismatch',
          'rangeOverflow',
          'rangeUnderflow',
          'stepMismatch',
          'tooLong',
          'tooShort',
          'typeMismatch',
          'valueMissing',
        ];

        for (let i = 0; i < validityKeys.length; i++) {
          if (this.element.validity[validityKeys[i]]) {
            const key = toSnakeCase(validityKeys[i]);
            const type = this.element.type;
            let errorMessage;

            if (validityKeys[i] === 'badInput') {
              if (type === 'number') {
                errorMessage = this.$i18n.t(`form_errors.${key}.${type}`);
              } else {
                errorMessage = this.$i18n.t(`form_errors.${key}.default`);
              }
            } else if (validityKeys[i] === 'patternMismatch') {
              errorMessage = this.$i18n.t(`form_errors.${key}.default`);

              if (this.element.placeholder) {
                errorMessage += ` ${this.$i18n.t(`form_errors.${key}.example`, {
                  example: this.element.placeholder,
                })}`;
              }
            } else if (validityKeys[i] === 'typeMismatch') {
              errorMessage = this.$i18n.t(`form_errors.${key}.${this.element.type}`);
            } else if (validityKeys[i] === 'valueMissing') {
              errorMessage = this.$i18n.t(`form_errors.${key}`);
            } else {
              let value;

              if (validityKeys[i] === 'rangeOverflow') {
                value = this.element.max;
              } else if (validityKeys[i] === 'rangeUnderflow') {
                value = this.element.min;
              } else if (validityKeys[i] === 'tooLong') {
                value = this.element.maxlength;
              } else if (validityKeys[i] === 'tooShort') {
                value = this.element.minlength;
              } else if (validityKeys[i] === 'stepMismatch') {
                value = this.element.step;
              }

              errorMessage = this.$i18n.t(`form_errors.${key}`, { value });
            }

            this.setInvalid(errorMessage);

            return false;
          }
        }
      }

      return true;
    },
    checkCustomValidity(constraints = this.constraints) {
      if (constraints) {
        const constraintsKeys = Object.keys(constraints);

        for (let i = 0; i < constraintsKeys.length; i++) {
          const constraint = constraints[constraintsKeys[i]];
          const isSimple = typeof constraint === 'string';
          const pattern = isSimple ? constraint : constraint.pattern;
          const errorMatches = (!isSimple && constraint.errorMatches) || false;
          const regexp = new RegExp(pattern, '');
          const result = regexp.test(this.computedValue);

          if ((errorMatches && result) || (!errorMatches && !result)) {
            const key = toSnakeCase(constraintsKeys[i]);
            const errorMessage = (!isSimple && constraint.message) || this.$i18n.t(`form_errors.${key}`);

            this.setInvalid(errorMessage);

            return false;
          }
        }
      }

      return true;
    },
  },
  created() {
    if (!this.parent) {
      this.$destroy();
      throw new Error(`You should wrap ${this.$options.name} in a Form`);
    } else if (this.parent._registerInput) {
      this.parent._registerInput(this);
    }
  },
  beforeDestroy() {
    if (this.parent && this.parent._unregisterInput) {
      this.parent._unregisterInput(this);
    }
  },
};
</script>

<template>
  <div class="inline-flex flex-column items-center justify-center text-white text-center">
    <div class="w-34 mx-auto pl-4">
      <LogoSVG class="inline-block fill-white events-none w-full" />
    </div>

    <div class="text-tiny font-bold mt-1">{{ $t('logo') }}</div>
  </div>
</template>

<script>
import LogoSVG from '@/assets/svgs/logo.svg';

export default {
  name: 'Logo',
  components: { LogoSVG },
};
</script>

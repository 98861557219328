<template>
  <div class="absolute top-0 left-0 px-4 py-1">
    <Button color="btn-transparent-primary" size="small" :label="$t('back')" @click="goBack">
      <template #icon>
        <LeftArrowSVG class="w-6 mr-2" />
      </template>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

import LeftArrowSVG from '@/assets/svgs/left_arrow.svg';

export default {
  name: 'Back',
  components: { Button, LeftArrowSVG },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"},{name:"focus-outside",rawName:"v-focus-outside",value:(_vm.hide),expression:"hide"},{name:"escape-pressed",rawName:"v-escape-pressed",value:(_vm.hide),expression:"hide"}],staticClass:"relative",class:[
    {
      'rounded-t-lg': !_vm.itemsUnderTrigger,
      'rounded-lg': _vm.itemsUnderTrigger,
      'bg-grey': !_vm.dark && _vm.active,
      'bg-dark-grey': _vm.dark && _vm.active,
    },
  ]},[_vm._t("default",null,{"open":_vm.active,"toggle":_vm.toggle}),(_vm.active)?_c('div',{staticClass:"rounded-b-lg absolute right-0 min-w-full z-0",class:[
      {
        'top-full': !_vm.itemsUnderTrigger,
        'top-0': _vm.itemsUnderTrigger,
        'rounded-b-lg': !_vm.itemsUnderTrigger,
        'rounded-lg': _vm.itemsUnderTrigger,
        'min-h-full': _vm.itemsUnderTrigger,
        'bg-grey': !_vm.dark,
        'bg-dark-grey': _vm.dark,
      },
    ]},[_vm._t("items")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { composeDirective } from './helpers';

const NAME = 'click-outside';
const LISTENERS = ['mousedown', 'touchstart'];

function validator(el, e) {
  return el !== e.target && !el.contains(e.target);
}

export default composeDirective(NAME, LISTENERS, validator);

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-50 min-h-30 border border-solid",class:[
      {
        'leading-0': !_vm.file.deleted,
        'bg-white-8': !_vm.file.deleted,
        'border-white-4': !_vm.file.deleted,
        'bg-red-5': _vm.file.deleted,
        'border-red-1': _vm.file.deleted,
      },
    ]},[(_vm.file.deleted)?_c('div',{staticClass:"w-full h-full flex items-center justify-center text-center px-2"},[_vm._v(" "+_vm._s(_vm.$t('record.video_deleted'))+" ")]):(_vm.thumbnailUrl)?_c('img',{staticClass:"max-w-full max-h-full",attrs:{"src":_vm.thumbnailUrl,"alt":"Clip thumbnail"}}):_vm._e()]),_c('div',{staticClass:"ml-6"},[_c('h3',{staticClass:"text-base text-white-1"},[_vm._v(_vm._s(_vm.$t('editor.removed_at')))]),_c('p',[_vm._v(_vm._s(_vm.$d(new Date(_vm.removedAt), 'long')))]),_c('h4',{staticClass:"text-white-1 mt-2"},[_vm._v(_vm._s(_vm.$t('editor.clip_length')))]),_c('p',[_vm._v(_vm._s(_vm.formattedLength))]),_c('Button',{staticClass:"p-0 mt-4",attrs:{"color":"btn-link-white-1","label":_vm.$t('editor.restore_clip')},on:{"click":_vm.restore},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('RestoreSVG',{staticClass:"h-5 w-5 mr-2"})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <label
      :tabindex="disabled ? -1 : 0"
      class="flex items-center w-full user-select-none group outline-none focus:z-1"
      @keydown.space.enter.prevent.stop
      @keyup.space.enter="toggle"
      @click="toggle"
    >
      <span v-if="labelPosition === 'left'" class="flex-1 flex mr-2">
        <slot />
      </span>

      <span
        :disabled="disabled"
        :class="[
          'w-12',
          'h-6',
          'border-2',
          'border-solid',
          'rounded-full',
          'relative',
          {
            'group-focus:outline-primary': !disabled,
            'cursor-pointer': !disabled,
            'bg-primary': newValue,
            'border-primary': newValue,
            'bg-grey': !newValue,
            'border-grey': !newValue,
            'opacity-4': !newValue,
          },
        ]"
      >
        <span
          class="block w-5 h-5 top-0 rounded-full absolute"
          :class="[
            {
              'bg-black': newValue,
              'right-0': newValue,
              'left-auto': newValue,
              'bg-primary-2': !newValue,
              'left-0': !newValue,
              'right-auto': newValue,
            },
          ]"
        />
      </span>

      <span v-if="labelPosition === 'right'" class="flex-1 flex ml-2">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Switcher',
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  props: {
    customValue: {
      type: Boolean,
      required: true,
    },
    labelPosition: {
      type: String,
      default: 'right',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.customValue,
    };
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }

      this.updateChecked(!this.newValue);
    },
    updateChecked(value) {
      this.newValue = value;
      this.$emit('customInput', value);
    },
  },
};
</script>

export default function (token, speakerId = null, grantedBytes = 0) {
  return new Promise((resolve, reject) => {
    if (!token) {
      reject(new Error('Unknown token dir!'));
      return;
    }

    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

    if (!window.requestFileSystem) {
      reject();
      return;
    }

    const dirName = speakerId ? `${token}/${speakerId}` : token;

    window.requestFileSystem(
      window.PERSISTENT,
      grantedBytes,
      (fs) => {
        fs.root.getDirectory(
          dirName,
          { create: true },
          (dirEntry) => resolve(dirEntry),
          (error) => reject(error),
        );
      },
      (error) => reject(error),
    );
  });
}

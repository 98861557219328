<template>
  <div class="flex-1 flex flex-column h-full overflow-auto relative">
    <LanguageSelect class="fixed top-4 right-7 w-35" />

    <div class="my-auto">
      <h2 class="text-3xl text-white-1 text-center">{{ $t('welcome.content.hello') }}</h2>

      <div v-if="!computedInitialized" class="container-sm text-center mt-3">
        <VideoCallSVG class="flex-shrink-0 fill-white-1 w-80 mt-6" />
        <p
          class="font-light mt-6"
          v-html="$t('welcome.content.intro', { registerLabel: $t('welcome.overview.register_cta') })"
        />
      </div>

      <div v-else class="mt-3">
        <p class="container-sm font-light text-center">{{ messageText }}</p>

        <SpeakersList
          showContinueAsSpeaker
          :speakers="speakers"
          :uploads="uploads"
          class="mt-6 mx-auto w-88"
          @continueAs="continueAs"
        />
      </div>
    </div>

    <router-link :to="{ name: 'Help' }" custom #default="{ href, navigate }">
      <a :href="href" class="fixed bottom-4 right-7" @click="navigate">
        {{ $t('header.user_menu.help') }}
      </a>
    </router-link>

    <LoginConfirmEmailModal
      :active="loginModalActive"
      :requiredEmail="speakerInAction ? speakerInAction.email : ''"
      @cancel="hideLoginModal"
      @confirm="confirmLogin(false)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SpeakersList from '@/components/SpeakersList.vue';
import LoginConfirmEmailModal from '@/components/LoginConfirmEmailModal.vue';
import LanguageSelect from '@/components/LanguageSelect.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import VideoCallSVG from '@/assets/svgs/video_call.svg';

export default {
  name: 'WelcomeContent',
  components: {
    SpeakersList,
    LoginConfirmEmailModal,
    LanguageSelect,
    VideoCallSVG,
  },
  mixins: [ErrorNotificationsMixin],
  data() {
    return {
      speakerInAction: null,
      loginModalActive: false,
    };
  },
  computed: {
    ...mapState(['speakers', 'localSpeakers', 'speakersInitialized', 'uploads']),
    computedInitialized() {
      return this.speakersInitialized && this.speakers.some((s) => s.name && s.email);
    },
    speakersCount() {
      return this.speakers.length;
    },
    submittedSpeakersCount() {
      return this.speakers.filter(
        (s) => !s.recording_enabled && s.disabled_recording_reason === 'final_recording_submitted',
      ).length;
    },
    messageText() {
      if (this.submittedSpeakersCount === 0) {
        return this.$i18n.t('welcome.content.nothing_submitted');
      }

      return this.$i18n.t('welcome.content.submitted_speakers', {
        count: `${this.submittedSpeakersCount}/${this.speakersCount}`,
      });
    },
  },
  methods: {
    ...mapActions(['loginSpeaker']),
    hideLoginModal() {
      this.loginModalActive = false;
      this.speakerInAction = null;
    },
    confirmLogin() {
      this.triggerLoginSpeaker();
      this.hideLoginModal();
    },
    triggerLoginSpeaker() {
      this.loginSpeaker({ id: this.speakerInAction.id })
        .then(() => {
          this.$router.push({ name: 'About' });
        })
        .catch((error) => {
          this.notificationFromError([error, 'contact_support']);
        });
    },
    continueAs(speaker) {
      this.speakerInAction = speaker;

      if (this.localSpeakers.includes(speaker.id)) {
        this.triggerLoginSpeaker();
        return;
      }

      this.loginModalActive = true;
    },
  },
};
</script>

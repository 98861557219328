var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',{staticClass:"flex items-center w-full user-select-none group outline-none focus:z-1",attrs:{"tabindex":_vm.disabled ? -1 : 0},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggle.apply(null, arguments)},"click":_vm.toggle}},[(_vm.labelPosition === 'left')?_c('span',{staticClass:"flex-1 flex mr-2"},[_vm._t("default")],2):_vm._e(),_c('span',{class:[
        'w-12',
        'h-6',
        'border-2',
        'border-solid',
        'rounded-full',
        'relative',
        {
          'group-focus:outline-primary': !_vm.disabled,
          'cursor-pointer': !_vm.disabled,
          'bg-primary': _vm.newValue,
          'border-primary': _vm.newValue,
          'bg-grey': !_vm.newValue,
          'border-grey': !_vm.newValue,
          'opacity-4': !_vm.newValue,
        },
      ],attrs:{"disabled":_vm.disabled}},[_c('span',{staticClass:"block w-5 h-5 top-0 rounded-full absolute",class:[
          {
            'bg-black': _vm.newValue,
            'right-0': _vm.newValue,
            'left-auto': _vm.newValue,
            'bg-primary-2': !_vm.newValue,
            'left-0': !_vm.newValue,
            'right-auto': _vm.newValue,
          },
        ]})]),(_vm.labelPosition === 'right')?_c('span',{staticClass:"flex-1 flex ml-2"},[_vm._t("default")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { toSnakeCase } from '@/helpers';

export default {
  methods: {
    notificationCodeFromError(error) {
      let code;

      if (Array.isArray(error)) {
        error = error.map((e) => e.message || e);
        code = error.join('::');
      } else if (error instanceof Error) {
        code = toSnakeCase(error.message);
      } else {
        code = error;
      }

      return code;
    },

    notificationFromError(
      error,
      { sound = undefined, synthesisMessage = '', closeAfterS = undefined, notificationType = 'error' } = {},
    ) {
      const code = this.notificationCodeFromError(error);
      let content = '';
      let spinner = false;

      const exists = this.$store.state.notifications.some((n) => n.type === notificationType && n.code === code);

      if (exists) {
        return;
      }

      if (!Array.isArray(error)) {
        error = [error];
      }

      error.forEach((key) => {
        key = key.message || key;

        if (content !== '') {
          content += '<br>';
        }

        if (typeof key === 'string' && key.startsWith('retry')) {
          if (!spinner) {
            const retryS = parseInt(key.split('_')[1], 10);

            content += this.$i18n.tc(`errors.retry_in_s`, retryS, { seconds: retryS });
            spinner = true;
          }
        } else if (
          typeof key === 'string' &&
          (this.$i18n.te(`errors.${key}`) || this.$i18n.te(`errors.${key}`, this.$i18n.fallbackLocale))
        ) {
          if (key === 'contact_support') {
            const email = this.$store.state.supportEmail || 'support@slideslive.com';
            content += this.$i18n.t(`errors.${key}`, { email });
          } else {
            content += this.$i18n.t(`errors.${key}`);
          }
        } else {
          content += key || key.message || key.code || '<undefined>';
        }
      });

      this.$store.commit('addNotification', {
        type: notificationType,
        content,
        closeable: !spinner,
        sound,
        spinner,
        synthesisMessage,
        code,
        closeAfterS,
      });
    },
  },
};

<template>
  <li
    :style="size"
    tabindex="0"
    :class="[
      'flex-none',
      'text-left',
      'border-solid',
      'overflow-hidden',
      'relative',
      'visibility-group',
      {
        'border-white-4': !isCurrent && !file.deleted,
        'border-accent-1': isCurrent && !file.deleted,
        'border': !isCurrent,
        'border-2': isCurrent,
        'outline-none': !isCurrent,
        'outline-accent': isCurrent,
        'z-0': !isCurrent,
        'z-1': isCurrent,
        'bg-white-8': !file.deleted,
        'bg-red-5': file.deleted,
        'border-red-1': file.deleted,
      },
    ]"
    @keydown.delete.stop.prevent
    @keyup.delete.stop.prevent="removeClip"
  >
    <div v-if="file.deleted" class="w-full h-full flex items-center justify-center text-center px-2">
      {{ $t('record.video_deleted') }}
    </div>

    <img v-else-if="thumbnailUrl" :src="thumbnailUrl" class="h-full opacity-4" alt="Clip thumbnail" />

    <Tooltip>
      <template #trigger>
        <Button
          @click.stop="removeClip"
          disableMinHeight
          color="btn-white-white"
          class="p-0 rounded-full w-8 h-8"
          :class="[
            'absolute',
            'right-1',
            'top-1',
            'visibility-group-hover:visible',
            'visibility-group-focus:visible',
            'visibility-group-focus-within:visible',
            {
              hidden: !isCurrent,
            },
          ]"
        >
          <template #icon>
            <TrashSVG class="w-6 w-6" />
          </template>
        </Button>
      </template>

      <template #default>
        <span v-html="$t('editor.remove_clip')" />
      </template>
    </Tooltip>
  </li>
</template>

<script>
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';
import TrashSVG from '@/assets/svgs/trash.svg';

export default {
  name: 'EditorClipThumbnail',
  components: { Button, Tooltip, TrashSVG },
  props: {
    clip: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size() {
      const width = Math.round((this.clip[this.clip.type].duration_ms / 1000) * this.scale);

      return `width: ${width}px;`;
    },
    thumbnailUrl() {
      if (!this.clip.frontend || !this.clip.frontend.thumbnail_url) {
        return null;
      }

      return this.clip.frontend.thumbnail_url;
    },
  },
  methods: {
    removeClip() {
      this.$emit('remove');
    },
  },
};
</script>

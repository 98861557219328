<template>
  <Modal size="small" :active="active" @close="close">
    <div class="text-center px-4 py-8">
      <div class="mt-0">
        <CopyToClipboard :label="$t('local_data_link_modal.log_link')" :value="slRecLogLink" />
        <CopyToClipboard class="mt-4" :label="$t('local_data_link_modal.data_link')" :value="localDataLink" />

        <div class="mt-6">
          <Button class="ml-4" color="btn-primary-outline" :label="$t('close')" @click="close" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default {
  name: 'LocalDataLinkModal',
  components: { Modal, Button, CopyToClipboard },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['recorderToken', 'loggedInSpeakerId']),
    slRecLogLink() {
      return `filesystem:https://recorder-v3.slideslive.com/persistent/${this.recorderToken}/slrec.log`;
    },
    localDataLink() {
      return `filesystem:https://recorder-v3.slideslive.com/persistent/${this.recorderToken}/${this.loggedInSpeakerId}`;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

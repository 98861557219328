<template>
  <div class="flex justify-center -mx-1">
    <div class="w-full flex items-start relative z-1" :style="fitViewportStyle">
      <OverlaySpinner v-if="isSpinnerVisible" class="z-2">
        <p v-if="speakerRecorderProgress !== ''">{{ $t('record.processing_video') }}: {{ speakerProgressText }}</p>
        <p v-if="screenSharingRecorderProgress !== ''">
          {{ $t('record.processing_screen') }}: {{ screenSharingProgressText }}
        </p>
      </OverlaySpinner>

      <div class="flex-1 relative z-1">
        <SpeakerStream
          class="border-4 border-solid"
          :class="[
            {
              'border-transparent': !isRecording,
              'border-accent': isRecording,
            },
          ]"
          #default="{ stream, enabled }"
        >
          <MediaPreview
            :stream="stream"
            :enabled="enabled"
            :errors="cameraErrors"
            icon="SpeakerSVG"
            #default="{ klass }"
          >
            <MicrophonePreview
              class="absolute left-2"
              :class="klass"
              :stream="stream"
              :errors="microphoneErrors"
              :loading="isWaitingForSpeakerStream"
              dark
            />

            <RecordingState class="absolute top-2 right-4 h-8" :isRecording="isRecording" :isPaused="isPaused" />
          </MediaPreview>
        </SpeakerStream>
      </div>

      <div class="flex-1 relative z-1">
        <ScreenSharingStream
          class="border-4 border-solid"
          :class="[
            {
              'border-transparent': !isRecording || !screenSharingEnabled || screenSharingErrors.length > 0,
              'border-accent': isRecording && screenSharingEnabled && screenSharingErrors.length === 0,
            },
          ]"
          #default="{ stream, enabled }"
        >
          <MediaPreview :stream="stream" :enabled="enabled" :errors="screenSharingErrors" icon="PresentationSVG">
            <RecordingState
              v-if="enabled"
              class="absolute top-2 right-4 h-8"
              :isRecording="isRecording"
              :isPaused="isPaused"
            />
          </MediaPreview>
        </ScreenSharingStream>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import OverlaySpinner from '@/components/OverlaySpinner.vue';
import SpeakerStream from '@/components/SpeakerStream.vue';
import ScreenSharingStream from '@/components/ScreenSharingStream.vue';
import MediaPreview from '@/components/MediaPreview.vue';
import MicrophonePreview from '@/components/MicrophonePreview.vue';
import RecordingState from '@/components/RecordingState.vue';

export default {
  name: 'RecordPreview',
  components: {
    OverlaySpinner,
    SpeakerStream,
    ScreenSharingStream,
    MediaPreview,
    MicrophonePreview,
    RecordingState,
  },
  data() {
    return {
      windowInnerHeight: 0,
    };
  },
  computed: {
    ...mapState([
      'isWaitingForSpeakerStream',
      'isWaitingForStreams',
      'isWaitingForWebMFiles',
      'isRecording',
      'isPaused',
      'isRestoring',
      'cameraErrors',
      'microphoneErrors',
      'screenSharingEnabled',
      'screenSharingErrors',
      'speakerRecorderProgress',
      'screenSharingRecorderProgress',
    ]),
    isSpinnerVisible() {
      return this.isRestoring || this.isWaitingForStreams || this.isWaitingForWebMFiles;
    },
    speakerProgressText() {
      const message = this.$i18n.te(`recorder.${this.speakerRecorderProgress}`)
        ? this.$i18n.t(`recorder.${this.speakerRecorderProgress}`)
        : this.speakerRecorderProgress;

      return message;
    },
    screenSharingProgressText() {
      const message = this.$i18n.te(`recorder.${this.screenSharingRecorderProgress}`)
        ? this.$i18n.t(`recorder.${this.screenSharingRecorderProgress}`)
        : this.screenSharingRecorderProgress;

      return message;
    },
    fitViewportStyle() {
      if (this.windowInnerHeight < 600) {
        return 'width: 600px;';
      }

      if (this.windowInnerHeight < 650) {
        return 'width: 650px;';
      }

      if (this.windowInnerHeight < 700) {
        return 'width: 800px;';
      }

      if (this.windowInnerHeight < 785) {
        return 'width: 900px;';
      }

      return '';
    },
  },
  methods: {
    handleWindowResize() {
      this.windowInnerHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    setTimeout(this.handleWindowResize, 0);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
};
</script>

<template>
  <div
    v-click-outside="hide"
    v-focus-outside="hide"
    v-escape-pressed="hide"
    class="relative"
    :class="[
      {
        'rounded-t-lg': !itemsUnderTrigger,
        'rounded-lg': itemsUnderTrigger,
        'bg-grey': !dark && active,
        'bg-dark-grey': dark && active,
      },
    ]"
  >
    <slot :open="active" :toggle="toggle" />

    <div
      v-if="active"
      class="rounded-b-lg absolute right-0 min-w-full z-0"
      :class="[
        {
          'top-full': !itemsUnderTrigger,
          'top-0': itemsUnderTrigger,
          'rounded-b-lg': !itemsUnderTrigger,
          'rounded-lg': itemsUnderTrigger,
          'min-h-full': itemsUnderTrigger,
          'bg-grey': !dark,
          'bg-dark-grey': dark,
        },
      ]"
    >
      <slot name="items" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    itemsUnderTrigger: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    hide() {
      this.active = false;
    },
    toggle() {
      if (this.disabled) {
        return;
      }

      this.active = !this.active;
    },
  },
};
</script>

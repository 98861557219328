import validate from './validate';
import elementPropName from './element_prop_name';

export default function (name, el, binding) {
  if (!validate(name, binding)) {
    return;
  }

  const propName = elementPropName(name);

  el[propName].callback = binding.value;
}

import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';
import Logger from '@/modules/logger';

import { removeConfirmCloseTab } from '@/helpers';

export default function (
  { dispatch, commit, state },
  { skipNoStreamRecorderErrors = false, interrupted = false } = {},
) {
  commit('setKey', { key: 'isRecording', isRecording: false });

  return Promise.all([
    state.speakerRecorder.stopRecording(skipNoStreamRecorderErrors, interrupted),
    state.screenSharingEnabled && state.screenSharingRecorder.stopRecording(skipNoStreamRecorderErrors, interrupted),
  ])
    .then(([finishedSpeakerRecording, finishedSlidesRecording]) => {
      if (!finishedSpeakerRecording && !finishedSlidesRecording) {
        return Promise.resolve(null);
      }

      SentryAddBreadcrumb({
        category: 'stop_recording',
        message: `Data`,
        data: {
          speakerRecording: finishedSpeakerRecording,
          slidesRecording: finishedSlidesRecording,
        },
        level: Severity.Debug,
      });
      Logger.log(
        `Recording stopped, data: ${JSON.stringify({
          speakerRecording: finishedSpeakerRecording,
          slidesRecording: finishedSlidesRecording,
        })}`,
      );

      return dispatch('handleFinishedRecording', {
        speakerRecording: finishedSpeakerRecording,
        slidesRecording: finishedSlidesRecording,
      });
    })
    .catch((error) => {
      removeConfirmCloseTab();
      throw error;
    })
    .finally(() => {
      commit('updateVersion', {
        id: state.currentVersionId,
        recording_in_progress: null,
      });
    });
}

<template>
  <p>
    <span class="font-bold">{{ formatElapsed }}</span>
    /
    <span>{{ formatDuration }}</span>
  </p>
</template>

<script>
import { formatSeconds } from '@/helpers';

export default {
  name: 'PlayerProgressTime',
  props: {
    elapsed: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    formatDuration() {
      return formatSeconds(this.duration / 1000);
    },
    formatElapsed() {
      return formatSeconds(this.elapsed / 1000);
    },
  },
};
</script>

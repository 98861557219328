<template>
  <div class="flex flex-column text-center">
    <div class="text-white text-6xl font-bold">{{ recordingCountdownValue }}</div>

    <Button class="mt-4 w-30 mx-auto" size="small" color="btn-accent-outline" :label="$t('cancel')" @click="cancel" />

    <div v-if="screenSharingEnabled" class="mt-10">
      <p class="text-white text-xl font-bold">{{ $t('recorder.hide_share_banner') }}</p>

      <img :src="imageSrc" class="w-1/2 max-w-full mt-2" alt="Sharing message example" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';

import ShareBannerImage from '@/assets/share_banner.png';

export default {
  name: 'RecordingCountdownModal',
  components: { Button },
  computed: {
    ...mapState(['recordingCountdownValue', 'screenSharingEnabled']),
    imageSrc() {
      return ShareBannerImage;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

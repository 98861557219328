var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"escape-pressed",rawName:"v-escape-pressed",value:(_vm.cancel),expression:"cancel"}],class:[
    'bg-blur-transition',
    'bg-blurred',
    'flex',
    'flex-column',
    'items-center',
    'justify-center',
    'text-center',
    'absolute',
    '-inset-x-2',
    'top-0',
    '-bottom-2',
  ]},[_c('h2',{staticClass:"text-white-1 container-sm mx-auto"},[_vm._v(_vm._s(_vm.$t('editor.remove_confirm.title')))]),_c('p',{staticClass:"font-light mt-3"},[_vm._v(_vm._s(_vm.$t('editor.remove_confirm.message')))]),_c('div',{staticClass:"mt-10"},[_c('Button',{ref:"confirm",attrs:{"color":"btn-accent","label":_vm.$t('editor.remove_confirm.confirm'),"loading":_vm.loading},on:{"click":_vm.confirm}}),_c('Button',{staticClass:"ml-4",attrs:{"color":"btn-accent-outline","label":_vm.$t('editor.remove_confirm.cancel'),"loading":_vm.loading},on:{"click":_vm.cancel}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <VersionsTabs
      class="relative z-1"
      :sortedVersions="sortedVersions"
      :currentVersion="currentVersion"
      :disabled="isRecording || isRestoring || isUploading"
      :downloadingVersionsJSONFromCloud="downloadingVersionsJSONFromCloud"
      @delete="deleteVersion"
      @create="triggerCreateVersion"
      @copy="triggerCopyVersion"
      @rename="triggerRenameVersion"
      @submit="submitVersion"
      @downloadLocal="triggerDownloadLocalVersion"
      @downloadCloud="triggerDownloadCloudVersion"
    />

    <div class="flex-1 bg-white-9 rounded-b-lg rounded-tr-lg pt-4 pb-12 px-6 relative z-0">
      <div class="flex items-stretch justify-end" :class="isAbovePlayerBarHidden && 'hidden'">
        <CopyToClipboard
          v-if="currentVersion.share_link_url"
          :label="$t('share.share_link')"
          :value="currentVersion.share_link_url"
          class="max-w-78"
        />

        <RecordUploadActions
          v-if="!isCurrentVersionSubmitted"
          :isUploaded="isCurrentVersionUploaded"
          :disabled="isUploading"
          @submit="submitCurrentVersion"
          @backup="backup"
        />
      </div>

      <RecordPreview v-if="isPreviewVisible" class="mt-1" />

      <RecordPlayer v-else class="mt-1 py-1" :version="currentVersion" :key="currentVersion.id" />

      <RecordCopyToEdit v-if="isCopyToEditVisible" class="mt-12" @copy="triggerCopyVersion" />

      <RecordTabs
        v-else-if="areTabsVisible"
        :hasClips="hasClips"
        :multiplePartsRecordingEnabled="multiplePartsRecordingEnabled"
        :isRecording="isRecording || isRestoring"
        :disabled="isUploading"
        class="mt-6"
      />
    </div>

    <p class="text-center mt-4 text-xs">
      <a :href="emailUrl" target="_blank" rel="noopener noreferrer">{{ supportEmail }}</a>
    </p>

    <LocalDownloadUnloadListeners />

    <Modal
      size="small"
      :active="modalActive"
      :closeable="modalCloseable"
      :emptyContent="isCountdownModalActive"
      @close="hideModal"
    >
      <RecordingCountdownModal v-if="isCountdownModalActive" @cancel="hideModal" />

      <VersionDownloadModal v-if="isVersionActionDownloadLocal || isVersionActionDownloadCloud" />

      <VersionDeleteModal
        v-else-if="isVersionActionDelete"
        :loading="versionActionInProgress"
        @cancel="hideModal"
        @confirm="confirmVersionModal"
      />

      <VersionNameModal
        v-else-if="isVersionActionCreate || isVersionActionCopy || isVersionActionRename"
        :loading="versionActionInProgress"
        :name="versionActionName"
        :title="nameModalTitle"
        :placeholder="nameModalPlaceholder"
        @cancel="hideModal"
        @confirm="confirmVersionModal"
      />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import { defaultVersionName, copyVersionName } from '@/helpers';

import VersionsTabs from '@/components/VersionsTabs.vue';
import Modal from '@/components/Modal.vue';
import RecordingCountdownModal from '@/components/RecordingCountdownModal.vue';
import VersionDeleteModal from '@/components/VersionDeleteModal.vue';
import VersionNameModal from '@/components/VersionNameModal.vue';
import RecordUploadActions from '@/components/RecordUploadActions.vue';
import RecordPreview from '@/components/RecordPreview.vue';
import RecordPlayer from '@/components/RecordPlayer.vue';
import RecordTabs from '@/components/RecordTabs.vue';
import RecordCopyToEdit from '@/components/RecordCopyToEdit.vue';
import CopyToClipboard from '@/components/CopyToClipboard.vue';
import VersionDownloadModal from '@/components/VersionDownloadModal.vue';
import LocalDownloadUnloadListeners from '@/components/LocalDownloadUnloadListeners.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

export default {
  name: 'RecordNew',
  components: {
    VersionsTabs,
    Modal,
    RecordingCountdownModal,
    VersionDeleteModal,
    VersionNameModal,
    RecordUploadActions,
    RecordPreview,
    RecordPlayer,
    RecordTabs,
    RecordCopyToEdit,
    CopyToClipboard,
    VersionDownloadModal,
    LocalDownloadUnloadListeners,
  },
  mixins: [ErrorNotificationsMixin],
  props: {
    downloadingVersionsJSONFromCloud: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      versionAction: null,
      versionActionInProgress: false,
      versionActionVersion: null,
      versionActionName: '',
      modalActive: false,
      modalCloseable: true,
      isDownloadModalActive: false,
    };
  },
  computed: {
    ...mapState([
      'supportEmail',
      'recorderToken',
      'recordingStartRequested',
      'isRecording',
      'isPaused',
      'isRestoring',
      'isUploading',
      'multiplePartsRecordingEnabled',
      'isWaitingForWebMFiles',
      'isCountdownModalActive',
      'versions',
      'currentVersionId',
    ]),
    ...mapGetters(['currentVersion', 'sortedVersions']),
    hasClips() {
      return this.currentVersion.timelines.speaker.video.length > 0;
    },
    isAbovePlayerBarHidden() {
      return this.isCurrentVersionUploading || this.isPreviewVisible;
    },
    isCurrentVersionSubmitted() {
      return this.currentVersion.final_recording || false;
    },
    isCurrentVersionUploading() {
      return (this.currentVersion.upload_id && !this.currentVersion.upload_complete) || false;
    },
    isCurrentVersionUploaded() {
      return this.currentVersion.upload_complete || false;
    },
    isPreviewVisible() {
      return (
        (this.isRecording ||
          this.isRestoring ||
          this.recordingStartRequested ||
          this.isWaitingForWebMFiles ||
          !this.hasClips) &&
        !this.isCurrentVersionUploading &&
        !this.isCurrentVersionUploaded
      );
    },
    isCopyToEditVisible() {
      return this.isCurrentVersionUploading || this.isCurrentVersionUploaded;
    },
    areTabsVisible() {
      return !this.isCurrentVersionUploading && !this.isCurrentVersionUploaded;
    },
    isVersionActionDelete() {
      return this.versionAction === 'delete';
    },
    isVersionActionCreate() {
      return this.versionAction === 'create';
    },
    isVersionActionCopy() {
      return this.versionAction === 'copy';
    },
    isVersionActionRename() {
      return this.versionAction === 'rename';
    },
    isVersionActionDownloadLocal() {
      return this.versionAction === 'downloadLocal';
    },
    isVersionActionDownloadCloud() {
      return this.versionAction === 'downloadCloud';
    },
    nameModalTitle() {
      let localeKey = 'name_create_modal';

      if (this.versionAction === 'copy') {
        localeKey = 'name_copy_modal';
      } else if (this.versionAction === 'rename') {
        localeKey = 'rename_modal';
      }

      return this.$i18n.t(`version.${localeKey}.title`);
    },
    nameModalPlaceholder() {
      let localeKey = 'name_create_modal';

      if (this.versionAction === 'copy') {
        localeKey = 'name_copy_modal';
      } else if (this.versionAction === 'rename') {
        localeKey = 'rename_modal';
      }

      return this.$i18n.t(`version.${localeKey}.placeholder`);
    },
    emailUrl() {
      let result = `mailto:${this.supportEmail}`;
      result += '?subject=Recorder%203.0';
      result += `&body=Recorder%20Token%3A%20${this.recorderToken}`;

      return result;
    },
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification']),
    ...mapActions([
      'copyVersion',
      'upload',
      'downloadLocalVersion',
      'downloadCloudVersion',
      'triggerCancelRecordingCountdown',
    ]),
    submitCurrentVersion() {
      this.$emit('submit', this.currentVersionId);
    },
    submitVersion(versionId) {
      this.$emit('submit', versionId);
    },
    backup() {
      this.upload().finally(() => {
        this.setKey({ key: 'isUploadVisible', isUploadVisible: false });
      });
    },
    deleteVersion(version) {
      this.versionActionVersion = version;
      this.versionAction = 'delete';
      this.openModal();
    },
    triggerCreateVersion() {
      this.versionActionName = defaultVersionName(this.$i18n.locale);
      this.versionAction = 'create';
      this.openModal();
    },
    triggerCopyVersion(version = this.currentVersion) {
      this.versionActionVersion = version;
      this.versionAction = 'copy';
      this.versionActionName = copyVersionName(this.versions, version);
      this.openModal();
    },
    triggerRenameVersion(version) {
      this.versionActionVersion = version;
      this.versionAction = 'rename';
      this.versionActionName = version.version_name;
      this.openModal();
    },
    triggerDownloadLocalVersion(version) {
      this.versionAction = 'downloadLocal';
      this.openModal(false);

      this.downloadLocalVersion({ id: version.id }).finally(() => {
        this.hideModal();
      });
    },
    triggerDownloadCloudVersion(version) {
      this.versionAction = 'downloadCloud';
      this.openModal(false);

      this.downloadCloudVersion({ id: version.id })
        .then(() => {
          const newVersionName = copyVersionName(this.versions, version);

          return this.$store.dispatch('copyVersion', {
            id: version.id,
            version_name: newVersionName,
          });
        })
        .then((newVersion) => {
          this.setKey({ key: 'currentVersionId', currentVersionId: newVersion.id });

          this.addNotification({
            type: 'info',
            content: this.$i18n.t('record.new_version_notification'),
            closeable: true,
            closeAfterS: 3,
          });
        })
        .catch((error) => {
          this.notificationFromError(error);

          if (this.isCountdownModalActive) {
            this.cancelRecordingCountdown();
          }
        })
        .finally(() => {
          this.versionActionInProgress = false;
          this.hideModal();
        });
    },
    confirmVersionModal(name = null) {
      if (this.isVersionActionRename && name.trim() === this.versionActionVersion.version_name) {
        this.hideModal();
        return;
      }

      let actionName = '';
      const payload = {};

      if (this.isVersionActionCreate) {
        actionName = 'addVersion';
        payload.name = name;
      } else if (this.isVersionActionCopy) {
        actionName = 'copyVersion';
        payload.id = this.versionActionVersion.id;
        payload.version_name = name;
      } else if (this.isVersionActionRename) {
        actionName = 'renameVersion';
        payload.id = this.versionActionVersion.id;
        payload.version_name = name;
      } else if (this.isVersionActionDelete) {
        actionName = this.versionActionVersion.local ? 'deleteVersion' : 'deleteUpload';
        payload.id = this.versionActionVersion.id;
      }

      this.performAction(actionName, payload);
    },
    performAction(action, payload) {
      this.versionActionInProgress = true;

      this.$store
        .dispatch(action, payload)
        .then((version) => {
          if (!this.isVersionActionCreate && !this.isVersionActionCopy) {
            return;
          }

          this.setKey({ key: 'currentVersionId', currentVersionId: version.id });

          this.addNotification({
            type: 'info',
            content: this.$i18n.t('record.new_version_notification'),
            closeable: true,
            closeAfterS: 3,
          });
        })
        .catch((error) => {
          this.notificationFromError(error);

          if (this.isCountdownModalActive) {
            this.cancelRecordingCountdown();
          }
        })
        .finally(() => {
          this.versionActionInProgress = false;
          this.hideModal();
        });
    },
    cancelRecordingCountdown() {
      this.triggerCancelRecordingCountdown();
    },
    openModal(closeable = true) {
      if (this.isCountdownModalActive) {
        return;
      }

      this.modalCloseable = closeable;
      this.modalActive = true;
    },
    hideModal() {
      if (this.isCountdownModalActive) {
        this.cancelRecordingCountdown();
        return;
      }

      this.modalActive = false;
      this.versionAction = null;
      this.versionActionVersion = null;
    },
  },
  watch: {
    isCountdownModalActive(value) {
      this.modalActive = value;
    },
  },
};
</script>

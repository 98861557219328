<template>
  <div
    v-escape-pressed="cancel"
    :class="[
      'bg-blur-transition',
      'bg-blurred',
      'flex',
      'flex-column',
      'items-center',
      'justify-center',
      'text-center',
      'absolute',
      '-inset-x-2',
      'top-0',
      '-bottom-2',
    ]"
  >
    <h2 class="text-white-1 container-sm mx-auto">{{ $t('editor.remove_confirm.title') }}</h2>

    <p class="font-light mt-3">{{ $t('editor.remove_confirm.message') }}</p>

    <div class="mt-10">
      <Button
        ref="confirm"
        color="btn-accent"
        :label="$t('editor.remove_confirm.confirm')"
        :loading="loading"
        @click="confirm"
      />

      <Button
        class="ml-4"
        color="btn-accent-outline"
        :label="$t('editor.remove_confirm.cancel')"
        :loading="loading"
        @click="cancel"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'EditorRemoveClipConfirm',
  components: { Button },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.$refs.confirm.$el.focus();
  },
};
</script>

export default function (locale, position) {
  if (locale !== 'en') {
    return '.';
  }

  if (position < 11 || position > 13) {
    if (position % 10 === 1) {
      return 'st';
    }

    if (position % 10 === 2) {
      return 'nd';
    }

    if (position % 10 === 3) {
      return 'rd';
    }
  }

  return 'th';
}

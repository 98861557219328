import showLoading from './show_loading';
import hideLoading from './hide_loading';
import resetStore from './reset_store';
import setKey from './set_key';
import logout from './logout';
import login from './login';
import updateSelfRecording from './update_self_recording';
import updateVersion from './update_version';
import updateUpload from './update_upload';
import updateSpeaker from './update_speaker';
import setTokenData from './set_token_data';
import addNotification from './add_notification';
import deleteNotification from './delete_notification';

export default {
  showLoading,
  hideLoading,
  resetStore,
  setKey,
  logout,
  login,
  updateSelfRecording,
  updateVersion,
  updateUpload,
  updateSpeaker,
  setTokenData,
  addNotification,
  deleteNotification,
};

<template>
  <div
    :class="[
      'min-h-50',
      'flex',
      'flex-column',
      'items-center',
      'justify-center',
      'p-4',
      'text-white-3',
      'text-base',
      'border',
      'border-dashed',
      'w-full',
      'rounded-lg',
      'text-center',
      {
        'bg-white-9': !readOnly && !disabled,
        'border-transparent': !readOnly,
        'border-white-3': readOnly,
      },
    ]"
  >
    <p v-if="!readOnly && !disabled && title && !isLinkSelected" class="text-xl text-white-1 font-bold mb-3">
      {{ title }}
    </p>

    <div v-if="isLinkSelected">
      <SelectedTag
        v-for="(link, index) in customValue"
        :key="index"
        class="my-1"
        :label="link"
        :disabled="disabled || readOnly"
        @delete="deleteLink(index)"
        @click.stop
      />
    </div>

    <Form class="w-full" @submit="addLink">
      <Input
        v-if="!readOnly && !disabled"
        class="w-7/8 max-w-80 mt-3 mx-auto"
        name="url"
        type="url"
        v-model="inputValue"
        :placeholder="placeholder"
        :constraints="linkConstraints"
        required
        :validateOnBlur="false"
      />

      <Button
        v-if="!readOnly && !disabled"
        type="submit"
        class="w-7/8 max-w-80 mt-3"
        color="btn-primary-outline"
        :label="selectButtonText || $t('select')"
      />
    </Form>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';
import SelectedTag from '@/components/SelectedTag.vue';

export default {
  name: 'LinksSelect',
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  components: { Button, Form, Input, SelectedTag },
  props: {
    customValue: {
      type: [Object, Array],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    nothingSelectedText: {
      type: String,
      default: null,
    },
    selectButtonText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      newValue: this.customValue,
    };
  },
  watch: {
    customValue: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('customInput', value);
      },
    },
    isLinkSelected() {
      return this.newValue && this.newValue.length > 0;
    },
    linkConstraints() {
      return {
        forbiddenDomain: {
          pattern: '^http[s]?:\\/\\/(?:[a-z0-9-]+\\.)*(slideslive)\\.[a-z]+(?:\\/|$|\\:\\d{1,5}|\\s)',
          message: this.$i18n.t('form_errors.forbidden_slideslive_domain'),
          errorMatches: true,
        },
      };
    },
  },
  methods: {
    addLink() {
      const links = [...this.computedValue, this.inputValue.trim()].filter(
        (value, index, self) => self.indexOf(value) === index,
      );

      this.inputValue = '';

      if (links.length === this.computedValue.length) {
        return;
      }

      this.computedValue = links;
    },
    deleteLink(index) {
      this.computedValue = this.computedValue.filter((link, i) => i !== index);
    },
  },
};
</script>

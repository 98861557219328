export default function (state, payload) {
  const speakers = [...state.speakers];
  const speakerIndex = speakers.findIndex((s) => s.id === payload.id);

  speakers[speakerIndex] = {
    ...speakers[speakerIndex],
    ...payload,
  };

  state.speakers = speakers;
}

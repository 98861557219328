<template>
  <ol class="inline-flex justify-center items-center list-style-none -mx-2 group">
    <li v-for="n in 5" :key="n">
      <button
        @click="itemClicked(n)"
        type="button"
        :disabled="disabled"
        :class="[
          'leading-0',
          'border-none',
          'bg-transparent',
          'radius-0',
          'mx-2',
          'outline-none',
          'rounded-lg',
          'group-hover:opacity-3',
          'hover:opacity',
          'focus:opacity',
          'focus:outline-white',
        ]"
        :title="n"
      >
        <component
          :is="`Feedback${n}SVG`"
          class="w-16 h-16"
          :class="[
            {
              'fill-white-1': value !== n,
              [activeItemColor(n)]: value === n,
            },
          ]"
        />
      </button>
    </li>
  </ol>
</template>

<script>
import Feedback1SVG from '@/assets/svgs/feedback_1.svg';
import Feedback2SVG from '@/assets/svgs/feedback_2.svg';
import Feedback3SVG from '@/assets/svgs/feedback_3.svg';
import Feedback4SVG from '@/assets/svgs/feedback_4.svg';
import Feedback5SVG from '@/assets/svgs/feedback_5.svg';

export default {
  name: 'FeedbackList',
  components: { Feedback1SVG, Feedback2SVG, Feedback3SVG, Feedback4SVG, Feedback5SVG },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    activeItemColor(n) {
      if (n === 1) {
        return 'fill-rating-1';
      }

      if (n === 2) {
        return 'fill-rating-2';
      }

      if (n === 3) {
        return 'fill-rating-3';
      }

      if (n === 4) {
        return 'fill-rating-4';
      }

      return 'fill-rating-5';
    },
    itemClicked(n) {
      this.$emit('input', n);
    },
  },
};
</script>

import VolumeMeterNode from './volume_meter_node';

class VolumeMeter {
  constructor(stream) {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    this._volume = 0;

    this.stream = stream;

    this.audioContext = null;
    this.microphone = null;
    this.node = null;

    this.callbacks = new Map([['volumeChange', new Set()]]);

    this._connectAudioContextAndStream();
  }

  destroy() {
    this.audioContext = null;
    this.microphone = null;
    this.node = null;

    this.volume = 0;
  }

  on(event, callback) {
    this.callbacks.get(event).add(callback);
  }

  _emit(event, ...args) {
    for (const callback of this.callbacks.get(event)) {
      callback(...args);
    }
  }

  _onvolumeChange() {
    this._emit('volumeChange', { percent: Math.round(this.volume * 10) });
  }

  _connectAudioContextAndStream() {
    this.audioContext = new AudioContext();

    this.audioContext.audioWorklet.addModule('/volume_meter_processor.js').then(() => {
      if (!this.audioContext) {
        return;
      }

      this.microphone = this.audioContext.createMediaStreamSource(this.stream);
      this.node = new VolumeMeterNode(this.audioContext, 25);

      const draw = () => {
        if (!this.node) {
          return;
        }

        this.volume = this.node.sensibleVolume;

        window.requestAnimationFrame(draw);
      };

      draw();

      this.microphone.connect(this.node);
    });
  }

  set volume(value) {
    this._volume = value;
    this._onvolumeChange();
  }

  get volume() {
    return this._volume;
  }
}

export default VolumeMeter;

<template>
  <div>
    <slot name="trigger" :toggle="toggle" :open="active" />

    <transition :name="animation" v-on="$listeners">
      <slot v-if="active" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    animation: {
      type: String,
      default: 'slide',
      validate: (value) => ['fade', 'slide', 'zoom-in', 'zoom-out'].includes(value),
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: this.open,
    };
  },
  methods: {
    toggle(show = !this.active) {
      this.active = show;
    },
  },
};
</script>

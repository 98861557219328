<template>
  <div class="text-center px-4 py-8">
    <h2 class="text-2xl text-white-1">{{ $t('recorded_tokens.delete_modal.title') }}</h2>

    <p class="font-light mt-3">{{ $t('recorded_tokens.delete_modal.message') }}</p>

    <div class="mt-10">
      <Button :label="$t('recorded_tokens.delete_modal.confirm')" :loading="loading" @click="confirm" />

      <Button class="ml-4" color="btn-primary-outline" :label="$t('cancel')" :loading="loading" @click="cancel" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'RecordedTokenDeleteModal',
  components: { Button },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

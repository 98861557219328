import readEntries from './read_entries';

export default function (grantedBytes = 0) {
  return new Promise((resolve, reject) => {
    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

    window.requestFileSystem(
      window.PERSISTENT,
      grantedBytes,
      (fs) => {
        const dirEntry = fs.root;

        const dirReader = dirEntry.createReader();

        readEntries(dirReader)
          .then((entries) => {
            const dirs = entries.filter((e) => e.isDirectory);
            const names = dirs.map((d) => d.name);

            resolve(names);
          })
          .catch((error) => reject(error));
      },
      (error) => reject(error),
    );
  });
}

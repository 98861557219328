<template>
  <form @submit.prevent="submit">
    <NumberInput type="number" placeholder="1" class="w-24 mx-auto" block :min="speakers.length || 1" v-model="count" />

    <p class="text-xs pt-2">{{ messageText }}</p>

    <Button class="w-54 mt-6" type="submit" :loading="inProgress" :label="$t('continue')" />
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Button from '@/components/Button.vue';
import NumberInput from '@/components/NumberInput.vue';

export default {
  name: 'SpeakersCountForm',
  components: { Button, NumberInput },
  data() {
    return {
      count: 1,
      inProgress: false,
    };
  },
  computed: {
    ...mapState(['speakers', 'registerSpeaker']),
    messageText() {
      return this.$i18n.tc('speakers_count.total_speakers', this.count, { count: this.count });
    },
  },
  watch: {
    speakers: {
      immediate: true,
      handler(value) {
        this.count = value.length || 1;
      },
    },
  },
  methods: {
    ...mapActions(['updateSpeakerCount', 'assignSpeaker']),
    submit() {
      this.inProgress = true;

      this.updateSpeakerCount({ count: this.count })
        .then(() => {
          if (this.speakers.length === 1) {
            const payload = {
              id: this.speakers[this.speakers.length - 1].id,
              ...this.registerSpeaker,
            };

            this.assignSpeaker(payload)
              .then(() => {
                this.$router.push({ name: 'About' });
              })
              .finally(() => {
                this.inProgress = false;
              });

            return;
          }

          this.$router.push({ name: 'Speakers' });
          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <h2 class="text-xl text-white-1">{{ $t('about.recommendations.title') }}</h2>

    <div class="flex flex-wrap mt-4">
      <div class="pr-2 w-1/2">
        <AnonymousSVG class="fill-white-1 w-6 h-6" />

        <h3 class="text-base text-white-1 mt-2">
          {{ $t('about.recommendations.anonymous_window.title') }}
        </h3>

        <p class="mt-2 text-sm">{{ $t('about.recommendations.anonymous_window.message') }}</p>
      </div>

      <div class="pl-2 w-1/2">
        <VideoSVG class="fill-white-1 w-6 h-6" />

        <h3 class="text-base text-white-1 mt-2">
          {{ $t('about.recommendations.videos.title') }}
        </h3>

        <p class="mt-2 text-sm">{{ $t('about.recommendations.videos.message') }}</p>
      </div>

      <div class="mt-4 pr-2 w-1/2">
        <CursorSVG class="fill-white-1 w-6 h-6" />

        <h3 class="text-base text-white-1 mt-2">
          {{ $t('about.recommendations.cursor.title') }}
        </h3>

        <p class="mt-2 text-sm">{{ $t('about.recommendations.cursor.message') }}</p>
      </div>

      <div class="mt-4 pl-2 w-1/2">
        <NotesSVG class="fill-white-1 w-6 h-6" />

        <h3 class="text-base text-white-1 mt-2">
          {{ $t('about.recommendations.notes.title') }}
        </h3>

        <p class="mt-2 text-sm">{{ $t('about.recommendations.notes.message') }}</p>
      </div>

      <div class="mt-4 pr-2 w-1/2">
        <PartsSVG class="fill-white-1 w-6 h-6" />

        <h3 class="text-base text-white-1 mt-2">
          {{ $t('about.recommendations.parts.title') }}
        </h3>

        <p class="mt-2 text-sm">{{ $t('about.recommendations.parts.message') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import AnonymousSVG from '@/assets/svgs/anonymous.svg';
import PartsSVG from '@/assets/svgs/parts.svg';
import NotesSVG from '@/assets/svgs/notes.svg';
import VideoSVG from '@/assets/svgs/video.svg';
import CursorSVG from '@/assets/svgs/cursor.svg';

export default {
  name: 'Recommendations',
  components: { AnonymousSVG, PartsSVG, NotesSVG, VideoSVG, CursorSVG },
};
</script>

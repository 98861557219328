var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"list-style-none flex items-end text-white-6 text-xs w-full"},_vm._l((_vm.partsCount),function(tick){return _c('li',{key:tick,staticClass:"border-y-0 border-r-0 border-l border-solid border-l-white-6 relative",class:[
      {
        'hidden': _vm.isTickHidden(tick),
        'h-6': _vm.isBigTick(tick),
        'h-4': !_vm.isBigTick(tick) && _vm.isMediumTick(tick),
        'h-3': !_vm.isBigTick(tick) && !_vm.isMediumTick(tick),
      },
    ],style:(`width: ${tick === _vm.partsCount ? 1 : _vm.partSize}px;`)},[(_vm.shouldRenderTime(tick))?_c('span',{staticClass:"absolute left-0 top-full -translate-x-1/2"},[_vm._v(" "+_vm._s(_vm.formatTick(tick))+" ")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
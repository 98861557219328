export default function (options = {}) {
  const defaultOptions = { languageCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  const result = browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });

  if (opt.first) {
    return result.find((l) => l !== 'en') || result[0];
  }

  return result;
}

<template>
  <ul :class="['fixed', 'right-0', 'top-0', 'h-full', 'w-110', 'events-none', 'z-11', 'overflow-hidden']">
    <ul class="list-style-none v-full h-full overflow-y-auto overflow-x-inherit">
      <transition-group name="slide-left">
        <NotificationItem v-for="item in items" :key="item.id" v-bind="item" @close="close" />
      </transition-group>
    </ul>
  </ul>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import NotificationItem from '@/components/NotificationItem.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

export default {
  name: 'Notifications',
  components: { NotificationItem },
  mixins: [ErrorNotificationsMixin],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState(['notifications', 'uploadingErrors', 'uploadingFilesErrors']),
  },
  methods: {
    ...mapMutations(['setKey', 'deleteNotification']),
    close(id) {
      this.items = this.items.filter((i) => i.id !== id);

      this.setKey({ key: 'notifications', notifications: this.items });
    },
  },
  watch: {
    notifications: {
      immediate: true,
      handler(value) {
        if (JSON.stringify(value) === JSON.stringify(this.items)) {
          return;
        }

        this.items = JSON.parse(JSON.stringify(value));
      },
    },
    uploadingErrors(value, oldValue) {
      const newErrors = value.filter((error) => !oldValue.includes(error));

      newErrors.forEach((error) => {
        error = Array.isArray(error) ? [...error] : [error];
        error.push('contact_support');

        this.notificationFromError(error);
      });
    },
    uploadingFilesErrors(value, oldValue) {
      const errorsEqual = (errorA, errorB) =>
        ((errorA.message.join && errorA.message.join('')) || errorA.mesage) ===
        ((errorB.message.join && errorB.message.join('')) || errorB.message);

      const fixedErrors = oldValue.filter((oldError) => !value.some((newError) => errorsEqual(oldError, newError)));
      const newErrors = value.filter((newError) => !oldValue.some((oldError) => errorsEqual(oldError, newError)));

      fixedErrors.forEach((error) => {
        const notification = this.notifications.find(
          (n) => n.code.replace(/::contact_support$/, '') === this.notificationCodeFromError(error.message),
        );

        if (!notification) {
          return;
        }

        this.deleteNotification({ id: notification.id });
      });

      newErrors.forEach((error) => {
        let message = error.message;
        message = Array.isArray(message) ? [...message] : [message];
        message.push('contact_support');

        const notificationType = error.type === 'warning' ? 'warning' : 'error';

        this.notificationFromError(message, { notificationType });
      });
    },
  },
};
</script>

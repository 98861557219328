<template>
  <div>
    <nav class="text-center overflow-x-auto rotate-180">
      <ol class="list-style-none inline-flex items-end justify-center relative rotate-180">
        <li v-for="childItem in items" v-show="childItem.visible" :key="childItem.value" class="mx-6">
          <SlotComponent
            v-if="childItem.$scopedSlots.trigger"
            :component="childItem"
            name="trigger"
            tag="button"
            type="button"
            :ref="`trigger-${childItem.value}`"
            :class="triggerClasses(childItem)"
            :disabled="childItem.disabled"
            @click="childClick(childItem)"
            @mouseenter="!childItem.isActive && moveUnderlineFromEvent($event)"
            @mouseleave="!childItem.isActive && resetUnderline($event)"
            @focusin="!childItem.isActive && moveUnderlineFromEvent($event)"
            @focusout="!childItem.isActive && resetUnderline($event)"
          />

          <button
            v-else
            type="button"
            :ref="`trigger-${childItem.value}`"
            class="text-white-1 font-black py-2"
            :class="triggerClasses(childItem)"
            :disabled="childItem.disabled"
            @click="childClick(childItem)"
            @mouseenter="!childItem.isActive && moveUnderlineFromEvent($event)"
            @mouseleave="!childItem.isActive && resetUnderline($event)"
            @focusin="!childItem.isActive && moveUnderlineFromEvent($event)"
            @focusout="!childItem.isActive && resetUnderline($event)"
          >
            {{ childItem.label }}
          </button>
        </li>

        <li
          :class="[
            $style.underline,
            'absolute',
            'left-0',
            'bottom-0',
            'w-0',
            'border-b-0',
            'border-x-0',
            'border-t-2',
            'border-solid',
            'border-accent',
          ]"
          :style="underlineCss"
        />
      </ol>
    </nav>

    <section
      class="relative mt-4 flex flex-column"
      :class="[
        {
          'overflow-hidden': isTransitioning,
          'overflow-visible': !isTransitioning,
        },
      ]"
    >
      <slot />
    </section>
  </div>
</template>

<script>
import SlotComponent from '@/components/SlotComponent.vue';

export default {
  name: 'Tabs',
  provide() {
    return {
      Tabs: this,
    };
  },
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  components: { SlotComponent },
  props: {
    customValue: {
      type: String,
      default: undefined,
    },
    animated: {
      type: Boolean,
      default: true,
    },
    destroyOnHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeId: this.customValue,
      isTransitioning: false,
      childItems: [],
      tmpUnderlineCss: null,
      activeUnderlineCss: {},
    };
  },
  computed: {
    underlineCss() {
      if (this.tmpUnderlineCss) {
        return this.tmpUnderlineCss;
      }

      return this.activeUnderlineCss;
    },
    activeItem() {
      if (this.activeId === undefined) {
        return this.items[0];
      }

      if (this.activeId === null) {
        return null;
      }

      return this.childItems.find((i) => i.value === this.activeId);
    },
    items() {
      return this.childItems.slice().sort((i1, i2) => i1.index - i2.index);
    },
  },
  methods: {
    triggerClasses(item) {
      return [
        'border-transparent',
        'bg-transparent',
        'outline-none',
        {
          'opacity-5': !item.isActive,
          'hover:opacity-3': !item.isActive && !item.disabled,
          'focus:opacity-3': !item.isActive && !item.disabled,
        },
      ];
    },
    childClick(child) {
      this.activeId = child.value;

      if (this.tmpUnderlineCss) {
        this.activeUnderlineCss = this.tmpUnderlineCss;
        this.tmpUnderlineCss = null;
      } else {
        this.moveUnderline();
      }
    },
    _registerItem(item) {
      item.activate(this.childItems.findIndex((it) => it.value === this.activeId));
      this.childItems.push(item);

      this.moveUnderline();
    },
    _unregisterItem(item) {
      this.childItems = this.childItems.filter((it) => it !== item);

      if (item.value === this.activeId) {
        const activeItem = this.items.find((it) => !it.disabled && it.visible);

        if (activeItem) {
          this.activeId = activeItem.value;
        }
      }

      this.moveUnderline();
    },
    moveUnderlineFromEvent(event) {
      this.moveUnderline(event.target);
    },
    moveUnderline(target = null, force = false) {
      if (!target) {
        if (!this.activeId) {
          return;
        }

        target = this.$refs[`trigger-${this.activeId}`];

        if (!target || target.length === 0) {
          return;
        }

        if (Array.isArray(target)) {
          target = target[0];
        }

        if (target._isVue) {
          target = target.$el;
        }

        force = true;
      }

      const parent = target.parentNode;

      const left = parent.offsetLeft;
      const width = target.clientWidth;
      const css = { left: `${left}px`, width: `${width}px` };

      if (force) {
        this.activeUnderlineCss = css;
      } else {
        this.tmpUnderlineCss = css;
      }
    },
    resetUnderline() {
      this.tmpUnderlineCss = null;
    },
  },
  watch: {
    customValue(value) {
      this.activeId = value;
    },
    activeId(val, oldValue) {
      const oldTab =
        oldValue !== undefined && oldValue !== null ? this.childItems.find((i) => i.value === oldValue) : null;

      if (oldTab && this.activeItem) {
        oldTab.deactivate(this.activeItem.index);
        this.activeItem.activate(oldTab.index);
      }

      if (this.activeItem) {
        val = this.activeItem.value;
      } else {
        val = undefined;
      }

      if (val !== this.customValue) {
        this.$emit('customInput', val);
      }

      this.moveUnderline();
    },
    childItems(items) {
      if (items.length > 0 && this.$scopedSlots.default) {
        const tag = items[0].$vnode.tag;
        let index = 0;

        const deepSearch = (children) => {
          for (const child of children) {
            if (child.tag === tag) {
              const item = items.find((i) => i.$vnode === child);

              if (item) {
                item.index = index++;
              }
            } else if (child.tag) {
              let sub;

              if (child.componentInstance) {
                if (child.componentInstance.$scopedSlots.default) {
                  sub = child.componentInstance.$scopedSlots.default();
                } else {
                  sub = child.componentInstance.$children;
                }
              } else {
                sub = child.children;
              }

              if (Array.isArray(sub) && sub.length > 0) {
                deepSearch(sub.map((e) => e.$vnode));
              }
            }
          }

          return false;
        };

        deepSearch(this.$scopedSlots.default());
      }
    },
  },
  mounted() {
    if (!this.activeId) {
      return;
    }

    const activeChild = this.childItems.find((child) => child.value === this.activeId);
    activeChild.activate(0);

    setTimeout(() => {
      this.moveUnderline();
    }, 0);
  },
};
</script>

<style lang="scss" module>
.underline {
  will-change: left, width;
  transition:
    left 0.3s ease-in-out,
    width 0.3s ease-in-out;
}
</style>

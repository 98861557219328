<template>
  <div class="flex items-start w-full text-center">
    <div class="flex-none w-1/4 px-2">
      <p class="text-xs font-bold">{{ $t('speaker') }}</p>
      <p class="text-white-1 font-light">{{ speaker }}</p>
    </div>

    <div class="flex-none w-1/4 px-2">
      <p class="text-xs font-bold">{{ $t('share.uploaded_at') }}</p>
      <p class="text-white-1 font-light">{{ $d(new Date(uploadedAt), 'long') }}</p>
    </div>

    <div class="flex-none w-1/4 px-2">
      <p class="text-xs font-bold">{{ $t('share.final_version') }}</p>
      <p class="text-white-1 font-light">{{ isFinal ? $t('yes') : $t('no') }}</p>
    </div>

    <div class="flex-none w-1/4 px-2">
      <p class="text-xs font-bold">{{ $t('share.share_open_count') }}</p>
      <p class="text-white-1 font-light">{{ openCount }}×</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareDetails',
  props: {
    speaker: {
      type: String,
      required: true,
    },
    uploadedAt: {
      type: String,
      required: true,
    },
    isFinal: {
      type: Boolean,
      default: false,
    },
    openCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>

import Logger from '@/modules/logger';

import { loadSpeakerLocalVersions } from '@/helpers';

export default function (store, { id }) {
  const { state, commit } = store;

  return loadSpeakerLocalVersions(store, state.recorderToken, id).then((versions) => {
    Logger.log(`Load speaker local versions, id: ${id}, local data:`, versions);

    commit('setKey', { key: 'versions', versions });
  });
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"text-center overflow-x-auto rotate-180"},[_c('ol',{staticClass:"list-style-none inline-flex items-end justify-center relative rotate-180"},[_vm._l((_vm.items),function(childItem){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(childItem.visible),expression:"childItem.visible"}],key:childItem.value,staticClass:"mx-6"},[(childItem.$scopedSlots.trigger)?_c('SlotComponent',{ref:`trigger-${childItem.value}`,refInFor:true,class:_vm.triggerClasses(childItem),attrs:{"component":childItem,"name":"trigger","tag":"button","type":"button","disabled":childItem.disabled},on:{"click":function($event){return _vm.childClick(childItem)},"mouseenter":function($event){!childItem.isActive && _vm.moveUnderlineFromEvent($event)},"mouseleave":function($event){!childItem.isActive && _vm.resetUnderline($event)},"focusin":function($event){!childItem.isActive && _vm.moveUnderlineFromEvent($event)},"focusout":function($event){!childItem.isActive && _vm.resetUnderline($event)}}}):_c('button',{ref:`trigger-${childItem.value}`,refInFor:true,staticClass:"text-white-1 font-black py-2",class:_vm.triggerClasses(childItem),attrs:{"type":"button","disabled":childItem.disabled},on:{"click":function($event){return _vm.childClick(childItem)},"mouseenter":function($event){!childItem.isActive && _vm.moveUnderlineFromEvent($event)},"mouseleave":function($event){!childItem.isActive && _vm.resetUnderline($event)},"focusin":function($event){!childItem.isActive && _vm.moveUnderlineFromEvent($event)},"focusout":function($event){!childItem.isActive && _vm.resetUnderline($event)}}},[_vm._v(" "+_vm._s(childItem.label)+" ")])],1)}),_c('li',{class:[
          _vm.$style.underline,
          'absolute',
          'left-0',
          'bottom-0',
          'w-0',
          'border-b-0',
          'border-x-0',
          'border-t-2',
          'border-solid',
          'border-accent',
        ],style:(_vm.underlineCss)})],2)]),_c('section',{staticClass:"relative mt-4 flex flex-column",class:[
      {
        'overflow-hidden': _vm.isTransitioning,
        'overflow-visible': !_vm.isTransitioning,
      },
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
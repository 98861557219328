export default {
  id: null,
  recorded_at: null,

  camera_enabled: false,
  microphone_enabled: false,
  screen_sharing_enabled: false,

  speaker_recording_file_id: null,
  slides_recording_file_id: null,

  speaker_slides_recording_start_diff_ms: 0,
  speaker_slides_recording_stop_diff_ms: 0,
};

import { v4 as uuidv4 } from 'uuid';

export default function (state, payload) {
  if (payload.uniq) {
    const notification = state.notifications.find((n) => n.code === payload.code);

    if (notification) {
      return notification.id;
    }

    delete payload.uniq;
  }

  const id = uuidv4();
  const notification = {
    ...payload,
    id,
  };

  state.notifications = [...state.notifications, notification];

  return id;
}

<template>
  <Form class="container-sm mx-auto text-center" :disabled="disabled" @submit="submit">
    <h2 class="text-white-1">{{ $t('post_pro_note.add') }}</h2>

    <Input
      v-model="computedNote"
      name="note"
      tag="textarea"
      class="resize-vertical min-h-25 block w-full text-left mt-3"
      :readonly="disabled"
      :placeholder="$t('post_pro_note.placeholder')"
      :validateOnBlur="false"
    />

    <Button
      class="w-40 mt-3"
      color="btn-accent"
      type="submit"
      :disabled="disabled"
      :label="submitLabel"
      :loading="saving"
    />
  </Form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';

import { upsertJSONFile } from '@/helpers';

export default {
  name: 'PostProNote',
  components: { Button, Form, Input },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      note: '',
      saved: false,
      saving: false,
    };
  },
  computed: {
    ...mapState(['currentVersionId']),
    ...mapGetters(['currentVersion']),
    submitLabel() {
      if (this.saved) {
        return this.$i18n.t('saved');
      }

      return this.$i18n.t('save');
    },
    computedNote: {
      get() {
        return this.note;
      },
      set(value) {
        this.note = value.trim();
        this.saved = false;
      },
    },
  },
  methods: {
    ...mapMutations(['updateVersion']),
    submit() {
      this.saving = true;

      this.updateVersion({
        id: this.currentVersionId,
        post_production_note: this.note,
      });

      upsertJSONFile(this.currentVersion).then(() => {
        this.saved = true;
        this.saving = false;
      });
    },
  },
  watch: {
    currentVersionId() {
      this.computedNote = this.currentVersion.post_production_note;
      this.saved = true;
    },
  },
  mounted() {
    this.computedNote = this.currentVersion.post_production_note;
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'bg-white-7',
    'text-center',
    'w-full',
    'p-4',
    'min-h-30',
    'flex',
    'flex-column',
    'items-center',
    'justify-center',
    'z-8',
  ]},[_c('h1',{staticClass:"text-base text-white-1"},[(_vm.percent < 100)?_c('span',[_vm._v(_vm._s(_vm.$t('upload.uploading_to_cloud')))]):_c('span',[_vm._v(_vm._s(_vm.$t('upload.uploaded')))])]),_c('div',{staticClass:"flex items-center mt-2"},[_c('div',{staticClass:"bg-white-8 rounded-full h-2 relative w-86 mx-auto"},[_c('div',{staticClass:"rounded-full h-full absolute left-0 top-0",class:[
          {
            'bg-primary': _vm.percent < 100,
            'bg-green': _vm.percent === 100,
          },
        ],style:(_vm.progressBarActiveStyle)})]),_c('p',{staticClass:"text-white-1 font-black ml-5",class:[{ 'text-green': _vm.percent === 100 }]},[_vm._v(_vm._s(_vm.percentText)+" %")]),(_vm.percent < 100)?_c('Tooltip',[_c('p',{staticClass:"text-sm text-left ml-1"},[_vm._v(" "+_vm._s(_vm.$t('upload.speed'))+": "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.humanFileSize(this.uploadingProgress.totalSpeed))+"/s")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('upload.eta'))+": "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.formatTime(this.uploadingProgress.timeRemaining)))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('upload.files'))+": "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(this.uploadingProgress.totalFiles - this.uploadingProgress.pendingFiles)+" / "+_vm._s(this.uploadingProgress.totalFiles)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('upload.size'))+": "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.humanFileSize(this.uploadingProgress.totalUploadedBytes))+" / "+_vm._s(_vm.humanFileSize(this.uploadingProgress.totalBytes))+" ")])])]):_vm._e()],1),(_vm.percent < 100)?_c('p',{staticClass:"text-yellow font-black flex items-center mt-1"},[_c('WarningFullSVG',{staticClass:"fill-yellow w-6 h-6"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('upload.dont_close_browser')))])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
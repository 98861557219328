<template>
  <div class="flex self-stretch p-4 w-full rounded-scrollbar">
    <div class="flex-1 flex flex-column h-full py-10 overflow-auto relative">
      <Back />

      <div class="text-center container-sm m-auto">
        <h2 class="text-3xl text-white-1 text-center">{{ $t('recorded_tokens.title') }}</h2>

        <p class="font-light mt-3">{{ $t('recorded_tokens.message') }}</p>

        <RecordedTokensTable class="mt-6" />
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/Back.vue';
import RecordedTokensTable from '@/components/RecordedTokensTable.vue';

export default {
  name: 'RecordedTokens',
  components: { Back, RecordedTokensTable },
};
</script>

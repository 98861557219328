<template>
  <div class="text-center py-1">
    <h1 class="text-2xl text-white-1">
      {{ $t('upload_confirm_speakers.title') }}
    </h1>

    <Button
      v-if="!sortable"
      :loading="inProgress"
      :label="$t('speakers_order.reorder')"
      color="btn-primary-outline"
      class="w-64 mx-auto mt-6"
      block
      @click="enableSorting"
    />

    <Button
      v-else
      :loading="inProgress"
      :label="$t('save')"
      color="btn-primary-outline"
      class="w-64 mx-auto mt-6"
      block
      @click="saveOrder"
    />

    <SpeakersList :speakers="newSpeakers" :sortable="sortable" class="mt-3 mx-auto w-120 px-16" @sorted="sorted" />

    <Button
      class="w-68 mt-6"
      :class="sortable && 'hidden'"
      :loading="inProgress"
      :label="$t('upload_confirm_speakers.confirm')"
      @click="confirm"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SpeakersList from '@/components/SpeakersList.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'ConfirmSpeakersOrder',
  components: { SpeakersList, Button },
  computed: {
    ...mapState(['speakers']),
  },
  data() {
    return {
      inProgress: false,
      sortable: false,
      newSpeakers: [],
    };
  },
  methods: {
    ...mapActions(['updateSpeakersOrder']),
    enableSorting() {
      this.sortable = true;
    },
    sorted(speakers) {
      this.newSpeakers = speakers;
    },
    saveOrder() {
      this.inProgress = true;

      const order = this.newSpeakers.map((s) => s.id);

      this.updateSpeakersOrder({ order }).finally(() => {
        this.sortable = false;
        this.inProgress = false;
      });
    },
    confirm() {
      this.$emit('continue');
    },
  },
  mounted() {
    this.newSpeakers = JSON.parse(JSON.stringify(this.speakers));
  },
};
</script>

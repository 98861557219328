<template>
  <div class="flex self-stretch p-4 w-full rounded-scrollbar">
    <EventOverview hideRegister />

    <div class="flex-1 flex flex-column h-full py-10 overflow-auto relative">
      <Back />

      <div class="text-center m-auto">
        <h2 class="text-3xl text-white-1 text-center">{{ $t('register.title') }}</h2>

        <p class="font-light mt-3">{{ $t('register.message') }}</p>

        <RegisterForm class="mt-6" />
      </div>
    </div>
  </div>
</template>

<script>
import EventOverview from '@/components/EventOverview.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import Back from '@/components/Back.vue';

export default {
  name: 'Register',
  components: { EventOverview, RegisterForm, Back },
};
</script>

<template>
  <div class="flex-1 flex flex-column self-stretch w-full">
    <Header :hideDeadline="hideDeadline" class="relative z-9" />

    <div class="flex-1 flex flex-column w-full overflow-auto relative">
      <transition :name="uploadProgressTransitionName">
        <UploadProgress v-if="showUpload" />
      </transition>

      <Back v-if="!showUpload && !hideBack && !noHistoryToGoTo" class="mt-4 lg:ml-7 z-8" />

      <div class="flex-1 flex flex-column px-4 w-full max-h-full relative">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Header.vue';
import Back from '@/components/Back.vue';
import UploadProgress from '@/components/UploadProgress.vue';

export default {
  name: 'ContainerWithHeaderAndBack',
  components: { Header, Back, UploadProgress },
  props: {
    hideBack: {
      type: Boolean,
      default: false,
    },
    hideDeadline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadProgressTransitionName: 'slide-down',
    };
  },
  computed: {
    ...mapState(['isUploadVisible', 'initialHistoryLength']),
    showUpload() {
      return (
        this.isUploadVisible && ['Setup', 'Record', 'SelfRecording', 'SelfRecordingUpload'].includes(this.$route.name)
      );
    },
    noHistoryToGoTo() {
      return window.history.length - this.initialHistoryLength === 0;
    },
  },
  watch: {
    isUploadVisible() {
      setTimeout(() => {
        this.uploadProgressTransitionName = this.isUploadVisible ? 'slide-up' : 'slide-down';
      }, 500);
    },
  },
};
</script>

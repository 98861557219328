/* eslint-disable camelcase */

import Logger from '@/modules/logger';

import { upsertJSONFile, makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ commit, state }, { id: idToRename, version_name }) {
  const version = state.versions[idToRename];

  Logger.log(
    // eslint-disable-next-line max-len
    `Rename version, id: ${idToRename}, old name: ${version.version_name}, new name: ${version_name}`,
  );

  const doRename = () => {
    commit('updateVersion', { id: idToRename, version_name });

    return upsertJSONFile(state.versions[idToRename]).then(() => state.versions[idToRename]);
  };

  if (!version.upload_id) {
    return doRename();
  }

  const upload = state.uploads.find((u) => u.id === version.upload_id);

  if (!upload) {
    return doRename();
  }

  const requestData = {
    upload_token: upload.upload_token,
    token_upload: {
      name: version_name,
    },
  };

  return makeRequest('PATCH', `${API_URL}/token_uploads/${upload.id}`, requestData).then((response) => {
    commit('updateUpload', response.data);
    return doRename();
  });
}

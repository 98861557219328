import Logger from '@/modules/logger';

export default function ({ commit, dispatch, state, getters }) {
  if (state.currentVersionId && getters.currentVersion) {
    Logger.log(`Init current version, already exists, id: ${state.currentVersionId}`);

    return Promise.resolve(getters.currentVersion);
  }

  if (getters.sortedVersions.length) {
    const submittedVersion = getters.sortedVersions.find(
      (v) => v.has_local_data && !v.broken_data && v.final_recording,
    );
    const localVersions = getters.sortedVersions.filter(
      (v) => v.has_local_data && !v.broken_data && !v.final_recording,
    );
    const lastVersion = localVersions.length > 0 ? localVersions[0] : null;

    if (lastVersion || submittedVersion) {
      let newCurrentVersion = lastVersion || submittedVersion;

      if (lastVersion && submittedVersion) {
        newCurrentVersion = lastVersion.created_at > submittedVersion.created_at ? lastVersion : submittedVersion;
      }

      commit('setKey', {
        key: 'currentVersionId',
        currentVersionId: newCurrentVersion.id,
      });

      Logger.log(`Init current version, id: ${newCurrentVersion.id}`);

      return Promise.resolve(newCurrentVersion);
    }
  }

  return dispatch('addVersion').then((version) => {
    commit('setKey', { key: 'currentVersionId', currentVersionId: version.id });

    Logger.log(`Init current version, new one, id: ${version.id}`);

    return version;
  });
}

<template>
  <div class="relative z-0">
    <slot :stream="speakerStream" :enabled="cameraEnabled" :open="open" :close="closeCurrentStream"></slot>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Logger from '@/modules/logger';

export default {
  name: 'SpeakerStream',
  data() {
    return {
      audioSkipWatch: false,
      videoSkipWatch: false,
      openTriggeredAt: null,
    };
  },
  computed: {
    ...mapState([
      'cameraId',
      'microphoneId',
      'speakerStream',
      'cameraRequested',
      'cameraEnabled',
      'microphoneRequested',
      'microphoneEnabled',
      'devices',
    ]),
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification']),
    buildConstraintsVariants() {
      const updateDeviceIdsInConstraints = (constraints) => {
        if (!this.microphoneRequested) {
          constraints.audio = false;
        } else if (this.microphoneId && this.microphoneId !== '') {
          constraints.audio = { deviceId: this.microphoneId };
        }

        if (!this.cameraRequested) {
          constraints.video = false;
        } else if (this.cameraId && this.cameraId !== '') {
          constraints.video.deviceId = this.cameraId;
        }

        return constraints;
      };

      const variants = [];

      variants.push(
        updateDeviceIdsInConstraints({
          audio: true,
          video: {
            aspectRatio: {
              ideal: 16 / 9.0,
              min: 4 / 3.0,
            },
            frameRate: {
              ideal: 25,
              max: 30,
            },
            width: {
              max: 1920,
              ideal: 1280,
              min: 320,
            },
            height: {
              max: 1080,
              ideal: 720,
              min: 240,
            },
          },
        }),
      );

      variants.push(updateDeviceIdsInConstraints({ audio: true, video: {} }));

      return variants;
    },
    open() {
      const now = window.performance.now();
      this.openTriggeredAt = now;

      if (!this.cameraRequested && !this.microphoneRequested) {
        this.updateStream(null);
        return;
      }

      this.setKey({
        key: 'isWaitingForSpeakerStream',
        isWaitingForSpeakerStream: true,
      });
      this.clearError('camera');
      this.clearError('microphone');

      const constraintsVariants = this.buildConstraintsVariants();
      let lastFailedConstraintIndex = -1;

      const openStream = (constraintsVariantIndex) => {
        if (now !== this.openTriggeredAt) {
          return;
        }

        const constraints = constraintsVariants[constraintsVariantIndex];

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            if (now !== this.openTriggeredAt) {
              return null;
            }

            const cameraRecognized =
              !this.cameraRequested || this.devices.some((d) => d.kind === 'videoinput' && d.deviceId !== '');
            const microphoneRecognized =
              !this.microphoneRequested || this.devices.some((d) => d.kind === 'audioinput' && d.deviceId !== '');

            if (cameraRecognized && microphoneRecognized) {
              return stream;
            }

            if (lastFailedConstraintIndex !== constraintsVariantIndex) {
              lastFailedConstraintIndex = constraintsVariantIndex;

              return this.updateDevices().then(() => {
                openStream(constraintsVariantIndex);
                return null;
              });
            }

            if (!cameraRecognized) {
              this.addNotification({
                type: 'error',
                code: 'no_camera',
                closeable: true,
              });
            }

            if (!microphoneRecognized) {
              this.addNotification({
                type: 'error',
                code: 'no_microphone',
                closeable: true,
              });
            }

            return null;
          })
          .then((stream) => {
            if (!stream) {
              return;
            }

            const streamDevices = this.getStreamDevices(stream);

            if (
              (streamDevices.video &&
                constraints.video &&
                constraints.video.deviceId &&
                streamDevices.video !== constraints.video.deviceId) ||
              (streamDevices.audio &&
                constraints.audio &&
                constraints.audio.deviceId &&
                streamDevices.audio !== constraints.audio.deviceId)
            ) {
              Logger.log(`SpeakerStream: stream constraints not met, using more benevolent ones.`);

              openStream(constraintsVariantIndex + 1);
              return;
            }

            this.updateStream(stream);
            this.setKey({
              key: 'isWaitingForSpeakerStream',
              isWaitingForSpeakerStream: false,
            });
          })
          .catch((error) => {
            if (now !== this.openTriggeredAt) {
              return;
            }

            Logger.log(
              `SpeakerStream: getUserMedia(${constraintsVariantIndex + 1}/${
                constraintsVariants.length
              }); Constraints: ${JSON.stringify(constraints)}; Error: ${JSON.stringify(error)}`,
            );

            if (constraintsVariantIndex >= constraintsVariants.length - 1) {
              this.setKey({ key: 'isRecording', isRecording: false });
              this.setKey({ key: 'isWaitingForStreams', isWaitingForStreams: false });

              const cameraDeclined = this.cameraRequested !== this.cameraEnabled;
              const microphoneDeclined = this.microphoneRequested !== this.microphoneEnabled;

              if (cameraDeclined) {
                this.setKey({ key: 'cameraRequested', cameraRequested: false });
                this.setKey({ key: 'cameraEnabled', cameraEnabled: false });

                let content = this.$i18n.t('errors.camera_declined');
                content += '<br />';
                content += this.$i18n.t('errors.how_to_enable_camera_microphone');

                this.addNotification({
                  type: 'warning',
                  content,
                  code: 'camera_declined',
                  closeable: true,
                  closeAfterS: 8,
                });
              }

              if (microphoneDeclined) {
                this.setKey({ key: 'microphoneRequested', microphoneRequested: false });
                this.setKey({ key: 'microphoneEnabled', microphoneEnabled: false });

                let content = this.$i18n.t('errors.microphone_declined');
                content += '<br />';
                content += this.$i18n.t('errors.how_to_enable_camera_microphone');

                this.addNotification({
                  type: 'warning',
                  content,
                  code: 'microphone_declined',
                  closeable: true,
                  closeAfterS: 8,
                });
              }

              if (cameraDeclined && microphoneDeclined) {
                this.updateStream(null);
              }
            } else {
              setTimeout(() => openStream(constraintsVariantIndex + 1));
            }

            this.setKey({
              key: 'isWaitingForSpeakerStream',
              isWaitingForSpeakerStream: false,
            });
          });
      };

      if (this.devices.length > 0) {
        openStream(0);
      } else {
        this.updateDevices().then(() => {
          openStream(0);
        });
      }
    },
    closeCurrentStream() {
      if (this.speakerStream) {
        for (const track of this.speakerStream.getTracks()) {
          track.stop();
        }
      }

      this.setKey({ key: 'speakerStream', speakerStream: null });
    },
    updateStream(stream = null) {
      this.closeCurrentStream();

      const tracks = this.checkTracksFromStream(stream);
      const hasVideoTrack = !!tracks.video;
      const hasAudioTrack = !!tracks.audio;

      this.setKey({ key: 'cameraRequested', cameraRequested: hasVideoTrack });
      this.setKey({ key: 'cameraEnabled', cameraEnabled: hasVideoTrack });
      this.setKey({
        key: 'microphoneRequested',
        microphoneRequested: hasAudioTrack,
      });
      this.setKey({ key: 'microphoneEnabled', microphoneEnabled: hasAudioTrack });
      this.setKey({ key: 'speakerStream', speakerStream: stream });

      Logger.log(`SpeakerStream: Set; Video: ${hasVideoTrack}; Audio: ${hasAudioTrack}`);

      this.updateErrors(tracks);
      this.addAudioTrackListeners(tracks.audio);
    },
    checkTracksFromStream(stream) {
      const result = {
        audio: undefined,
        video: undefined,
      };

      if (!stream) {
        return result;
      }

      for (const track of stream.getTracks()) {
        const trackSettings = track.getSettings();
        const storeKey = `${track.kind === 'video' ? 'camera' : 'microphone'}Id`;

        result[track.kind] = track;

        if (this[storeKey] !== trackSettings.deviceId) {
          Logger.log(`SpeakerStream: Update ${track.kind} device; ID: ${trackSettings.deviceId}`);

          const skipKey = `${track.kind}SkipWatch`;

          this[skipKey] = true;
          this.setKey({ key: storeKey, [storeKey]: trackSettings.deviceId });
        }
      }

      return result;
    },
    getStreamDevices(stream) {
      const result = {
        audio: undefined,
        video: undefined,
      };

      if (!stream) {
        return result;
      }

      for (const track of stream.getTracks()) {
        const trackSettings = track.getSettings();
        result[track.kind] = trackSettings.deviceId;
      }

      return result;
    },
    updateErrors(tracks) {
      if (!this.cameraEnabled) {
        this.setError('camera', 'camera_disabled');
      } else if (!this.cameraId || !tracks.video) {
        this.setError('camera', 'no_camera');
      } else {
        this.clearError('camera');
      }

      if (!this.microphoneEnabled) {
        this.setError('microphone', 'microphone_disabled');
      } else if (!this.microphoneId || !tracks.audio) {
        this.setError('microphone', 'no_microphone');
      } else if (tracks.audio && tracks.audio.muted) {
        this.setError('microphone', 'microphone_muted');
      } else {
        this.clearError('microphone');
      }
    },
    clearError(kind) {
      const storeKey = `${kind}Errors`;

      this.setKey({ key: storeKey, [storeKey]: [] });
    },
    setError(kind, key) {
      const storeKey = `${kind}Errors`;
      const errors = [];

      Logger.log(`SpeakerStream: Error: ${kind}, ${key}`);

      errors.push(key);

      this.setKey({ key: storeKey, [storeKey]: errors });
    },
    addAudioTrackListeners(track) {
      if (!track) {
        return;
      }

      track.onmute = () => {
        this.setError('audio', 'muted_audio');
      };

      track.onunmute = () => {
        this.clearError('audio');
      };
    },
    updateDevices() {
      return navigator.mediaDevices.enumerateDevices().then((devices) => {
        Logger.log(`SpeakerStream: Update devices. Devices: ${JSON.stringify(devices)}`);

        const cameraStillConnected = devices.some((d) => d.deviceId === this.cameraId);
        const microphoneStillConnected = devices.some((d) => d.deviceId === this.microphoneId);

        if (!cameraStillConnected || !microphoneStillConnected) {
          if (!cameraStillConnected) {
            Logger.log(`SpeakerStream: camera in action disconnected, using default one.`);

            const cameras = devices.filter((d) => d.kind === 'videoinput');
            const camera = cameras.find((d) => d.deviceId === 'default') || cameras[0];

            this.videoSkipWatch = true;
            if (camera && camera.deviceId !== '') {
              this.setKey({ key: 'cameraId', cameraId: camera.deviceId });
            } else {
              Logger.log(`SpeakerStream: no default camera.`);
              this.setKey({ key: 'cameraId', cameraId: '' });
            }
          }

          if (!microphoneStillConnected) {
            Logger.log(`SpeakerStream: microphone in action disconnected, using default one.`);

            const microphones = devices.filter((d) => d.kind === 'audioinput');
            const microphone = microphones.find((d) => d.deviceId === 'default') || microphones[0];

            this.audioSkipWatch = true;
            if (microphone && microphone.deviceId !== '') {
              this.setKey({ key: 'microphoneId', microphoneId: microphone.deviceId });
            } else {
              Logger.log(`SpeakerStream: no default microphone.`);
              this.setKey({ key: 'microphoneId', microphoneId: '' });
            }
          }

          this.open();
        }

        this.setKey({ key: 'devices', devices });
      });
    },
  },
  watch: {
    cameraRequested() {
      this.open();
    },
    microphoneRequested() {
      this.open();
    },
    microphoneId() {
      if (this.audioSkipWatch) {
        this.audioSkipWatch = false;
        return;
      }

      this.open();
    },
    cameraId() {
      if (this.videoSkipWatch) {
        this.videoSkipWatch = false;
        return;
      }

      this.open();
    },
  },
  mounted() {
    navigator.mediaDevices.ondevicechange = this.updateDevices;

    if (!this.speakerStream && (this.cameraRequested || this.microphoneRequested)) {
      this.open();
    }
  },
  beforeDestroy() {
    this.closeCurrentStream();
    navigator.mediaDevices.ondevicechange = null;
  },
};
</script>

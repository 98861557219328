<template>
  <div class="absolute inset-0 group-focus-within:outline-accent">
    <video ref="video0" class="h-full w-full" :class="isVideo0Hidden && 'none'">
      <track kind="captions" />
    </video>

    <video ref="video1" class="h-full w-full" :class="isVideo1Hidden && 'none'">
      <track kind="captions" />
    </video>

    <div
      v-if="deleted"
      :class="[
        'w-full',
        'h-full',
        'bg-red-1',
        'text-white-1',
        'flex',
        'items-center',
        'justify-center',
        'text-lg',
        'text-center',
        'px-4',
      ]"
    >
      {{ $t('record.video_deleted') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerWindow',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVideo0Hidden() {
      return this.deleted || this.index % 2 === 1;
    },
    isVideo1Hidden() {
      return this.deleted || this.index % 2 === 0;
    },
  },
};
</script>

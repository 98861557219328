<template>
  <ContainerWithHeaderAndBack>
    <div class="m-auto py-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('self_recording.title') }}</h1>

      <p class="font-light text-center mt-3">{{ $t('self_recording.message') }}</p>

      <SelfRecordingRequirements class="mt-6 mx-auto w-120" />

      <router-link :to="{ name: 'SelfRecordingUpload' }" custom #default="{ href, navigate }">
        <Button
          :href="href"
          class="w-80 mt-6 mx-auto no-underline hover:no-underline"
          block
          :label="$t('self_recording.continue_to_upload')"
          @click="navigate"
        />
      </router-link>
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import SelfRecordingRequirements from '@/components/SelfRecordingRequirements.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'SelfRecording',
  components: { ContainerWithHeaderAndBack, SelfRecordingRequirements, Button },
};
</script>

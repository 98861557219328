const UNITS = [
  ['year', 24 * 60 * 60 * 1000 * 365],
  ['month', (24 * 60 * 60 * 1000 * 365) / 12],
  ['day', 24 * 60 * 60 * 1000],
  ['hour', 60 * 60 * 1000],
  ['minute', 60 * 1000],
  ['second', 1000],
];

export default function (date, { locale = 'en', from = new Date(), minUnit = 'second' } = {}) {
  const rtf = new Intl.RelativeTimeFormat(locale, {
    localeMatcher: 'lookup',
    numeric: 'auto',
  });
  const elapsed = (typeof date === 'string' ? new Date(date) : date) - from;

  for (let i = 0; i < UNITS.length; i++) {
    const unit = UNITS[i];

    if (Math.abs(elapsed) >= unit[1] || unit[0] === minUnit) {
      return {
        text: rtf.format(Math.round(elapsed / unit[1]), unit[0]),
        updateAtMs: unit[1] - (Math.abs(elapsed) % unit[1]),
        future: elapsed > 0,
      };
    }
  }

  return null;
}

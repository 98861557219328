import { v4 as uuidv4 } from 'uuid';

import Logger from '@/modules/logger';

import initialVersion from '../initial_version';

export default function ({ state, commit }, { id }) {
  Logger.log(
    `Merge speaker local versions and uploads, speaker id: ${id}, versions:`,
    state.versions,
    ', uploads:',
    state.uploads,
  );

  if (!state.uploads.length) {
    return Promise.resolve();
  }

  const speakerUploads = state.uploads.filter((u) => u.speaker_id === id);

  for (const upload of speakerUploads) {
    const versionId = Object.keys(state.versions).find((vId) => state.versions[vId].upload_id === upload.id);
    let versionData;

    if (versionId) {
      versionData = {
        id: versionId,

        upload_id: upload.id,
        recorded_at: upload.recorded_at,
        final_recording: upload.final_recording,
        upload_token: upload.upload_token,
        upload_complete: upload.upload_complete,
        share_link_url: upload.share_link_url,
      };
    } else {
      versionData = {
        ...initialVersion(),
        id: uuidv4(),
        recorder_token: state.recorderToken,
        speaker_id: id,

        has_local_data: false,

        created_at: upload.recorded_at,
        updated_at: upload.recorded_at,

        upload_id: upload.id,
        version_name: upload.name,
        recorded_at: upload.recorded_at,
        final_recording: upload.final_recording,
        upload_token: upload.upload_token,
        upload_complete: upload.upload_complete,
        share_link_url: upload.share_link_url,
      };
    }

    versionData.skipUpdatedAt = true;

    commit('updateVersion', versionData);
  }

  return Promise.resolve();
}

import createPersistedState from 'vuex-persistedstate';

import initialState from './initial_state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import modules from './modules';

// modules are not in the initialState, include them manually

const sessionIgnore = [
  'devices',
  'speakerStream',
  'cameraErrors',
  'microphoneErrors',
  'screenSharingStream',
  'screenSharingErrors',
  'speakerRecorderProgress',
  'screenSharingRecorderProgress',
  'notifications',
  'selfRecording',
  'speakerRecorder',
  'screenSharingRecorder',
  'recordingStartRequested',
  'isRecording',
  'isPaused',
  'isRestoring',
  'isWaitingForWebMFiles',
  'isWaitingForStreams',
  'isWaitingForSpeakerStream',
  'isWaitingForScreenSharingStream',
  'recordingCountdownValue',
  'isCountdownModalActive',
  'lastRecordingDuration',
  'recordingStartedAt',
  'recordingErrors',
  'isUploading',
  'isUploadVisible',
  'uploadingErrors',
  'uploadingFilesErrors',
  'uploadingProgress',
  'localDownloadStream',

  // data loaded on every refreh
  'versions',
  'localSpeakers',
  'recordedTokens',

  // data loaded from API
  'tokenId',
  'speakersToken',
  'supportEmail',
  'conferenceName',
  'presentationTitle',
  'presentationDurationS',
  'deadlineAt',
  'multiplePartsRecordingEnabled',
  'allowSelfUploadWithOneFile',
  'allowUploadWithoutAttachedFiles',
  // 'speakers', // data fetch is disabled on the share page - required when logged in for header
  'uploads',
];
const sessionKeys = Object.keys(initialState()).filter((key) => !sessionIgnore.includes(key));
const session = {
  storage: window.sessionStorage,
  paths: sessionKeys,
};

const localKeys = ['locale'];
const local = {
  storage: window.localStorage,
  paths: localKeys,
};

export default {
  state: initialState(),
  mutations,
  actions,
  getters,
  modules,
  plugins: [createPersistedState(session), createPersistedState(local)],
};

import Vue from 'vue';

import App from './App.vue';

import router from './router';
import store from './store';

import './plugins/sentry';
import i18n from './plugins/i18n';
import './plugins/markdown';
import './plugins/tippy';

import { registerDirectives } from './directives';
import { registerHooks } from './router/hooks';

import './styles/index.scss';

Vue.config.productionTip = false;

registerDirectives(Vue);
registerHooks(router, store, i18n);

store.commit('setAvailableLocales', { availableLocales: Object.keys(i18n.messages) });

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default app;

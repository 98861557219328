export default function ({ commit, state }) {
  return new Promise((resolve) => {
    if (!state.resumableUpload) {
      resolve();
      return;
    }

    if (state.resumableUpload.initialized) {
      state.resumableUpload.abortUpload();
    }

    commit('setKey', { key: 'resumableUpload', resumableUpload: null });
    resolve();
  });
}

let filterObjectKeys;

const filterArray = (array) => {
  return array.map((item) => {
    if (Array.isArray(item)) {
      return filterArray(item);
    }

    if (item !== null && typeof item === 'object') {
      return filterObjectKeys(item);
    }

    return item;
  });
};

filterObjectKeys = (obj) => {
  return Object.keys(obj).reduce((result, key) => {
    if (key === 'frontend') {
      return result;
    }

    if (Array.isArray(obj[key])) {
      result[key] = filterArray(obj[key]);
    } else if (obj[key] !== null && typeof obj[key] === 'object') {
      result[key] = filterObjectKeys(obj[key]);
    } else {
      result[key] = obj[key];
    }

    return result;
  }, {});
};

export default function (json) {
  json = JSON.parse(JSON.stringify(json));

  if (Array.isArray(json)) {
    return filterArray(json);
  }

  if (typeof json === 'object') {
    return filterObjectKeys(json);
  }

  return json;
}

<template>
  <div class="flex flex-column max-h-full w-full">
    <div class="flex-none flex items-center justify-center w-full min-h-15 pb-2 relative">
      <Button
        class="absolute left-2 w-25"
        color="float-left btn-primary-outline"
        size="small"
        :label="$t('back')"
        @click="close"
      />

      <h2 class="text-white-1 text-center">{{ $t('editor.trash') }}</h2>

      <CopyToClipboard
        :label="$t('editor.recordings_link')"
        :value="recordingsFolderUrl"
        class="absolute right-0 top-0 max-w-78"
      />
    </div>

    <div class="flex-1 overflow-auto">
      <p v-if="clips.length === 0" class="text-center">{{ $t('editor.empty_trash') }}</p>

      <ol v-else class="list-style-none container-sm mx-auto">
        <li v-for="(clip, index) in clips" :key="clip.removedAt" :class="index > 0 && 'mt-6'">
          <EditorTrashClip
            :clip="clip.speaker.video"
            :file="clipFile(clip.speaker.video)"
            :removedAt="clip.removedAt"
            @restore="restoreClip(index)"
          />
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button.vue';
import EditorTrashClip from '@/components/EditorTrashClip.vue';
import CopyToClipboard from '@/components/CopyToClipboard.vue';

export default {
  name: 'EditorTrash',
  components: { Button, EditorTrashClip, CopyToClipboard },
  props: {
    clips: {
      type: Array,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['loggedInSpeakerId', 'recorderToken']),
    recordingsFolderUrl() {
      const origin = window.location.origin;

      return `filesystem:${origin}/persistent/${this.recorderToken}/${this.loggedInSpeakerId}`;
    },
  },
  methods: {
    clipFile(clip) {
      return this.files.find((f) => f.id === clip[clip.type].file_id);
    },
    close() {
      this.$emit('close');
    },
    restoreClip(index) {
      this.$emit('restoreClip', index);
    },
  },
};
</script>

<template>
  <li
    class="py-2"
    @mouseenter="pauseCloseTimer"
    @mouseleave="resumeCloseTimer"
    @focusin="pauseCloseTimer"
    @focusout="resumeCloseTimer"
  >
    <div class="flex items-center rounded-l-lg pl-4 pr-12 py-5 min-h-12 events-all relative" :class="bgColor">
      <template v-if="!hideIcon">
        <BellSVG v-if="type === 'info'" class="flex-none fill-white-1 w-6 h-6 mr-3" />

        <WarningFullSVG v-else-if="type === 'warning'" class="flex-none fill-white-1 w-6 h-6 mr-3" />

        <ErrorFullCircleSVG v-else-if="type === 'error'" class="flex-none fill-white-1 w-6 h-6 mr-3" />
      </template>

      <div class="flex-1 w-1">
        <p class="text-white-1 font-black">{{ $t(`notifications.${type}`) }}</p>

        <p v-html="content" class="text-white-1 font-light w-full" />

        <p v-if="code" :class="['text-white-3', 'text-xs', 'no-wrap', 'overflow-hidden', 'overflow-ellipsis']">
          {{ code }}
        </p>
      </div>

      <Tooltip v-if="closeable">
        <template #trigger>
          <Button
            disableMinHeight
            :rounded="false"
            color="btn-transparent-white-simple"
            class="absolute right-2 top-2 p-0 rounded-full w-8 h-8"
            @click="close"
          >
            <template #icon>
              <CrossSVG class="w-5 w-5 fill-white-1" />
            </template>
          </Button>
        </template>

        <template #default>
          {{ $t('close') }}
        </template>
      </Tooltip>

      <Spinner v-if="spinner" size="small" class="absolute right-2 inset-y-0 my-auto" />
    </div>
  </li>
</template>

<script>
import Button from '@/components/Button.vue';
import Spinner from '@/components/Spinner.vue';
import Tooltip from '@/components/Tooltip.vue';

import CrossSVG from '@/assets/svgs/cross.svg';
import WarningFullSVG from '@/assets/svgs/warning_full.svg';
import ErrorFullCircleSVG from '@/assets/svgs/error_full_circle.svg';
import BellSVG from '@/assets/svgs/bell.svg';

import Timer from '@/modules/timer';

import ErrorMP3 from '@/assets/error.mp3';

import { playSound } from '@/helpers';

export default {
  name: 'NotificationItem',
  components: { Button, Spinner, Tooltip, CrossSVG, WarningFullSVG, ErrorFullCircleSVG, BellSVG },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'info',
      validate: (value) => ['info', 'warning', 'error'].includes(value),
    },
    content: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      default: null,
    },
    closeable: {
      type: Boolean,
      default: false,
      validate: (value) => value && !this.spinner,
    },
    closeAfterS: {
      type: Number,
      default: null,
      validate: (value) => [undefined, null].includes(value) || value > 0,
    },
    synthesisMessage: {
      type: String,
      default: '',
    },
    sound: {
      type: String,
      default: null,
      validate: (value) => [undefined, null, 'error'].includes(value),
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeTimer: null,
    };
  },
  computed: {
    bgColor() {
      if (this.type === 'warning') {
        return 'bg-yellow-1';
      }

      if (this.type === 'error') {
        return 'bg-red-1';
      }

      return 'bg-blue-1';
    },
  },
  methods: {
    close() {
      if (this.closeTimer) {
        this.closeTimer.clear();
      }

      this.$emit('close', this.id);
    },
    pauseCloseTimer() {
      if (!this.closeTimer) {
        return;
      }

      this.closeTimer.pause();
    },
    resumeCloseTimer() {
      if (!this.closeTimer) {
        return;
      }

      this.closeTimer.resume();
    },
    triggerCloseTimer() {
      if (!this.closeAfterS) {
        return;
      }

      this.closeTimer = new Timer(this.close, this.closeAfterS * 1000, true);
    },
    triggerSpeechSynthesis() {
      if (!window.speechSynthesis || !this.synthesisMessage) {
        return;
      }

      const message = new SpeechSynthesisUtterance(this.synthesisMessage);
      message.lang = this.$i18n.locale;

      window.speechSynthesis.speak(message);
    },
    triggerSound() {
      if (this.sound === 'error') {
        return playSound(ErrorMP3);
      }

      return Promise.resolve();
    },
  },
  mounted() {
    this.triggerCloseTimer();
    this.triggerSound().then(() => this.triggerSpeechSynthesis());
  },
};
</script>

import { v4 as uuidv4 } from 'uuid';

import Logger from '@/modules/logger';

import { defaultVersionName } from '@/helpers';

import initialVersion from '../initial_version';

export default function ({ commit, state }, { name = defaultVersionName(state.locale.locale) } = {}) {
  return new Promise((resolve) => {
    const INITIAL_VERSION = initialVersion();
    const now = new Date();
    const id = uuidv4();
    const createdAt = now.toISOString();

    commit('updateVersion', {
      ...INITIAL_VERSION,
      id,
      recorder_token: state.recorderToken,
      speaker_id: state.loggedInSpeakerId,

      version_name: name,
      created_at: createdAt,
      updated_at: createdAt,
    });

    Logger.log(`Add version, id: ${id}, name: ${now}`);

    resolve(state.versions[id]);
  });
}

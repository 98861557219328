<template>
  <component :is="tag" class="flex">
    <template v-if="!dragDrop">
      <slot :triggerClick="clickInput" :triggerDelete="handleDelete" />
    </template>

    <div
      v-else
      :class="[
        'flex-1',
        'text-white-3',
        'text-base',
        'border',
        'border-dashed',
        'outline-none',
        'w-full',
        'rounded-lg',
        'text-center',
        {
          'bg-white-9': !readOnly && (disabled || !dragDropFocus),
          'bg-white-7': !readOnly && !disabled && dragDropFocus,
          'border-transparent': !readOnly,
          'border-white-3': readOnly,
        },
      ]"
      @dragover.prevent="updateDragDropFocus(true)"
      @dragleave.prevent="updateDragDropFocus(false)"
      @dragenter.prevent="updateDragDropFocus(true)"
      @drop.prevent="onFileChange"
    >
      <slot :triggerClick="clickInput" :triggerDelete="handleDelete" />
    </div>

    <input
      class="none"
      ref="input"
      type="file"
      v-bind="$attrs"
      :multiple="multiple"
      :accept="accept"
      :disabled="disabled"
      @change="onFileChange"
    />
  </component>
</template>

<script>
import { createFileObject } from '@/helpers';

export default {
  name: 'Upload',
  model: {
    prop: 'customValue',
    event: 'customInput',
  },
  props: {
    tag: {
      type: String,
      default: 'label',
      validate: (value) => ['label', 'div'].includes(value),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    dragDrop: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    attachmentType: {
      type: String,
      required: true,
      validate: (value) => ['speaker', 'slides', 'presentation', 'other'].includes(value),
    },
  },
  data() {
    return {
      newValue: this.customValue,
      dragDropFocus: false,
    };
  },
  watch: {
    customValue(value) {
      this.newValue = value;

      if (!value || (Array.isArray(value) && value.length === 0)) {
        this.$refs.input.value = null;
      }
    },
  },
  methods: {
    clickInput() {
      this.$refs.input.click();
    },
    onFileChange(event) {
      if (this.disabled) {
        return;
      }

      if (this.dragDrop) {
        this.updateDragDropFocus(false);
      }

      const value = event.target.files || event.dataTransfer.files;

      if (value.length === 0) {
        if (!this.newValue) {
          return;
        }
      } else if (!this.multiple) {
        if (this.dragDrop && value.length !== 1) {
          return;
        }

        const file = value[0];

        if (this.checkType(file)) {
          this.newValue = createFileObject({
            type: 'attachment',
            typeData: { type: this.attachmentType },
            fileName: file.name,
            fileSize: file.size,
            file,
          });
        } else if (this.newValue) {
          this.newValue = null;
        }
      } else {
        let newValues = false;

        if (!this.newValue) {
          this.newValue = [];
          newValues = true;
        }

        for (let i = 0; i < value.length; i++) {
          const file = value[i];

          if (this.checkType(file)) {
            const fileObject = createFileObject({
              type: 'attachment',
              typeData: { type: this.attachmentType },
              fileName: file.name,
              fileSize: file.size,
              file,
            });
            this.newValue.push(fileObject);
            newValues = true;
          }
        }

        if (!newValues) {
          return;
        }
      }

      this.$emit('customInput', this.newValue);
    },
    handleDelete(index) {
      if (this.newValue) {
        this.newValue = this.newValue.filter((v, i) => i !== index);
      }

      this.$emit('customInput', this.newValue);
      this.$refs.input.value = null;
    },
    updateDragDropFocus(focus) {
      if (this.disabled) {
        return;
      }

      this.dragDropFocus = focus;
    },
    checkType(file) {
      if (!this.accept) {
        return true;
      }

      const types = this.accept.split(',');

      if (types.length === 0) {
        return true;
      }

      let valid = false;

      for (let i = 0; i < types.length && !valid; i++) {
        const type = types[i].trim();

        if (type) {
          if (type.substring(0, 1) === '.') {
            const extIndex = file.name.lastIndexOf('.');
            const extension = extIndex >= 0 ? file.name.substring(extIndex) : '';

            if (extension.toLowerCase() === type.toLowerCase()) {
              valid = true;
            }
          } else if (file.type.match(type)) {
            valid = true;
          }
        }
      }

      if (!valid) {
        this.$emit('invalid');
      }

      return valid;
    },
  },
};
</script>

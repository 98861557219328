<script>
export default {
  name: 'TabItem',
  inject: {
    parent: {
      from: 'Tabs',
      default: false,
    },
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default() {
        return this._uid.toString();
      },
    },
  },
  data() {
    return {
      index: null,
      transitionName: null,
    };
  },
  computed: {
    isRendered() {
      return !this.parent.destroyOnHide || (this.visible && this.isActive);
    },
    isActive() {
      return this.parent.activeItem === this;
    },
  },
  methods: {
    activate(oldIndex) {
      this.transitionName = this.index < oldIndex ? 'slide-next' : 'slide-prev';
    },
    deactivate(newIndex) {
      this.transitionName = newIndex < this.index ? 'slide-next' : 'slide-prev';
    },
  },
  watch: {
    visible() {
      this.parent.moveUnderline();
    },
  },
  render(createElement) {
    if (!this.isRendered) {
      return null;
    }

    const vnode = createElement(
      'div',
      {
        directives: [
          {
            name: 'show',
            value: this.isActive && this.visible,
          },
        ],
      },
      this.$slots.default || this.$scopedSlots.default({ isActive: this.isActive }),
    );

    if (!this.parent.animated) {
      return vnode;
    }

    return createElement(
      'transition',
      {
        props: {
          name: this.transitionName,
        },
        on: {
          'before-enter': () => {
            this.parent.isTransitioning = true;
          },
          'after-enter': () => {
            this.parent.isTransitioning = false;
          },
        },
      },
      [vnode],
    );
  },
  created() {
    if (!this.parent) {
      this.$destroy();
      throw new Error(`You should wrap ${this.$options.name} in a Tab`);
    } else if (this.parent._registerItem) {
      this.parent._registerItem(this);
    }
  },
  beforeDestroy() {
    if (this.parent && this.parent._unregisterItem) {
      this.parent._unregisterItem(this);
    }
  },
};
</script>

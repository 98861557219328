import { v4 as uuidv4 } from 'uuid';

import Logger from '@/modules/logger';

import { upsertJSONFile } from '@/helpers';

import initialVersion from '../initial_version';

export default function ({ commit, state }, { id: idToCopy, version_name: name }) {
  const INITIAL_VERSION = initialVersion();
  const versionToCopy = JSON.parse(JSON.stringify(state.versions[idToCopy]));
  const now = new Date().toISOString();
  const id = uuidv4();

  delete versionToCopy.recorded_at;
  delete versionToCopy.final_recording;
  delete versionToCopy.upload_token;
  delete versionToCopy.upload_complete;
  delete versionToCopy.share_link_url;

  commit('updateVersion', {
    ...versionToCopy,
    id,

    upload_id: null,

    version_name: name,
    created_at: now,
    updated_at: now,

    is_final: false,

    feedback: {
      ...INITIAL_VERSION.feedback,
    },
  });

  Logger.log(`Copy version, id: ${idToCopy}, new version id: ${id}, name: ${name}`);

  const version = state.versions[id];

  return upsertJSONFile(version).then(() => version);
}

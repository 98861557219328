<template>
  <Draggable
    tag="ol"
    v-bind="dragOptions"
    v-model="computedItems"
    :draggable="`.${sortableClass}`"
    :handle="handleClass ? `.${handleClass}` : undefined"
    class="ordered-list"
  >
    <transition-group type="transition">
      <OrderedListItem
        v-for="(item, index) in computedItems"
        :key="item.id"
        :class="'mt-3'"
        :hideNumber="computedItems.length === 1"
        :floatingNumber="floatingNumbers"
        :sortableClass="sortableClass"
      >
        <slot :index="index" :item="item" />
      </OrderedListItem>
    </transition-group>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';

import OrderedListItem from '@/components/OrderedListItem.vue';

export default {
  name: 'OrderedList',
  components: { Draggable, OrderedListItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    floatingNumbers: {
      type: Boolean,
      default: true,
    },
    sortGroup: {
      type: String,
      default: 'ordered-list',
    },
    sortableEnabled: {
      type: Boolean,
      default: false,
    },
    sortableClass: {
      type: String,
      required: true,
    },
    handleClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      newItems: this.items,
    };
  },
  watch: {
    items(items) {
      if (this.newItems === items) {
        return;
      }

      this.newItems = items;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: !this.sortableEnabled,
        ghostClass: 'opacity-5',
      };
    },
    computedItems: {
      get() {
        return this.newItems;
      },
      set(items) {
        this.newItems = items;
        this.$emit('sorted', items);
      },
    },
  },
};
</script>

<style lang="scss">
.ordered-list {
  counter-reset: ordered-list-counter;
  list-style: none;
}
</style>

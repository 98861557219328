<template>
  <span :class="highlightPast && !format.future && 'text-red-3'">{{ format.text }}</span>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

import { formatTimeFromNow } from '@/helpers';

export default {
  name: 'DateFromNow',
  props: {
    date: {
      type: [Date, String],
      required: true,
    },
    highlightPast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeout: null,
      format: {},
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale.locale,
    }),
  },
  watch: {
    locale() {
      Vue.nextTick(() => {
        this.setFormatTimeout();
      });
    },
  },
  methods: {
    setFormatTimeout() {
      this.clearFormatTimeout();

      this.format = formatTimeFromNow(this.date, { locale: this.$i18n.locale, minUnit: 'minute' });
      this.timeout = setTimeout(
        () => {
          this.setFormatTimeout();
        },
        this.format.updateAtMs > 1000 * 60 ? 1000 * 60 : this.format.updateAtMs,
      );
    },
    clearFormatTimeout() {
      if (!this.timeout) {
        return;
      }

      clearTimeout(this.timeout);
      this.timeout = null;
    },
  },
  mounted() {
    this.setFormatTimeout();
  },
  beforeDestroy() {
    this.clearFormatTimeout();
  },
};
</script>

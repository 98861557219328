<template>
  <div :class="sizeClasses" class="inline-block relative">
    <div class="spinner-animation" :class="partClasses"></div>
    <div class="spinner-animation" :class="partClasses"></div>
    <div class="spinner-animation" :class="partClasses"></div>
    <div class="spinner-animation" :class="partClasses"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: 'large',
      validator: (value) => ['small', 'large'].includes(value),
    },
  },
  computed: {
    sizeSuffix() {
      if (this.size === 'small') {
        return 10;
      }

      return 20;
    },
    sizeClasses() {
      const width = this.size === 'small' ? 'w-10' : 'w-20';
      const height = this.size === 'small' ? 'h-10' : 'h-20';

      return [width, height];
    },
    partClasses() {
      const border = this.size === 'small' ? 'border-3' : 'border-6';
      const width = this.size === 'small' ? 'w-6' : 'w-16';
      const height = this.size === 'small' ? 'h-6' : 'h-16';

      return [
        border,
        'border-solid',
        'border-x-transparent',
        'border-t-white',
        'border-b-transparent',
        'rounded-full',
        'absolute',
        'left-2',
        'top-2',
        width,
        height,
      ];
    },
  },
};
</script>

<style lang="scss">
.spinner-animation {
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import Logger from '@/modules/logger';

export default function (to, from, next) {
  if (!from || !from.name) {
    Logger.log(`Loaded app at route '${to.name}'`);
  } else {
    Logger.log(`Route changed from '${from.name}' to '${to.name}', params ${JSON.stringify(to.params)}`);
  }

  next();
}

<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="w-full self-start px-4">
      <div class="embed-spinner-padding w-full relative">
        <Spinner class="absolute inset-0 m-auto bg-black rounded-full" />
      </div>
    </div>

    <RecordPlayer
      v-else-if="data.version.timelines"
      class="w-full self-start px-3 py-1"
      withBackground
      :version="data.version"
    />
  </transition>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import Spinner from '@/components/Spinner.vue';
import RecordPlayer from '@/components/RecordPlayer.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import { parseUrlParams, mergeVersionWithUpload } from '@/helpers';

export default {
  name: 'Embed',
  mixins: [ErrorNotificationsMixin],
  components: {
    Spinner,
    RecordPlayer,
  },
  data() {
    return {
      data: {},
      loading: true,
    };
  },
  computed: {
    urlParams() {
      return parseUrlParams();
    },
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    ...mapActions(['fetchShareData']),
  },
  created() {
    this.loading = true;

    this.fetchShareData({
      tokenId: this.urlParams.share,
      shareToken: this.urlParams.s,
      embed: 'yes',
    })
      .then((data) => {
        data.version.files = data.version.files.map((file) => {
          file.deleted = !file.url;
          return file;
        });

        data.version = mergeVersionWithUpload(data.version, data.upload);

        this.data = data;
        this.loading = false;
      })
      .catch((error) => this.notificationFromError(error));
  },
  beforeMount() {
    document.body.classList.add('bg-transparent');
  },
};
</script>

<style lang="scss">
.embed-spinner-padding {
  padding-bottom: calc(9 / 16) * 50%;
}
</style>

export default function (version) {
  const removedClips = version.removed_clips;
  const editorHistory = version.editor_history;
  const editoryHistoryIndex = version.editor_history_index;

  delete version.removed_clips;
  delete version.editor_history;
  delete version.editor_history_index;

  version.editor = {
    removed_clips: removedClips,
    history: editorHistory,
    history_index: editoryHistoryIndex,
  };

  return version;
}

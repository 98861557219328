/* eslint-disable import/prefer-default-export */
import clickOutside from './click_outside';
import focusOutside from './focus_outside';
import escapePressed from './escape_pressed';

function registerDirectives(Vue) {
  Vue.directive(clickOutside.name, clickOutside.directive);
  Vue.directive(focusOutside.name, focusOutside.directive);
  Vue.directive(escapePressed.name, escapePressed.directive);
}

export { registerDirectives };

import { getBrowserLocales } from '@/helpers';

export default function (i18n, store, to, from, next) {
  const queryLocale = to.query.locale;

  if (queryLocale && queryLocale !== store.state.locale.locale) {
    store.commit('setLocale', { locale: queryLocale });
  }

  if (!store.state.locale.locale) {
    store.commit('setLocale', {
      locale: getBrowserLocales({ languageCodeOnly: true, first: true }),
      fallback: 'en',
    });
  }

  if (store.state.locale.locale && store.state.locale.locale !== i18n.locale) {
    i18n.locale = store.state.locale.locale;
  }

  return next();
}

<template>
  <div>
    <Switcher v-model="computedRequested" :disabled="switcherDisabled" labelPosition="left" class="mx-12 relative">
      <span class="text-white-1 text-xl font-bold">{{ $t('setup.screen_sharing.title') }}</span>

      <Spinner
        v-if="switcherDisabled || isWaitingForScreenSharingStream"
        size="small"
        class="absolute left-full top-0 bottom-0 my-auto"
      />
    </Switcher>

    <ScreenSharingStream #default="{ stream, enabled, open }">
      <transition name="slide" mode="out-in">
        <p v-if="!enabled" class="mx-12 pt-2 font-light">{{ $t('setup.not_connected') }}</p>

        <div v-else class="pt-6">
          <Button
            class="w-full"
            color="btn-primary-outline"
            :label="$t('setup.screen_sharing.change')"
            :disabled="!enabled"
            @click="open"
          />

          <MediaPreview
            class="mt-10"
            :stream="stream"
            :enabled="enabled"
            :errors="computedErrors"
            icon="PresentationSVG"
          />
        </div>
      </transition>
    </ScreenSharingStream>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Switcher from '@/components/Switcher.vue';
import Button from '@/components/Button.vue';
import ScreenSharingStream from '@/components/ScreenSharingStream.vue';
import MediaPreview from '@/components/MediaPreview.vue';
import Spinner from '@/components/Spinner.vue';

import Logger from '@/modules/logger';

export default {
  name: 'SetupScreenSharing',
  components: { Switcher, Button, ScreenSharingStream, MediaPreview, Spinner },
  computed: {
    ...mapState([
      'screenSharingErrors',
      'screenSharingRequested',
      'screenSharingEnabled',
      'isWaitingForScreenSharingStream',
    ]),
    computedRequested: {
      get() {
        return this.screenSharingRequested;
      },
      set(value) {
        this.setKey({
          key: 'screenSharingRequested',
          screenSharingRequested: value,
        });

        Logger.log(`SetupScreenSharing: requested: ${value}`);
      },
    },
    switcherDisabled() {
      return this.screenSharingEnabled !== this.computedRequested;
    },
    computedErrors() {
      if (this.isWaitingForScreenSharingStream) {
        return [];
      }

      return this.screenSharingErrors;
    },
  },
  methods: {
    ...mapMutations(['setKey']),
  },
};
</script>

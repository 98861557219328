var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
    'flex-none',
    'text-left',
    'border-solid',
    'overflow-hidden',
    'relative',
    'visibility-group',
    {
      'border-white-4': !_vm.isCurrent && !_vm.file.deleted,
      'border-accent-1': _vm.isCurrent && !_vm.file.deleted,
      'border': !_vm.isCurrent,
      'border-2': _vm.isCurrent,
      'outline-none': !_vm.isCurrent,
      'outline-accent': _vm.isCurrent,
      'z-0': !_vm.isCurrent,
      'z-1': _vm.isCurrent,
      'bg-white-8': !_vm.file.deleted,
      'bg-red-5': _vm.file.deleted,
      'border-red-1': _vm.file.deleted,
    },
  ],style:(_vm.size),attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;$event.stopPropagation();$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.removeClip.apply(null, arguments)}}},[(_vm.file.deleted)?_c('div',{staticClass:"w-full h-full flex items-center justify-center text-center px-2"},[_vm._v(" "+_vm._s(_vm.$t('record.video_deleted'))+" ")]):(_vm.thumbnailUrl)?_c('img',{staticClass:"h-full opacity-4",attrs:{"src":_vm.thumbnailUrl,"alt":"Clip thumbnail"}}):_vm._e(),_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"p-0 rounded-full w-8 h-8",class:[
          'absolute',
          'right-1',
          'top-1',
          'visibility-group-hover:visible',
          'visibility-group-focus:visible',
          'visibility-group-focus-within:visible',
          {
            hidden: !_vm.isCurrent,
          },
        ],attrs:{"disableMinHeight":"","color":"btn-white-white"},on:{"click":function($event){$event.stopPropagation();return _vm.removeClip.apply(null, arguments)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('TrashSVG',{staticClass:"w-6 w-6"})]},proxy:true}])})]},proxy:true},{key:"default",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('editor.remove_clip'))}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';
import Logger from '@/modules/logger';

import {
  upsertJSONFile,
  getFilesByNames,
  makeRequest,
  deleteFrontendFromJSON,
  sendErrorToSentry,
  mergeVersionWithUpload,
  addConfirmCloseTab,
  removeConfirmCloseTab,
} from '@/helpers';

import { API_URL } from '@/constants';

import initialState from '../initial_state';

export default function ({ state, commit, dispatch, getters }, { final = false, selfRecording = false } = {}) {
  const uploadFilesErrors = {};
  const data = selfRecording ? state.selfRecording : getters.submitVersion;
  const isUpdate = !selfRecording && !!data.upload_id;
  let upload = null;

  if (isUpdate) {
    upload = state.uploads.find((u) => {
      if (data.has_local_data) {
        return u.id === data.upload_id;
      }

      return u.upload_token === data.upload_token;
    });
  }

  const cancelUploadWithFatalErrors = (errors, resetData = false) => {
    Logger.log(`Upload fatal error: ${JSON.stringify(errors)}`);

    let messages;

    if (Array.isArray(errors)) {
      messages = errors.map((e) => e.message || (e.error && (e.error.message || e.error.code || e.error)) || e);
    } else {
      messages =
        errors.message || (errors.error && (errors.error.message || errors.error.code || errors.error)) || errors;
    }

    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    const doResetProps = () => {
      commit('setKey', { key: 'uploadingErrors', uploadingErrors: messages });
      commit('setKey', { key: 'uploadingFilesErrors', uploadingFilesErrors: [] });
      commit('setKey', { key: 'isUploading', isUploading: false });
      commit('setKey', { key: 'isUploadVisible', isUploadVisible: false });
    };

    if (resetData) {
      if (selfRecording) {
        commit('updateSelfRecording', { upload_id: null });
      } else {
        commit('updateVersion', { id: data.id, upload_id: null });
      }

      doResetProps();

      if (!selfRecording) {
        data.upload_id = null;
        return upsertJSONFile(data);
      }
    } else {
      doResetProps();
    }

    return Promise.resolve();
  };

  if (!navigator.onLine) {
    return new Promise((resolve, reject) => {
      const error = new Error('upload_offline');
      cancelUploadWithFatalErrors(error).then(() => {
        reject(error);
      });
    });
  }

  if (isUpdate) {
    if (!upload) {
      const error = new Error('upload_cannot_find_upload_during_update');

      return cancelUploadWithFatalErrors(error).then(() => Promise.reject(error));
    }

    if (!final) {
      const error = new Error('upload_cannot_update_when_not_final');

      return cancelUploadWithFatalErrors(error).then(() => Promise.reject(error));
    }
  }

  commit('setKey', {
    key: 'uploadingProgress',
    uploadingProgress: initialState().uploadingProgress,
  });
  commit('setKey', { key: 'uploadingErrors', uploadingErrors: [] });
  commit('setKey', { key: 'uploadingFilesErrors', uploadingFilesErrors: [] });
  commit('setKey', { key: 'isUploading', isUploading: true });
  commit('setKey', { key: 'isUploadVisible', isUploadVisible: true });

  const filterFilesObjects = (files) => {
    return files.map((f) => {
      const obj = JSON.parse(JSON.stringify(f));
      delete obj.file;

      return obj;
    });
  };

  const getJSONFiles = () => {
    let file;

    if (!selfRecording) {
      file = new File([JSON.stringify(deleteFrontendFromJSON(data))], `${data.id}.json`, {
        type: 'application/json',
      });
    } else {
      const selfRecordingData = {
        ...data,
        files: filterFilesObjects(data.files),
      };
      file = new File([JSON.stringify(selfRecordingData)], 'self_recording.json', {
        type: 'application/json',
      });
    }

    return [file];
  };

  const isFileRequired = (fileId) => {
    if (selfRecording) {
      return true;
    }

    const speakerTimelineFiles = data.timelines.speaker.video.map((c) => c[c.type].file_id);
    const slidesTimelineFiles = data.timelines.slides.video.map((c) => c[c.type].file_id);

    return speakerTimelineFiles.includes(fileId) || slidesTimelineFiles.includes(fileId);
  };

  const getFilesToUpload = (onlyAttachments) => {
    const attachments =
      onlyAttachments || final ? data.files.filter((f) => f.type === 'attachment').map((f) => f.file) : [];

    if (onlyAttachments) {
      return Promise.resolve(attachments);
    }

    const fileNames = data.files.filter((f) => f.type !== 'attachment').map((f) => [f.file_name, isFileRequired(f.id)]);

    return getFilesByNames(state.recorderToken, state.loggedInSpeakerId, fileNames).then((recordingFiles) => [
      ...recordingFiles,
      ...attachments,
    ]);
  };

  const removeDuplicateFiles = (files) => {
    const fileNames = files.map((f) => f.name);

    return files.filter((f, index) => fileNames.indexOf(f.name) === index);
  };

  const setUploadFilesErrors = () => {
    const errorsFileNames = Object.keys(uploadFilesErrors).sort();
    const uploadingFilesErrors = [];

    for (const fileName of errorsFileNames) {
      const fileError = uploadFilesErrors[fileName];

      if (
        !uploadingFilesErrors.some(
          (error) =>
            ((error.message.join && error.message.join('')) || error.mesage) ===
            ((fileError.message.join && fileError.message.join('')) || fileError.message),
        )
      ) {
        uploadingFilesErrors.push(fileError);
      }
    }

    Logger.log(`Upload files error, data: ${JSON.stringify(uploadingFilesErrors)}`);

    commit('setKey', { key: 'uploadingFilesErrors', uploadingFilesErrors });
  };

  const handleFileUploadError = (fileName, error) => {
    uploadFilesErrors[fileName] = {
      message: error.errorMessage,
      type: error.errorType,
    };

    setUploadFilesErrors();
  };

  const handleFileUploadProgress = (fileName) => {
    if (!uploadFilesErrors[fileName]) {
      return;
    }

    delete uploadFilesErrors[fileName];
    setUploadFilesErrors();
  };

  const tokenUpload = () => {
    if (isUpdate) {
      commit('setKey', { key: 'currentUploadId', currentUploadId: upload.id });

      return Promise.resolve({ uploadId: upload.id, uploadToken: upload.upload_token });
    }

    const name = selfRecording ? 'Self recording' : data.version_name;
    const recordedAt = selfRecording ? new Date().toISOString() : data.created_at;
    const reqData = {
      token_upload: {
        token_id: state.tokenId,
        speaker_id: state.loggedInSpeakerId,
        name,
        recorded_at: recordedAt,
        final_recording: false,
      },
    };

    SentryAddBreadcrumb({
      category: 'upload',
      message: `Token upload data`,
      data: reqData,
      level: Severity.Debug,
    });

    return makeRequest('POST', `${API_URL}/token_uploads`, reqData)
      .then(({ data: responseData }) => {
        upload = responseData;

        commit('setKey', { key: 'currentUploadId', currentUploadId: upload.id });

        return {
          uploadId: upload.id,
          uploadToken: upload.upload_token,
        };
      })
      .catch((error) => {
        const ignoredErrorNames = ['TypeError', 'AbortError'];
        const ignoredErrorMessages = [];

        if (!ignoredErrorNames.includes(error.name) && !ignoredErrorMessages.includes(error.message)) {
          sendErrorToSentry('TOKEN UPLOAD ERROR', error);
        }

        throw error;
      });
  };

  const finishUpload = () => {
    const url = `${API_URL}/token_uploads/${upload.id}/complete_upload`;
    const reqData = {
      upload_token: upload.upload_token,
      final_recording: selfRecording || final,
    };

    SentryAddBreadcrumb({
      category: 'upload',
      message: `Finish upload data`,
      data: reqData,
      level: Severity.Debug,
    });

    return makeRequest('POST', url, reqData).catch((error) => {
      const ignoredErrorNames = ['TypeError', 'AbortError'];
      const ignoredErrorMessages = [];

      if (!ignoredErrorNames.includes(error.name) && !ignoredErrorMessages.includes(error.message)) {
        sendErrorToSentry('COMPLETE UPLOAD ERROR', error);
      }

      throw error;
    });
  };

  addConfirmCloseTab();

  return getFilesToUpload(isUpdate || selfRecording)
    .then((files) => Promise.all([files, tokenUpload()]))
    .then(([files, responseData]) => Promise.all([files, dispatch('initResumableUpload', responseData)]))
    .then(([files, resumableUpload]) => {
      if (resumableUpload.initialized) {
        return [files, resumableUpload];
      }

      return resumableUpload.initialize().then(() => [files, resumableUpload]);
    })
    .then(([files, resumableUpload]) => {
      Logger.log(
        `Upload data: ${JSON.stringify({
          upload,
          selfRecording,
          final,
        })}`,
      );

      Logger.pause();

      return Promise.all([resumableUpload, files, getJSONFiles(), Logger.getInstance().file()]);
    })
    .then(([resumableUpload, files, jsonFiles, logFile]) => {
      return new Promise((resolve, reject) => {
        SentryAddBreadcrumb({
          category: 'upload',
          message: `Upload data`,
          data: {
            upload,
            selfRecording,
            final,
            files: files.map((f) => f.name),
            jsonFile: jsonFiles && jsonFiles.length > 0,
            logFile: !!logFile,
          },
          level: Severity.Debug,
        });
        Logger.log(
          `Upload files: ${JSON.stringify({
            files,
            jsonFile: jsonFiles && jsonFiles.length > 0,
            logFile: !!logFile,
          })}`,
        );

        if (files.length === 0 && !selfRecording && !isUpdate) {
          throw new Error('upload_files_not_found');
        }

        const addFile = (file, name = file.name) => {
          resumableUpload.addFile(
            name,
            file.type,
            file,
            {
              uploadError: handleFileUploadError.bind(this, file.name),
              uploadProgress: handleFileUploadProgress.bind(this, file.name),
              uploadRetry: handleFileUploadProgress.bind(this, file.name),
            },
            { name },
          );
        };

        files = removeDuplicateFiles(files);

        if (jsonFiles && jsonFiles.length) {
          addFile(jsonFiles[0], 'slrec.json');
        }

        if (logFile) {
          addFile(logFile);
        }

        for (const file of files) {
          addFile(file);
        }

        resumableUpload.on('error', (error) => reject(error));

        resumableUpload.on('totalProgress', (responseData) => {
          const progress = {
            ...responseData,
            totalProgress: Math.min(99.9, responseData.totalProgress),
          };

          commit('setKey', {
            key: 'uploadingProgress',
            uploadingProgress: progress,
          });
        });

        resumableUpload.on('totalComplete', () => {
          finishUpload()
            .then((response) => {
              commit('setKey', {
                key: 'uploadingProgress',
                uploadingProgress: {
                  ...state.uploadingProgress,
                  totalProgress: 100,
                },
              });

              const responseSpeaker = response.data.speaker;
              const responseUpload = response.data;
              delete responseUpload.speaker;

              Logger.resume();
              Logger.log(`Upload complete, response: ${JSON.stringify(response)}`);

              commit('setKey', { key: 'uploads', uploads: [...state.uploads, responseUpload] });
              commit('updateSpeaker', responseSpeaker);

              if (selfRecording) {
                commit('updateSelfRecording', { upload_id: responseUpload.id });
              } else {
                const newVersionData = mergeVersionWithUpload({}, responseUpload);

                commit('updateVersion', { id: data.id, ...newVersionData });
              }

              if (!selfRecording) {
                upsertJSONFile(getters.submitVersion).then(() => {
                  commit('setKey', { key: 'currentUploadId', currentUploadId: null });
                  resolve(responseUpload);
                });
              } else {
                commit('setKey', { key: 'currentUploadId', currentUploadId: null });
                resolve(responseUpload);
              }
            })
            .catch((error) => {
              throw error;
            });
        });

        resumableUpload.startUpload().catch((error) => {
          console.error('ResumableUpload#startUpload error (ignored):', error);
        });
      });
    })
    .then((responseUpload) => {
      commit('setKey', { key: 'isUploading', isUploading: false });
      return [responseUpload];
    })
    .catch((error) => {
      Logger.resume();
      Logger.log(`Upload failed, error: ${JSON.stringify(error)}`);

      const ignoredErrorNames = ['TypeError', 'AbortError'];
      const ignoredErrorMessages = [];

      if (!ignoredErrorNames.includes(error.name) && !ignoredErrorMessages.includes(error.message)) {
        sendErrorToSentry('UPLOAD ERROR', error);
      }

      commit('setKey', { key: 'currentUploadId', currentUploadId: null });

      return Promise.all([undefined, error, cancelUploadWithFatalErrors(error, !isUpdate)]);
    })
    .then(([responseUpload, error = null]) => {
      removeConfirmCloseTab();

      if (error) {
        throw error;
      }

      return responseUpload;
    });
}

<template>
  <ContainerWithHeaderAndBack>
    <div class="pt-14 pb-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('speakers.reorder_title') }}</h1>

      <Button
        :loading="inProgress"
        :label="$t('save')"
        color="btn-primary-outline"
        class="w-64 mx-auto mt-6"
        block
        @click="saveOrder"
      />

      <SpeakersList :speakers="newSpeakers" sortable class="mt-3 mx-auto w-120 px-16" @sorted="sorted" />
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import SpeakersList from '@/components/SpeakersList.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'SpeakersOrder',
  components: { ContainerWithHeaderAndBack, SpeakersList, Button },
  computed: {
    ...mapState(['speakers']),
  },
  data() {
    return {
      inProgress: false,
      newSpeakers: [],
    };
  },
  methods: {
    ...mapActions(['updateSpeakersOrder']),
    sorted(speakers) {
      this.newSpeakers = speakers;
    },
    saveOrder() {
      this.inProgress = true;

      const order = this.newSpeakers.map((s) => s.id);

      this.updateSpeakersOrder({ order })
        .then(() => {
          this.$router.go(-1);
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
  mounted() {
    this.newSpeakers = JSON.parse(JSON.stringify(this.speakers));
  },
};
</script>

<template>
  <div class="text-center container-md px-4 py-8">
    <h1 class="text-white-1" v-html="$t('storage_declined.title')"></h1>

    <Button class="w-60 mt-6" :label="$t('storage_declined.retry')" @click="retry" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'StorageDeclined',
  components: { Button },
  methods: {
    retry() {
      this.$emit('retry');
    },
  },
};
</script>

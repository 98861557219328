import isServer from './is_server';
import elementPropName from './element_prop_name';

export default function (name, listeners, el, vNode) {
  const propName = elementPropName(name);

  if (isServer(vNode) || !el[propName]) {
    return;
  }

  listeners.forEach((listener) => {
    document.removeEventListener(listener, el[propName].handler);
  });

  delete el[propName];
}

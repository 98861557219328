<template>
  <div>
    <Select v-model="computedLocale" :options="computedOptions" valueKey="value" labelKey="label" size="small">
      <template #item="slotProps">
        <LanguageItem v-bind="slotProps" />
      </template>
    </Select>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapState, mapMutations } from 'vuex';

import Select from '@/components/Select.vue';
import LanguageItem from '@/components/LanguageItem.vue';

import CsSVG from '@/assets/svgs/cs.svg';
import EnSVG from '@/assets/svgs/en.svg';
import DeSVG from '@/assets/svgs/de.svg';

export default {
  name: 'LanguageSelect',
  components: { Select, LanguageItem },
  computed: {
    ...mapState({
      locale: (state) => state.locale.locale,
      availableLocales: (state) => state.locale.availableLocales,
    }),
    computedLocale: {
      get() {
        return this.locale;
      },
      set(value) {
        this.setLocale({ locale: value });
      },
    },
    computedOptions() {
      return this.availableLocales.map((locale) => {
        return {
          value: locale,
          label: this.getLocaleLabel(locale),
          flagComponent: this.getFlagComponent(locale),
        };
      });
    },
  },
  watch: {
    locale(value) {
      this.$root.$i18n.locale = value;
      this.updateTitleLocale();
    },
  },
  methods: {
    ...mapMutations(['setLocale']),
    getLocaleLabel(locale) {
      if (locale === 'cs') {
        return 'Čeština';
      }

      if (locale === 'en') {
        return 'English';
      }

      if (locale === 'de') {
        return 'Deutsch';
      }

      return locale;
    },
    getFlagComponent(locale) {
      if (locale === 'cs') {
        return CsSVG;
      }

      if (locale === 'en') {
        return EnSVG;
      }

      if (locale === 'de') {
        return DeSVG;
      }

      return null;
    },
    updateTitleLocale() {
      const titleKey = this.$route.meta && this.$route.meta.title;
      const routerName = titleKey ? this.$i18n.t(`meta_title.${titleKey}`) : null;
      const prefix = routerName ? `${routerName} · ` : '';

      document.title = `${prefix}${this.$i18n.t('app_name')}`;
    },
  },
};
</script>

<template>
  <ContainerWithHeaderAndBack>
    <div class="pt-14 pb-4">
      <h1 class="text-3xl text-white-1 text-center">{{ $t('help.title') }}</h1>

      <div class="container-md mt-6">
        <Faq />

        <div class="mt-6">
          <h2 class="text-white-1">
            {{ $t('help.contact') }}
          </h2>

          <a :href="emailUrl" target="_blank" class="inline-block mt-4" rel="noopener noreferrer">
            {{ supportEmail }}
          </a>
        </div>
      </div>
    </div>
  </ContainerWithHeaderAndBack>
</template>

<script>
import { mapState } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import Faq from '@/components/Faq.vue';

export default {
  name: 'Help',
  components: { ContainerWithHeaderAndBack, Faq },
  computed: {
    ...mapState(['supportEmail', 'recorderToken']),
    emailUrl() {
      let result = `mailto:${this.supportEmail}`;
      result += '?subject=Recorder%203.0';
      result += `&body=Recorder%20Token%3A%20${this.recorderToken}`;

      return result;
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="loading || errors.length === 0"
      class="inline-flex items-center justify-center rounded py-1 pl-1 pr-3"
      :class="[
        {
          'bg-white-7': !dark,
          'bg-black-4': dark,
        },
      ]"
    >
      <MicrophoneSVG class="fill-white-1 w-6 h-6" />

      <div class="bg-grey ml-1 rounded-full h-1 w-24 relative overflow-hidden">
        <div class="volume-preview-gradient rounded-full absolute inset-0 bg-grey z-0" :style="previewMaskStyle" />
      </div>
    </div>

    <ul v-else class="list-style-none text-red text-center rounded-full px-3 py-1" :class="dark && 'bg-black-4'">
      <li v-for="(error, index) in errors" :key="index">
        <p>{{ $t(`errors.${error}`) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import MicrophoneSVG from '@/assets/svgs/mic.svg';

import VolumeMeter from '@/modules/volume_meter';

export default {
  name: 'MicrophonePreview',
  components: { MicrophoneSVG },
  props: {
    stream: MediaStream,
    errors: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      volumeMeter: null,
      maskWidth: 100,
    };
  },
  computed: {
    previewMaskStyle() {
      return `clip-path: inset(0 ${this.maskWidth}% 0 0 round 9999rem);`;
    },
  },
  methods: {
    initVolumeMeter() {
      this.volumeMeter = new VolumeMeter(this.stream);
      this.volumeMeter.on('volumeChange', (data) => {
        this.maskWidth = Math.max(Math.min(100, 100 - data.percent), 0);
      });
    },
  },
  watch: {
    stream(value) {
      if (this.volumeMeter) {
        this.volumeMeter.destroy();
        this.volumeMeter = null;
      }

      if (value && value.getAudioTracks().length) {
        this.initVolumeMeter();
      }
    },
  },
  mounted() {
    if (this.stream && this.stream.getAudioTracks().length) {
      this.initVolumeMeter();
    }
  },
  beforeDestroy() {
    if (this.volumeMeter) {
      this.volumeMeter.destroy();
      this.volumeMeter = null;
    }
  },
};
</script>

<style lang="scss">
.volume-preview-gradient {
  background: linear-gradient(to left, color('red') 1%, color('yellow') 12%, color('green') 75%);
}
</style>

<template>
  <Modal :active="active" @close="cancel" size="small">
    <div class="text-center px-4 py-8">
      <h2 class="text-2xl text-white-1">{{ $t('welcome.login_modal.title') }}</h2>

      <p class="font-light mt-3">{{ $t('welcome.login_modal.message') }}</p>

      <Form class="mt-4" @submit="confirm">
        <Input
          v-model="newValue"
          name="email"
          type="email"
          :placeholder="$t('placeholders.email')"
          :submitConstraints="emailConstraints"
          class="w-78 mx-auto"
          required
          :validateOnBlur="false"
        />

        <div class="mt-6">
          <Button type="submit" :label="$t('continue')" />

          <Button class="ml-4" type="button" color="btn-primary-outline" :label="$t('cancel')" @click="cancel" />
        </div>
      </Form>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';

export default {
  name: 'LoginConfirmEmailModal',
  components: { Modal, Button, Form, Input },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    requiredEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newValue: '',
      error: false,
    };
  },
  computed: {
    emailConstraints() {
      return {
        doNotMatch: {
          pattern: `^${this.requiredEmail}$`,
          message: this.$i18n.t('form_errors.email_do_not_match'),
        },
      };
    },
  },
  methods: {
    handleValidity(name, value) {
      this.error = !value;
    },
    cancel() {
      this.newValue = '';
      this.$emit('cancel');
    },
    confirm() {
      if (this.error) {
        return;
      }

      this.newValue = '';
      this.$emit('confirm');
    },
  },
};
</script>

import { Decoder, Reader, tools } from 'ts-ebml';

import { readAsArrayBuffer } from '@/helpers';

class WebmSeekableMetadataFixer {
  constructor(kind, progressCallback = null, fireProgressOnProcess = false) {
    this.kind = kind;
    this.progressCallback = progressCallback;
    this.fireProgressOnProcess = fireProgressOnProcess;

    this.decoder = new Decoder();
    this.reader = new Reader();
    this.tasks = Promise.resolve();
    this.isProcessingData = false;
    this.processDataQueue = [];

    this.reader.drop_default_duration = false;
  }

  processData(data) {
    if (this.isProcessingData) {
      this.processDataQueue.push(data);
      return;
    }

    let task;

    this.isProcessingData = true;

    const processingDone = () => {
      if (this.processDataQueue.length > 0) {
        this.tasks = this.tasks.then(() => task(this.processDataQueue.shift()));
      } else {
        this.isProcessingData = false;
      }
    };

    task = async (d) => {
      try {
        const buffer = await readAsArrayBuffer(d, this.fireProgressOnProcess && this.progressCallback);
        const elms = this.decoder.decode(buffer);

        for (const elm of elms) {
          this.reader.read(elm);
        }

        processingDone();
      } catch (error) {
        if (error.message && error.message.startsWith('Unrepresentable length:')) {
          console.warn(`WebmSeekableMetadataFixer: ${error.message}`);

          processingDone();

          return;
        }

        throw error;
      }
    };

    this.tasks = this.tasks.then(() => task(data));
  }

  fixMetadata(sourceFileEntry, targetFileEntry) {
    return new Promise((resolve, reject) => {
      if (!this.reader.metadatas || this.reader.metadatas.length === 0) {
        resolve();
        return;
      }

      this.reader.stop();

      const refinedMetadataBuf = tools.makeMetadataSeekable(
        this.reader.metadatas,
        this.reader.duration,
        this.reader.cues,
      );

      const skipBytes = this.reader.metadataSize;
      let bytesSkipped = 0;

      sourceFileEntry.file(
        (file) => {
          if (!file) {
            console.warn(this.kind, 'WebmSeekableMetadataFixer: no file opened for source file entry', sourceFileEntry);

            reject();
            return;
          }

          if (typeof file.stream !== 'function') {
            console.warn(
              this.kind,
              'WebmSeekableMetadataFixer: opening file for source file entry failed',
              'file.stream is not a function',
              typeof file.stream,
              sourceFileEntry,
            );

            reject();
            return;
          }

          const stream = file.stream();
          const reader = stream.getReader();
          let processedBytes = 0;

          targetFileEntry.createWriter(
            (writer) => {
              const copy = () => {
                reader
                  .read()
                  .then(({ done, value }) => {
                    if (this.progressCallback) {
                      this.progressCallback({ done, size: file.size, processed: processedBytes });
                    }

                    if (done) {
                      resolve();
                      return;
                    }

                    processedBytes += value.length;

                    if (bytesSkipped < skipBytes) {
                      const toSkip = skipBytes - bytesSkipped;
                      if (toSkip >= value.length) {
                        bytesSkipped += value.length;
                        copy();
                      } else {
                        bytesSkipped += toSkip;

                        const valueToWrite = value.slice(toSkip, value.length);
                        writer.write(new Blob([valueToWrite]));
                      }
                    } else {
                      writer.write(new Blob([value]));
                    }
                  })
                  .catch((error) => {
                    console.warn(this.kind, 'WebmSeekableMetadataFixer: reader error', error);
                    reject();
                  });
              };

              writer.onerror = (error) => {
                console.warn(this.kind, 'WebmSeekableMetadataFixer: writer error', error);
                reject();
              };

              writer.onwriteend = () => copy();

              writer.seek(0);
              writer.write(new Blob([refinedMetadataBuf]), () => copy());
            },
            (error) => {
              console.warn(this.kind, 'WebmSeekableMetadataFixer: creating writer for target file entry failed', error);
              reject();
            },
          );
        },
        (error) => {
          console.warn(this.kind, 'WebmSeekableMetadataFixer: opening source file entry failed', error);
          reject();
        },
      );
    });
  }
}

export default WebmSeekableMetadataFixer;

<template>
  <div
    :class="[
      'bg-white-7',
      'text-center',
      'w-full',
      'p-4',
      'min-h-30',
      'flex',
      'flex-column',
      'items-center',
      'justify-center',
      'z-8',
    ]"
  >
    <h1 class="text-base text-white-1">
      <span v-if="percent < 100">{{ $t('upload.uploading_to_cloud') }}</span>

      <span v-else>{{ $t('upload.uploaded') }}</span>
    </h1>

    <div class="flex items-center mt-2">
      <div class="bg-white-8 rounded-full h-2 relative w-86 mx-auto">
        <div
          class="rounded-full h-full absolute left-0 top-0"
          :class="[
            {
              'bg-primary': percent < 100,
              'bg-green': percent === 100,
            },
          ]"
          :style="progressBarActiveStyle"
        ></div>
      </div>

      <p class="text-white-1 font-black ml-5" :class="[{ 'text-green': percent === 100 }]">{{ percentText }} %</p>

      <Tooltip v-if="percent < 100">
        <p class="text-sm text-left ml-1">
          {{ $t('upload.speed') }}:
          <span class="font-bold">{{ humanFileSize(this.uploadingProgress.totalSpeed) }}/s</span>
          <br />
          {{ $t('upload.eta') }}:
          <span class="font-bold">{{ formatTime(this.uploadingProgress.timeRemaining) }}</span>
          <br />
          {{ $t('upload.files') }}:
          <span class="font-bold">
            {{ this.uploadingProgress.totalFiles - this.uploadingProgress.pendingFiles }}
            /
            {{ this.uploadingProgress.totalFiles }}
          </span>
          <br />
          {{ $t('upload.size') }}:
          <span class="font-bold">
            {{ humanFileSize(this.uploadingProgress.totalUploadedBytes) }}
            /
            {{ humanFileSize(this.uploadingProgress.totalBytes) }}
          </span>
        </p>
      </Tooltip>
    </div>

    <p v-if="percent < 100" class="text-yellow font-black flex items-center mt-1">
      <WarningFullSVG class="fill-yellow w-6 h-6" />

      <span class="ml-2">{{ $t('upload.dont_close_browser') }}</span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Tooltip from '@/components/Tooltip.vue';

import WarningFullSVG from '@/assets/svgs/warning_full.svg';

import { humanFileSize, formatTime } from '@/helpers';

export default {
  name: 'UploadProgress',
  components: { Tooltip, WarningFullSVG },
  mixins: [{ methods: { humanFileSize, formatTime } }],
  computed: {
    ...mapState(['uploadingProgress']),
    progressBarActiveStyle() {
      return `width: ${this.percent}%;`;
    },
    percent() {
      if (this.uploadingProgress.totalProgress >= 100) {
        return 100;
      }

      return Math.min(Math.round(this.uploadingProgress.totalProgress * 10) / 10, 99.9);
    },
    percentText() {
      return Number(this.percent).toFixed(1);
    },
  },
};
</script>

export default function (seconds) {
  seconds = Math.round(seconds);
  const hours = parseInt(seconds / 3600, 10);
  seconds %= 3600;
  const minutes = parseInt(seconds / 60, 10);
  seconds = Math.round(seconds % 60);

  let string = '';

  if (hours > 0) {
    string += `${hours} h`;
  }

  if (minutes > 0) {
    if (string !== '') {
      string += ' ';
    }

    string += `${minutes} m`;
  }

  if (seconds > 0 || string === '') {
    if (string !== '') {
      string += ' ';
    }

    string += `${seconds} s`;
  }

  return string;
}

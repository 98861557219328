import bind from './bind';
import update from './update';
import unbind from './unbind';

export default function (name, listeners, validator) {
  return {
    name,
    directive: {
      bind: (element, binding, vNode) => bind(name, listeners, (el, e) => validator(el, e), element, binding, vNode),
      update: (element, binding) => update(name, element, binding),
      unbind: (element, binding, vNode) => unbind(name, listeners, element, vNode),
    },
  };
}

import cloneDeep from 'lodash.clonedeep';

import requestFileSystemDirectory from '@/helpers/request_file_system_directory';
import parseUrlParams from '@/helpers/parse_url_params';

class Logger {
  constructor(token) {
    if (!Logger._instance) {
      this.token = token;
      this.fileWriter = null;
      this.fileWriterReady = true;
      this.fileChunks = [];
      this.storageQuotaGranted = false;
      this.paused = false;

      this._initCatchUnhandledErrors();
      this._initCatchUnhandledPromiseRecejtions();

      Logger._instance = this;
    }

    // eslint-disable-next-line no-constructor-return
    return Logger._instance;
  }

  _initCatchUnhandledErrors() {
    window.addEventListener('error', (event) => {
      const { message, filename, lineno, colno, error } = event;

      const formattedMessage = [
        `  Message: ${message}`,
        `  URL: ${filename}`,
        `  Line: ${lineno}`,
        `  Column: ${colno}`,
        `  Error object: ${JSON.stringify(error)}`,
        `  Error stack: ${error && error.stack}`,
      ].join('\n');

      console.warn(formattedMessage);
      console.warn(event);

      const content = Logger.formatMessage(`Unhandled error:\n${formattedMessage}`);
      this._writeFile(content);

      return false;
    });
  }

  _initCatchUnhandledPromiseRecejtions() {
    window.addEventListener('unhandledrejection', (event) => {
      const message = `  Message: ${event.reason.message}`;
      const content = Logger.formatMessage(`Unhandled promise rejection:\n${message}`);

      console.warn(content);
      console.warn(event);

      this._writeFile(content);

      return false;
    });
  }

  _writeFileData(message) {
    const blob = new Blob([message], { type: 'text/plain' });

    this.fileWriter.write(blob);
  }

  _writeFile(message, forceAfterStorageQuotaGranted = false) {
    if (this.paused || !this.storageQuotaGranted) {
      this.fileChunks.push(message);
      return;
    }

    const doWrite = () => {
      if (!this.fileWriterReady && !forceAfterStorageQuotaGranted) {
        this.fileChunks.push(message);
        return;
      }

      this.fileWriterReady = false;
      this._writeFileData(message);
    };

    if (this.fileWriter) {
      doWrite();
      return;
    }

    const onWriteEnd = () => {
      if (this.fileChunks.length) {
        this._writeFileData(this.fileChunks.shift());
        return;
      }

      this.fileWriterReady = true;
    };

    requestFileSystemDirectory(this.token, null, 1024 * 1024)
      .then((dirEntry) => {
        dirEntry.getFile(
          Logger.fileName,
          { create: true },
          (fileEntry) => {
            fileEntry.createWriter(
              (fileWriter) => {
                const params = parseUrlParams();

                if (!params.clear_log) {
                  this.fileWriter = fileWriter;
                  this.fileWriter.seek(this.fileWriter.length);
                  this.fileWriter.onwriteend = onWriteEnd;

                  doWrite();

                  return;
                }

                fileWriter.onwriteend = () => {
                  this.fileWriter = fileWriter;
                  this.fileWriter.seek(0);
                  this.fileWriter.onwriteend = onWriteEnd;

                  doWrite();
                };

                fileWriter.truncate(0);
              },
              (error) => console.log('Write log file failed', error),
            );
          },
          (error) => console.log('Write log file failed', error),
        );
      })
      .catch(() => {});
  }

  file() {
    return new Promise((resolve) => {
      requestFileSystemDirectory(this.token, null, 1024 * 1024)
        .then((dirEntry) => {
          dirEntry.getFile(
            Logger.fileName,
            { create: true },
            (fileEntry) => {
              fileEntry.file(
                (file) => resolve(file),
                (error) => {
                  console.warn(error);
                  resolve();
                },
              );
            },
            () => resolve(),
          );
        })
        .catch(() => resolve());
    });
  }

  static pause() {
    const instance = Logger.getInstance();
    instance.paused = true;
  }

  static resume() {
    const instance = Logger.getInstance();
    instance.paused = false;

    if (instance.fileChunks.length === 0) {
      return;
    }

    instance.fileWriterReady = false;
    instance._writeFile(instance.fileChunks.shift(), true);
  }

  static storageQuotaGranted(token) {
    const instance = Logger.getInstance();

    if (!instance.token) {
      instance.token = token;
    }

    if (instance.fileChunks.length === 0) {
      instance.storageQuotaGranted = true;
      return;
    }

    instance.fileWriterReady = false;
    instance.storageQuotaGranted = true;
    instance._writeFile(instance.fileChunks.shift(), true);
  }

  static log(...attrs) {
    const content = Logger.formatMessage(`${attrs.map((attr) => Logger.stripMessage(attr)).join(' ')}`);

    Logger.getInstance()._writeFile(content);
  }

  static getInstance() {
    if (!this._instance) {
      const params = parseUrlParams();
      return new Logger(params.token);
    }

    return this._instance;
  }

  static formatMessage(message) {
    const prefix = `[${new Date().toISOString()}] `;
    const content = `${prefix}${message}\n`;

    return content;
  }

  static stripMessage(message, isNested = false) {
    const toRemove = ['thumbnail_url'];

    if (typeof message === 'undefined' || message === null) {
      if (isNested) {
        return message;
      }

      return JSON.stringify(message);
    }

    if (Array.isArray(message)) {
      message = cloneDeep(message);

      for (let i = 0; i < message.length; i++) {
        message[i] = Logger.stripMessage(message[i], true);
      }

      if (isNested) {
        return message;
      }

      return JSON.stringify(message);
    }

    if (typeof message === 'object') {
      message = cloneDeep(message);

      const keys = Object.keys(message);

      keys.forEach((key) => {
        if (toRemove.includes(key)) {
          delete message[key];
        } else {
          message[key] = Logger.stripMessage(message[key], true);
        }
      });

      if (isNested) {
        return message;
      }

      return JSON.stringify(message);
    }

    return message;
  }

  static get fileName() {
    return 'slrec.log';
  }
}

export default Logger;

import capitalizeFirstLetter from './capitalize_first_letter';

export default function (direction, element, offsetToDocument = false) {
  const d = capitalizeFirstLetter(direction.toLowerCase());

  let e = element;
  let offset = 0;

  do {
    if (!Number.isNaN(Number(e[`offset${d}`]))) {
      offset += e[`offset${d}`];
    }

    e = e.offsetParent;
  } while (offsetToDocument && e);

  return offset;
}

<template>
  <div
    :class="[
      'flex',
      'flex-column',
      'items-stretch',
      'justify-center',
      'px-6',
      'py-4',
      'min-h-17',
      'bg-white-7',
      'border',
      'border-solid',
      'border-transparent',
      'rounded-lg',
      'w-full',
      'text-left',
      {
        'cursor-grab': showHandle,
      },
    ]"
  >
    <div
      v-if="showHandle"
      :class="['handle', 'absolute', 'flex', 'items-center', 'rounded-lg', 'bg-black-4', 'left-0', 'inset-y-0']"
    >
      <VerticalDostSVG class="fill-white-3 w-4 h-4" />
    </div>

    <div class="flex justify-between items-center">
      <SpeakerNameState :name="speaker.name" :isSubmitted="isSubmitted" />

      <Tooltip v-if="!!shareLinkUrl && !shareLinkIsSelfRecording && isSubmitted">
        <template #trigger>
          <Button
            disableMinHeight
            :href="shareLinkUrl"
            :rounded="false"
            target="_blank"
            rel="noopener noreferrer"
            color="btn-transparent-white"
            class="p-0 rounded-full w-8 h-8"
          >
            <template #icon>
              <EyeSVG class="w-6 h-6" />
            </template>
          </Button>
        </template>

        <template #default>
          {{ $t('speakers_list.show_speaker_recording') }}
        </template>
      </Tooltip>

      <Tooltip v-if="showDisabledReason || shareLinkIsSelfRecording" iconSize="large">
        <p class="text-xs">
          <span v-if="shareLinkIsSelfRecording">
            {{ $t('speakers_list.share_link_is_self_recording') }}
          </span>

          <span v-else>
            {{ $t(`speakers_list.disabled_reasons.${speaker.disabled_recording_reason}`) }}
          </span>
        </p>
      </Tooltip>
    </div>

    <Button
      v-if="!isSubmitted && showContinueAs && speaker.recording_enabled"
      :label="$t('speakers_list.continue_as', { name: speaker.name })"
      @click="continueAs"
      block
      color="btn-primary-outline"
      class="mt-4 w-full"
    />
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import SpeakerNameState from '@/components/SpeakerNameState.vue';
import Tooltip from '@/components/Tooltip.vue';

import EyeSVG from '@/assets/svgs/eye.svg';
import VerticalDostSVG from '@/assets/svgs/vertical_dots.svg';

export default {
  name: 'SpeakerItem',
  components: { SpeakerNameState, Button, Tooltip, EyeSVG, VerticalDostSVG },
  props: {
    speaker: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    shareLinkUrl: {
      type: String,
      default: '',
    },
    shareLinkIsSelfRecording: {
      type: Boolean,
      default: false,
    },
    showContinueAs: {
      type: Boolean,
      default: false,
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSubmitted() {
      return !this.speaker.recording_enabled && this.speaker.disabled_recording_reason === 'final_recording_submitted';
    },
    showDisabledReason() {
      if (
        this.speaker.recording_enabled ||
        !this.speaker.disabled_recording_reason ||
        this.speaker.disabled_recording_reason === 'final_recording_submitted'
      ) {
        return false;
      }

      return !!this.speaker.disabled_recording_reason;
    },
  },
  methods: {
    continueAs() {
      this.$emit('continueAs', { index: this.index, ...this.speaker });
    },
  },
};
</script>

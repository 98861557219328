import Logger from '@/modules/logger';

export default function ({ commit, state, getters }, { index = null } = {}) {
  return new Promise((resolve) => {
    const version = getters.currentVersion;

    if (index === null) {
      index = version.editor.history_index;
    }

    if (index < 0) {
      return;
    }

    let newData = {
      id: version.id,
      editor: {
        history_index: index - 1,
      },
    };

    if (version.editor.history_index === version.editor.history.length - 1) {
      newData.editor.history = [...version.editor.history];
      newData.editor.history.push({
        duration: version.duration,
        removed_clips: JSON.parse(JSON.stringify(version.editor.removed_clips)),
        timelines: JSON.parse(JSON.stringify(version.timelines)),
        manually_edited: version.manually_edited,
      });
    }

    const history = JSON.parse(JSON.stringify(version.editor.history[index]));
    newData = {
      ...newData,
      duration: history.duration,
      timelines: history.timelines,
      editor: {
        ...version.editor,
        ...newData.editor,
        removed_clips: history.removed_clips,
      },
      manually_edited: history.manually_edited,
    };

    if (state.player.elapsed > history.duration) {
      commit('player/setPlayerKey', { key: 'elapsed', elapsed: history.duration }, { root: true });
    }

    Logger.log('Editor undo, timelines:', newData.timelines);

    commit('updateVersion', newData);
    resolve();
  });
}

import { v4 as uuidv4 } from 'uuid';

import INITIAL_FILE from '@/store/initial_file';
import INITIAL_FILE_ATTACHMENT from '@/store/initial_file_attachment';
import INITIAL_FILE_RECORDING from '@/store/initial_file_recording';

export default ({ type, typeData, fileName, fileSize, fileUrl, file = null, mimeType = null }) => {
  const fileObject = { ...INITIAL_FILE };

  if (type === 'speaker_recording') {
    fileObject.type = 'speaker_recording';
    fileObject[fileObject.type] = { ...INITIAL_FILE_RECORDING, ...typeData };
  } else if (type === 'slides_recording') {
    fileObject.type = 'slides_recording';
    fileObject[fileObject.type] = { ...INITIAL_FILE_RECORDING, ...typeData };
  } else {
    fileObject.type = 'attachment';
    fileObject[fileObject.type] = { ...INITIAL_FILE_ATTACHMENT, ...typeData };
  }

  fileObject.id = uuidv4();
  fileObject.file_name = fileName;
  fileObject.size_b = fileSize;
  fileObject.url = fileUrl;

  if (file) {
    fileObject.file = file;
  }

  if (mimeType) {
    fileObject.mime_type = mimeType;
  }

  return fileObject;
};

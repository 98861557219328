import requestFileSystemDirectory from './request_file_system_directory';
import readEntries from './read_entries';
import parseVersionJSON from './parse_version_json';
import loadBrokenJSON from './load_broken_json';

function fileNamesFromFileEntries(fileEntries) {
  return fileEntries.map((fileEntry) => fileEntry.name);
}

function findVersionJsonFilesByVersionId(fileEntries) {
  return fileEntries.reduce((result, entry) => {
    const parts = entry.name.split('.');
    const extension = parts.pop();

    if (extension !== 'json') {
      return result;
    }

    const nameParts = parts[0].split('_');
    const lastPart = nameParts[nameParts.length - 1];
    const hasSuffix = nameParts.length > 1 && ['1', '2'].includes(lastPart);
    let name = parts[0];

    if (hasSuffix) {
      nameParts.pop();
      name = nameParts.join('_');
    }

    if (!result[name]) {
      result[name] = [];
    }

    if (hasSuffix) {
      result[name].unshift(entry);
    } else {
      result[name].push(entry);
    }

    return result;
  }, {});
}

function loadVersion(token, speakerId, versionId, versionJsonFiles, localFileNames, store) {
  return new Promise((resolve) => {
    const loadNextVersionJsonFile = () => {
      if (versionJsonFiles.length === 0) {
        loadBrokenJSON(store, versionId)
          .then((version) => resolve(version))
          .catch(() => {
            resolve({
              id: versionId,
              version: 1,
              version_name: '<broken data>',
              recorder_token: token,
              speaker_id: speakerId,
              created_at: new Date().toISOString(),
              broken_data: true,
              files: [],
            });
          });

        return;
      }

      const versionJsonFile = versionJsonFiles.shift();

      versionJsonFile.file(
        (file) => {
          const reader = new FileReader();

          reader.onloadend = (event) => {
            parseVersionJSON(event.target.result, localFileNames)
              .then((version) => resolve(version))
              .catch(() => {
                loadNextVersionJsonFile();
              });
          };

          reader.readAsText(file);
        },
        () => {
          loadNextVersionJsonFile();
        },
      );
    };

    loadNextVersionJsonFile();
  });
}

export default function (store, token, speakerId) {
  return requestFileSystemDirectory(token, speakerId)
    .then((dirEntry) => {
      const dirReader = dirEntry.createReader();

      return readEntries(dirReader);
    })
    .then((entries) => {
      const fileNames = fileNamesFromFileEntries(entries);
      const versionJsonFilesByVersionId = findVersionJsonFilesByVersionId(entries);
      const versionIds = Object.keys(versionJsonFilesByVersionId);

      if (versionIds.length === 0) {
        return null;
      }

      const promises = versionIds.map((versionId) =>
        loadVersion(token, speakerId, versionId, versionJsonFilesByVersionId[versionId], fileNames, store),
      );

      return Promise.all(promises);
    })
    .then((versions) => {
      if (!versions) {
        return Promise.resolve({});
      }

      const versionsById = versions.reduce((versionById, version) => {
        versionById[version.id] = version;
        return versionById;
      }, {});

      return Promise.resolve(versionsById);
    });
}

<template>
  <div class="text-center container-md px-4 py-8">
    <LanguageSelect class="fixed top-4 right-7 w-35" />

    <h1 class="text-white-1" v-html="$t('browser_error.title')"></h1>

    <p class="font-light mt-6">
      <a class="break-word" :href="$t('browser_error.link')" target="_blank" rel="noopener noreferrer">
        {{ $t('browser_error.link') }}
      </a>
      <br />
      {{ $t('browser_error.text') }}
    </p>

    <router-link :to="{ name: 'Help' }" custom #default="{ href, navigate }">
      <a :href="href" class="fixed bottom-4 right-7" @click="navigate">
        {{ $t('header.user_menu.help') }}
      </a>
    </router-link>
  </div>
</template>

<script>
import Logger from '@/modules/logger';

import LanguageSelect from '@/components/LanguageSelect.vue';

export default {
  name: 'BrowserError',
  components: { LanguageSelect },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  created() {
    Logger.log(`Browser error: ${JSON.stringify(this.errors)}`);

    this.$nextTick(() => {
      document.title = `${this.$i18n.t('meta_title.browser_error')} · ${this.$i18n.t('app_name')}`;
    });
  },
};
</script>

import Logger from '@/modules/logger';

import { makeRequest } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ state, commit }, { count }) {
  const requestData = {
    speakers_token: state.speakersToken,
    speaker_count: count,
  };

  Logger.log(`Update speaker count: ${count}`);

  return makeRequest('POST', `${API_URL}/tokens/${state.tokenId}/speakers/count`, requestData).then((response) => {
    commit('setKey', { key: 'speakers', speakers: response.data });
    commit('setKey', { key: 'speakersInitialized', speakersInitialized: true });
  });
}

export default function () {
  return {
    tokenId: null,
    recorderToken: null,
    speakersToken: null,

    supportEmail: '',

    conferenceName: '',
    presentationTitle: '',
    presentationDurationS: 0,
    deadlineAt: null,

    multiplePartsRecordingEnabled: true,
    allowSelfUploadWithOneFile: false,
    allowUploadWithoutAttachedFiles: false,

    speakers: [],
    uploads: [],

    speakersInitialized: false,
    lastDataFetchAt: null,
    initialHistoryLength: 0,

    isLoaded: false,
    isLoading: true,

    recordedTokens: [],
    localSpeakers: [],

    registerSpeaker: null,

    grantedBytes: null,

    loggedInSpeakerId: null,

    versions: {},
    currentVersionId: null,
    submitVersionId: null,

    devices: [],

    cameraRequested: true,
    cameraEnabled: false,
    cameraId: null,
    cameraErrors: [],
    speakerStream: null,
    microphoneRequested: true,
    microphoneEnabled: false,
    microphoneId: null,
    microphoneErrors: [],
    screenSharingRequested: true,
    screenSharingEnabled: false,
    screenSharingStream: null,
    screenSharingErrors: [],
    speakerRecorderProgress: '',
    screenSharingRecorderProgress: '',

    notifications: [],

    selfRecording: {
      files: [],
      links: [],
      upload_id: null,
      feedback: {
        value: null,
        note: '',
        uuid: null,
      },
    },

    speakerRecorder: null,
    screenSharingRecorder: null,

    recordingStartRequested: false,
    isRecording: false,
    isPaused: false,
    isRestoring: false,
    isWaitingForWebMFiles: false,
    isWaitingForStreams: false,
    isWaitingForSpeakerStream: false,
    isWaitingForScreenSharingStream: false,

    isCountdownModalActive: false,
    recordingCountdownValue: 0,
    lastRecordingDuration: 0,
    recordingStartedAt: null,
    recordingErrors: [],

    isUploading: false,
    isUploadVisible: false,
    currentUploadId: null,
    uploadingErrors: [],
    uploadingFilesErrors: [],
    uploadingProgress: {
      pendingFiles: 0,
      timeRemaining: 0,
      totalBytes: 0,
      totalFiles: 0,
      totalProgress: 0,
      totalSpeed: 0,
      totalUploadedBytes: 0,
    },

    localDownloadStream: null,
    cloudDownloadProgress: {
      pendingFiles: 0,
      totalBytes: 0,
      totalFiles: 0,
      totalProgress: 0,
      totalDownloadedBytes: 0,
    },
  };
}

export default function () {
  return new Promise((resolve, reject) => {
    navigator.webkitPersistentStorage.queryUsageAndQuota(
      (usage, quota) => {
        resolve({ usage, quota });
      },
      (error) => {
        const errors = [];

        errors.push('unable_to_get_available_storage');

        reject(errors, error);
      },
    );
  });
}

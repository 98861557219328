<template>
  <button
    type="button"
    @click.prevent.stop
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @mousedown="handleMousedown"
    @focusin="handleMouseenter"
    @focusout="handleMouseleave"
    class="bg-transparent border-none rounded-0 cursor-pointer w-full h-4 group outline-none"
  >
    <div
      class="bg-accent absolute my-auto inset-0 z-2"
      :class="[
        {
          'h-1': !isProgressBarActive,
          'h-2': isProgressBarActive,
        },
      ]"
      :style="progressBarStyle"
    >
      <div
        :class="[
          'border-none',
          'bg-accent',
          'absolute',
          'inset-y-0',
          'right-0',
          'translate-x-1/2',
          'm-auto',
          'rounded-full',
          'outline-none',
          'group-focus:outline-white',
          {
            'h-3': !isProgressBarActive,
            'w-3': !isProgressBarActive,
            'h-4': isProgressBarActive,
            'w-4': isProgressBarActive,
          },
        ]"
      />
    </div>

    <div
      class="absolute inset-0 my-auto events-none z-1"
      :class="[
        {
          'h-1': !isProgressBarActive,
          'h-2': isProgressBarActive,
        },
      ]"
    >
      <div
        v-for="(part, index) of parts"
        :key="index"
        class="absolute top-0 h-full rounded-1 border border-solid border-white-4 bg-white-7"
        :style="partStyle(index)"
      />
    </div>
  </button>
</template>

<script>
import { getEventPageX, getOffset } from '@/helpers';

export default {
  name: 'PlayerProgressBar',
  props: {
    elapsed: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      required: true,
    },
    parts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isProgressBarActive: false,
      isMouseDown: false,
      isMouseIn: false,
      mouseupHandler: this.handleMouseupDocument.bind(this),
      mousemoveHandler: this.handleMousemoveDocument.bind(this),
    };
  },
  computed: {
    pct() {
      return (this.elapsed * 100) / this.duration;
    },
    progressBarStyle() {
      return `width: ${Math.min(this.pct, 100)}%`;
    },
  },
  methods: {
    partStyle(index) {
      const width = (this.parts[index] * 100) / this.duration;
      let left = 0;

      for (let i = 0; i < index; i++) {
        left += this.parts[i] / this.duration;
      }

      left *= 100;

      return `left: ${left}%; width: ${width}%`;
    },
    handleMouseupDocument(event) {
      this.endMove(event);

      this.isMouseDown = false;

      if (this.isMouseIn) {
        return;
      }

      this.setInactive();
    },
    handleMousemoveDocument(event) {
      if (!this.isMouseDown) {
        return;
      }

      this.followMouse(event);
    },
    handleMouseenter() {
      this.setActive();
    },
    handleMouseleave() {
      this.setInactive();
    },
    handleMousedown(event) {
      this.isMouseDown = true;
      this.startMove(event);
    },
    startMove(event) {
      const pct = this.mousePositionPct(event);
      this.$emit('startMove', pct);
    },
    endMove() {
      this.$emit('endMove');
    },
    followMouse(event) {
      const pct = this.mousePositionPct(event);
      this.$emit('updateMove', pct);
    },
    setActive() {
      this.isMouseIn = true;

      document.addEventListener('mouseup', this.mouseupHandler, true);
      document.addEventListener('mousemove', this.mousemoveHandler, true);

      this.isProgressBarActive = true;
    },
    setInactive() {
      this.isMouseIn = false;

      if (this.isMouseDown) {
        return;
      }

      document.removeEventListener('mouseup', this.mouseupHandler, true);
      document.removeEventListener('mousemove', this.mousemoveHandler, true);

      this.isProgressBarActive = false;
    },
    mousePositionPct(event) {
      const mouseX = getEventPageX(event) - getOffset('left', this.$el, true) || 0;
      const pct = Math.max(0, Math.min(100, (mouseX / this.$el.clientWidth) * 100));

      return pct;
    },
  },
  beforeDestroy() {
    if (!this.isMouseDown) {
      return;
    }

    document.removeEventListener('mouseup', this.mouseupHandler, true);
    document.removeEventListener('mousemove', this.mousemoveHandler, true);
  },
};
</script>

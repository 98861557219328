<template>
  <transition name="fade">
    <div
      v-if="computedActive"
      v-escape-pressed="close"
      role="dialog"
      :aria-labelledby="labeledBy"
      :aria-describedby="describedBy"
      class="inset-0 fixed z-10 overflow-hidden bg-blur-transiotion"
      :class="active && 'bg-blurred'"
    >
      <div class="bg-real-black-2 fixed inset-0 -z-1" />

      <div class="flex p-7 h-full overflow-x-hidden overflow-y-auto">
        <FocusTrap v-if="emptyContent" class="m-auto">
          <slot :close="close" />
        </FocusTrap>

        <FocusTrap
          v-else
          v-click-outside="close"
          :class="[
            'bg-black',
            'relative',
            'm-auto',
            'flex',
            'flex-column',
            'rounded-lg',
            'p-2',
            'md:p-4',
            'z-1',
            'w-9/10',
            'min-h-16',
            {
              'lg:container-sm': size === 'small',
              'lg:container-md': size === 'medium',
              'lg-container-lg': size === 'large',
              'lg:container-xl': size === 'xlarge',
            },
          ]"
        >
          <div v-if="$slots.header" class="pb-2">
            <slot name="header" :close="close" />
          </div>

          <div
            class="overflow-auto flex flex-column items-stretch"
            :class="[
              {
                'pt-2': $slots.header,
                'pb-2': $slots.footer,
              },
            ]"
          >
            <slot :close="close" />
          </div>

          <div v-if="$slots.footer" class="pt-2">
            <slot name="footer" :close="close" />
          </div>

          <Tooltip v-if="closeable">
            <template #trigger>
              <Button
                disableMinHeight
                :rounded="false"
                @click="close"
                color="btn-transparent-white"
                class="absolute right-3 top-3 p-0 rounded-full w-10 h-10"
              >
                <template #icon>
                  <CrossSVG class="w-6 w-6 fill-white-1" />
                </template>
              </Button>
            </template>

            <template #default>
              <span class="no-wrap">{{ $t('close') }}</span>
            </template>
          </Tooltip>
        </FocusTrap>
      </div>
    </div>
  </transition>
</template>

<script>
import FocusTrap from 'vue-focus-lock';

import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import CrossSVG from '@/assets/svgs/cross.svg';

export default {
  name: 'Modal',
  components: { FocusTrap, Button, Tooltip, CrossSVG },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validate: (value) => ['small', 'medium', 'large', 'xlarge'].includes(value),
    },
    labeledBy: {
      type: String,
      default: 'h1',
    },
    describedBy: {
      type: String,
      default: 'p',
    },
    emptyContent: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newActive: false,
    };
  },
  computed: {
    computedActive: {
      get() {
        return this.newActive;
      },
      set(active) {
        this.newActive = active;
        document.querySelector('body').classList.toggle('overflow-hidden', active);
      },
    },
  },
  watch: {
    active(active) {
      if (active === this.computedActive) {
        return;
      }

      this.computedActive = active;
    },
  },
  methods: {
    close() {
      if (!this.closeable) {
        return;
      }

      this.computedActive = false;
      this.emitEvent('close');
    },
    open() {
      this.computedActive = true;
      this.emitEvent('open');
    },
    emitEvent(event) {
      this.$emit(event);
    },
  },
  mounted() {
    this.computedActive = this.active;
  },
};
</script>

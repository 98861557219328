<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="m-auto py-4">
      <Spinner />
    </div>

    <ContainerWithHeaderAndBack v-else hideBack hideDeadline>
      <ShareContent
        :token="data.token"
        :version="data.version"
        :upload="data.upload"
        :speaker="data.speaker"
        class="flex-1 flex flex-column w-full py-4"
      />
    </ContainerWithHeaderAndBack>
  </transition>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import Spinner from '@/components/Spinner.vue';
import ShareContent from '@/components/ShareContent.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import { parseUrlParams, mergeVersionWithUpload } from '@/helpers';

export default {
  name: 'Share',
  mixins: [ErrorNotificationsMixin],
  components: {
    ContainerWithHeaderAndBack,
    Spinner,
    ShareContent,
  },
  data() {
    return {
      data: {},
      loading: true,
    };
  },
  computed: {
    urlParams() {
      return parseUrlParams();
    },
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    ...mapActions(['fetchShareData']),
  },
  created() {
    this.loading = true;

    this.fetchShareData({ tokenId: this.urlParams.share, shareToken: this.urlParams.s })
      .then((data) => {
        data.version.files = data.version.files.map((file) => {
          file.deleted = !file.url;
          return file;
        });

        data.version = mergeVersionWithUpload(data.version, data.upload);

        this.data = data;
        this.loading = false;
      })
      .catch((error) => this.notificationFromError([error, 'contact_support']));
  },
};
</script>

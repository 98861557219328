<script>
export default {
  name: 'SlotComponent',
  props: {
    component: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'default',
    },
    scoped: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    tag: {
      type: String,
      default: 'div',
    },
    event: {
      type: String,
      default: 'hook:updated',
    },
  },
  methods: {
    refresh() {
      this.$forceUpdate();
    },
  },
  created() {
    this.component.$on(this.event, this.refresh);
  },
  beforeDestroy() {
    this.component.$off(this.event, this.refresh);
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        attrs: this.$attrs,
        on: this.$listeners,
      },
      this.scoped ? this.component.$scopedSlots[this.name](this.props) : this.component.$slots[this.name],
    );
  },
};
</script>

import { downloadZip } from 'client-zip';

import Logger from '@/modules/logger';
import StreamSaver from '@/modules/stream_saver';

import { requestFileSystemDirectory, readEntries } from '@/helpers';

const fileFromFileEntry = (fileEntry) => {
  return new Promise((resolve) => {
    fileEntry.file(
      (file) => resolve(file),
      (error) => {
        resolve(null);
        console.warn(error);
      },
    );
  });
};

const getFiles = (dirEntry, speakerId) => {
  return new Promise((resolve, reject) => {
    const dirReader = dirEntry.createReader();

    readEntries(dirReader)
      .then((rootDirEntries) => {
        const logFileEntry = rootDirEntries.find((entry) => entry.name === Logger.fileName);
        const speakerDirEntry = rootDirEntries.find((entry) => entry.isDirectory && entry.name === speakerId);

        const speakerDirReader = speakerDirEntry.createReader();
        return Promise.all([logFileEntry, readEntries(speakerDirReader)]);
      })
      .then(([logFileEntry, entries]) => {
        const filePromises = [
          fileFromFileEntry(logFileEntry),
          ...entries.map((fileEntry) => fileFromFileEntry(fileEntry)),
        ];

        Promise.all(filePromises).then((files) => {
          resolve(files.filter((file) => !!file));
        });
      })
      .catch((error) => reject(error));
  });
};

export default function ({ commit, state }) {
  return requestFileSystemDirectory(state.recorderToken)
    .then((dirEntry) => {
      Logger.pause();

      return getFiles(dirEntry, state.loggedInSpeakerId);
    })
    .then((files) => {
      const archiveName = `slideslive_archive-${state.recorderToken}`;
      const response = downloadZip(files);
      const fileStream = StreamSaver.createWriteStream(`${archiveName}.zip`, {
        autoClose: false,
      });

      Logger.log(`Download all versions, token: ${state.recorderToken}, files: ${files.length}`);

      commit('setKey', { key: 'localDownloadStream', localDownloadStream: fileStream });

      return response.body.pipeTo(fileStream);
    })
    .then(() => {
      Logger.resume();

      commit('setKey', { key: 'localDownloadStream', localDownloadStream: null });
    })
    .catch((error) => {
      console.error(error);
    });
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"inline-flex justify-center items-center list-style-none -mx-2 group"},_vm._l((5),function(n){return _c('li',{key:n},[_c('button',{class:[
        'leading-0',
        'border-none',
        'bg-transparent',
        'radius-0',
        'mx-2',
        'outline-none',
        'rounded-lg',
        'group-hover:opacity-3',
        'hover:opacity',
        'focus:opacity',
        'focus:outline-white',
      ],attrs:{"type":"button","disabled":_vm.disabled,"title":n},on:{"click":function($event){return _vm.itemClicked(n)}}},[_c(`Feedback${n}SVG`,{tag:"component",staticClass:"w-16 h-16",class:[
          {
            'fill-white-1': _vm.value !== n,
            [_vm.activeItemColor(n)]: _vm.value === n,
          },
        ]})],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
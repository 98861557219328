import { mapState } from 'vuex';

import Logger from '@/modules/logger';

import { INACTIVITY_REFETCH_MS } from '@/constants';

export default {
  data() {
    return {
      inactivityTimer: null,
      inactivityTimerFinised: false,
      actionInactivityHandler: this.actionInactivityPerformed.bind(this),
      visibilityChangeHandler: this.visibilityChange.bind(this),
    };
  },
  computed: {
    ...mapState(['isRecording', 'isUploading', 'lastDataFetchAt']),
    refetchDisabled() {
      return this.isFetchDisabled || this.isUnknownUser || ['Share', 'Embed'].includes(this.$route.name);
    },
    documentHiddenKey() {
      if ('hidden' in document) {
        return 'hidden';
      }

      if ('webkitHidden' in 'document') {
        return 'webkitHidden';
      }

      if ('mozHidden' in document) {
        return 'mozHidden';
      }

      if ('msHidden' in document) {
        return 'msHidden';
      }

      return null;
    },
    documentVisibilityChangeListener() {
      if ('hidden' in document) {
        return 'visibilitychange';
      }

      if ('msHidden' in document) {
        return 'webkitvisibilitychange';
      }

      if ('mozHidden' in document) {
        return 'mozvisibilitychange';
      }

      if ('webkitHidden' in 'document') {
        return 'msvisibilitychange';
      }

      return null;
    },
    inactivityEvents() {
      return ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    },
  },
  methods: {
    refetchDataAfterInactivity() {
      if (this.refetchDisabled) {
        return;
      }

      Logger.log('Refetch token data after inactivity');

      this.showLoading();
      this.fetchTokenData({ token: this.token })
        .catch((error) => this.notificationFromError([error, 'contact_support']))
        .finally(() => this.hideLoading());

      this.resetInactivityTimer();
    },
    clearInactivityTimer() {
      if (!this.inactivityTimer) {
        return;
      }

      clearTimeout(this.inactivityTimer);
    },
    resetInactivityTimer() {
      if (this.inactivityTimerFinised) {
        this.inactivityTimerFinised = false;
        this.refetchDataAfterInactivity();
      }

      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.inactivityTimerFinised = true;
      }, INACTIVITY_REFETCH_MS);
    },
    actionInactivityPerformed() {
      if (this.isRecording || this.isUploading) {
        return;
      }

      this.resetInactivityTimer();
    },
    visibilityChange() {
      if (this.isRecording || this.isUploading) {
        return;
      }

      if (document[this.documentHiddenKey]) {
        this.clearInactivityTimer();
        return;
      }

      const now = new Date().getTime();

      if (now - this.lastDataFetchAt < INACTIVITY_REFETCH_MS) {
        this.resetInactivityTimer();
        return;
      }

      this.refetchDataAfterInactivity();
    },
  },
  watch: {
    isRecording(value) {
      if (this.refetchDisabled) {
        return;
      }

      if (value) {
        this.clearInactivityTimer();
        return;
      }

      if (!this.documentHiddenKey || !document[this.documentHiddenKey]) {
        this.resetInactivityTimer();
      }
    },
    isUploading(value) {
      if (this.refetchDisabled) {
        return;
      }

      if (value) {
        this.clearInactivityTimer();
        return;
      }

      if (!this.documentHiddenKey || !document[this.documentHiddenKey]) {
        this.resetInactivityTimer();
      }
    },
  },
  mounted() {
    if (this.refetchDisabled) {
      return;
    }

    if (this.documentVisibilityChangeListener) {
      document.addEventListener(this.documentVisibilityChangeListener, this.visibilityChangeHandler, false);
    }

    this.actionInactivityHandler();
    this.inactivityEvents.forEach((name) => {
      document.addEventListener(name, this.actionInactivityHandler, true);
    });
  },
  beforeDestroy() {
    if (this.refetchDisabled) {
      return;
    }

    this.clearInactivityTimer();

    if (this.documentVisibilityChangeListener) {
      document.removeEventListener(this.documentVisibilityChangeListener, this.visibilityChangeHandler, false);
    }

    this.inactivityEvents.forEach((name) => {
      document.removeEventListener(name, this.actionInactivityHandler, true);
    });
  },
};

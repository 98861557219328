<template>
  <Form @submit="submit">
    <Input
      v-model="name"
      name="name"
      type="text"
      :placeholder="$t('placeholders.name')"
      class="w-78 mx-auto"
      required
      :validateOnBlur="false"
    />

    <Input
      v-model="email"
      name="email"
      type="email"
      :placeholder="$t('placeholders.email')"
      class="w-78 mt-2 mx-auto"
      required
      :validateOnBlur="false"
    />

    <Button class="w-54 mt-6" type="submit" :loading="inProgress" :label="$t('continue')" />

    <p class="text-xs mt-2">{{ $t('register.no_password_required') }}</p>
  </Form>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Button from '@/components/Button.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/Input.vue';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

export default {
  name: 'RegisterForm',
  components: { Button, Form, Input },
  mixins: [ErrorNotificationsMixin],
  data() {
    return {
      id: null,
      name: '',
      email: '',
      inProgress: false,
    };
  },
  computed: {
    ...mapState(['speakersInitialized', 'speakers', 'localSpeakers']),
  },
  methods: {
    ...mapMutations(['setKey', 'logout']),
    ...mapActions(['loginSpeaker']),
    submit() {
      this.inProgress = true;

      this.logout();

      if (this.id) {
        this.loginSpeaker({ id: this.id })
          .then(() => {
            this.$router.push({ name: 'About' });
          })
          .catch((error) => {
            this.notificationFromError(error);
          })
          .finally(() => {
            this.inProgress = false;
          });

        return;
      }

      const payload = {
        name: this.name,
        email: this.email,
      };

      this.setKey({ key: 'registerSpeaker', registerSpeaker: payload });

      if (!this.speakersInitialized) {
        this.$router.push({ name: 'SpeakersCount' });
        this.inProgress = false;
        return;
      }

      this.$router.push({ name: 'Speakers' });
      this.inProgress = false;
    },
  },
  // ToDo autocomplete existing speakers
};
</script>

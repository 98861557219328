<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LocalDownloadUnloadListeners',
  computed: {
    ...mapState(['localDownloadStream']),
  },
  data() {
    return { unloadListenerPresent: false };
  },
  watch: {
    localDownloadStream(newValue) {
      if (!newValue) {
        this.removeUnloadListener();
        return;
      }

      this.addUnloadListener();
    },
  },
  methods: {
    checkActiveDownload() {
      if (!this.localDownloadStream) {
        return;
      }

      this.localDownloadStream.abort();
    },
    addUnloadListener() {
      if (this.unloadListenerPresent) {
        return;
      }

      this.unloadListenerPresent = true;
      window.addEventListener('beforeunload', this.checkActiveDownload.bind(this), false);
    },
    removeUnloadListener() {
      if (!this.unloadListenerPresent) {
        return;
      }

      this.unloadListenerPresent = false;
      window.removeEventListener('beforeunload', this.checkActiveDownload.bind(this), false);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.localDownloadStream) {
      return;
    }

    this.$buefy.dialog.confirm({
      message: this.$i18n.t('download.leave_message'),
      onConfirm: () => {
        this.localDownloadStream.abort();
        next(false);
      },
      onCancel: () => {
        next();
      },
    });
  },
};
</script>

import i18n from '@/plugins/i18n';

import brokenJSONs from './broken_jsons';
import getVideoThumbnail from './get_video_thumbnail';
import upsertJSONFile from './upsert_json_file';

function generateTimelines(version) {
  version.recordings.forEach((r) => {
    const file = version.files.find((f) => f.id === r.speaker_recording_file_id);
    const speaker = {
      file_id: r.speaker_recording_file_id,
      start_ms: version.duration,
      in_cut_ms: 0,
      duration_ms: file.speaker_recording.duration_ms,
    };
    const slides = {
      file_id: r.slides_recording_file_id,
      start_ms: version.duration,
      in_cut_ms: 0,
      duration_ms: file.speaker_recording.duration_ms,
    };

    version.duration += file.speaker_recording.duration_ms;

    version.timelines.speaker.video.push({ type: 'clip', clip: { ...speaker } });
    version.timelines.speaker.audio.push({ type: 'clip', clip: { ...speaker } });
    version.timelines.slides.video.push({ type: 'clip', clip: { ...slides } });
    version.timelines.slides.audio.push({ type: 'clip', clip: { ...slides } });
  });
}

function generateThumbnails(version) {
  return new Promise((resolve) => {
    let counter = 0;

    version.timelines.slides.video.forEach((v, i) => {
      const fileId = v[v.type].file_id;
      const file = version.files.find((f) => f.id === fileId);

      getVideoThumbnail(file.url)
        .then((url) => {
          version.timelines.speaker.video[i].frontend = {
            thumbnail_url: url,
          };

          if (++counter >= version.timelines.speaker.video.length) {
            resolve();
          }
        })
        .catch(() => {
          if (++counter >= version.timelines.speaker.video.length) {
            resolve();
          }
        });
    });
  });
}

export default function (store, versionId) {
  if (!(versionId in brokenJSONs)) {
    return Promise.reject(new Error('no_fix_for_broken_json_file'));
  }

  const email = store.state.supportEmail || 'support@slideslive.com';
  let content = i18n.t('errors.reconstructing_broken_json');
  content += '<br>';
  content += i18n.t(`errors.contact_support`, { email });

  const notificationId = store.commit('addNotification', {
    type: 'info',
    content,
    code: 'reconstructing_broken_json::contact_support',
    spinner: true,
    uniq: true,
  });

  const version = brokenJSONs[versionId];

  generateTimelines(version);

  return generateThumbnails(version)
    .then(() => upsertJSONFile(version))
    .then(() => version)
    .finally(() => {
      store.commit('deleteNotification', { id: notificationId });
    });
}

import Vue from 'vue';

export default function (i18n, to) {
  Vue.nextTick(() => {
    const routerName = to.meta && to.meta.title ? i18n.t(`meta_title.${to.meta.title}`) : null;
    const prefix = routerName ? `${routerName} · ` : '';

    document.title = `${prefix}${i18n.t('app_name')}`;
  });
}

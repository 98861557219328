var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"flex items-center w-full relative",class:_vm.sortableClass},[(!_vm.hideNumber)?_c('Position',{class:[
      'mr-6',
      {
        'absolute': _vm.floatingNumber,
        'right-full': _vm.floatingNumber,
      },
    ],attrs:{"number":"css"}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.computedActive)?_c('div',{directives:[{name:"escape-pressed",rawName:"v-escape-pressed",value:(_vm.close),expression:"close"}],staticClass:"inset-0 fixed z-10 overflow-hidden bg-blur-transiotion",class:_vm.active && 'bg-blurred',attrs:{"role":"dialog","aria-labelledby":_vm.labeledBy,"aria-describedby":_vm.describedBy}},[_c('div',{staticClass:"bg-real-black-2 fixed inset-0 -z-1"}),_c('div',{staticClass:"flex p-7 h-full overflow-x-hidden overflow-y-auto"},[(_vm.emptyContent)?_c('FocusTrap',{staticClass:"m-auto"},[_vm._t("default",null,{"close":_vm.close})],2):_c('FocusTrap',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],class:[
          'bg-black',
          'relative',
          'm-auto',
          'flex',
          'flex-column',
          'rounded-lg',
          'p-2',
          'md:p-4',
          'z-1',
          'w-9/10',
          'min-h-16',
          {
            'lg:container-sm': _vm.size === 'small',
            'lg:container-md': _vm.size === 'medium',
            'lg-container-lg': _vm.size === 'large',
            'lg:container-xl': _vm.size === 'xlarge',
          },
        ]},[(_vm.$slots.header)?_c('div',{staticClass:"pb-2"},[_vm._t("header",null,{"close":_vm.close})],2):_vm._e(),_c('div',{staticClass:"overflow-auto flex flex-column items-stretch",class:[
            {
              'pt-2': _vm.$slots.header,
              'pb-2': _vm.$slots.footer,
            },
          ]},[_vm._t("default",null,{"close":_vm.close})],2),(_vm.$slots.footer)?_c('div',{staticClass:"pt-2"},[_vm._t("footer",null,{"close":_vm.close})],2):_vm._e(),(_vm.closeable)?_c('Tooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{staticClass:"absolute right-3 top-3 p-0 rounded-full w-10 h-10",attrs:{"disableMinHeight":"","rounded":false,"color":"btn-transparent-white"},on:{"click":_vm.close},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CrossSVG',{staticClass:"w-6 w-6 fill-white-1"})]},proxy:true}],null,false,3313522144)})]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.$t('close')))])]},proxy:true}],null,false,3771979850)}):_vm._e()],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="flex absolute -translate-x-px top-0 bottom-5 z-1" :style="`left: ${computedLeft}px;`">
    <div class="border-y-0 border-x border-solid border-accent-1 h-full" />

    <div
      :class="[
        'absolute',
        'flex',
        'overflow-visible',
        'w-auto',
        'events-none',
        'bottom-full',
        'left-0',
        'pb-2',
        {
          '-left-4': isAtBeginning,
          'right-0': !isAtBeginning,
          'justify-center': !isAtBeginning,
        },
      ]"
    >
      <div
        class="absolute w-4 h-4 arrow-bottom arrow-black-1 -bottom-2"
        :class="[
          {
            'left-4': isAtBeginning,
            '-ml-2': isAtBeginning,
            'translate-x-px': isAtBeginning,
          },
        ]"
      />

      <div
        :class="[
          'text-left',
          'rounded',
          'text-xs',
          'font-normal',
          'py-2',
          'px-4',
          'relative',
          'text-white-1',
          'bg-black-1',
        ]"
      >
        <p class="text-xs font-black pr-10">{{ formatteddElapsed }}</p>

        <Tooltip>
          <template #trigger>
            <Button
              disableMinHeight
              :rounded="false"
              :loading="loading"
              color="btn-accent"
              class="absolute right-0 inset-y-0 w-10 p-0 rounded-r events-all"
              @click.stop="cut"
            >
              <template #icon>
                <ScissorsSVG class="w-6 w-6" />
              </template>
            </Button>
          </template>

          <template #default>
            <span class="no-wrap max-w">
              <span v-html="$t('editor.cut_tooltip')" />
            </span>
          </template>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip.vue';
import Button from '@/components/Button.vue';

import ScissorsSVG from '@/assets/svgs/scissors.svg';

import { formatSeconds } from '@/helpers';

export default {
  name: 'EditorCurrentPosition',
  components: { Tooltip, Button, ScissorsSVG },
  props: {
    elapsed: {
      type: Number,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedLeft() {
      return Math.round((this.elapsed / 1000) * this.scale);
    },
    isAtBeginning() {
      return this.computedLeft < 35;
    },
    formatteddElapsed() {
      return formatSeconds(this.elapsed / 1000);
    },
  },
  methods: {
    cut() {
      this.$emit('cut');
    },
  },
};
</script>

<template>
  <div>
    <p class="text-white-1">{{ name }}</p>

    <p v-if="isUploading" class="text-white-1 text-xs font-black flex items-center">
      <CloudUploadSVG class="fill-primary w-4 h-4 mr-1" />
      {{ $t('version.uploading') }}
    </p>

    <p v-else-if="isSubmitted" class="text-green text-xs font-black flex items-center">
      <CheckLineSVG class="fill-green w-4 h-4 mr-1" />
      {{ $t('version.submitted') }}
    </p>

    <p v-else class="text-yellow text-xs font-black flex items-center">
      <WarningFullSVG class="fill-yellow w-4 h-4 mr-1" />
      {{ $t('version.not_submitted') }}
    </p>
  </div>
</template>

<script>
import CloudUploadSVG from '@/assets/svgs/cloud_upload.svg';
import CheckLineSVG from '@/assets/svgs/check_line.svg';
import WarningFullSVG from '@/assets/svgs/warning_full.svg';

export default {
  name: 'SpeakerNameState',
  components: { CloudUploadSVG, CheckLineSVG, WarningFullSVG },
  props: {
    name: {
      type: String,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

export default function (version, upload) {
  if (!upload) {
    return version;
  }

  version.upload_id = upload.id;
  version.version_name = upload.name;
  version.recorded_at = upload.recorded_at;
  version.final_recording = upload.final_recording;
  version.upload_token = upload.upload_token;
  version.upload_complete = upload.upload_complete;
  version.share_link_url = upload.share_link_url;

  return version;
}

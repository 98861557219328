import { addBreadcrumb as SentryAddBreadcrumb, Severity } from '@sentry/browser';
import Logger from '@/modules/logger';

import { makeRequest, sendErrorToSentry, errorToObject } from '@/helpers';

import { API_URL } from '@/constants';

export default function ({ commit }, { token }) {
  return new Promise((resolve, reject) => {
    if (!token) {
      const errors = [new Error('token_is_required')];
      reject(errors);

      return;
    }

    commit('setKey', { key: 'lastDataFetchAt', lastDataFetchAt: new Date().getTime() });

    makeRequest('GET', `${API_URL}/tokens/${encodeURIComponent(token)}`)
      .then((response) => {
        SentryAddBreadcrumb({
          category: 'fetch_token_data',
          message: `Response data`,
          data: Object.keys(response).reduce((obj, key) => {
            obj[key] = typeof response[key] === 'boolean' ? `${response[key]}` : response[key];

            return obj;
          }, {}),
          level: Severity.Debug,
        });
        Logger.log(`FetchTokenData: ${JSON.stringify(response.data)}`);

        commit('setTokenData', response.data);

        resolve(response);
      })
      .catch((error) => {
        if (error.customMessage === 'page_not_found' && error.statusCode === 404) {
          error.message = 'token_not_found';
        }

        Logger.log(`FetchTokenData error: ${error.name}; ${JSON.stringify(errorToObject(error))}`);

        const ignoredErrorNames = ['TypeError', 'AbortError'];
        const ignoredErrorMessages = ['token_not_found'];

        if (!ignoredErrorNames.includes(error.name) && !ignoredErrorMessages.includes(error.message)) {
          sendErrorToSentry('FETCH SHARE DATA ERROR', error);
        }

        if (error.message !== 'token_not_found') {
          sendErrorToSentry('FETCH TOKEN DATA ERROR', error);
        }

        reject(error);
      });
  });
}

<template>
  <div
    :class="[
      'flex',
      'items-center',
      'justify-between',
      'text-white-3',
      'text-base',
      'placeholder-white-5',
      'bg-white-7',
      'min-h-13',
      'rounded',
      'border-2',
      'border-solid',
      'border-transparent',
      'pl-5',
      'pr-3',
      'relative',
    ]"
  >
    <div class="w-full text-xs overflow-hidden">
      <p class="uppercase font-black">{{ label }}</p>
      <p class="text-white-1 font-light no-wrap overflow-hidden overflow-ellipsis">{{ value }}</p>
    </div>

    <VueCopyToClipboard :text="value" @copy="copy">
      <Tooltip>
        <template #trigger>
          <Button
            disableMinHeight
            :rounded="false"
            color="btn-transparent-white"
            class="flex-none p-0 ml-1 rounded-full w-8 h-8 relative z-1"
          >
            <template #icon>
              <CopySVG class="w-6 h-6" />
            </template>
          </Button>
        </template>

        <template #default>
          {{ $t('copy') }}
        </template>
      </Tooltip>
    </VueCopyToClipboard>

    <transition name="fade" mode="out-in">
      <p
        v-if="copied"
        :class="[
          'flex',
          'items-center',
          'justify-center',
          'text-white-1',
          'font-bold',
          'absolute',
          'inset-0',
          'bg-blur-transition',
          'bg-blurred',
          'z-10',
        ]"
      >
        {{ $t('copied_to_clipboard') }}
      </p>
    </transition>
  </div>
</template>

<script>
import VueCopyToClipboard from 'vue-copy-to-clipboard';

import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import CopySVG from '@/assets/svgs/copy.svg';

export default {
  name: 'CopyToClipboard',
  components: { VueCopyToClipboard, Button, Tooltip, CopySVG },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copy() {
      document.activeElement.blur();
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
};
</script>

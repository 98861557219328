export default function (a, b) {
  if (a.has_local_data !== b.has_local_data) {
    if (a.has_local_data) {
      return -1;
    }

    return 1;
  }

  if (!a.has_local_data) {
    if (a.recorded_at > b.recorded_at) {
      return -1;
    }

    if (a.recorded_at < b.recorded_at) {
      return 1;
    }
  } else {
    /*
    if (a.updated_at > b.updated_at) {
      return -1;
    }

    if (a.updated_at < b.updated_at) {
      return 1;
    }
    */

    if (a.created_at > b.created_at) {
      return -1;
    }

    if (a.created_at < b.created_at) {
      return 1;
    }
  }

  if (a.version_name > b.version_name) {
    return -1;
  }

  if (a.version_name < b.version_name) {
    return 1;
  }

  return 0;
}

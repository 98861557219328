import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      submitInProgress: false,
      thankYou: false,
      currentUpload: null,
    };
  },
  computed: {
    ...mapState(['isUploading']),
  },
  methods: {
    ...mapMutations(['setKey', 'updateVersion']),
    ...mapActions(['upload']),
    triggerSubmit(versionId) {
      this.setKey({
        key: 'submitVersionId',
        submitVersionId: versionId,
      });

      this.submitInProgress = true;
    },
    cancelSubmit() {
      this.submitInProgress = false;

      this.setKey({
        key: 'submitVersionId',
        submitVersionId: null,
      });
    },
    submit() {
      this.thankYou = true;
      this.submitInProgress = false;

      this.upload({
        final: true,
        selfRecording: this.isSelfRecording,
      })
        .then((upload) => {
          this.currentUpload = upload;
        })
        .catch(() => {
          this.currentUpload = null;
          this.thankYou = false;
        });
    },
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute inset-0 group-focus-within:outline-accent"},[_c('video',{ref:"video0",staticClass:"h-full w-full",class:_vm.isVideo0Hidden && 'none'},[_c('track',{attrs:{"kind":"captions"}})]),_c('video',{ref:"video1",staticClass:"h-full w-full",class:_vm.isVideo1Hidden && 'none'},[_c('track',{attrs:{"kind":"captions"}})]),(_vm.deleted)?_c('div',{class:[
      'w-full',
      'h-full',
      'bg-red-1',
      'text-white-1',
      'flex',
      'items-center',
      'justify-center',
      'text-lg',
      'text-center',
      'px-4',
    ]},[_vm._v(" "+_vm._s(_vm.$t('record.video_deleted'))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
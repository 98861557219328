import requestFileSystemDirectory from './request_file_system_directory';
import readEntries from './read_entries';

function doFlag(version, filesNames) {
  version.files = version.files
    .filter((file) => file.type !== 'attachment')
    .map((file) => {
      const exists = filesNames.includes(file.file_name);
      file.deleted = !exists;

      return file;
    });

  return version;
}

export default function (version, existingFiles = null) {
  if (existingFiles) {
    return Promise.resolve(doFlag(version, existingFiles));
  }

  return requestFileSystemDirectory(version.recorder_token, version.speaker_id)
    .then((dirEntry) => {
      const dirReader = dirEntry.createReader();
      return readEntries(dirReader);
    })
    .then((entries) => {
      const fileNames = entries.map((entry) => entry.name);
      return doFlag(version, fileNames);
    });
}

class Timer {
  constructor(cb, delay, autostart = false) {
    this.timerId = null;
    this.startAt = null;

    this.cb = cb;
    this.delay = delay;
    this.remaining = delay;

    if (autostart) {
      this.start();
    }
  }

  pause() {
    this.clear();

    this.remaining -= window.performance.now() - this.startAt;
  }

  resume() {
    if (this.timerId) {
      return;
    }

    this.startAt = window.performance.now();
    this.timerId = setTimeout(this.cb, this.remaining);
  }

  start() {
    if (this.timerId) {
      this.reset();
      return;
    }

    this.startAt = window.performance.now();
    this.timerId = setTimeout(this.cb, this.remaining);
  }

  reset() {
    this.clear();
    this.remaining = this.delay;
    this.start();
  }

  clear() {
    if (!this.timerId) {
      return;
    }

    clearTimeout(this.timerId);
    this.timerId = null;
  }
}

export default Timer;

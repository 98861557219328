<template>
  <ContainerWithHeaderAndBack hideBack>
    <transition name="fade" mode="out-in">
      <div v-if="isInitializingCurrentVersionId" class="m-auto py-4">
        <Spinner />
      </div>

      <ThankYou v-else-if="thankYou" :upload="currentUpload" />

      <Submit
        v-else-if="submitInProgress"
        class="flex-1 flex flex-column w-full py-4"
        @cancel="cancelSubmit"
        @continue="submit"
      />

      <RecordNew
        v-else
        class="flex-1 flex flex-column w-full py-4"
        :downloadingVersionsJSONFromCloud="downloadingVersionsJSONFromCloud"
        @submit="triggerSubmitVersion"
      />
    </transition>
  </ContainerWithHeaderAndBack>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';

import ContainerWithHeaderAndBack from '@/components/ContainerWithHeaderAndBack.vue';
import Spinner from '@/components/Spinner.vue';
import Submit from '@/components/Submit.vue';
import RecordNew from '@/components/RecordNew.vue';
import ThankYou from '@/components/ThankYou.vue';

import SubmitMixin from '@/mixins/submit';
import ErrorNotificationsMixin from '@/mixins/error_notifications';

import { flagVersionDeletedFiles, parseUrlParams, mergeVersionWithUpload } from '@/helpers';

export default {
  name: 'Record',
  mixins: [SubmitMixin, ErrorNotificationsMixin],
  components: {
    ContainerWithHeaderAndBack,
    Spinner,
    Submit,
    RecordNew,
    ThankYou,
  },
  data() {
    return {
      isInitializingCurrentVersionId: true,
      downloadingVersionsJSONFromCloud: [],
    };
  },
  computed: {
    ...mapState(['currentVersionId', 'versions', 'uploads']),
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification', 'updateVersion']),
    ...mapActions(['initCurrentVersion', 'downloadVersionJSONFromCloud']),
    triggerSubmitVersion(versionId = this.currentVersionId) {
      const version = this.versions[versionId];

      if (!version.has_local_data) {
        this.downloadingVersionsJSONFromCloud = [...this.downloadingVersionsJSONFromCloud, versionId];

        const upload = this.uploads.find((u) => u.id === version.upload_id);
        const urlParams = parseUrlParams(version.share_link_url);
        const tokenId = urlParams.share;
        const shareToken = urlParams.s;

        this.downloadVersionJSONFromCloud({
          tokenId,
          shareToken,
          embed: 'yes',
        })
          .then(({ json }) => {
            json = mergeVersionWithUpload(json, upload);

            this.updateVersion({
              ...json,
              id: versionId,
              has_local_data: false,
            });

            this.triggerSubmit(versionId);
          })
          .catch((error) => {
            this.setKey({
              key: 'submitVersionId',
              submitVersionId: null,
            });

            this.notificationFromError(error);
          })
          .finally(() => {
            this.downloadingVersionsJSONFromCloud = this.downloadingVersionsJSONFromCloud.filter(
              (id) => id !== versionId,
            );
          });

        return;
      }

      flagVersionDeletedFiles(version).then((flaggedVersion) => {
        let usedFiles = flaggedVersion.timelines.speaker.video.map((c) => c[c.type].file_id);
        usedFiles = usedFiles.concat(flaggedVersion.timelines.slides.video);
        usedFiles = usedFiles.filter((value, index, self) => self.includes(value));

        if (flaggedVersion.files.some((f) => f.deleted && usedFiles.includes(f.id))) {
          this.addNotification({
            type: 'warning',
            content: this.$i18n.t('errors.trigger_submit_with_deleted_files'),
            closeable: true,
            code: 'trigger_submit_with_deleted_files',
          });
          this.updateVersion({ id: flaggedVersion.id, files: flaggedVersion.files });
        }

        this.triggerSubmit(versionId);
      });
    },
  },
  beforeMount() {
    this.isInitializingCurrentVersionId = true;

    this.setKey({
      key: 'submitVersionId',
      submitVersionId: null,
    });

    this.initCurrentVersion().then(() => {
      this.isInitializingCurrentVersionId = false;
    });
  },
};
</script>
